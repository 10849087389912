import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Stack,
  Input,
  Flex,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  HStack,
  Avatar,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Skeleton,
} from "@chakra-ui/react";
import {
  useCreateAssetMutation,
  useGetAssetsQuery,
} from "../../../api/services/assets";
import Upload from "./Upload";
import Paginator from "../../../components/Common/Pagination/Paginator";
import { useSelector } from "react-redux";
import formatBytes from "../../../helpers/textHelper";
import { formatDate } from "../../../helpers/dateHelper";
import {
  FiCalendar,
  FiGrid,
  FiImage,
  FiList,
  FiPlusSquare,
  FiTrash2,
  FiVideo,
  FiFilePlus,
} from "react-icons/fi";

const Manager = ({
  selectedAssets,
  setSelectedAssets,
  multiple = false,
  type = "asset",
}) => {
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);

  const [viewType, setViewType] = useState(
    localStorage.getItem("asset_library_view") || "list"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { data, isFetching, isLoading } = useGetAssetsQuery(
    {
      per_page: 10,
      workspace_id: currentWorkspace?.id,
      page: currentPage,
      query: query,
      asset_type: type,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleSelectAll = () => {
    if (!multiple) {
      return;
    }

    if (selectedAssets.length === data?.assets?.length) {
      setSelectedAssets([]);
    } else {
      const allRows = data?.assets || [];
      setSelectedAssets(allRows);
    }
  };

  const handleRowClick = (asset) => {
    const selectedAssetIndex = selectedAssets.findIndex(
      (selectedAsset) => selectedAsset.uuid === asset.uuid
    );
    let newSelectedAssets = [];

    if (!multiple) {
      if (selectedAssetIndex === -1) {
        return setSelectedAssets([asset]);
      } else {
        return setSelectedAssets([]);
      }
    }

    if (selectedAssetIndex === -1) {
      newSelectedAssets = [...selectedAssets, asset];
    } else {
      newSelectedAssets = [
        ...selectedAssets.slice(0, selectedAssetIndex),
        ...selectedAssets.slice(selectedAssetIndex + 1),
      ];
    }

    setSelectedAssets(newSelectedAssets);
  };

  const setViewTypeAndSave = (type) => {
    localStorage.setItem("asset_library_view", type);
    setViewType(type);
  };

  return (
    <Box w="100%">
      <VStack
        spacing={5}
        alignItems="stretch"
        maxW="100%"
        bg="white"
        borderRadius={8}
      >
        <Flex justifyContent="space-between" alignItems={"center"}>
          <Heading w={"full"} fontSize={"3xl"} as="h1" mb={0} pb={0}>
            Assets Library
          </Heading>
          <Stack
            justifyContent={"flex-end"}
            w={"full"}
            direction={["column", "row"]}
            spacing={6}
          >
            <Upload type={type} />
            <Menu>
              <MenuButton
                transition="all 0.3s"
                px={5}
                pt={1}
                _active={{ color: "white", bg: "black" }}
                as={IconButton}
                variant={"outline"}
                _focus={{ boxShadow: "none" }}
              >
                <Icon as={viewType == "grid" ? FiGrid : FiList} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  color={"gray.500"}
                  onClick={() => setViewTypeAndSave("list")}
                  icon={<Icon as={FiList} fontSize={"xl"} />}
                >
                  List
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  color={"gray.500"}
                  onClick={() => setViewTypeAndSave("grid")}
                  icon={<Icon as={FiGrid} fontSize={"xl"} />}
                >
                  Large
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>

        {data?.assets?.length > 0 && (
          <HStack justifyContent={"space-between"}>
            <Input
              maxW={"md"}
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <HStack spacing={4}></HStack>
          </HStack>
        )}
        {data?.assets?.length === 0 && (
          <VStack
            alignItems="center"
            justifyContent="center"
            minH="60vh"
            bg="gray.50"
          >
            <Icon
              as={FiFilePlus}
              boxSize={12}
              strokeWidth={1.5}
              color={"gray.300"}
            />
            <Heading
              mt={5}
              textAlign={"center"}
              fontWeight={500}
              fontSize={"xl"}
            >
              There are no files in Asset Library
            </Heading>
            <Text textAlign={"center"} color={"gray.500"} fontSize={"sm"}>
              When you upload new files they will be listed here
            </Text>
          </VStack>
        )}

        {data?.assets?.length > 0 && (
          <Skeleton isLoaded={!isLoading}>
            {viewType === "list" && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th w={"60px"}>
                      <Checkbox
                        isChecked={
                          selectedAssets.length === data?.assets?.length
                        }
                        onChange={handleSelectAll}
                        size={"lg"}
                      />
                    </Th>
                    <Th
                      color={"gray.600"}
                      fontWeight={400}
                      fontSize={"xs"}
                      textTransform={"capitalize"}
                    >
                      Filename
                    </Th>
                    <Th
                      color={"gray.600"}
                      fontWeight={400}
                      fontSize={"xs"}
                      textTransform={"capitalize"}
                    >
                      Last Modified
                    </Th>
                    <Th
                      color={"gray.600"}
                      fontWeight={400}
                      fontSize={"xs"}
                      textTransform={"capitalize"}
                    >
                      File Size
                    </Th>
                  </Tr>
                </Thead>
                <Tbody fontWeight={500}>
                  {data?.assets?.map((asset) => {
                    return (
                      <Tr
                        onClick={() => handleRowClick(asset)}
                        _hover={{ bg: "gray.50" }}
                        cursor={"pointer"}
                        key={asset.uuid}
                      >
                        <Td>
                          <Checkbox
                            isChecked={selectedAssets.some(
                              (item) => item.uuid == asset.uuid
                            )}
                            size={"lg"}
                          />
                        </Td>
                        <Td maxW={"sm"}>
                          <HStack spacing={4}>
                            <Icon
                              as={
                                asset.assetType == "image" ? FiImage : FiVideo
                              }
                            />
                            <Text>{asset.filename}</Text>
                          </HStack>
                        </Td>
                        <Td>{formatDate(asset.updatedAt)}</Td>
                        <Td>{formatBytes(asset.filesize)}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

            {viewType === "grid" && (
              <>
                <HStack spacing={5} flexWrap={"wrap"}>
                  {data?.assets?.map((asset) => {
                    return (
                      <Box
                        position={"relative"}
                        onClick={() => handleRowClick(asset)}
                        _hover={{ bg: "gray.50" }}
                        cursor={"pointer"}
                        key={asset.uuid}
                        w={"170px"}
                        h={"200px"}
                        borderRadius={"md"}
                        p={4}
                      >
                        <Checkbox
                          _hover={{ display: "block !important" }}
                          position={"absolute"}
                          display={
                            selectedAssets.some(
                              (item) => item.uuid == asset.uuid
                            )
                              ? "block"
                              : "none"
                          }
                          right={"10px"}
                          bg={"white"}
                          isChecked={selectedAssets.some(
                            (item) => item.uuid == asset.uuid
                          )}
                          size={"lg"}
                        />
                        <VStack spacing={2} alignItems={"flex-start"}>
                          {asset.thumbnailUrl && (
                            <Box w={"100px"} h={"100px"} overflow={"hidden"}>
                              <Image maxW={"100px"} src={asset.thumbnailUrl} />
                            </Box>
                          )}
                          {!asset.thumbnailUrl && (
                            <Box w={"100px"} h={"100px"}>
                              <Icon
                                color={"gray.300"}
                                fontSize={"5rem"}
                                as={
                                  asset.assetType == "image" ? FiImage : FiVideo
                                }
                              />
                            </Box>
                          )}
                          <Text
                            overflowWrap={"anywhere"}
                            noOfLines={2}
                            fontSize={"sm"}
                          >
                            {asset.filename}
                          </Text>
                          <Text fontSize={"xs"} color={"gray.500"}>
                            {formatBytes(asset.filesize)}
                          </Text>
                        </VStack>
                      </Box>
                    );
                  })}
                </HStack>
              </>
            )}
          </Skeleton>
        )}

        <Spacer h={"30px"} />

        {data?.meta?.pagination?.totalAmount >
          data?.meta?.pagination?.perPage && (
          <Paginator
            meta={data?.meta?.pagination}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </VStack>
    </Box>
  );
};

export default Manager;
