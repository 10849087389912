import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetClientQuery } from "../../api/services/shared/clients";
import { useClientToken } from "./useClientToken";


export const useClient = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useClientToken();

  const { data } = useGetClientQuery({ token: token }, { refetchOnMountOrArgChange: true });
  const client = data?.client;

  return { client };

};