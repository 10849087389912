import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useToast, VStack, Input, Button, FormControl, FormLabel, FormErrorMessage, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, HStack, Text, InputRightElement, IconButton, InputGroup } from "@chakra-ui/react";
import { useUpdateUserMutation } from '../../api/services/users';
import { FiKey, FiUnlock, FiLock } from "react-icons/fi";

const UpdatePassword = ({ disclosure }) => {
    const { register, handleSubmit, watch, formState: { errors, isValid }, reset } = useForm();
    const [updatePassword, { isSuccess, isLoading, isError, error }] = useUpdateUserMutation();
    const dataErrors = error?.data?.errors;
    const toast = useToast();
    const [showPassword, setShowPassword] = React.useState(false)
    const handleClickPassword = () => setShowPassword(!showPassword)

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
    const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

    const onSubmit = (data) => {
        updatePassword(data);
    };

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: "Password updated.",
                description: "Your password has been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            disclosure.onClose();
            reset();
        }
    }, [isSuccess]);

    return (
        <>
            <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement='right' size='sm'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Change Your Password</DrawerHeader>
                        <DrawerBody>
                            <VStack spacing={4}>
                                <FormControl isInvalid={isError && dataErrors?.old_password} mt={4}>
                                    <Text fontWeight={"semibold"} mb={3}>Old Password</Text>
                                    <Input placeholder="Old Password" type="password" {...register("oldPassword", { required: "This field is required" })} />
                                    <FormErrorMessage>{dataErrors?.old_password[0] == 'is invalid' && <>Old password is invalid</>}</FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <Text fontWeight={"semibold"} mb={3}>New Password</Text>
                                    <InputGroup>
                                        <Input placeholder="Password" {...register("password", {
                                            required: true, minLength: { value: 8, message: "Minimum length is 8 characters" },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).+$/,
                                                message: "Must include at least one lowercase letter, one uppercase letter, a number, and a special character."
                                            }
                                        })} name="password" type={showPassword ? "text" : "password"} />
                                        <InputRightElement >
                                            <IconButton size={'l'} onClick={handleClickPassword} border="none"
                                                variant={"raised"}
                                                style={{ backgroundColor: 'transparent' }}>
                                                {showPassword ? <FiLock /> : <FiUnlock />}
                                            </IconButton>
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors.password && <Text fontSize="sm" color="red" mt={1}>{errors.password.message}</Text>}
                                </FormControl>

                                <FormControl>
                                    <Text fontWeight={"semibold"} mb={3}>Confirm Password</Text>
                                    <InputGroup>
                                        <Input placeholder="Confirm Password" {...register("confirmPassword", {
                                            required: true, minLength: { value: 8, message: "Minimum length is 8 characters" },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).+$/,
                                                message: "Must include at least one lowercase letter, one uppercase letter, a number, and a special character."
                                            }, validate: (value) => value === watch('password') || 'Passwords do not match'
                                        })} name="confirmPassword" type={showConfirmPassword ? "text" : "password"} />
                                        <InputRightElement >
                                            <IconButton size={'l'} onClick={handleClickConfirmPassword} border="none"
                                                variant={"raised"}
                                                style={{ backgroundColor: 'transparent' }}>
                                                {showConfirmPassword ? <FiLock /> : <FiUnlock />}
                                            </IconButton>
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors.confirmPassword && <Text fontSize="sm" color="red" mt={1}>{errors.confirmPassword.message}</Text>}
                                </FormControl>
                            </VStack>
                            <Button type="submit" isLoading={isLoading} isDisabled={isLoading} colorScheme="blue" mt={3}>Update</Button>
                        </DrawerBody>
                    </DrawerContent>
                </form>
            </Drawer>
        </>
    );
};

export default UpdatePassword;