import React, { useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
  useToast,
  Divider,
} from '@chakra-ui/react'
import { useDeleteMediaPlanMutation, useDuplicateMediaPlanMutation } from '../../api/services/mediaPlans';

const Duplicate = ({ mediaPlan, disclosure }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = disclosure;
  const cancelRef = React.useRef();
  const [duplicateMediaPlan, { isLoading, isSuccess, isError }] = useDuplicateMediaPlanMutation();

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: "Media Plan duplicated!",
        status: "success",
      });
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast({
        description: "Error during duplicating media plan! Please contact support.",
        status: "error",
      });
    }
  }, [isError]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        size={'lg'}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Duplicate Media Plan
              <Divider mt={5} />
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody>
              Are you sure you want to duplicate <b>{mediaPlan?.name}</b> Media Plan?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='gray' _hover={{ bg: 'gray.50' }} variant={'outline'} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button isDisabled={isLoading} onClick={() => duplicateMediaPlan({ id: mediaPlan?.id })} ml={3}>
                Duplicate
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
};

export default Duplicate;