import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useGetSharedFoldersQuery } from '../../../api/services/shared/folders';
import { Accordion, Box, VStack } from '@chakra-ui/react';
import Paginator from '../../../components/Common/Pagination/Paginator';
import SidebarFoldersItem from './SidebarFoldersItem';

const SidebarFolders = () => {
  const [searchParams] = useSearchParams();
  const { uuid, organization } = useParams();
  const token = searchParams.get('token') || localStorage.getItem('token');
  const [openIndex, setOpenIndex] = useState(-1);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [search] = React.useState('');

  const { data: folders, isSuccess, isFetching } = useGetSharedFoldersQuery({ per_page: 10, page: currentPage, campaign_uuid: uuid, token: token, query: search }, { pollingInterval: 30000 });

  return (
    <>
      {uuid && isSuccess && !isFetching && <Box p={0} pl={10} mb={2}>
        <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
          <Accordion borderLeft={'solid 1px'} borderLeftColor={'gray.100'} defaultIndex={0} >
            {folders?.folders?.map((folderItem, index) => {
              return <SidebarFoldersItem organization={organization} key={folderItem.id} folderItem={folderItem} index={index} uuid={uuid} openIndex={openIndex} setOpenIndex={setOpenIndex} />
            })}
          </Accordion>

        </VStack>
      </Box >}
    </>
  );
};

export default SidebarFolders;