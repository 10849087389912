import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { } from 'react';
import DataSummary from '../../Ads/Editors/Google/Search/DataSummary';
import Story from '../../Ads/Preview/Instagram/Story';
import SingleImageVideoFacebook from '../../Ads/Preview/Facebook/SingleImageVideo';
import SingleImageVideoInstagram from '../../Ads/Preview/Instagram/SingleImageVideo';
import FacebookCarousel from '../../Ads/Preview/Facebook/Carousel';
import InstagramCarousel from '../../Ads/Preview/Instagram/Carousel';
import FacebookMetaCollection from "../../Ads/Preview/Facebook/Collection";
import InstagramMetaCollection from "../../Ads/Preview/Instagram/Collection";
import AggregatedPreview from '../../Ads/Preview/Google/Display/AggregatedPreview';
import AggregatedPreviewPmax from '../../Ads/Preview/Google/PMax/AggregatedPreview';
import AggregatedPreviewDemandGen from '../../Ads/Preview/Google/DemandGen/AggregatedPreview';
import GoogleSearchAggregatedPreview from "../../Ads/Editors/Google/Search/AggregatedPreview";
import TiktokPreview from '../../Ads/Preview/Tiktok/SingleImageVideo';
import GoogleDisplayInsights from '../../Ads/Insights/Google/DisplayInsights';
import GoogleSearchInsights from '../../Ads/Insights/Google/SearchInsights';
import PmaxInsights from '../../Ads/Insights/Google/PmaxInsights';
import FacebookInsights from '../../Ads/Insights/Facebook/Insights';
import TiktokInsights from '../../Ads/Insights/Tiktok/Insights';
import SingleImageVideoLinkedin from '../../Ads/Preview/Linkedin/SingleImageVideo';
import LinkedinCarousel from '../../Ads/Preview/Linkedin/Carousel';
import SingleImageVideoSnapchat from '../../Ads/Preview/Snapchat/SingleImageVideo';
import StorySnapchat from '../../Ads/Preview/Snapchat/Story';
import CollectionSnapchat from '../../Ads/Preview/Snapchat/Collection';

const Previews = ({ platform, ad, selectedPreviewVariation, refreshFlag, snake_case_ad }) => {

  return (
    <>
      {/* Add the rest of your content here based on platform */}
      {platform === 'meta' && (
        <Box mt={7}>
          {ad?.metadata?.adType === 'single_image_video' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <SingleImageVideoFacebook ad={ad?.metadata}
                instantExperienceData={snake_case_ad} />
              <SingleImageVideoInstagram ad={ad?.metadata}
                instantExperienceData={snake_case_ad} />
            </HStack>
          )}

          {ad?.metadata?.adType === 'story' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <Story ad={ad?.metadata}
                instantExperienceData={snake_case_ad} />
            </HStack>
          )}

          {ad?.metadata?.adType === 'carousel' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <FacebookCarousel ad={ad?.metadata}
                instantExperienceData={snake_case_ad} />
              <InstagramCarousel ad={ad?.metadata}
                instantExperienceData={snake_case_ad} />
            </HStack>
          )}
          <FacebookInsights insight={ad.adInsight} />
        </Box>
      )}
      {platform === 'meta_instant_experience' && (
        <Box mt={7}>
          {ad?.metadata?.adType === 'collection' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <Box>
                <FacebookMetaCollection ad={ad?.metadata}
                  instantExperienceData={snake_case_ad} />
              </Box>
              <Box>
                <InstagramMetaCollection ad={ad?.metadata}
                  instantExperienceData={snake_case_ad} />
              </Box>
            </HStack>
          )}
          <FacebookInsights insight={ad.adInsight} />
        </Box>
      )}
      {platform === 'google_search' && (
        <>
          <HStack minW={'full'} alignItems={'flex-start'} spacing={10}>
            <Box mt={7} w={'full'}>
              <DataSummary headlines={ad?.metadata?.headlines?.map((item) => item.text)} descriptions={ad?.metadata?.descriptions?.map((item) => item.text)}></DataSummary>
            </Box>
            <Box mt={7} w={'full'}>
              <VStack spacing={10}>
                <GoogleSearchAggregatedPreview
                  refreshFlag={refreshFlag}
                  selectedVariation={Number(selectedPreviewVariation?.value)}
                  businessName={ad?.metadata?.businessName}
                  baseUrl={ad?.metadata?.finalUrl}
                  display_path_1={ad?.metadata?.displayPath_1}
                  display_path_2={ad?.metadata?.displayPath_2}
                  headlines={ad?.metadata?.headlines?.map((item) => item.text)}
                  descriptions={ad?.metadata?.descriptions?.map((item) => item.text)}
                  calls={ad?.metadata?.calls}
                  callouts={ad?.metadata?.callouts}
                  sitelinks={ad?.metadata?.sitelinks}
                  snippets={ad?.metadata?.snippets}
                />
              </VStack>
            </Box>
          </HStack>
          <GoogleSearchInsights insight={ad.adInsight} />
        </>
      )}
      {platform == 'google_display' && <Box mt={7}>
        <HStack alignItems={'flex-start'} spacing={10}>
          <AggregatedPreview metadata={{
            headlines: ad.metadata.headlines?.map((item) => item.text),
            descriptions: ad.metadata.descriptions?.map((item) => item.text),
            longHeadline: ad.metadata.longHeadline,
            businessName: ad.metadata.businessName,
            callToAction: ad.metadata.callToAction,
            images: ad.metadata.images,
            logos: ad.metadata.logos,
            videos: ad.metadata.videos,
          }} />
        </HStack>

        <GoogleDisplayInsights insight={ad.adInsight} />
      </Box>}

      {platform == 'google_pmax' && <Box mt={7}>
        <HStack alignItems={'flex-start'} spacing={10}>
          <AggregatedPreviewPmax metadata={{
            headlines: ad.metadata.headlines?.map((item) => item.text),
            descriptions: ad.metadata.descriptions?.map((item) => item.text),
            longHeadline: ad.metadata.longHeadline,
            businessName: ad.metadata.businessName,
            finalUrl: ad.metadata.finalUrl,
            callToAction: ad.metadata.callToAction,
            images: ad.metadata.images,
            logos: ad.metadata.logos,
            videos: ad.metadata.videos,
            sitelinks: ad.metadata?.sitelinks?.map((item) => item.text),
            snippets: ad.metadata?.snippets?.map((item) => item.text),
          }} />
        </HStack>

        <PmaxInsights insight={ad.adInsight} />
      </Box>}

      {platform == 'google_demandgen' && <Box mt={7}>
        <HStack alignItems={'flex-start'} spacing={10}>
          <AggregatedPreviewDemandGen metadata={{
            headlines: ad.metadata.headlines?.map((item) => item.text),
            descriptions: ad.metadata.descriptions?.map((item) => item.text),
            longHeadlines: ad.metadata.longHeadlines?.map((item) => item.text),
            businessName: ad.metadata.businessName,
            finalUrl: ad.metadata.finalUrl,
            callToAction: ad.metadata.callToAction,
            images: ad.metadata.images,
            logos: ad.metadata.logos,
            videos: ad.metadata.videos,
            adType: ad.metadata.adType,
            carouselItems: ad.metadata.googleDemandgenAdCarouselItems
          }} />
        </HStack>

        <PmaxInsights insight={ad.adInsight} />
      </Box>}

      {platform == 'tiktok' && <Box mt={7}>
        <HStack alignItems={'flex-start'} spacing={10}>
          <TiktokPreview ad={ad?.metadata} />
        </HStack>

        <TiktokInsights insight={ad.adInsight} />
      </Box>}

      {platform === 'linkedin' && (
        <Box mt={7}>
          {ad?.metadata?.adType === 'single_image_video' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <SingleImageVideoLinkedin ad={ad?.metadata} />
            </HStack>
          )}
          {ad?.metadata?.adType === 'carousel' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <LinkedinCarousel ad={ad?.metadata} />
            </HStack>
          )}
        </Box>)}

      {platform === 'snapchat' && (
        <Box mt={7}>
          {ad?.metadata?.adType === 'single_image_video' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <SingleImageVideoSnapchat ad={ad?.metadata} />
            </HStack>
          )}

          {ad?.metadata?.adType === 'story' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <StorySnapchat ad={ad?.metadata} />
            </HStack>
          )}

          {ad?.metadata?.adType === 'collection' && (
            <HStack alignItems={'flex-start'} spacing={10}>
              <CollectionSnapchat ad={ad?.metadata} />
            </HStack>
          )}
        </Box>)}
    </>
  )
}

export default Previews;