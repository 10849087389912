import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LuCheckCircle } from "react-icons/lu";
import { Button, Box, Text, Heading, Icon, Spacer, Center, Divider } from "@chakra-ui/react";
import { useConfirmUserMutation } from '../../api/services/users';
import { FiCheckCircle, FiLogIn, FiRefreshCw } from 'react-icons/fi';

const Congratulations = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [confirmUser] = useConfirmUserMutation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            confirmUser({ confirmationToken: token, update: true })
                .unwrap()
                .catch(() => {
                    // navigate('/oops');
                });
        }

    }, [location, confirmUser]);

    return (
        <Center mt={10}>
            <Box textAlign="center">
                <Icon fontSize={'2rem'} color={'green'} as={FiCheckCircle} />
                <Text fontSize="lg" fontWeight={'bold'} mt={10}>
                    Congratulations! 🎉
                </Text>
                <Text fontSize="sm" mt={5}>
                    Your account has been activated successfully. You may now log in to WhatCampaign app.
                </Text>

                <Spacer height={10} />
                <Button leftIcon={<Icon as={FiLogIn} />} onClick={() => navigate('/login')} variant={'ai'} >Log in</Button>
                <Spacer my={10} />
            </Box>
        </Center>
    );
};

export default Congratulations;
