import React, { useEffect } from 'react';
import { Box, Heading, VStack, Flex, Stack, Button, useDisclosure, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, MenuDivider, useToast, HStack, Tag, Text } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { useGetMediaPlanQuery } from '../../api/services/mediaPlans';
import MediaPlanSettings from './MediaPlanSettings';
import { FiCheckCircle, FiMessageSquare, FiMoreVertical, FiPlus, FiSettings, FiTrash } from 'react-icons/fi';
import SimpleTable from './Simple/SimpleTable'
import { useCreateMediaPlanCampaignMutation } from '../../api/services/mediaPlanCampaigns';
import Comments from '../Comments/Comments';
import { FaCircle } from 'react-icons/fa';
import ApprovalRequest from './ApprovalRequest';
import Budget from './Components/Budget';
import Delete from './Delete';

const MediaPlan = () => {
    const { id } = useParams();
    const toast = useToast();
    const { data, error, isLoading, isSuccess } = useGetMediaPlanQuery(id);
    const mediaPlan = data?.mediaPlan;
    const isApproved = mediaPlan?.status == 'approved';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const deleteDisclosure = useDisclosure();

    const commentsDisclosure = useDisclosure();

    const [createCampaign, { isSuccess: isSuccessCreate, isLoading: isloadingCreate }] = useCreateMediaPlanCampaignMutation();

    useEffect(() => {
        if (isSuccessCreate) {
            toast({
                title: "Campaign created.",
                description: "The campaign has been successfully created.",
                status: "success",
                duration: 5000,
                isClosable: true,

            })
        }
    }, [isSuccessCreate])

    return (
        <>
            {isSuccess && <>
                <Box mt={5} px={10}>
                    <Box w='100%'>
                        <VStack spacing={0} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
                            <Flex mb={10} justifyContent='space-between' alignItems={'center'}>
                                <HStack justifyContent={'flex-start'} w={'full'}>
                                    <Heading fontSize={'2xl'} as="h1" mb={0} pb={0}>
                                        {data?.mediaPlan?.name}
                                    </Heading>
                                    <Tag fontSize={'xs'} ml={5} colorScheme='gray'>Version {data?.mediaPlan?.version}</Tag>
                                </HStack>

                                <HStack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                                    {!isApproved && <Button size={'sm'} isLoading={isloadingCreate} onClick={() => createCampaign({ mediaPlanId: mediaPlan?.id })} leftIcon={<Icon as={FiPlus} />}>Add Campaign</Button>}

                                    <Button size={'sm'} rightIcon={data?.mediaPlan?.hasComments ? <Icon color={'orange'} fontSize={'10px'} as={FaCircle} /> : <></>} variant={'outline'} size={'sm'} onClick={commentsDisclosure.onOpen} leftIcon={<Icon as={FiMessageSquare} />}>Comments</Button>

                                    <ApprovalRequest mediaPlan={mediaPlan} />
                                    <Menu>
                                        <MenuButton
                                            transition="all 0.3s"
                                            paddingY={'7px'}
                                            _active={{ color: "white", bg: "black" }}
                                            as={IconButton}
                                            variant={"outline"}
                                            _focus={{ boxShadow: "none" }}
                                        >
                                            <Icon as={FiMoreVertical} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem icon={<Icon as={FiSettings} />} onClick={onOpen}>
                                                Settings
                                            </MenuItem>
                                            <MenuDivider />
                                            <MenuItem icon={<Icon as={FiTrash} />} onClick={deleteDisclosure.onOpen} >
                                                Delete
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </HStack>
                            </Flex>
                        </VStack>

                        {isApproved && <Tag mb={5} colorScheme='green'>
                            <HStack>
                                <Icon as={FiCheckCircle} />
                                <Text>Approved</Text>
                            </HStack>
                        </Tag>}

                        <Budget mediaPlan={mediaPlan} />
                    </Box>
                </Box>

                <Box overflow={'scroll'} px={10}>
                    <SimpleTable readOnly={isApproved} mediaPlan={mediaPlan} />
                </Box>

                <MediaPlanSettings isOpen={isOpen} onClose={onClose} />
                <Delete mediaPlan={mediaPlan} disclosure={deleteDisclosure} />

                {commentsDisclosure.isOpen && <Comments allowCommenting mediaPlan={mediaPlan} disclosure={commentsDisclosure} />}
            </>}
        </>
    );
};
export default MediaPlan;