import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
} from '@chakra-ui/react';
import CalloutCard from './CalloutCard';

function Callouts({ isOpen, onClose, initialCallouts, setInitialCallouts }) {
  const newCallout = { text: '' }
  const [callouts, setCallouts] = useState(initialCallouts.length > 0 ? [...initialCallouts] : [newCallout]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedCalls = [...callouts];
    updatedCalls[index] = { ...updatedCalls[index], [name]: value }
    setCallouts(updatedCalls);
  };

  const handleAddCallout = () => {
    if (callouts.length < 10) {
      setCallouts([...callouts, newCallout]);
    }
  };

  const handleRemoveCallout = (index) => {
    const updatedCalls = callouts.filter((_, i) => i !== index);
    setCallouts(updatedCalls);
  };

  const handleSave = () => {
    setInitialCallouts(callouts);
    onClose();
  };

  const handleCancel = () => {
    setCallouts([...initialCallouts]);
    onClose();
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add more business information</DrawerHeader>
          <DrawerBody>
            <Stack spacing='24px'>
              {callouts.map((callout, index) => (
                <CalloutCard
                  key={index}
                  callout={callout}
                  index={index}
                  handleInputChange={handleInputChange}
                  handleRemoveCallout={handleRemoveCallout}
                />
              ))}
            </Stack>
            <Button
                colorScheme='gray'
                variant={'outline'}
                mt={3}
                onClick={handleAddCallout}
                isDisabled={callouts.length >= 10}
              >
                Add callout
              </Button>
          </DrawerBody>
          <DrawerFooter align="start" borderTopWidth='1px'>
            <Button colorScheme='gray' mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button colorScheme='red' variant='outline' onClick={handleCancel}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Callouts;
