import React from 'react';
import {
  Table,
  Box,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
  useToast,
  Spacer,
  HStack
} from '@chakra-ui/react'
import Mapper from '../Components/TableFields/Mapper'
import { useDeleteMediaPlanCampaignMutation, useGetMediaPlanCampaignsQuery } from '../../../api/services/mediaPlanCampaigns';
import { FiTrash2, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { tableColumns } from './columns'
import { platformSupported } from './platformSupported'
import { useSelector } from 'react-redux';

const SimpleTableContents = ({ mediaPlan, mediaPlanCampaigns = {}, readOnly = false, canDelete = false }) => {
  const toast = useToast();
  const mediaPlanId = mediaPlan.id;

  const [deleteCampaign, { isLoading }] = useDeleteMediaPlanCampaignMutation();
  const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });

  const mediaPlanVisibleColumns = mediaPlan?.columnSettings?.shown || [];
  const mediaPlanHiddenColumns = mediaPlan?.columnSettings?.hidden || [];

  const defaultShownItems = [
    ...tableColumns.filter((column) => mediaPlanVisibleColumns.includes(column.name)),
    ...mediaPlan.customFields?.filter((field) => mediaPlanVisibleColumns.includes(field.name))
  ];

  const sortShownItems = defaultShownItems.sort((a, b) => mediaPlanVisibleColumns.indexOf(a.name) - mediaPlanVisibleColumns.indexOf(b.name));
  const visibleColumns = sortShownItems?.length > 0 ? sortShownItems : (mediaPlanHiddenColumns?.length > 0 ? [] : [...tableColumns, ...mediaPlan.customFields]);

  const sortedData = React.useMemo(() => {
    let sortableItems = [...(mediaPlanCampaigns?.mediaPlanCampaigns || [])];
    const columnType = tableColumns.find(column => column.name === sortConfig.key)?.type;

    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (columnType === 'dropdown') {
          const columnValues = tableColumns.find(column => column.name === sortConfig.key)?.values || [];
          aValue = columnValues.find(value => value.name === aValue)?.label || aValue;
          bValue = columnValues.find(value => value.name === bValue)?.label || bValue;
        }

        let aIsNumeric = !isNaN(parseFloat(aValue)) && isFinite(aValue);
        let bIsNumeric = !isNaN(parseFloat(bValue)) && isFinite(bValue);

        if (columnType === 'number' || (aIsNumeric && bIsNumeric)) {
          return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        } else {
          const aStrValue = String(aValue).toLowerCase();
          const bStrValue = String(bValue).toLowerCase();

          if (aStrValue < bStrValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aStrValue > bStrValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;
  }, [mediaPlanCampaigns, sortConfig, tableColumns]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const platformSupportsValue = (platform, valueName) => {
    const supportedValues = platformSupported[platform];
    return supportedValues ? supportedValues.includes(valueName) : false;
  };

  return (
    <>
      <TableContainer minH={'md'}>
        <Table variant='bordered' overflow={'visible'}>
          {/* <TableCaption>Displaying 3 out of 5</TableCaption> */}
          <Thead>
            <Tr fontWeight={'bold'}>
              {visibleColumns?.map((column) => (
                <Th key={column.name} fontWeight={'bold'} onClick={() => requestSort(column.name)} cursor={'pointer'}>
                  <HStack>

                    <Text>{column.label}</Text>
                    {sortConfig.key === column.name ? (
                      sortConfig.direction === 'ascending' ? <Icon as={FiChevronUp} /> : <Icon as={FiChevronDown} />) : (<Icon as={FiChevronUp} />)}
                  </HStack>
                </Th>))}
              {canDelete && <Th fontWeight={'bold'}>Delete</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.map((campaign) => (
              <Tr _hover={{ bg: readOnly ? 'gray.50' : 'white' }} key={campaign.id}>
                {visibleColumns.map((column) => {

                  let columnValues = column?.values;
                  if (column.name === 'adFormat') {
                    const platform = campaign['platform'];
                    if (platform) {
                      columnValues = columnValues.filter(value => platformSupportsValue(platform, value.name));
                    } else {
                      columnValues = columnValues.filter(value => value.name === 'singleimagevideo' || value.name === 'carousel');
                    }
                  }

                  if (column.meta?.addonLabel == 'CURRENCY') {
                    column = { ...column, meta: { ...column.meta, addonLabel: mediaPlan.currencyCode } };
                  }

                  return (
                    <Td key={column.name}>
                      <Mapper
                        mediaPlanId={mediaPlanId}
                        campaignId={campaign.id}
                        name={column.name}
                        value={campaign[column.name] || campaign.customFields[column.name?.replace('custom_field', 'customField')]}
                        values={columnValues}
                        type={column.type}
                        meta={column.meta}
                        readOnly={readOnly}
                      />
                    </Td>
                  );

                })}
                {canDelete && <Td>
                  <Box>
                    <IconButton
                      onClick={() => {
                        deleteCampaign({ mediaPlanId: mediaPlanId, mediaPlanCampaignId: campaign.id });
                        toast({
                          title: 'Media plan campaign deleted!',
                          description: 'The media plan campaign has been successfully deleted.',
                          status: 'success',
                          duration: 5000,
                          isClosable: true,
                        });
                      }}
                      p={2}
                      isLoading={isLoading}
                      variant={'outline'}
                      colorScheme='red'
                      icon={<Icon as={FiTrash2} />}
                      aria-label={''}
                    ></IconButton>
                  </Box>
                </Td>}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Spacer mt={10} h={'250px'} />

      </TableContainer >
    </>
  )
};

export default SimpleTableContents;