import { Box, Button, Card, CardBody, HStack, Heading, Image, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import MetaInstantExperienceBlankImage from '../../../../../assets/images/meta_instant_experience_image.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { transformKeysTo } from '../../../../../helpers/apiHelper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './CustomSwiperStyles.css';
import { FiArrowLeft } from 'react-icons/fi';

const SellProductsPreview = ({ instantExperienceData, setInstantExperienceOpenedIfPossible }) => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const carouselItems = instantExperienceData?.metaInstantExperienceCarouselItems;
    const transformedCarouselItems = carouselItems?.map(item => transformKeysTo('snake_case', structuredClone(item)));
    const instantExperienceAsset = transformKeysTo('camel', structuredClone(instantExperienceData?.instantExperienceAsset));

    return (
        <>
            <Box overflow={'hidden'} height='auto'>
                <Button ml={-2} background={'none'} color={'black'} leftIcon={<FiArrowLeft color="gray.700" />} onClick={() => setInstantExperienceOpenedIfPossible(false)}>
                </Button>
                {instantExperienceAsset == null && <Image w={'full'} src={MetaInstantExperienceBlankImage} />}

                {instantExperienceAsset?.assetType == 'image' && <Box overflow={'hidden'} objectFit={'fill'}>
                    <Image w={'full'} src={instantExperienceAsset?.fileUrl} />
                </Box>}
                {instantExperienceAsset?.assetType == 'video' && !instantExperienceAsset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                    <video key={instantExperienceAsset?.uuid} muted width={'100%'} height="auto" loop autoPlay>
                        <source src={instantExperienceAsset?.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>}
                {instantExperienceAsset?.assetType == 'video' && instantExperienceAsset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                    <iframe width="100%" height="315" src={instantExperienceAsset?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Box>}
            </Box>
            <Text textAlign={'center'}>{instantExperienceData?.description_1}</Text>
            <Text textAlign={'center'}>{instantExperienceData?.description_2}</Text>
            <Box w="100%">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    centeredSlidesBounds
                    centeredSlides
                    grabCursor
                    slidesPerView={1}
                    freeMode
                    spaceBetween={35}
                    scrollbar={{ draggable: true }}
                    style={{ padding: '0rem' }}
                    navigation
                >
                    {transformedCarouselItems && transformedCarouselItems.map((item, index) => {
                        const isYouTubeUrl = /youtube/.test(item.asset?.fileUrl);
                        const youTubeEmbedUrl = isYouTubeUrl ? item.asset?.fileUrl.replace('watch?v=', 'embed/') : item.asset?.fileUrl;

                        return (
                            <SwiperSlide style={{ padding: '0rem' }} key={item.uuid}>
                                <Box height="auto" overflow={'hidden'}>
                                    {item.asset == null && <Image borderTopRadius={'md'} w={'100%'} src={MetaInstantExperienceBlankImage} />}
                                    {item.asset?.assetType == 'image' && <Image borderTopRadius={'md'} w={'100%'} h={'400px'} objectFit={'fill'} src={item?.asset?.fileUrl} />}
                                    {item.asset?.assetType == 'video' && !isYouTubeUrl && <Box maxH={'400px'} overflow={'hidden'}>
                                        <video key={item?.asset?.uuid} controls muted width={'100%'} height="auto" loop>
                                            <source src={item?.asset?.fileUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>}
                                    {item.asset?.assetType == 'video' && isYouTubeUrl && <Box maxH={'400px'} overflow={'hidden'}>
                                        <iframe width="100%"
                                            height="315"
                                            src={youTubeEmbedUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </Box>}
                                </Box>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Box>
            <Button variant="outline" w="100%" onClick={() => openInNewTab(instantExperienceData?.buttonDestination_1)}>{instantExperienceData?.buttonLabel_1}</Button>
        </>
    );
};

export default SellProductsPreview;