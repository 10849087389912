import React, { useState } from "react";
import AssetsLibraryManager from "./Manager/Manager";
import { Box } from "@chakra-ui/react";

const Dashboard = () => {
  const [assets, setAssets] = useState([]);

  return (
    <>
      <Box p={10}>
        <AssetsLibraryManager selectedAssets={assets} setSelectedAssets={setAssets} type="all" />
      </Box>
    </>
  )
};

export default Dashboard;