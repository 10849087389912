import { Image, Button, Icon, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, InputGroup, Avatar, Textarea, FormErrorMessage, Stack, FormHelperText, useDisclosure } from "@chakra-ui/react";
import React, { useContext } from "react";
import { FiHelpCircle, FiImage, FiVideo } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import EditorContext from "../../../../contexts/EditorContext";
import { snapchatCallToActions } from "../../../../components/Constants/snapchat_call_to_actions";
import AssetsLibraryButton from "../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { HiOutlineChip } from "react-icons/hi";
import Suggestions from "../../../Suggestions/Suggestions";
import SuggestionsButton from "../../../Suggestions/SuggestionsButton";
import { validateUrl } from "../../../../components/utils/urlUtils";
const CommonEditor = ({ ad, onFormSubmit, isLoading, register, watch, handleSubmit, getValues, setValue, assetsState, snapchatLogoState }) => {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const brandName = watch('brand_name');
    const headline = watch('headline');

    const { errors } = useContext(EditorContext);

    const wAll = watch();

    const [assets, setAssets] = assetsState;
    const [snapchatLogo, setSnapchatLogo] = snapchatLogoState;

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('asset_id', assets[0]?.id);
        formData.append('snapchat_logo_asset_id', snapchatLogo[0]?.id);

        Object.keys(data).forEach((key) => {
            if (data[key] == null)
                return;

            formData.append(key, data[key]);
        });

        onFormSubmit(formData);
    }

    /** AI Suggestions */
    const aiDrawer = useDisclosure();

    const updateHeadline = (headlines) => {
        setValue('headline', headlines[0]);
    };


    return (
        <>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={5} >

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Identity</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                            <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>

                        </CardHeader>
                        <CardBody>
                            <FormControl mt={2} isInvalid={errors?.snapchat_account_name}>
                                <FormLabel>Public Profile</FormLabel>
                                <HStack spacing={5}>
                                    <AssetsLibraryButton assets={snapchatLogo} setAssets={setSnapchatLogo} type={'image'} rounded />
                                    <Input {...register('snapchat_account_name')} w={'full'} placeholder={'Account name'} />
                                </HStack>
                                <FormErrorMessage justifyContent={'flex-end'}>{errors?.snapchat_account_name}</FormErrorMessage>
                            </FormControl>
                        </CardBody>
                    </Card>

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Media</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

                            <HStack>
                                <AssetsLibraryButton assets={assets} setAssets={setAssets} types={['all']} />
                            </HStack>

                            <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                                <AssetsLibraryPreview assets={assets} setAssets={setAssets} />
                            </Stack>
                        </CardBody>
                    </Card>


                    <Card w={'full'}>
                        <CardHeader>
                            <HStack justifyContent={'space-between'}>
                                <HStack>
                                    <Text>Content</Text>
                                    <Icon as={FiHelpCircle} />
                                </HStack>
                                <SuggestionsButton updateHeadline={updateHeadline} />
                            </HStack>
                        </CardHeader>
                        <CardBody>

                            <VStack spacing={5}>
                                <FormControl>
                                    <FormLabel>Brand Name (Optional)</FormLabel>
                                    <Input {...register('brand_name')} placeholder={'Add brand name here...'} maxLength={32} />
                                    <FormHelperText color={(getValues('brand_name')?.length || 0) > 32 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('brand_name')?.length || 0} / 32</FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Headline</FormLabel>
                                    <Input {...register('headline')} placeholder={'Add headline here...'} maxLength={34} />
                                    <FormHelperText mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                                        {(headline?.trim()?.toLowerCase() || '') === (brandName?.trim()?.toLowerCase() || '') ? <Text color={'red'}>Headline cannot be the same as Brand Name</Text> : <Text color={'#96999F'}>{`${getValues('headline')?.length || 0} / 34`}</Text>}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Website URL</FormLabel>

                                    
                                    <Input {...register('website_url')} placeholder={'Add website URL here...'} 
                                    onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue('website_url', validatedUrl);
                                    }}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel> Call To Action</FormLabel>
                                    <Select {...register('call_to_action')}>
                                        <option value={''}>No Button</option>
                                        {snapchatCallToActions.map((cta, index) => (
                                            <option value={cta.value} key={index}>{cta.label}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </VStack>
                        </CardBody>
                    </Card>

                    <HStack w={'full'} justifyContent={'flex-start'}>
                        <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
                        <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
                    </HStack>
                </VStack>
            </form>
        </>
    )
}

export default CommonEditor;