import React, { useEffect } from 'react';
import { Box, Heading, Input, Button, Alert, AlertIcon, ScaleFade, Text, Icon, Stack, Spacer, HStack, Grid, IconButton, InputGroup, InputRightElement, VStack, useEditable } from "@chakra-ui/react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Link as ReachLink } from 'react-router-dom';
import { FiCheckCircle, FiCircle, FiLock, FiSend, FiUnlock, FiUserPlus } from 'react-icons/fi';
import { useSavePasswordMutation } from '../../api/services/users';


const ResetPassword = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const [show, setShow] = React.useState(false);
  const { register, handleSubmit, watch, formState: { isValid, errors } } = useForm();
  const [savePassword, { isSuccess, isError, isLoading }] = useSavePasswordMutation();

  const onSubmit = async data => {
    savePassword({ ...data, token });
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [isSuccess])

  return (
    <>
      <Spacer />
      <Box w={'full'} justifyContent={'center'} p={10} display={'flex'}>
        <Stack flexDir={['column-reverse', 'row']} maxW={'container.xl'} direction={['column', 'row']} gap={'7rem'}>
          <Box w={['full', '2xl']} mt={10} >
            <Heading as="h3" fontWeight={400} size="xl">Simplify Your Digital Advertising Journey - From Concept to Launch</Heading>
            <Text color="gray.400" mt={5}>Navigate the complexities of digital ad creation with ease. Our platform bridges the gap between advertising agencies and their clients, ensuring a seamless and understandable journey from the initial concept to the final launch.</Text>
          </Box>

          <Box w={'full'}>
            <Heading as="h2" fontWeight={400} textAlign="center" mb={6}>
              Reset your password
            </Heading>

            <Text fontSize={'sm'} color={'gray.500'} mb={5}>
              Please provide new password for your account. After you save the password, you will be able to login with your new password.
            </Text>

            {isSuccess && <>
              <ScaleFade initialScale={0.9} in={isSuccess}>
                <Alert variant={'top-accent'} mt={5} mb={5} borderRadius={'md'} fontSize={'sm'} status='success'>
                  <AlertIcon />
                  Your password has been successfully updated. You can now login with your new password. Redirecting to login page in 3 seconds...
                </Alert>
              </ScaleFade>
            </>}

            {isError && <>

              <ScaleFade initialScale={0.9} in={isError}>
                <Alert variant={'top-accent'} mt={5} mb={5} borderRadius={'md'} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  An error occurred while updating your password. Please try again later.
                </Alert>
              </ScaleFade>
            </>}

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid mt={4} templateColumns="repeat(2, 1fr)" gap={7}>
                <Box>
                  <Text fontSize={'sm'} mb={3}>Password</Text>
                  <Box>
                    <InputGroup size='md' >
                      <Input
                        placeholder='Password'
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register("password", {
                          required: true, minLength: { value: 8, message: "Minimum length is 8 characters" },
                          pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).+$/, message: "Must include at least one lowercase letter, one uppercase letter, a number, and a special character." }
                        })}
                        name="password"
                        type={show ? 'text' : 'password'}
                      />
                      <InputRightElement height="full" width='4.5rem'>
                        <IconButton size={'l'} onClick={() => setShow(!show)} border="none"
                          variant={"raised"}
                          style={{ backgroundColor: 'transparent' }} aria-label={''}>
                          {show ? <FiLock /> : <FiUnlock />}
                        </IconButton>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && (<Text fontSize="xs" color="red" mt={1}>{errors.password.message}</Text>)}
                  </Box>
                </Box>
                <Box>
                  <Text fontSize={'sm'} mb={3}>Confirm Password</Text>
                  <Input placeholder="Confirm Password"
                    {...register("confirmPassword",
                      { required: 'Confirm Password is required', validate: (value) => value === watch('password') || 'Passwords do not match' })}
                    name="confirmPassword"
                    type={show ? 'text' : 'password'} />
                  {errors.confirmPassword && <Text fontSize="xs" color="red" mt={1}>{errors.confirmPassword.message}</Text>}
                </Box>
              </Grid>

              <VStack w={'full'} alignItems={'flex-start'} mt={4} spacing={1}>

                <HStack >
                  {watch('password')?.length >= 8 && <Icon as={FiCheckCircle} fontSize={'x-small'} color="green.500" />}
                  {(watch('password')?.length < 8 || watch('password') == undefined) && <Icon as={FiCircle} fontSize={'x-small'} color="red.500" />}
                  <Text fontSize={'x-small'}>Must contain at least 8 characters.</Text>
                </HStack>
                <HStack>
                  {watch('password')?.match(/^(?=.*[A-Z])/) && <Icon as={FiCheckCircle} fontSize={'x-small'} color="green.500" />}
                  {!watch('password')?.match(/^(?=.*[A-Z])/) && <Icon as={FiCircle} fontSize={'x-small'} color="red.500" />}
                  <Text fontSize={'x-small'}>Must contain uppercase letter.</Text>
                </HStack>
                <HStack>
                  {watch('password')?.match(/^(?=.*[0-9])/) && <Icon as={FiCheckCircle} fontSize={'x-small'} color="green.500" />}
                  {!watch('password')?.match(/^(?=.*[0-9])/) && <Icon as={FiCircle} fontSize={'x-small'} color="red.500" />}
                  <Text fontSize={'x-small'}>Must contain a number.</Text>
                </HStack>
                <HStack>
                  {watch('password')?.length > 0 && watch('password') == watch('confirmPassword') && <Icon as={FiCheckCircle} fontSize={'x-small'} color="green.500" />}
                  {(watch('password') == '' || watch('password') == undefined || watch('password') != watch('confirmPassword')) && <Icon as={FiCircle} fontSize={'x-small'} color="red.500" />}
                  <Text fontSize={'x-small'}>Confirm password must match.</Text>
                </HStack>
              </VStack>

              <HStack mt={4} justifyContent="space-between">
                <Button leftIcon={<Icon as={FiUnlock} />} isLoading={isLoading} isDisabled={!isValid} type="submit" colorScheme="brand" width="auto">
                  Save password
                </Button>
                <HStack>
                  <Button as={ReachLink} to="/login" leftIcon={<Icon as={FiUserPlus} />} variant="outline" colorScheme="brand" size="sm">Sign In</Button>
                </HStack>
              </HStack>
            </form>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ResetPassword;
