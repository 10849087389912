import React from 'react';
import { Box, HStack, Text } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import SimpleTable from '../Simple/Public/SimpleTable';
import Budget from '../Components/Budget';
import { useClientToken } from '../../../components/Hooks/useClientToken';
import { useGetPublicMediaPlanQuery } from '../../../api/services/public/mediaPlans';

const PublicMediaPlan = () => {
  const { uuid, organization } = useParams();


  const { data, isSuccess } = useGetPublicMediaPlanQuery({ uuid: uuid }, { refetchOnMountOrArgChange: true });
  const mediaPlan = data?.mediaPlan;

  return (
    <>
      {isSuccess && <Box p={10} minHeight="100vh" >

        <HStack mb={10} w={'full'} justifyContent={'space-between'}>
          <Text mb={5} fontSize={'lg'} fontWeight={'semibold'}>{mediaPlan?.name}</Text>
        </HStack>

        <Budget mediaPlan={mediaPlan} />

        <Box overflow={'scroll'} px={0}>
          <SimpleTable mediaPlan={mediaPlan} />
        </Box>
      </Box>}
    </>
  );
};

export default PublicMediaPlan;