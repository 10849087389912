import React, { useEffect } from "react";

import { HStack, Icon, Image, Text, Avatar, SkeletonText, VStack, Box } from "@chakra-ui/react";
import { FiMenu, FiSearch } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import Display1 from "./Fullscreen/Display1";
import Display2 from "./Fullscreen/Display2";
import Display3 from "./Fullscreen/Display3";

const FullscreenAd = ({ variation, isDesktop = false }) => {

  const [style, setStyle] = React.useState(<Display1 variation={variation} />);

  const randomize = () => {
    const styles = [
      <Display1 variation={variation} />,
      <Display2 variation={variation} />,
      <Display3 variation={variation} />
    ];

    const random = Math.floor(Math.random() * styles.length);
    setStyle(styles[random]);
  }

  useEffect(() => {
    randomize();
  }, [variation])

  return (
    <>
      {isDesktop && <>
        <Box mx={'auto'} mt={3} maxW={'sm'}>
          {style}
        </Box>
      </>}

      {!isDesktop && <Phone>
        {style}
      </Phone>}
    </>
  )
};

export default FullscreenAd;