import React from "react";

import HomePageAd from "./YouTube/HomePageAd1";
import HomePageAd2 from "./YouTube/HomePageAd2";
import WatchPageAd from "./YouTube/WatchPageAd";
import VideoInStreamAd from "./YouTube/VideoInStreamAd";
import YoutubeAd from "./YouTube/YoutubeAd";
import { Box, Center, HStack, Icon, IconButton, useSteps } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import DesktopVideoInStreamAd from "./YouTube/DesktopVideoInStreamAd";

const Youtube = ({ variations, displayType }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 4
  })

  return (
    <>
      {displayType == 'mobile' && <>
        <Center>
          <HStack spacing={10} mb={4}>
            <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
            <IconButton isDisabled={activeStep == 4} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
          </HStack>
        </Center>

        {activeStep == 0 && <HomePageAd variation={variations[0]} />}
        {activeStep == 1 && <HomePageAd2 variation={variations[1]} />}
        {activeStep == 2 && <WatchPageAd variation={variations[1]} />}
        {activeStep == 3 && <VideoInStreamAd variation={variations[1]} />}
        {activeStep == 4 && <YoutubeAd variation={variations[1]} />}
      </>}

      {displayType == 'desktop' && <>
        <DesktopVideoInStreamAd variation={variations[0]} />
      </>}
    </>
  )
};

export default Youtube;