import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Skeleton, Image, SkeletonCircle, SkeletonText, Divider } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp } from 'react-icons/fa6';
import { FiCornerUpRight, FiDownload, FiExternalLink, FiMessageSquare, FiMoreVertical, FiRewind, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { googleDisplayCallToActions } from "../../../../../../components/Constants/google_display_call_to_actions";

import Phone from './Phone';

const WatchPageAd = ({ variation }) => {

  return (
    <>
      <Phone>
        <Skeleton h={'50px'} startColor={'gray.100'} endColor={'gray.100'} />

        <Box p={5}>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={3} />

          <HStack mt={5} justifyContent={'space-between'}>
            <VStack>
              <Icon as={FiThumbsUp} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>
            <VStack>
              <Icon as={FiThumbsDown} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiMessageSquare} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiCornerUpRight} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiDownload} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>
          </HStack>
        </Box>

        <Divider my={2} />

        <HStack px={5} mt={4} w={'full'}>
          <Box>
            <SkeletonCircle startColor="gray.400" endColor="gray.400" size='10' />
          </Box>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
        </HStack>

        <Divider my={2} />


        <HStack alignItems={'flex-start'} p={5}>
          <Box w={'full'}>
            <Image w={'full'} src={variation.imageUrl} />
          </Box>
          <VStack w={'full'} alignItems={'flex-start'} spacing={2} p={1}>
            <Text fontSize={'xs'}>{variation.headline?.truncate(30)}</Text>
            <HStack>
              <Text rounded={'md'} bg={'yellow.400'} color={'white'} py={0} px={1} fontSize={'xs'}>Ad</Text>
              <Text color={'gray'} fontSize={'x-small'} >{variation.businessName}</Text>
            </HStack>

            <Text bg={'white'} px={2} rounded={'md'} border={'solid 1px'} borderColor={'blue.300'} py={1} fontWeight={'bold'} color={'blue.500'} fontSize={'x-small'}>{googleDisplayCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Text>
          </VStack>
        </HStack>

        <Divider my={2} />
        <SkeletonText p={5} w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />

      </Phone>
    </>
  )
};

export default WatchPageAd;