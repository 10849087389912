import React from "react";

import { Tab, TabList, Text, TabPanel, TabPanels, Tabs, useSteps } from "@chakra-ui/react";
import MobileClosed from "./Gmail/MobileClosed";
import DesktopClosed from "./Gmail/DesktopClosed";
import MobileOpened from "./Gmail/MobileOpened";
import DesktopOpened from "./Gmail/DesktopOpened";

const Gmail = ({ variations, displayType, adType }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  if (adType == 'video') {
    return (
      <>
        <Text>Video ads don't appear on Gmail.</Text>
      </>
    )
  }


  return (
    <>
      <Tabs w={'full'}>
        <TabList>
          <Tab w={'full'}>
            Closed
          </Tab>
          <Tab w={'full'}>
            Open
          </Tab>
        </TabList>
        <TabPanels mt={5}>
          <TabPanel>
            {displayType == 'mobile' && <MobileClosed adType={adType} variations={variations} />}
            {displayType == 'desktop' && <DesktopClosed variations={variations} />}
          </TabPanel>
          <TabPanel>
            {displayType == 'mobile' && <MobileOpened adType={adType} variations={variations} />}
            {displayType == 'desktop' && <DesktopOpened adType={adType} variations={variations} />}

          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
};

export default Gmail;