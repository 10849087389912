export function validateUrl(url) {
  if (typeof url !== 'string') return null; 
  url = url.trim(); 
  const protocolCorrectionRegex = /^(ht+tp?s?):\/\//i;
  if (protocolCorrectionRegex.test(url)) {
    url = url.replace(protocolCorrectionRegex, 'http://');
  }

  try {
    new URL(url);
    return url;
  } catch (_) {
    const newValue = 'https://' + url;
    try {
      new URL(newValue);
      return newValue;
    } catch (_) {
      // If the URL is still invalid, return deffault value
      return 'https://';
    }
  }
}
