import { baseApi } from "./base/base";

export const workspacesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspaces: builder.query({
      query: (data) => ({ url: '/workspaces', params: data }),
      providesTags: ['Workspace']
    }),
    getWorkspace: builder.query({
      query: (uuid) => ({ url: `/workspaces/${uuid}` }),
      providesTags: ['Workspace']
    }),
    createWorkspace: builder.mutation({
      query: (data) => ({ url: `/workspaces`, method: 'POST', body: data }),
      invalidatesTags: ['Workspace', 'User']
    }),
    updateWorkspace: builder.mutation({
      query: (data) => ({ url: `/workspaces/${data.uuid}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Workspace', 'User']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetWorkspacesQuery, useGetWorkspaceQuery, useCreateWorkspaceMutation, useUpdateWorkspaceMutation
} = workspacesApi;
