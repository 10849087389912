import React from "react";

import { Box, HStack, Icon, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

const Display5 = ({ variation }) => {
  return (
    <>
      <VStack p={5} bgGradient={'linear(to-r, #3f4954, #26292d)'} borderRadius={'2xl'} alignItems={'flex-start'}>

        <Text color={'#43d7ff'} fontSize={'2xl'} fontWeight={800}>{variation.headline?.truncate(30)}</Text>

        <HStack alignItems={'flex-start'} justifyContent={'space-between'} w={'full'}>
          <Text noOfLines={2} fontSize={'sm'} color={'white'}>{variation.description?.truncate(90)}</Text>

          <IconButton bg={'#43d7ff'} fontSize={'2xl'} color={'black'} borderRadius={'full'} p={4} icon={<Icon as={FiChevronRight} />} aria-label={""} />
        </HStack>

      </VStack >
    </>
  )
};

export default Display5;