import React, { useState } from 'react';
import { Box, Center, Heading, Text, Input, VStack, HStack, Button, Stack, Icon, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import { useGetCampaignsQuery } from '../../api/services/campaigns';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSession } from '../../helpers/sessionHelper';
import Create from './Create';
import { FiPlus } from 'react-icons/fi';
import { useGetWorkspaceQuery } from '../../api/services/workspaces';
import { formatDate } from '../../helpers/dateHelper';
import { useParams } from 'react-router-dom';

const Workspace = () => {
  const { uuid } = useParams();
  const createWorkspaceDisclosure = useDisclosure();

  const { data } = useGetWorkspaceQuery(uuid, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });
  const currentUser = useSelector((state) => state.user.currentUser);

  return (
    <Box p={4} minHeight="100vh" w={'full'}>
      <Flex gap={6} direction={['column', 'row']} alignItems={'center'} p={8} justifyContent={'space-between'}>
        <Heading fontSize={'3xl'} as="h1" mb={0} pb={0}>
          Workspace { uuid }
        </Heading>
      </Flex>
    </Box >
  );
};

export default Workspace;
