import { baseApi } from "./base/base";

export const suggestionsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createSuggestion: builder.mutation({
            query: (data) => ({ url: `/suggestions`, method: 'POST', body: data }),
            invalidatesTags: ['suggestion']
        }),
    }),

    overrideExisting: false
});

export const {
    useCreateSuggestionMutation
} = suggestionsApi;
