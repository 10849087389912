import { Input, Text, Box, HStack, Icon, VStack, FormControl, FormLabel, FormHelperText, Select } from "@chakra-ui/react";
import { BiSortAlt2 } from "react-icons/bi";
import React, { useEffect } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { toCamelCase } from "../../../../../helpers/apiHelper";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const Slide = ({ ad = null, isOpen, onToggle, getValues, provided, item, remove, register, index, setValue }) => {

    useEffect(() => {
        setValue(`meta_ad_collection_items.${index}.priority`, index);
    }, [index]);

    const [asset, setAsset] = React.useState([]);

    useEffect(() => {
        if (asset[0] == undefined) return;
        setValue(`meta_ad_collection_items.${index}.asset`, asset[0]);
    }, [asset])

    useEffect(() => {
        const adAsset = getValues(`meta_ad_collection_items.${index}.asset`);

        if (adAsset == undefined) return;

        setAsset([toCamelCase(adAsset)]);
    }, [])

    return (
      <>
        <Box bg={'white'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box p={5} px={0} borderBottom={'solid'} borderBottomWidth={isOpen ? 0 : '1px'} borderColor={'gray.200'} onClick={(e) => onToggle()}  >
            <HStack justifyContent={'space-between'}>
              <HStack>
                <Icon as={BiSortAlt2} />
                <Text>Thumbnail #{index + 1}</Text>
              </HStack>
              {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </HStack>
          </Box>

          <Input type={'hidden'} {...register(`meta_ad_collection_items.${index}.uuid`)} defaultValue={item.uuid} />
          <Input type={'hidden'} {...register(`meta_ad_collection_items.${index}.priority`)} value={index} />

          <Box display={isOpen ? 'block' : 'none'}>
            <VStack spacing={2} alignItems={'flex-start'}>
              <Box>
                <HStack>
                  <Box mb={8}>
                    <AssetsLibraryButton assets={asset} setAssets={setAsset} types={['all']} />
                  </Box>
                  <AssetsLibraryPreview assets={asset} setAssets={setAsset} />
                </HStack>
              </Box>
              <Box w={'full'}>
                <VStack alignItems={'flex-start'} spacing={7}>
                  <FormControl>
                    <FormLabel>Website URL</FormLabel>
                    <Input key={item.id} defaultValue={item.website_url} 
                    {...register(`meta_ad_collection_items.${index}.website_url`)} 

                    placeholder={'Add website URL here...'} 
                    onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue(`meta_ad_collection_items.${index}.website_url`, validatedUrl);
                                    }}
                    />
              
                   
                  </FormControl>
                </VStack>
              </Box>
            </VStack>
          </Box>
        </Box>
      </>
    )
};

export default Slide;