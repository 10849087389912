import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: 'users',
  initialState: {
    currentUser: null,
    currentWorkspace: JSON.parse(localStorage.getItem("currentWorkspace")) || null
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentWorkspace: (state, action) => {
      state.currentWorkspace = action.payload;
    },
    setOrganizationState: (state, action) => {
      state.currentUser.organization.state = action.payload;
    }
  },
});

export const { setCurrentUser, setCurrentWorkspace, setOrganizationState } = slice.actions;
export default slice.reducer;

export const currentUser = (state) => state.user.currentUser;
