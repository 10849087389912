import { baseApi } from "../base/base";

export const sharedRepliesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSharedReplies: builder.query({
            query: (data) => ({ url: `/shared/comments/${data.comment_id}/replies`, params: data }),
            providesTags: ['Reply']
        }),
        createSharedReply: builder.mutation({
            query: (data) => ({ url: `/shared/comments/${data.comment_id}/replies`, method: 'POST', body: data }),
            invalidatesTags: ['Reply']
        }),
    }),

    overrideExisting: false
});

export const {
    useCreateSharedReplyMutation, useGetSharedRepliesQuery
} = sharedRepliesApi;
