import { baseApi } from "../base/base";

export const sharedFoldersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedFolders: builder.query({
      query: (data) => ({ url: '/shared/folders', params: data }),
      providesTags: ['Folder']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetSharedFoldersQuery
} = sharedFoldersApi;
