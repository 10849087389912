import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Button, Image, SkeletonCircle, SkeletonText, Divider, Skeleton } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp } from 'react-icons/fa6';
import { FiArrowLeft, FiArrowRight, FiCornerUpRight, FiDownload, FiExternalLink, FiMenu, FiMessageSquare, FiMoreVertical, FiRefreshCw, FiRewind, FiRotateCw, FiThumbsDown, FiThumbsUp, FiUser } from 'react-icons/fi';
import { googleDemandgenCallToActions } from '../../../../../../components/Constants/google_demandgen_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const DesktopVideoInStreamAd = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Video in-stream ad</Text>

      <HStack mt={4} px={4} w={'full'} bg={'gray.200'} roundedTopLeft={'md'} roundedTopRight={'md'}>
        <HStack>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
        </HStack>

        <HStack px={3} borderRight={'16px solid transparent'} pt={4} borderLeft={'16px solid transparent'} h={0} borderBottom={'solid 28px #f1f3f4'} marginLeft={10} spacing={3} w={'200px'}>
          <Box mt={7} w={3} h={3} bg={'gray.400'}></Box>
          <Box mt={7} w={'full'} h={2} bg={'gray.300'}></Box>
        </HStack>
      </HStack>
      <HStack bg={'#f1f3f4'} spacing={10} w={'full'} px={5} py={4}>
        <HStack spacing={5}>
          <Icon as={FiArrowLeft} color={'gray.700'} />
          <Icon as={FiArrowRight} color={'gray.700'} />
          <Icon as={FiRotateCw} color={'gray.700'} />
        </HStack>
        <Box w={'full'} h={'25px'} border={'solid 1px'} borderColor={'gray.300'} bg={'white'}></Box>
        <Icon as={FiMoreVertical} color={'gray.700'} />
      </HStack>

      <HStack bg={'white'} spacing={20} w={'full'} px={5} py={4}>
        <HStack spacing={5}>
          <Icon as={FiMenu} color={'gray.700'} />
          <Image src={YoutubeLogo} w={20} />
        </HStack>
        <Box w={'full'} h={'25px'} bg={'gray.200'} ></Box>
        <Icon as={FiUser} color={'gray.700'} />
      </HStack>

      <HStack mt={5} spacing={5} alignItems={'flex-start'}>
        <VStack w={'55%'} spacing={3}>
          <Box w={'full'} mb={4} position={'relative'}>
            <Box w={'30%'} position={'absolute'} bottom={0} bg={'yellow.300'} h={'4px'}></Box>

            <Button bg={'black'} right={5} bottom={5} rounded={0} px={3} border={'solid 1px white'} boxShadow={'lg'} py={2} position={'absolute'} fontSize={'x-small'} >Skip Ad</Button>
            {variation.videoUrl && <>
              {!variation.remoteProvider && <video muted autoPlay width={'300px'} height="auto" key={variation.videoUrl}><source src={variation.videoUrl} type="video/mp4" /></video>}
              {variation.remoteProvider == 'youtube' && <iframe key={variation.videoUrl} width="300px" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
            </>}
            {!variation.videoUrl && <>
              <Box w={'100%'} h={'150px'} bg={'gray.200'}>
              </Box>
            </>}
          </Box>

          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />

          <HStack mt={3} w={'full'} justifyContent={'space-between'}>
            <Avatar size={'sm'} bg={'gray.300'} name={' '} />
            <Box w={'xs'} bg={'gray.300'} h={'20px'}></Box>
          </HStack>

          <SkeletonText mt={5} w={'full'} startColor="gray.200" endColor="gray.200" noOfLines={5} />

        </VStack>

        <VStack w={'45%'} alignItems={'flex-start'} spacing={5} p={1}>
          <Skeleton w={'full'} h={'30px'} startColor='gray.200' endColor='gray.200' />

          <HStack w={'full'} justifyContent={'space-between'}>
            <HStack alignItems={'flex-start'}>
              <Image src={variation.imageUrl} w={20} />
              <VStack alignItems={'flex-start'} spacing={0}>
                <Text fontSize={'x-small'}>{variation.headline?.truncate(30)}</Text>
                <Text fontSize={'x-small'} color={'gray.500'}>{variation.description?.truncate(90)}</Text>
                <HStack p={0}>
                  <Text fontSize={'xx-small'} fontWeight={'bold'}>Sponsored</Text>
                  <Text fontSize={'xx-small'}>• {variation.businessName}</Text>
                </HStack>

                <Button rightIcon={<Icon as={FiExternalLink} />} mt={2} borderRadius={'lg'} fontSize={'x-small'} w={'full'} maxW={'fit-content'} bg={'blue.100'} color={'blue.600'} px={2} py={1}>{googleDemandgenCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>
              </VStack>
            </HStack>

          </HStack>

          {[1, 2, 3, 4].map((i) => (<HStack w={'full'} alignItems={'flex-start'}>
            <Box w={'full'} h={'50px'} bg='gray.200'>&nbsp;</Box>
            <VStack w={'full'}>
              <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={1} />
              <SkeletonText w={'full'} startColor="gray.200" endColor="gray.200" noOfLines={2} />
            </VStack>
          </HStack>))}
        </VStack>
      </HStack>
    </>
  )
};

export default DesktopVideoInStreamAd;