import { Card, Text, Box, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Button } from "@chakra-ui/react";
import React from "react";
import { FiGlobe, FiMoreHorizontal, FiThumbsUp, FiMessageCircle, FiShare } from "react-icons/fi";
import MetaBlankImage from '../../../../assets/images/meta_empty_image.png';
import { metaCallToActions } from "../../../../components/Constants/meta_call_to_actions";
import CustomerAcquisitionPreview from "./InstantExperience/CustomerAcquisitionPreview";
import StorytellingPreview from "./InstantExperience/StorytellingPreview";

const SingleImageVideo = ({ ad, instantExperienceData }) => {
  const [instantExperienceOpened, setInstantExperienceOpened] = React.useState(false);

  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setInstantExperienceOpened(false);
      }
    };

    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const setInstantExperienceOpenedIfPossible = (value) => {
    if (instantExperienceData) {
      setInstantExperienceOpened(value);
    }
  }

  return (
    <Card maxW={'sm'} borderRadius={0}>
      {!instantExperienceOpened &&
        <>
          <CardHeader>
            <HStack justifyContent={'space-between'}>
              <HStack spacing={5}>
                <Avatar bg={'gray.300'} src={ad.facebookLogo?.fileUrl} icon={<></>} name='' />
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'sm'}>{ad.facebookPageName || 'Account Name'}</Text>
                  <HStack color={'gray'} fontWeight={400} fontSize={'sm'}>
                    <Text>Sponsored</Text>
                    <Text>·</Text>
                    <Icon as={FiGlobe} />
                  </HStack>
                </VStack>
              </HStack>
              <Icon as={FiMoreHorizontal} />
            </HStack>
          </CardHeader>
          <CardBody fontSize={'sm'} p={0}>
            <Text p={4} whiteSpace={'pre-wrap'} >{ad.primaryText || 'Post intro content...'}</Text>

            {ad.asset == null && <Image w={'full'} src={MetaBlankImage} />}

            {ad.asset?.assetType == 'image' && <Box overflow={'hidden'}>
              <Image w={'full'} src={ad.asset?.fileUrl} />
            </Box>}
            {ad.asset?.assetType == 'video' && !ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
              <video key={ad.asset?.uuid} muted width={'100%'} height="auto" loop autoPlay>
                <source src={ad.asset?.fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>}
            {ad.asset?.assetType == 'video' && ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
              <iframe width="100%"
                height="315"
                src={ad.asset?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>}
            <HStack justifyContent={'space-between'} bg={'gray.100'} p={4}>
              <Box>
                <Text fontSize={'xs'}>{ad.websiteUrl || 'https://app.whatcampaign.com/'}</Text>
                <Text fontSize={'sm'} fontWeight={500}>{ad.headline?.truncate(40) || 'Headline'}</Text>
                <Text mt={1} fontSize={'xs'}>{ad.description?.truncate(30) || 'Description'}</Text>
              </Box>
              {ad.callToAction && <Button onClick={() => setInstantExperienceOpenedIfPossible(true)} w={'fit-content'} px={7} fontSize={'sm'} bg={'gray.300'} p={3} color={'black'}>{metaCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}</Button>}
            </HStack>

            <HStack p={4} py={2} justifyContent={'space-between'}>
              <Button fontSize={'sm'} colorScheme={'gray'} leftIcon={<Icon as={FiThumbsUp} />} variant={'ghost'}>Like</Button>
              <Button fontSize={'sm'} colorScheme={'gray'} leftIcon={<Icon as={FiMessageCircle} />} variant={'ghost'}>Comment</Button>
              <Button fontSize={'sm'} colorScheme={'gray'} leftIcon={<Icon as={FiShare} />} variant={'ghost'}>Share</Button>
            </HStack>
          </CardBody>
        </>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'customer_acquisition' &&
        <VStack spacing={5}>
          <CustomerAcquisitionPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            description: instantExperienceData?.fields_data?.description,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            buttonLabel_2: instantExperienceData?.fields_data?.button_label_2,
            buttonDestination_2: instantExperienceData?.fields_data?.button_destination_2,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset: instantExperienceData?.fields_data?.instant_experience_asset
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}
          />
        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'storytelling' &&
        <VStack spacing={5}>
          <StorytellingPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset_1: instantExperienceData?.fields_data?.instant_experience_asset_1,
            destination_url_1: instantExperienceData?.fields_data?.destination_url_1,
            instantExperienceAsset_2: instantExperienceData?.fields_data?.instant_experience_asset_2,
            destination_url_2: instantExperienceData?.fields_data?.destination_url_2,
            instantExperienceAsset_3: instantExperienceData?.fields_data?.instant_experience_asset_3,
            destination_url_3: instantExperienceData?.fields_data?.destination_url_3
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}
          />
        </VStack>
      }
    </Card >
  )
};

export default SingleImageVideo;