import { Box, Button, Flex, Icon, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, FormErrorMessage, Stack, RadioGroup, Radio } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { FiEdit3, FiHelpCircle } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { FaMeta } from 'react-icons/fa6';
import { metaCallToActions } from "../../../../../components/Constants/meta_call_to_actions";
import { useNavigate, useParams } from "react-router-dom";
import EditorContext from "../../../../../contexts/EditorContext";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { HiOutlineChip } from "react-icons/hi";
import Suggestions from "../../../../Suggestions/Suggestions";
import InstantExperience from "../InstantExperience/InstantExperience";
import flattenObject from "../../../../../helpers/objectHelper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Slide from "../Collection/Slide";
import { useFieldArray } from "react-hook-form";
import SuggestionsButton from "../../../../Suggestions/SuggestionsButton";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const CommonEditor = ({ ad, onFormSubmit, isLoading, register, watch, control, handleSubmit, getValues, setValue, assetsState, instagramLogoState, facebookLogoState }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { errors } = useContext(EditorContext);

  const [assets, setAssets] = assetsState;
  const [facebookLogo, setFacebookLogo] = facebookLogoState;
  const [instagramLogo, setInstagramLogo] = instagramLogoState;
  const [destinationType, setDestinationType] = useState(ad?.metadata?.destinationType || 'website');
  const [openIndex, setOpenIndex] = useState(null);

  const [isInstantExperienceDrawerOpen, setIsInstantExperienceDrawerOpen] = useState(false);
  const openInstantExperienceDrawer = () => {
    setIsInstantExperienceDrawerOpen(true);
  };

  const closeInstantExperienceDrawer = () => {
    setIsInstantExperienceDrawerOpen(false);
  };

  const wAll = watch();

  const handleToggleSlide = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const thumbnailsCount = getValues('collection_thumbnails_count');

  const { fields, append, remove } = useFieldArray({
    control,
    name: "meta_ad_collection_items"
  });

  React.useEffect(() => {
    if (thumbnailsCount === undefined) {
      setValue('collection_thumbnails_count', 3);
    }
    const currentCount = fields.length;
    const newCount = Number(thumbnailsCount);

    if (newCount > currentCount) {
      for (let i = 0; i < newCount - currentCount; i++) {
        append({});
      }
    } else if (newCount < currentCount) {
      for (let i = 0; i < currentCount - newCount; i++) {
        remove(currentCount - i - 1);
      }
    }
  }, [thumbnailsCount, append, remove, fields.length]);


  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(getValues('meta_ad_collection_items'));

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue('meta_ad_collection_items', items);
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('asset_id', assets[0]?.id);
    formData.append('facebook_logo_asset_id', facebookLogo[0]?.id);
    formData.append('instagram_logo_asset_id', instagramLogo[0]?.id);

    const flObject = flattenObject(data);
    Object.keys(flObject).forEach((key) => {
      if (flObject[key] == null)
        return;

      const modifiedKey = key.replace(/\[\d+\]/g, '[]');
      formData.append(modifiedKey, flObject[key]);
    });

    onFormSubmit(formData);
  }

  const updateHeadline = (headlines) => {
    setValue('headline', headlines[0]);
  };

  const updateDescription = (descriptions) => {
    setValue('description', descriptions[0]);
  };

  const clearInstantExperienceValues = () => {
    setValue('meta_instant_experience', null);
  }

  return (
    <>
      <form style={{ width: '100%' }} mode="onBlur" onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} >

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Identity</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
            </CardHeader>
            <CardBody>
              <FormControl isInvalid={errors?.facebook_page_name}>
                <FormLabel>Facebook Page</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={facebookLogo} setAssets={setFacebookLogo} type={'image'} rounded />

                  <Input w={'full'} {...register('facebook_page_name')} placeholder={'Page name'} />
                </HStack>
                <FormErrorMessage justifyContent={'flex-end'}>{errors?.facebook_page_name}</FormErrorMessage>
              </FormControl>

              <FormControl mt={10} isInvalid={errors?.instagram_account_name}>
                <FormLabel>Instagram Account</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={instagramLogo} setAssets={setInstagramLogo} type={'image'} rounded />

                  <Input {...register('instagram_account_name')} w={'full'} placeholder={'Account name'} />
                </HStack>
                <FormErrorMessage justifyContent={'flex-end'}>{errors?.instagram_account_name}</FormErrorMessage>
              </FormControl>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Media</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>
              <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

              <HStack>
                <AssetsLibraryButton assets={assets} setAssets={setAssets} types={['image', 'video']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={assets} setAssets={setAssets} />
              </Stack>
            </CardBody>
          </Card>


          <Card w={'full'}>
            <CardHeader>
              <Flex>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                  <Text>Content</Text>
                  <Icon as={FiHelpCircle} />
                </Flex>
                <SuggestionsButton updateHeadline={updateHeadline} updateDescription={updateDescription} />
              </Flex>
            </CardHeader>
            <CardBody>
              <VStack spacing={5}>
                <FormControl>
                  <FormLabel>Primary Text</FormLabel>
                  <Textarea {...register('primary_text')} placeholder={'Add primary text here...'} />
                </FormControl>

                <FormControl>
                  <FormLabel>Headline</FormLabel>
                  <Input {...register('headline')} placeholder={'Add headline here...'} />
                </FormControl>

                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Input {...register('description')} placeholder={'Add description here...'} />
                </FormControl>

                <FormControl>
                  <FormLabel>Call To Action</FormLabel>
                  <Select {...register('call_to_action')}>
                    <option value={''}>No Button</option>
                    {metaCallToActions.map((cta, index) => (
                      <option value={cta.value} key={index}>{cta.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Collection details</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>
              <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <Slide
                                isOpen={index === openIndex}
                                onToggle={() => handleToggleSlide(index)}
                                setValue={setValue} getValues={getValues}
                                register={register} index={index}
                                provided={provided} item={item}
                                remove={() => remove(index)} />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Destination - Meta Instant Experience</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide the details for your Meta Instant Experience.</Text>
            </CardHeader>
            <CardBody>
              <VStack spacing={5}>
                <FormControl>
                  <HStack justifyContent={'space-between'}>
                    {getValues('meta_instant_experience') &&
                      <HStack spacing={5}>
                        <Icon fontSize={'xl'} as={FaMeta} color={'blue.500'} />
                        <VStack alignItems={'flex-start'}>
                          <Text>{getValues('meta_instant_experience.fields_data.headline') || 'Meta Instant Experience'}</Text>
                        </VStack>
                      </HStack>
                    }
                    <Button
                      leftIcon={<Icon as={getValues('meta_instant_experience') ? FaMeta : FaPlus} />}
                      onClick={openInstantExperienceDrawer}>
                      {getValues('meta_instant_experience') ? 'Modify Instant Experience' : 'Create New'}
                    </Button>
                    <InstantExperience
                      availableTemplates={['storefront', 'customer_acquisition', 'sell_products', 'storytelling']}
                      isOpen={isInstantExperienceDrawerOpen}
                      onClose={closeInstantExperienceDrawer}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      clearInstantExperienceValues={clearInstantExperienceValues} />
                  </HStack>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <HStack w={'full'} justifyContent={'flex-start'}>
            <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
            <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
          </HStack>
        </VStack>
      </form>

    </>
  )
}

export default CommonEditor;