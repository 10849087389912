import React, { useEffect, useState } from 'react';
import {
    Button,
    Text,
    Divider,
    VStack,
    useToast,
    Box,
    HStack,
    Input,
    Icon,
    Tag,
    Alert,
    AlertIcon,
    useDisclosure,
    FormLabel,
    FormControl
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { tableColumns } from './Simple/columns'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DraggableItem from './Components/DraggableItem';
import { useUpdateMediaPlanMutation } from '../../api/services/mediaPlans';
import { setColumns } from '../../api/slices/mediaPlanColumnSlice';
import CustomFieldsForm from './Components/CustomFieldsForm';
import { FiEdit, FiEye, FiEyeOff, FiMenu, FiPenTool, FiTrash2 } from 'react-icons/fi';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ColumnManagement = ({ mediaPlan, onBack }) => {
    const toast = useToast();
    const [updateCustomField, setUpdateCustomField] = useState(null);
    const [manageCustomFieldName, setManageCustomFieldName] = useState('');

    const updateCustomFieldDisclosure = useDisclosure();
    const deleteCustomFieldDisclosure = useDisclosure();

    const [updateMediaPlan, { isLoading, isSuccess }] = useUpdateMediaPlanMutation();
    const mediaPlanVisibleColumns = mediaPlan?.columnSettings?.shown || [];
    const mediaPlanHiddenColumns = mediaPlan?.columnSettings?.hidden || [];

    const defaultShownItems = [
        ...tableColumns.filter((column) => mediaPlanVisibleColumns.includes(column.name)),
        ...mediaPlan.customFields?.filter((field) => mediaPlanVisibleColumns.includes(field.name))
    ];

    const defaultHiddenItems = [
        ...tableColumns.filter((column) => mediaPlanHiddenColumns.includes(column.name)),
        ...mediaPlan.customFields?.filter((field) => mediaPlanHiddenColumns.includes(field.name))
    ];

    const sortShownItems = defaultShownItems.sort((a, b) => mediaPlanVisibleColumns.indexOf(a.name) - mediaPlanVisibleColumns.indexOf(b.name));
    const sortHiddenItems = defaultHiddenItems.sort((a, b) => mediaPlanHiddenColumns.indexOf(a.name) - mediaPlanHiddenColumns.indexOf(b.name));

    const [shownItems, setShownItems] = React.useState(sortShownItems?.length > 0 ? sortShownItems : (sortHiddenItems?.length > 0 ? [] : [...tableColumns, ...mediaPlan.customFields]));
    const [hiddenItems, setHiddenItems] = React.useState(sortHiddenItems);

    const handleHideColumn = (name) => {
        const item = shownItems.find((item) => item.name === name);
        setShownItems(shownItems.filter((item) => item.name !== name));
        setHiddenItems([...hiddenItems, item]);
    };

    const handleShowColumn = (name) => {
        const item = hiddenItems.find((item) => item.name === name);
        setHiddenItems(hiddenItems.filter((item) => item.name !== name));
        setShownItems([...shownItems, item]);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const itemsOrdered = reorder(shownItems, result.source.index, result.destination.index);
        setShownItems(itemsOrdered);
    }

    const onDragEndHidden = (result) => {
        if (!result.destination) {
            return;
        }

        const itemsOrdered = reorder(hiddenItems, result.source.index, result.destination.index);
        setHiddenItems(itemsOrdered);
    }

    const handleRemoveCustomField = () => {
        const name = manageCustomFieldName;

        setShownItems(shownItems.filter((item) => item.name !== name));
        setHiddenItems(hiddenItems.filter((item) => item.name !== name));

        updateMediaPlan({
            id: mediaPlan.id,
            customField: {
                name: name,
                action: 'remove'
            }
        })

        setManageCustomFieldName('');
    }

    const handleUpdateCustomField = () => {
        const name = manageCustomFieldName;

        setShownItems(shownItems.map((item) => {
            if (item.name === updateCustomField.name) {
                return {
                    ...item,
                    label: name
                }
            }
            return item;
        }));

        setHiddenItems(hiddenItems.map((item) => {
            if (item.name === updateCustomField.name) {
                return {
                    ...item,
                    label: name
                }
            }
            return item;
        }));

        updateMediaPlan({
            id: mediaPlan.id,
            customField: {
                name: updateCustomField?.name,
                label: name,
                action: 'update'
            }
        })

        setManageCustomFieldName('');
    }

    const handleHideAll = () => {
        setHiddenItems([...hiddenItems, ...shownItems]);
        setShownItems([]);
    }

    const handleShowAll = () => {
        setShownItems([...shownItems, ...hiddenItems]);
        setHiddenItems([]);
    }

    const handleSave = () => {
        updateMediaPlan({
            id: mediaPlan.id,
            columnSettings: {
                shown: shownItems.map((item) => item.name),
                hidden: hiddenItems.map((item) => item.name)
            }
        });
    }

    useEffect(() => {
        if (isSuccess && !(mediaPlanVisibleColumns?.length == 0 && mediaPlanHiddenColumns?.length == 0)) {
            toast({
                description: 'Columns have been saved successfully.',
                status: 'success',
                duration: 1000,
                isClosable: true,
            });
        }
    }, [isSuccess])

    useEffect(() => {
        if (mediaPlanVisibleColumns?.length == 0 && mediaPlanHiddenColumns?.length == 0) {
            handleSave();
        }
    }, [])

    return (
        <Box p={6} transform={'none !important'}>
            <VStack spacing={4} align="stretch">
                <Text textAlign="left" fontSize="xl" fontWeight="bold">Column Management</Text>
                <Text textAlign="left" fontSize="xs" color="gray.600">
                    Reorder columns simply by dragging them, and toggle
                    visibility to hide or show them as needed.
                </Text>
                <Divider borderColor="gray.300" my={2} />

                <Alert status="warning" borderRadius={'md'} gap={4} variant="subtle" flexDirection="row" alignItems="center" justifyContent="flex-start" textAlign="left" w={'full'}>
                    <AlertIcon boxSize={'16px'} mr={0} />
                    <Text fontSize="xs" color={'gray.600'}>Please make sure you save the data with the button below before leaving the page.</Text>
                </Alert>


                <HStack w={'full'} justifyContent={'space-between'}>
                    <Text fontSize={'sm'} fontWeight={'bold'}>Shown in the table</Text>
                    <Text onClick={() => handleHideAll()} fontSize={'xs'} color={'purple.500'} fontWeight={'semibold'} cursor={'pointer'}>Hide all</Text>
                </HStack>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <VStack
                                spacing={0}
                                gap={0}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                padding={'grid'}
                            >
                                {shownItems.map((item, index) => (
                                    <Draggable key={item.name} draggableId={item.name} index={index}>
                                        {(provided, snapshot) => (
                                            <HStack ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                margin={'0 0 8px 0'}
                                                style={provided.draggableProps.style} cursor={'pointer'} borderRadius={'md'} bg={'purple.100'} opacity={'0.9'} px={4} py={2} justifyContent={'space-between'} w={'full'}>
                                                <HStack spacing={3}>
                                                    <Icon color={'purple.300'} fontSize={'xs'} as={FiMenu} />
                                                    <Text color={'purple.700'} fontSize={'xs'}>{item.label}</Text>
                                                </HStack>
                                                <HStack spacing={4}>
                                                    {item.name.includes('custom_field') && <>
                                                        <Icon onClick={() => { setManageCustomFieldName(item.name); deleteCustomFieldDisclosure.onOpen() }} fontSize={'sm'} cursor={'pointer'} color={'purple.700'} as={FiTrash2} />
                                                        <Icon onClick={() => { setManageCustomFieldName(item.label); setUpdateCustomField(item); updateCustomFieldDisclosure.onOpen() }} fontSize={'sm'} cursor={'pointer'} color={'purple.700'} as={FiEdit} />
                                                    </>}
                                                    <Icon onClick={() => handleHideColumn(item.name)} _hover={{ color: 'purple.900' }} cursor={'pointer'} color={'purple.700'} as={FiEye} />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </VStack>
                        )}
                    </Droppable>
                </DragDropContext>

                <HStack w={'full'} justifyContent={'space-between'}>
                    <Text fontSize={'sm'} fontWeight={'bold'}>Hidden in the table</Text>
                    <Text onClick={() => handleShowAll()} fontSize={'xs'} color={'purple.500'} fontWeight={'semibold'} cursor={'pointer'}>Show all</Text>
                </HStack>

                <DragDropContext onDragEnd={onDragEndHidden}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <VStack
                                spacing={0}
                                gap={0}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                padding={'grid'}
                            >
                                {hiddenItems.map((item, index) => (
                                    <Draggable key={item.name} draggableId={item.name} index={index}>
                                        {(provided, snapshot) => (
                                            <HStack ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                margin={'0 0 8px 0'}
                                                style={provided.draggableProps.style} cursor={'pointer'} borderRadius={'md'} bg={'purple.100'} opacity={'0.9'} px={4} py={2} justifyContent={'space-between'} w={'full'}>
                                                <HStack spacing={3}>
                                                    <Icon color={'purple.300'} fontSize={'xs'} as={FiMenu} />
                                                    <Text color={'purple.700'} fontSize={'xs'}>{item.label}</Text>
                                                </HStack>
                                                <HStack spacing={4}>
                                                    {item.name.includes('custom_field') && <>
                                                        <Icon onClick={() => { setManageCustomFieldName(item.name); deleteCustomFieldDisclosure.onOpen() }} fontSize={'sm'} cursor={'pointer'} color={'purple.700'} as={FiTrash2} />
                                                        <Icon onClick={() => { setManageCustomFieldName(item.label); setUpdateCustomField(item); updateCustomFieldDisclosure.onOpen() }} fontSize={'sm'} cursor={'pointer'} color={'purple.700'} as={FiEdit} />
                                                    </>}

                                                    <Icon cursor={'pointer'} onClick={() => handleShowColumn(item.name)} color={'purple.700'} as={FiEyeOff} />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </VStack>
                        )}
                    </Droppable>
                </DragDropContext>

                <CustomFieldsForm setShownItems={setShownItems} mediaPlanId={mediaPlan?.id} />

                <Divider mt={4} />

                <HStack bg={'white'} p={3} position={'sticky'} bottom={0} spacing={4} justify="end">
                    <Button variant="outline" onClick={onBack}>
                        Back
                    </Button>
                    <Button isDisabled={isLoading} onClick={() => handleSave()}>
                        Save
                    </Button>
                </HStack>
            </VStack>

            <AlertDialog
                isOpen={deleteCustomFieldDisclosure.isOpen}
                onClose={deleteCustomFieldDisclosure.onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete custom field
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this custom field? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={'sm'} variant={'outline'} onClick={deleteCustomFieldDisclosure.onClose}>
                                Cancel
                            </Button>
                            <Button size={'sm'} bg='red.500' onClick={() => { handleRemoveCustomField(); deleteCustomFieldDisclosure.onClose() }} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
                isOpen={updateCustomFieldDisclosure.isOpen}
                onClose={updateCustomFieldDisclosure.onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Edit custom field
                            <Text mt={2} fontSize={'xs'} color={'gray.400'}>Enter new custom field label in the textbox below.</Text>

                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <FormControl>
                                <Input value={manageCustomFieldName} onChange={(e) => setManageCustomFieldName(e.target.value)} />
                            </FormControl>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={'sm'} variant={'outline'} onClick={updateCustomFieldDisclosure.onClose}>
                                Cancel
                            </Button>
                            <Button isDisabled={manageCustomFieldName.length == 0} size={'sm'} bg='brand.500' onClick={() => { handleUpdateCustomField(); updateCustomFieldDisclosure.onClose() }} ml={3}>
                                Save
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default ColumnManagement;