import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ChakraProvider, Button } from '@chakra-ui/react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthenticationLayout from './components/Layouts/AuthenticationLayout';
import ApplicationLayout from './components/Layouts/ApplicationLayout'
import Login from './views/Authentication/Login';
import Congratulations from './views/Authentication/Congratulations';
import Register from './views/Authentication/Register';
import { store } from './api/store';
import Dashboard from './views/Dashboard/Dashboard';
import theme from './components/Theme/theme';
import Campaigns from './views/Campaigns/Campaigns';
import Workspaces from './views/Workspaces/Workspaces';
import Campaign from './views/Campaigns/Campaign';
import Create from './views/Ads/Editor';
import Editor from './views/Ads/Editor';
import toastOptions from './components/Theme/toastOptions';
import Workspace from './views/Workspaces/Workspace';
import AssetsLibraryDashboard from './views/AssetsLibrary/Dashboard';
import Integrations from './views/Integrations/Integrations';
import SharedCampaign from './views/Campaigns/Shared/Campaign';
import PublicLayout from './components/Layouts/PublicLayout';
import Settings from './views/Settings/Settings';
import SettingsSidebar from './components/Layouts/ApplicationLayout/Sidebar/SettingsSidebar';
import AccountSettings from './views/Settings/AccountSettings';
import Confirmation from './views/Authentication/Confirmation';
import TeamMembers from './views/Team/TeamMembers';
import Billing from './views/Billing/Billing';
import Success from './views/Billing/Success';
import Failure from './views/Billing/Failure';
import NotFound from './views/Errors/NotFound';
import Oops from './views/Errors/Oops';
import ClientFolders from './views/Client/Folders/Folders';
import ClientLayout from './components/Layouts/ClientLayout';
import PublicFolder from './views/Public/Folder';
import PublicCampaign from './views/Public/Campaign';
import MediaPlans from './views/MediaPlans/MediaPlans';
import MediaPlan from './views/MediaPlans/MediaPlan';
import './helpers/stringHelper';
import './helpers/arrayHelper';
import BrandingSettings from './views/Settings/BrandingSettings';
import PublicMediaPlan from './views/MediaPlans/Public/MediaPlan';
import SharedMediaPlan from './views/MediaPlans/Shared/MediaPlan';
import EditorMediaPlan from './views/MediaPlans/Editor'
import ClientHome from './views/Client/Home';
import ClientMediaPlans from './views/Client/MediaPlans/MediaPlans';
import Sidebar from './components/Layouts/PublicLayout/Sidebar';
import Onboarding from './views/OnboardingWizard/Onboarding';
import Deactivated from './views/Dashboard/Deactivated';
import DeactivatedSidebar from './components/Layouts/ApplicationLayout/Sidebar/DeactivatedSidebar';
import DowngradingSidebar from './components/Layouts/ApplicationLayout/Sidebar/DowngradingSidebar';
import Downgrading from './views/Settings/Downgrading';
import ForgotPassword from './views/Authentication/ForgotPassword';
import ResetPassword from './views/Authentication/ResetPassword';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ChakraProvider theme={theme} toastOptions={toastOptions}>
          <Routes>

            <Route element={<ClientLayout />}>
              <Route path="/portal/:organization/home" element={<ClientHome />} />

              <Route path="/portal/:organization/campaigns" element={<ClientFolders />} />
              <Route path="/portal/:organization/campaign/:uuid" element={<SharedCampaign />} />

              <Route path='/portal/:organization/media-plans' element={<ClientMediaPlans />} />
              <Route path='/portal/:organization/media-plans/:uuid' element={<SharedMediaPlan />} />
            </Route>

            <Route element={<PublicLayout sidebar={<Sidebar />} />}>
              <Route path="/public/:organization/folders/:uuid" element={<PublicFolder />} />
              <Route path="/public/:organization/folders/:uuid/campaigns/:campaign_uuid" element={<PublicCampaign />} />
            </Route>

            <Route element={<PublicLayout />}>
              <Route path="/public/:organization/media-plans/:uuid" element={<PublicMediaPlan />} />
            </Route>


            <Route element={<AuthenticationLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/congratulations" element={<Congratulations />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<Register />} />
            </Route>

            <Route element={<ApplicationLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/assets-library" element={<AssetsLibraryDashboard />} />
              <Route path="/campaigns/:uuid" element={<Campaign />} />
              <Route path="/media-plans" element={<MediaPlans />} />
              <Route path="/media-plans/:id" element={<EditorMediaPlan />} />
              <Route path="/campaigns/:uuid/ad_groups/:ad_group_uuid/ads/create" element={<Editor />} />
              <Route path="/campaigns/:uuid/ad_groups/:ad_group_uuid/ads/:ad_uuid" element={<Editor />} />
            </Route>

            <Route element={<ApplicationLayout sidebar={<SettingsSidebar />} />}>
              <Route path="/settings" element={<Settings />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route path="/team" element={<TeamMembers />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
              <Route path="/workspaces" element={<Workspaces />} />
              <Route path="/workspaces/:uuid" element={<Workspace />} />
              <Route path="/branding-settings" element={<BrandingSettings />} />
            </Route >

            <Route element={<ApplicationLayout sidebar={<DeactivatedSidebar />} />}>
              <Route path="/deactivated" element={<Deactivated />} />
            </Route >

            <Route element={<ApplicationLayout sidebar={null} />}>
              <Route path="/onboarding" element={<Onboarding />} />
            </Route >

            <Route element={<ApplicationLayout sidebar={<DowngradingSidebar />} />}>
              <Route path="/downgrading" element={<Downgrading />} />
            </Route >

            <Route path="*" element={<NotFound />} />
            <Route path="/oops" element={<Oops />} />
          </Routes >
        </ChakraProvider >
      </Router >
    </Provider >
  );
};

export default App;
