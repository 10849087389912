import React from 'react';
import TextInput from './TextInput';
import NumberInput from './NumberInput';
import Dropdown from './Dropdown'
import DropdownMultiple from './DropdownMultiple'
import { useUpdateMediaPlanCampaignMutation } from '../../../../api/services/mediaPlanCampaigns';
import TextInputAddon from './TextInputAddon';
import DatepickerComponent from './DatepickerComponent';

const Mapper = ({ campaignId, mediaPlanId, name, value = null, type = 'text', values = [], meta = {}, readOnly }) => {
  const [updateCampaign] = useUpdateMediaPlanCampaignMutation();

  const handleSubmit = (value) => {
    let data = { mediaPlanCampaignId: campaignId, mediaPlanId: mediaPlanId };
    data[name] = value;

    if (name.includes('custom_field_')) {
      data.customFields = {
        [name]: value
      }
    }

    updateCampaign(data)
  };

  return (
    <>
      {type == 'text' && <TextInput readOnly={readOnly} key={`text-${campaignId}-${name}`} onSubmit={handleSubmit} value={value} />}
      {type == 'number' && <NumberInput readOnly={readOnly} key={`number-${campaignId}-${name}`} onSubmit={handleSubmit} value={value} />}
      {type == 'dropdown' && <Dropdown readOnly={readOnly} key={`dropdown-${campaignId}-${name}`} onSubmit={handleSubmit} values={values} defaultValue={value} />}
      {type == 'dropdown_multiple' && <DropdownMultiple readOnly={readOnly} key={`dropdownmultiple-${campaignId}-${name}`} onSubmit={handleSubmit} values={values} defaultValue={value} />}
      {type == 'text_addon' && <TextInputAddon readOnly={readOnly} key={`text_addon-${campaignId}-${name}`} onSubmit={handleSubmit} value={value} addonLabel={meta?.addonLabel} addonLabelPosition={meta?.addonLabelPosition} />}
      {type === 'date' && <DatepickerComponent readOnly={readOnly} key={`date-${campaignId}-${name}`} value={value} onChange={(date) => handleSubmit(date)} />}
    </>
  )
};

export default Mapper;