import React from "react";

import { Button, HStack, Icon, Image, ScaleFade, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { googlePmaxCallToActions } from "../../../../../../../components/Constants/google_pmax_call_to_actions";

const Display2 = ({ variation }) => {
  return (
    <>
      <VStack p={3} spacing={10} bg={'#263238'} border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <Image borderRadius={'md'} w={50} src={variation.logoUrl} />

        <Text color={'white'} fontSize={'4xl'} fontWeight={600}>{variation.headline?.truncate(30)}</Text>
        <Text color={'white'} fontSize={'lg'}>{variation.businessName}</Text>
        <Text color={'white'} fontSize={'xl'}>{variation.description?.truncate(90)}</Text>

        <HStack spacing={10} mt={5} mb={2} justifyContent={'space-between'} w={'full'}>
          <Button borderRadius={'2xl'} bg={'#263238'} border={'solid 1px white'} color={'white'} px={3} py={2} w={'full'} fontSize={'md'}>Close</Button>
          <Button borderRadius={'2xl'} color={'black'} bg={'white'} px={3} py={2} w={'full'} fontSize={'md'}>
            {googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}
          </Button>

        </HStack>
      </VStack>
    </>
  )
};

export default Display2;