import { baseApi } from "./base/base";

export const assetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: (data) => ({ url: `/assets`, params: data }),
      providesTags: ['Asset']
    }),
    getAsset: builder.query({
      query: (uuid) => ({ url: `/assets/${uuid}` }),
      providesTags: ['Asset']
    }),
    createAsset: builder.mutation({
      query: (data) => ({ url: `/assets`, method: 'POST', body: data }),
      invalidatesTags: ['Asset']
    }),
    createExternalAsset: builder.mutation({
      query: (data) => ({url: `/assets/external`, method: 'POST', body: data}),
      invalidatesTags: ['Asset']
    }),
    updateAsset: builder.mutation({
      query: (data) => ({ url: `/assets/${data.get('id')}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Asset']
    }),
    deleteAsset: builder.mutation({
      query: (id) => ({ url: `/assets/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Asset']
    }),
    reloadAssets: builder.mutation({
      query: (id) => ({ url: `/assets/${id}/reload`, method: 'POST' }),
      invalidatesTags: ['Asset', 'Campaign']
    })
  }),
  overrideExisting: false
});

export const {
  useGetAssetsQuery, useGetAssetQuery, useCreateAssetMutation, useCreateExternalAssetMutation, useUpdateAssetMutation, useDeleteAssetMutation, useReloadAssetsMutation
} = assetsApi;
