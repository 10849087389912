import { baseApi } from "../base/base";

export const sharedOrganizationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedOrganization: builder.query({
      query: (data) => ({ url: `/shared/organizations`, params: data }),
      providesTags: ['Organization']
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSharedOrganizationQuery
} = sharedOrganizationsApi;
