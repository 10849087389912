import { baseApi } from "./base/base";

export const campaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: (data) => ({ url: '/campaigns', params: data }),
      providesTags: ['Campaign']
    }),

    getCampaign: builder.query({
      query: (uuid) => ({ url: `/campaigns/${uuid}` }),
      providesTags: ['Campaign']
    }),

    createCampaign: builder.mutation({
      query: (data) => ({ url: `/campaigns`, method: 'POST', body: data }),
      invalidatesTags: ['Campaign', 'Folder']
    }),

    deleteCampaign: builder.mutation({
      query: (id) => ({ url: `/campaigns/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Campaign']
    }),

    updateCampaign: builder.mutation({
      query: (data) => ({ url: `/campaigns/${data.uuid || data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Campaign']
    }),

    shareCampaign: builder.mutation({
      query: (data) => ({ url: `/campaigns/${data.uuid}/share`, method: 'POST', body: data }),
      invalidatesTags: ['Campaign']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetCampaignsQuery, useGetCampaignQuery, useCreateCampaignMutation, useDeleteCampaignMutation, useUpdateCampaignMutation, useShareCampaignMutation
} = campaignsApi;
