
import {
  ModalHeader, Text, Box, Alert, AlertIcon, Input, Button, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, ScaleFade, Textarea, Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateWorkspaceMutation } from '../../api/services/workspaces';

const CreateWorkspace = ({ disclosure }) => {
  const toast = useToast();
  const { register, handleSubmit, reset } = useForm();
  const [create, { isLoading, isSuccess, isError }] = useCreateWorkspaceMutation();
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = (data) => {
    create(data).unwrap().then((response) => {
      // successful response
    }).catch((error) => {
      setErrorMessage(error.data.errors[''])
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Workspace created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      reset();
      disclosure.onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <Drawer
        size={'md'}
        isOpen={disclosure.isOpen}
        placement='right'
        onClose={disclosure.onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create new workspace</DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              </ScaleFade>}

              <FormControl>
                <FormLabel>Workspace name</FormLabel>
                <Input {...register('name', { required: true })} type='text' placeholder='Workspace name...' />
              </FormControl>

              <FormControl mt={5}>
                <FormLabel>Describe workspace</FormLabel>
                <Textarea fontSize={'sm'} {...register('description', { required: false })} placeholder='Add your description...' />
              </FormControl>
            </form>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={disclosure.onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)} colorScheme='blue'>Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateWorkspace;
