import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
  useToast
} from '@chakra-ui/react';
import SitelinkCard from './SitelinkCard';

function Sitelinks({ isOpen, onClose, initialSitelinks, setInitialSitelinks }) {
  const newSitelink = {text: '', descriptionLine_1: '', descriptionLine_2: '', finalUrl: ''};
  const [sitelinks, setSitelinks] = useState(initialSitelinks.length > 0 ? [...initialSitelinks] : [newSitelink]);
  const toast = useToast();

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSitelinks = [...sitelinks];
    updatedSitelinks[index] = { ...updatedSitelinks[index], [name]: value }
    setSitelinks(updatedSitelinks);
  };

  const handleAddSitelink = () => {
    if (sitelinks.length < 20) {
      setSitelinks([...sitelinks, newSitelink]);
    } else {
      toast({ title: 'Limit reached', description: 'You can only add up to 20 sitelinks.', status: 'error', duration: 5000, isClosable: true });
    }
  };

  const handleRemoveSitelink = (index) => {
    const updatedSitelinks = sitelinks.filter((_, i) => i !== index);
    setSitelinks(updatedSitelinks);
  };

  const handleSave = () => {

  const allValid = sitelinks.every(sitelink => sitelink.text.trim() && sitelink.finalUrl.trim());

  if (!allValid) {
    toast({
      title: 'Validation Error',
      description: 'Every sitelink has to have Text and Final URL.',
      status: 'error',
      duration: 5000,
      isClosable: true
    });
    return;
  }

    if (sitelinks.length >= 2) {
      setInitialSitelinks(sitelinks);
      onClose();
    } else {
      toast({ title: 'More sitelinks needed', description: 'Please add at least 2 sitelinks.', status: 'warning', duration: 5000, isClosable: true });
    }
  };

  const handleCancel = () => {
    setSitelinks([...initialSitelinks]);
    onClose();
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add more business information</DrawerHeader>
          <DrawerBody>
            <Stack spacing='24px'>
              {sitelinks.map((sitelink, index) => (
                <SitelinkCard
                  key={index}
                  sitelink={sitelink}
                  index={index}
                  handleInputChange={handleInputChange}
                  handleRemoveSitelink={handleRemoveSitelink}
                />
              ))}
            </Stack>
            <Button
                colorScheme='gray'
                variant={'outline'}
                mt={3}
                onClick={handleAddSitelink}
                isDisabled={sitelinks.length >= 20}
              >
                Add sitelink
              </Button>
          </DrawerBody>
          <DrawerFooter align="start" borderTopWidth='1px'>
          <Button colorScheme='gray' mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button colorScheme='red' variant='outline' onClick={handleCancel}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Sitelinks;
