import React from 'react';
import {
  Box, Flex, Icon, IconButton, Stack, MenuList, Text,
  Menu, MenuItem, MenuDivider, MenuButton, useDisclosure, HStack
} from "@chakra-ui/react";
import { useGetCampaignQuery, useUpdateCampaignMutation } from '../../api/services/campaigns';
import { useParams } from 'react-router-dom';
import { FiMessageSquare, FiMoreVertical, FiShare } from 'react-icons/fi';
import { LiaCommentSolid } from "react-icons/lia";
import { FaCircle } from "react-icons/fa";
import EditableInput from '../../components/Common/Input/EditableInput';
import CreateAdGroup from '../AdGroups/Create';
import AdGroup from '../AdGroups/AdGroup';
import Comments from '../Comments/Comments';
import Share from './Share';

const Campaign = () => {
  const { uuid } = useParams();

  const { data, isSuccess } = useGetCampaignQuery(uuid, { refetchOnMountOrArgChange: true });
  const campaign = data?.campaign;
  const hasComments = campaign?.adGroups?.some(adGroup => adGroup?.ads?.some(ad => ad?.hasComments));

  const [updateCampaign] = useUpdateCampaignMutation();
  const commentsDisclosure = useDisclosure();

  const onCampaignNameSave = (name) => {
    updateCampaign({
      uuid: campaign.uuid,
      name: name
    })
  };

  const disableEdit = campaign?.mode === 'imported'

  return (
    <>
      {isSuccess && <Box p={4} minHeight="100vh" w='100%'>
        <Flex gap={6} direction={['column', 'row']} alignItems={'center'} p={8} justifyContent={'space-between'}>
          <EditableInput isDisabled={disableEdit} letterSpacing={'-0.04rem'} fontSize={'3xl'} fontWeight={500} label={campaign?.name} onSave={onCampaignNameSave} />
          <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
            {!disableEdit && <CreateAdGroup campaign_id={campaign.id} />}
            <Menu>
              <MenuButton
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
                position={'relative'}>
                <IconButton
                  boxSize={14}
                  icon={<Icon as={FiMoreVertical} />}
                  variant={"outline"} />
                {hasComments && <FaCircle
                  color="red"
                  size="10px"
                  style={{ position: "absolute", top: 0, right: 0, margin: -3 }} />}
              </MenuButton>

              <MenuList>
                <MenuItem icon={<Icon as={FiMessageSquare} fontSize={'lg'} />} onClick={commentsDisclosure.onOpen}>
                  <HStack justifyContent={'space-between'}>
                    <Text>See comments</Text>
                    {hasComments && <FaCircle color="red" size="10px" style={{ marginLeft: "10px" }} />}
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
        {campaign?.adGroups &&
          campaign?.adGroups.map((ad_group) => (
            <AdGroup platform={campaign?.platform} ad_group={ad_group} key={ad_group.uuid} mode={campaign?.mode} disableEdit={disableEdit} />
          ))
        }

        <Comments campaign={campaign} disclosure={commentsDisclosure} />
      </Box >
      }
    </>
  );
};

export default Campaign;