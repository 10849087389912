import { Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, HStack, Icon, Input, Select, Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FiHelpCircle, FiImage } from 'react-icons/fi';
import { IoIosWarning } from "react-icons/io";
import AssetsLibraryButton from '../../../../AssetsLibrary/Manager/AssetsLibraryButton';
import AssetsLibraryPreview from '../../../../AssetsLibrary/Preview/AssetsLibraryPreview';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SellProductsSlide from './SellProductsSlide';
import SellProductsPreview from '../../../Preview/Facebook/InstantExperience/SellProductsPreview';
import { useFieldArray } from 'react-hook-form';
import { toSnakeCase, toCamelCase, transformKeysTo } from '../../../../../helpers/apiHelper';
import { sellProductsOptions } from "../../../../../components/Constants/sellProductsOptions";
import { validateUrl } from '../../../../../components/utils/urlUtils';

const warningIcon = <Icon alignSelf={'end'} color={'red.500'} as={IoIosWarning} />;

const SellProducts = ({ register, setValue, getValues, control, missingFields, setRequiredFields }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const [instantExperienceAsset, setInstantExperienceAsset] = useState(getValues('meta_instant_experience.fields_data.instant_experience_asset') ? [transformKeysTo('camel', structuredClone((getValues('meta_instant_experience.fields_data.instant_experience_asset'))))] : []);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "meta_instant_experience.fields_data.meta_instant_experience_carousel_items"
    });

    const instantExperienceDefaultValues = {
        'meta_instant_experience.instant_experience_type': 'sell_products',
        'meta_instant_experience.fields_data.destination_url': '',
        'meta_instant_experience.fields_data.button_label_1': 'Write something...',
        'meta_instant_experience.fields_data.button_destination_1': 'https://www.example.com',
        'meta_instant_experience.fields_data.description_1': 'Give people a reason to buy.',
        'meta_instant_experience.fields_data.description_2': 'Use this space to introduce your products or services and encourage people to explore and shop.',
        'meta_instant_experience.fields_data.button_label_2': 'Write something...',
        'meta_instant_experience.fields_data.button_destination_2': 'https://www.example.com',
        'meta_instant_experience.fields_data.product_count': 4,
        'meta_instant_experience.fields_data.meta_instant_experience_carousel_items': null,
        'meta_instant_experience.fields_data.instant_experience_asset': null
    };

    useEffect(() => {
        setRequiredFields([
            'meta_instant_experience.instant_experience_type',
            'meta_instant_experience.fields_data.button_label_1',
            'meta_instant_experience.fields_data.button_destination_1',
            'meta_instant_experience.fields_data.description_1',
            'meta_instant_experience.fields_data.description_2',
            'meta_instant_experience.fields_data.button_label_2',
            'meta_instant_experience.fields_data.button_destination_2',
            'meta_instant_experience.fields_data.product_count',
            'meta_instant_experience.fields_data.meta_instant_experience_carousel_items',
            'meta_instant_experience.fields_data.instant_experience_asset'
        ]);
    }, [setRequiredFields]);

    useEffect(() => {
        instantExperienceDefaultValues && Object.entries(instantExperienceDefaultValues).forEach(([key, value]) => {
            if (getValues(key) === undefined || getValues(key) == [])
                setValue(key, value);
        });
    }, []);

    const camelToSnake = (camelCaseStr) => {
        return camelCaseStr.replace(/([A-Z])/g, '_$1').toLowerCase();
    }

    const superSetInstantExperienceAssets = (assets) => {
        setInstantExperienceAsset(assets);

        if (assets[0]?.id) {
            setValue(`meta_instant_experience.fields_data.instant_experience_asset`, transformKeysTo('snake', structuredClone(assets[0])));
        }
    }

    const handleToggleSlide = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(getValues('meta_instant_experience.fields_data.meta_instant_experience_carousel_items'));

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setValue('meta_instant_experience.fields_data.meta_instant_experience_carousel_items', items);
    }

    const thumbnailsCount = getValues('meta_instant_experience.fields_data.product_count');
    useEffect(() => {

        if (thumbnailsCount === undefined) {
            setValue('meta_instant_experience.fields_data.product_count', 4);
        }
        const currentCount = fields.length;
        const newCount = Number(thumbnailsCount);

        if (newCount > currentCount) {
            for (let i = 0; i < newCount - currentCount; i++) {
                append({});
            }
        } else if (newCount < currentCount) {
            for (let i = 0; i < currentCount - newCount; i++) {
                remove(currentCount - i - 1);
            }
        }
    }, [thumbnailsCount, append, remove, fields.length]);

    return (
        <Flex direction="row" gap="5">
            <VStack w="50%" spacing={5} >
                <Card w={'full'}>
                    <CardHeader>
                        <HStack>
                            <Text>Media</Text>
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <Input type="hidden" {...register('meta_instant_experience.instant_experience_type')} value="sell_products" />
                        <FormControl mb='4'>
                            <Text mb={5} fontSize={'sm'}>Introduce your brand, product or service by using an eye-catching video or image. {missingFields.includes('meta_instant_experience.fields_data.instant_experience_asset') && warningIcon}</Text>

                            <HStack>
                                <AssetsLibraryButton assets={instantExperienceAsset} setAssets={superSetInstantExperienceAssets} types={['image', 'video']} />
                            </HStack>

                            <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                                <AssetsLibraryPreview assets={instantExperienceAsset} setAssets={superSetInstantExperienceAssets} />
                            </Stack>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Destination URL (optional) {missingFields.includes('meta_instant_experience.fields_data.destination_url') && warningIcon}</FormLabel>
                            <Input {...register('meta_instant_experience.fields_data.destination_url')} 
                            placeholder={'Destination URL...'} 
                                 onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue(`meta_instant_experience.fields_data.destination_url`, validatedUrl);
                                    }}/>
                        </FormControl>
                    </CardBody>
                </Card>

                <Card w={'full'}>
                    <CardHeader>
                        <HStack>
                            <Text>Text</Text>
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <FormControl>
                            <FormLabel>Label {missingFields.includes('meta_instant_experience.fields_data.description_1') && warningIcon}</FormLabel>
                            <Input {...register('meta_instant_experience.fields_data.description_1')} />
                        </FormControl>
                    </CardBody>
                </Card>
                <Card w={'full'}>
                    <CardHeader>
                        <HStack>
                            <Text>Text</Text>
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <FormControl>
                            <FormLabel>Label {missingFields.includes('meta_instant_experience.fields_data.description_2') && warningIcon}</FormLabel>
                            <Input {...register('meta_instant_experience.fields_data.description_2')} />
                        </FormControl>
                    </CardBody>
                </Card>
                <Card w={'full'}>
                    <CardBody>
                        <FormControl>
                            <FormLabel>Sell products count {missingFields.includes('meta_instant_experience.fields_data.product_count') && warningIcon}</FormLabel>
                            <Select {...register('meta_instant_experience.fields_data.product_count')}>
                                {sellProductsOptions.map((ct, index) => (
                                    <option value={ct.value} key={index}>{ct.value}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </CardBody>
                </Card>

                <Card w={'full'}>
                    <CardHeader>
                        <HStack>
                            <Text>Product list</Text>
                            <Icon as={FiHelpCircle} />
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <Text mb={5} fontSize={'sm'}>Feature 4-8 items so that people can browse and shop. Upload an image and add a headline, description and destination URL for each item. {missingFields.includes('meta_instant_experience.fields_data.meta_instant_experience_carousel_items') && warningIcon}</Text>

                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                        {fields.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <SellProductsSlide
                                                            isOpen={index === openIndex}
                                                            onToggle={() => handleToggleSlide(index)}
                                                            setValue={setValue} getValues={getValues}
                                                            register={register} index={index}
                                                            provided={provided} item={item}
                                                            remove={() => remove(index)}
                                                            attributeName='meta_instant_experience.fields_data.meta_instant_experience_carousel_items' />
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </CardBody>
                </Card>
                <Card w={'full'}>
                    <CardHeader>
                        <HStack>
                            <Text>Button</Text>
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <FormControl>
                            <FormLabel>Label {missingFields.includes('meta_instant_experience.fields_data.button_label_1') && warningIcon}</FormLabel>
                            <Input {...register('meta_instant_experience.fields_data.button_label_1')} placeholder={'Add label here...'} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Destination {missingFields.includes('meta_instant_experience.fields_data.button_destination_1') && warningIcon}</FormLabel>
                            <Input {...register('meta_instant_experience.fields_data.button_destination_1')} placeholder={'Add destination here...'} />
                        </FormControl>
                    </CardBody>
                </Card>
            </VStack>
            <VStack w="50%" spacing={5}>
                <SellProductsPreview instantExperienceData={{
                    headline: getValues('meta_instant_experience.fields_data.headline'),
                    description_1: getValues('meta_instant_experience.fields_data.description_1'),
                    description_2: getValues('meta_instant_experience.fields_data.description_2'),
                    buttonLabel_1: getValues('meta_instant_experience.fields_data.button_label_1'),
                    buttonDestination_1: getValues('meta_instant_experience.fields_data.button_destination_1'),
                    buttonLabel_2: getValues('meta_instant_experience.fields_data.button_label_2'),
                    buttonDestination_2: getValues('meta_instant_experience.fields_data.button_destination_2'),
                    carouselText: getValues('meta_instant_experience.fields_data.carousel_text'),
                    metaInstantExperienceCarouselItems: getValues('meta_instant_experience.fields_data.meta_instant_experience_carousel_items'),
                    instantExperienceAsset: instantExperienceAsset[0]
                }} />
            </VStack>
        </Flex >
    );
};

export default SellProducts;