import { Box, Stack, Spinner } from "@chakra-ui/react";
import React from "react";

const Status = ({ status }) => {
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>

        <Box
          w="12px"
          h="12px"
          fontSize="10px"
          bg={status == 'draft' ? 'orange' : 'green'}
          borderRadius="12px"
        />

        <Box fontSize="12px">{status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}</Box>
      </Stack>
    </>
  )
};


export default Status;
