import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Stack, Button, Box } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import { googleDemandgenCallToActions } from "../../../../../../components/Constants/google_demandgen_call_to_actions";
import DesktopLayout from "./DesktopLayout";

const DesktopOpened = ({ variations, adType = 'image' }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
      <Center>
        <HStack spacing={10} mb={4}>
          <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
          <IconButton isDisabled={activeStep == 1} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
        </HStack>
      </Center>

      <DesktopLayout>
        <HStack justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Avatar size={'sm'} src={variations[0].logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <HStack>
                <Text fontWeight={'semibold'} fontSize={'xs'}>{variations[0].businessName}</Text>
              </HStack>
              <Text fontSize={'x-small'}>me</Text>
            </VStack>
          </HStack>

          <HStack>
            <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'x-small'} px={1}>Ad</Text>
            <Icon as={FiMoreVertical} />
          </HStack>
        </HStack>



        {adType != 'carousel' && <Stack gap={10} alignItems={'center'} direction={activeStep == 0 ? 'column' : 'row'}>
          <Image mt={5} borderRadius={'lg'} w={activeStep == 0 ? 'xs' : '50%'} src={variations[0].imageUrl} />

          <VStack alignItems={activeStep == 0 ? 'center' : 'flex-start'} w={'full'}>
            <Text fontWeight={'semibold'} fontSize={'2xl'}>{variations[0].headline?.truncate(30)}</Text>
            <Text color={'gray.500'}>{variations[0].description?.truncate(90)}</Text>

            <Button mt={5} borderRadius={'md'} w={'fit-content'} fontSize={'sm'} fontWeight={'bold'} py={2} bg={'blue.500'}>{googleDemandgenCallToActions.find((i) => i.value == variations[0]?.cta)?.label || 'Learn more'}</Button>
          </VStack>

        </Stack>}

        {adType == 'carousel' && <>
          <Box h={'md'} overflowY={'scroll'}>
            {variations[0]?.carouselItems.map((item, index) => (

              <>
                <Stack gap={10} alignItems={'center'} direction={activeStep == 0 ? 'column' : 'row'}>
                  <Image mt={5} borderRadius={'lg'} w={activeStep == 0 ? 'xs' : '50%'} src={item?.asset?.fileUrl} />

                  <VStack alignItems={activeStep == 0 ? 'center' : 'flex-start'} w={'full'}>
                    <Text fontWeight={'semibold'} fontSize={'2xl'}>{item?.headline?.truncate(30)}</Text>
                    <Text color={'gray.500'}>{item?.description?.truncate(90)}</Text>

                    <Button mt={5} borderRadius={'md'} w={'fit-content'} fontSize={'sm'} fontWeight={'bold'} py={2} bg={'blue.500'}>{googleDemandgenCallToActions.find((i) => i.value == item?.callToAction)?.label || 'Learn more'}</Button>
                  </VStack>
                </Stack>
              </>))}
          </Box>
        </>}
      </DesktopLayout>
    </>
  )
};

export default DesktopOpened;