import React, { useEffect } from 'react';
import { Box, Center, Divider, Heading, Input, Button, Link, Alert, AlertIcon, ScaleFade, Text, Checkbox, Icon, Stack, Grid, Flex, VStack, Spacer, HStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useGetAccessTokenMutation } from '../../api/services/auth';
import { useForm } from 'react-hook-form';
import { loginSession } from '../../helpers/sessionHelper';
import { Link as ReachLink } from 'react-router-dom';
import { FiLifeBuoy, FiLock, FiLogIn, FiUserPlus } from 'react-icons/fi';


const Login = () => {
  const location = useLocation();

  const { register, handleSubmit, formState: { isValid } } = useForm();
  const { accessToken } = useSelector((state) => state.auth)
  const [getAccessToken, { isError, isLoading }] = useGetAccessTokenMutation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const returnUrl = urlParams.get("returnUrl");

  const isAccessTokenPresent = accessToken?.length > 0;

  const onSubmit = async data => {
    await getAccessToken(data).unwrap()
      .then((response) => {
        const token = response['token'];
        const refreshToken = response['refreshToken'];
        loginSession(token, refreshToken, dispatch)
      })
      .catch((error) => { });
  };


  return (
    <>
      <Spacer />
      <Box w={'full'} justifyContent={'center'} p={10} display={'flex'}>
        <Stack flexDir={['column-reverse', 'row']} maxW={'container.xl'} direction={['column', 'row']} gap={'7rem'}>
          <Box w={['full', '2xl']} mt={10} >
            <Heading as="h3" fontWeight={400} size="xl">Simplify Your Digital Advertising Journey - From Concept to Launch</Heading>
            <Text color="gray.400" mt={5}>Navigate the complexities of digital ad creation with ease. Our platform bridges the gap between advertising agencies and their clients, ensuring a seamless and understandable journey from the initial concept to the final launch.</Text>
          </Box>

          <Box w={'full'}>
            <Heading as="h2" fontWeight={400} textAlign="center" mb={6}>
              Sign in
            </Heading>

            {isError &&
              <ScaleFade initialScale={0.9} in={isError}>
                <Alert mt={5} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  Incorrect email or password.
                </Alert>
              </ScaleFade>}

            {isAccessTokenPresent &&
              <ScaleFade initialScale={0.9} in={isAccessTokenPresent}>
                <Alert mt={5} fontSize={'sm'} status='success'>
                  <AlertIcon />
                  Success! Please wait...
                </Alert>

                {returnUrl && <Navigate to={returnUrl} />}
                {!returnUrl && <Navigate to="/campaigns" />}
              </ScaleFade>}

            <form mt={4} onSubmit={handleSubmit(onSubmit)}>
              <Box mb={6}>
                <Text fontSize={'sm'} mb={4} fontWeight={'500'}>E-Mail</Text>
                <Input placeholder="name@example.com" {...register('email', { required: true })} name="email" />
              </Box>

              <Box mb={3}>
                <Text fontSize={'sm'} mb={4} fontWeight={'500'}>Password</Text>
                <Input placeholder="************" {...register('password', { required: true })} name="password" type="password" />
              </Box>

              <Box mt={5} mb={5}>
                <Checkbox size={'sm'} defaultChecked>Remember me next time</Checkbox>
              </Box>
              <HStack mt={4} justifyContent="space-between">
                <Button leftIcon={<Icon as={FiLogIn} />} isLoading={isLoading} isDisabled={!isValid} type="submit" colorScheme="brand" width="auto">
                  Sign In
                </Button>
                <HStack>
                  <Button as={ReachLink} to={'/forgot-password'} leftIcon={<Icon as={FiLock} />} variant="outline" colorScheme="brand" size="sm">Forgot password?</Button>
                  <Button as={ReachLink} to="/register" leftIcon={<Icon as={FiUserPlus} />} variant="outline" colorScheme="brand" size="sm">Sign Up</Button>
                </HStack>
              </HStack>
            </form>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Login;
