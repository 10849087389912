import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Box,
  FormLabel,
  Input,
  FormControl,
  HStack,
  Collapse,
  Icon,
  InputGroup,
  InputRightElement,
  Spacer,
  Link,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiDownloadCloud, FiExternalLink } from 'react-icons/fi';
import { useGetIntegrationsQuery } from '../../api/services/integrations';
import { iconMap } from '../Integrations/Components/ProviderLabel';
import GoogleFlow from './Import/GoogleFlow';
import FacebookFlow from './Import/FacebookFlow';
import TiktokFlow from './Import/TiktokFlow';
import { useFolder } from './Contexts/FolderContext';

const Import = ({ disclosure }) => {
  const { isOpen, onOpen, onClose } = disclosure;
  const providerDisclosure = useDisclosure();
  const { setFolder, folder } = useFolder();

  const [provider, setProvider] = useState({});
  const [selectedProvider, setSelectedProvider] = useState({});

  const { data: integrationsData } = useGetIntegrationsQuery({ page: 1, per_page: 20 });

  useEffect(() => {
    if (!disclosure.isOpen) {
      setFolder(null);
    }
  }, [disclosure.isOpen])
  return (
    <>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Import campaign {folder != null ? `into ${folder?.name}` : ''}</Text>
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <ModalBody>

            {/** Step 1 (Select Provider) */}

            {selectedProvider?.provider == null && (
              <>
                {integrationsData?.integrations?.length > 0 && <>
                  <FormControl mt={0}>
                    <FormLabel>Select provider</FormLabel>
                    <InputGroup alignItems={'center'} cursor={'pointer'} onClick={providerDisclosure.onToggle}>
                      <Input textTransform={'capitalize'} value={provider.provider != null ? `${provider.provider} (${provider.name}, ${provider.accountId})` : ''} cursor={'pointer'} type='text' readOnly placeholder='Choose integration provider...' />
                      <InputRightElement p={5}>
                        {providerDisclosure.isOpen ? <FiChevronUp /> : <FiChevronDown />}
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Collapse in={providerDisclosure.isOpen} animateOpacity>
                    <Box my={5} overflow={'visible'} borderRadius={'md'} border={'solid 1px'} borderColor={'brand.500'} boxShadow={'lg'}>
                      {integrationsData?.integrations?.map((item, index) => {
                        return (
                          <HStack rounded={'md'} key={index} onClick={() => { setProvider(item); providerDisclosure.onClose(); }} _hover={{ color: 'brand.500' }} cursor={'pointer'} color={provider == item.provider ? 'brand.500' : 'gray.500'} borderBottom={index == 3 ? '' : 'solid 1px'} borderColor={'gray.200'} gap={3} p={4}>
                            <Icon as={iconMap[item.provider]} fontSize={'xl'} />
                            <Text textTransform={'capitalize'} fontSize={'sm'}>{item.provider} ({item.name}, {item.accountId})</Text>
                          </HStack>
                        )
                      })}
                    </Box>
                  </Collapse>

                  <Spacer h={'20px'} />

                  <HStack mb={5}>
                    <Button onClick={onClose} variant='outline'>Cancel</Button>
                    <Button onClick={() => setSelectedProvider(provider)} colorScheme='primary' mr={3} >
                      Next
                    </Button>
                  </HStack>
                </>}

                {integrationsData?.integrations?.length == 0 && <>
                  <Text fontSize={'sm'}>
                    In order to import an existing campaign you first need to connect your (Google Ads, Meta, TikTok) Business account. <br /><br />
                    You can do this by navigating to <strong>Settings > Integrations</strong>, or by clicking the button below:
                  </Text>
                  <Link href="/integrations">
                    <Button leftIcon={<Icon as={FiExternalLink} />} mt={10} mb={4}>Connect Business account</Button>
                  </Link>
                </>}

              </>
            )}

            {selectedProvider?.provider &&
              <>
                {selectedProvider?.provider == 'google' && <GoogleFlow onClose={onClose} setProvider={setSelectedProvider} provider={selectedProvider} />}
                {selectedProvider?.provider == 'facebook' && <FacebookFlow onClose={onClose} setProvider={setSelectedProvider} provider={selectedProvider} />}
                {selectedProvider?.provider == 'tiktok' && <TiktokFlow onClose={onClose} setProvider={setSelectedProvider} provider={selectedProvider} />}
              </>
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const ImportContainer = ({ disclosure = useDisclosure() }) => {
  return (<>
    <Button size={'sm'} onClick={disclosure.onOpen} leftIcon={<Icon as={FiDownloadCloud} />} variant='outline'>Import campaign</Button>

    {disclosure.isOpen && <Import disclosure={disclosure} />}
  </>)
};

export default ImportContainer;