import { Text, Spacer } from "@chakra-ui/react";
import React, { } from "react";
import { FiLock } from 'react-icons/fi';
import SidebarLink from "../SidebarLink";

const DowngradingSidebar = () => {

  return (
    <>

      <Text p={7} pb={4} fontWeight={'bold'} color={'gray'}>Settings</Text>

      <SidebarLink to="/downgrading" icon={FiLock} text="Downgrade subscription" />

      <Spacer />
    </>
  )
}

export default DowngradingSidebar;