import React, { useEffect } from 'react';
import { Flex, Box, Text, Button, useDisclosure, Collapse, Icon, HStack, Stack, Menu, MenuButton, MenuItem, MenuList, MenuDivider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Center } from "@chakra-ui/react";
import { Link, useParams } from 'react-router-dom';
import Ad from './Ad';
import { FiChevronDown, FiChevronUp, FiMoreVertical, FiPlus, FiPlusSquare, FiTrash2 } from 'react-icons/fi';

const AdGroup = ({ ad_group, platform, disableComments = false }) => {
  const { uuid } = useParams();
  const { isOpen, onToggle, } = useDisclosure({ defaultIsOpen: true });

  return (
    <>
      <Flex borderBottom={'solid 1px'} borderBottomColor={'gray.300'} onClick={() => onToggle()} cursor={'pointer'} id={ad_group?.uuid} gap={6} direction={['column', 'row']} alignItems={'center'} p={0} py={2} mt={4} mx={8} justifyContent={'space-between'}>
        <Text letterSpacing={'-0.04rem'} fontSize={'2xl'} fontWeight={500}>{ad_group.name}</Text>

        {ad_group?.ads?.length > 0 && <>
          <HStack onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} gap={8}>
            <Icon as={isOpen ? FiChevronUp : FiChevronDown} />
          </HStack>
        </>}
      </Flex>

      <Collapse w={'full'} in={isOpen}>
        <Stack flexDir={'row'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={10} >
          {ad_group?.ads.map((ad) => (
            <Ad
              key={ad.uuid}
              ad={ad}
              platform={platform}
              disableComments={disableComments}
            />
          ))}
        </Stack>
      </Collapse>
    </>
  );
};

export default AdGroup;