import React, { useEffect } from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputField,
    Select,
    Text,
    Divider,
    Flex,
    VStack,
    InputGroup,
    InputRightElement,
    useToast, Box,
    HStack,
    Icon
} from '@chakra-ui/react';
import { currencies } from "../../components/Constants/currencies";
import { useParams } from 'react-router-dom';
import { useGetMediaPlanQuery, useUpdateMediaPlanMutation } from '../../api/services/mediaPlans';
import ColumnManagement from './ColumnManagement';
import { useDispatch } from 'react-redux';
import { setColumns } from '../../api/slices/mediaPlanColumnSlice';
import { FiEdit, FiLayout } from 'react-icons/fi';

const MediaPlanSettings = ({ isOpen, onClose }) => {
    const { id } = useParams();
    const { data, error, isLoading, isSuccess } = useGetMediaPlanQuery(id);
    const mediaPlan = data?.mediaPlan;
    const isApproved = mediaPlan?.status == 'approved';
    const toast = useToast();
    const [mediaPlanName, setMediaPlanName] = React.useState('');
    const [agencyFee, setAgencyFee] = React.useState('');
    const [selectedCurrency, setSelectedCurrency] = React.useState('');
    const [taxes, setTaxes] = React.useState('');
    const [showColumnManagement, setShowColumnManagement] = React.useState(false);
    const [updateMediaPlan] = useUpdateMediaPlanMutation();

    const handleEditColumnsClick = () => setShowColumnManagement(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isSuccess && mediaPlan) {
            setMediaPlanName(mediaPlan.name || '');
            setAgencyFee(mediaPlan.agencyFee || '');
            setTaxes(mediaPlan.taxes || '');
            setSelectedCurrency(mediaPlan.currencyCode || '');

            dispatch(setColumns(mediaPlan?.columnSettings?.columns));
        }
    }, [mediaPlan, isSuccess]);

    const handleMediaPlanNameChange = (e) => setMediaPlanName(e.target.value);
    const handleCurrencyChange = (e) => setSelectedCurrency(e.target.value);
    const handleAgencyFeePercentageChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setAgencyFee(value);
        }
    };

    const handleTaxRateChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setTaxes(value);
        }
    };

    const handleSave = () => {
        try {
            updateMediaPlan({
                id: mediaPlan.id,
                name: mediaPlanName,
                agencyFee,
                currencyCode: selectedCurrency,
                taxes,
            }).unwrap();
            onClose();
            toast({
                title: "Success!",
                description: "The media plan settings have been successfully configured.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
        }

    };

    return (
        <>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={'sm'}>
                <DrawerOverlay />
                <DrawerContent transform={isOpen && showColumnManagement ? 'none !important' : ''} overflowY={'auto'}>
                    <DrawerCloseButton />
                    {showColumnManagement && !isLoading ? (
                        <ColumnManagement mediaPlan={data?.mediaPlan} onBack={() => setShowColumnManagement(false)} onClose={onClose} />
                    ) : (
                        <VStack align="stretch">
                            <DrawerHeader>
                                <Text textAlign="left">{mediaPlanName} Settings</Text>
                            </DrawerHeader>
                            <Text textAlign="left" fontSize="xs" color="gray.600" px={6}>
                                Media plan settings define how and where ads are placed<br />
                                to maximize and reach target audiences effectively.
                            </Text>
                            <Divider borderColor="gray.300" mt={4} />
                            <DrawerBody>
                                <VStack spacing={4}>
                                    <FormControl>
                                        <FormLabel fontSize={'sm'} mb={3}>Name</FormLabel>
                                        <Input isDisabled={isApproved} borderRadius={'5px'} value={mediaPlanName} onChange={handleMediaPlanNameChange} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel mb={3} fontSize={'sm'}>Agency Fee Percentage</FormLabel>
                                        <InputGroup>
                                            <Input isDisabled={isApproved} borderRadius={'5px'} value={agencyFee} onChange={handleAgencyFeePercentageChange} />
                                            <InputRightElement children="%" mt={-1} />
                                        </InputGroup>
                                    </FormControl>
                                    <Flex w="full">
                                        <FormControl mr={2} flex="1">
                                            <FormLabel fontSize={'sm'} mb={3}>Tax Rate</FormLabel>
                                            <InputGroup>
                                                <Input isDisabled={isApproved} borderRadius={'5px'} value={taxes} onChange={handleTaxRateChange} />
                                                <InputRightElement children="%" mt={-1} />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl flex="1">
                                            <FormLabel fontSize={'sm'} mb={3}>Currency</FormLabel>
                                            <Select isDisabled={isApproved} borderRadius={'5px'} value={selectedCurrency} onChange={handleCurrencyChange} >
                                                {currencies.map((ct, index) => (
                                                    <option value={ct.value} key={index}>{ct.value}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Flex>
                                    <Divider borderColor="gray.300" mt={8} />
                                    <Box mb={4}>
                                        <Text fontSize="lg" fontWeight="bold" color="gray.700" ml={1} mb={2}>
                                            Column Management
                                        </Text>
                                        <HStack>
                                            <Text textAlign="left" fontSize="xs" color="gray.600" px={6} ml={-5}>
                                                Column management lets users customize their view by hiding or showing and rearranging columns as needed.
                                            </Text>
                                            <Box>
                                                <Button isDisabled={isApproved} id="editColumns" leftIcon={<Icon as={FiLayout} />} size={'sm'} variant="outline" colorScheme='primary' onClick={handleEditColumnsClick}>
                                                    Manage Columns
                                                </Button>
                                            </Box>

                                        </HStack>
                                    </Box>
                                </VStack>
                            </DrawerBody>
                            <DrawerFooter>
                                <Button variant="outline" borderRadius={'5px'} width={'100px'} mr={3} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button onClick={handleSave} borderRadius={'5px'} width={'100px'} isLoading={isLoading}>
                                    Save
                                </Button>
                            </DrawerFooter>
                        </VStack>
                    )}
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default MediaPlanSettings;