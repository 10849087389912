import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Skeleton, Image, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder } from 'react-icons/fa6';
import { FiExternalLink, FiMoreVertical } from 'react-icons/fi';
import { googlePmaxCallToActions } from '../../../../../../components/Constants/google_pmax_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const HomePageAd2 = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Home page ad</Text>

      <Phone p={3}>
        <HStack justifyContent={'space-between'}>
          <Image w={20} src={YoutubeLogo} />
          <Avatar size={'sm'} />
        </HStack>

        <HStack mt={4} w={'full'}>
          <Box>
            <SkeletonCircle startColor="gray.400" endColor="gray.400" size='10' />
          </Box>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
        </HStack>

        <Image w={'full'} mt={4} src={variation.imageUrl} />

        <HStack justifyContent={'space-between'} mt={2} p={1}>
          <Text fontWeight={'bold'} fontSize={'sm'}>{variation.headline?.truncate(30)}</Text>
          <Icon color={'black.500'} fontSize={'sm'} as={FiMoreVertical} />
        </HStack>

        <Text p={1} fontSize={'xs'} color={'gray.600'}>
          {variation.description?.truncate(90)}
        </Text>
        <HStack justifyContent={'space-between'}>
          <VStack alignItems={'flex-start'} spacing={0} p={1}>
            <Text color={'gray'} fontSize={'xs'} >{variation.businessName}</Text>
            <Text rounded={'md'} bg={'yellow.400'} color={'white'} py={0} px={1} fontSize={'xs'}>Ad</Text>
          </VStack>
          <Text bg={'blue.50'} px={4} rounded={'md'} border={'solid 1px'} borderColor={'blue.300'} py={1} fontWeight={'bold'} color={'blue.500'} fontSize={'xs'}>{googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Text>
        </HStack>


        <Skeleton mt={4} startColor="gray.400" endColor="gray.400" height='50px' />

        <HStack mt={10} px={5} w={'full'} justifyContent={'space-between'}>
          <Icon color={'red.500'} as={FaHouse} />
          <Icon color={'gray.500'} as={FaFire} />
          <Icon color={'gray.500'} as={FaVideo} />
          <Icon color={'gray.500'} as={FaBell} />
          <Icon color={'gray.500'} as={FaFolder} />
        </HStack>

      </Phone>
    </>
  )
};

export default HomePageAd2;