import React, { useState, useEffect } from 'react';
import { Box, Text, Divider, Button, Flex, Input, useToast, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

const Confirmation = ({ goToStep, selectedPlan }) => {
    const navigate = useNavigate();
    const isEnterpriseMonthly = selectedPlan === 'enterprise_monthly';

    const handleStartClick = () => {
        navigate('/campaigns');
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            px={4}
        >
            <Box width="100%" textAlign="center" mb={8}>
                {isEnterpriseMonthly ? (
                    <>
                        <Icon mt={10} fontSize={'2rem'} color={'green'} as={FiCheckCircle} />

                        <Text mt={3} fontSize="xl" fontWeight="bold" mb={2}>Thank you for your interest in our Enterprise plan.</Text>
                        <Text fontSize="sm" color="gray.600">
                            Our team will be in touch with you shortly to discuss your needs and how we can assist you.
                        </Text>
                        <Button mt={10} onClick={() => goToStep(1)}>
                            Back
                        </Button>
                    </>
                ) : (
                    <>
                        <Text fontSize="2xl" fontWeight="bold" mb={2}>Excellent!</Text>
                        <Text fontSize="lg" color="gray.600">
                            You have successfully completed the onboarding process. You can now start using WhatCampaign.
                        </Text>
                        <Flex justify="center">
                            <Button colorScheme="blue" onClick={handleStartClick} size="lg">Start</Button>
                        </Flex>
                    </>
                )}
            </Box>

        </Flex >
    );
};

export default Confirmation;