import React, { useState, useEffect } from 'react';
import { Box, Icon, Text, Button, VStack, Spinner, HStack, Checkbox, Divider, Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton, Spacer, useToast } from "@chakra-ui/react";
import { FiTable, FiExternalLink, FiLock, FiPackage, FiDownload, FiUsers, FiUnlock } from 'react-icons/fi';
import { Flex } from '@chakra-ui/react';
import { useCreateStripePortalSessionMutation } from '../../api/services/subscriptions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetWorkspacesQuery } from '../../api/services/workspaces';
import { useGetUsersQuery } from '../../api/services/users';
import { useDowngradeOrganizationMutation } from '../../api/services/organizations';
import { setOrganizationState } from '../../api/slices/usersSlice';

const Downgrading = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);

  const [selectedUsers, setSelectedUsers] = useState([currentUser?.id]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  const { data: workspacesData, isLoading: isLoadingWorkspaces } = useGetWorkspacesQuery({}, { refetchOnMountOrArgChange: true });
  const workspaces = workspacesData?.workspaces;

  const { data: usersData, isLoading: isLoadingUsers } = useGetUsersQuery({ per_page: 1000, page: 1 }, { refetchOnMountOrArgChange: true });
  const users = usersData?.users;

  const [downgradeOrganization, { isLoading, isSuccess }] = useDowngradeOrganizationMutation();

  if (currentUser?.organization?.state != 'downgrading') {
    navigate('/billing');
  }

  const handleSubmit = async () => {
    downgradeOrganization({
      users: selectedUsers,
      workspaces: selectedWorkspaces
    })
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/billing';
    }
  }, [isSuccess])

  const handleWorkspaceChange = (id) => {
    if (selectedWorkspaces.length == 2 && !selectedWorkspaces.some((item) => item == id)) {
      setSelectedWorkspaces([...selectedWorkspaces.slice(0, 1), id]);
      return;
    }

    if (selectedWorkspaces.some((item) => item == id)) {
      setSelectedWorkspaces(selectedWorkspaces.filter((item) => item != id));
    } else {
      setSelectedWorkspaces([...selectedWorkspaces, id]);
    }
  }

  const handleUserChange = (id) => {
    if (selectedUsers.length == 2 && !selectedUsers.some((item) => item == id)) {
      setSelectedUsers([...selectedUsers.slice(0, 1), id]);
      return;
    }

    if (selectedUsers.some((item) => item == id)) {
      setSelectedUsers(selectedUsers.filter((item) => item != id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  }


  return (
    <Box p={10}>

      <Alert mb={10} status='warning' fontSize={'xs'} borderRadius={'lg'}>
        <AlertIcon />
        <Box ml={5}>
          <AlertDescription>
            Downgrading will delete all your data except for 2 workspaces and 2 users. If you wish to return to the previous plan please contact us.
          </AlertDescription>
        </Box>
      </Alert>

      <HStack spacing={10} alignItems={'center'}>
        <Icon fontSize={'2rem'} color={'black'} as={FiDownload} />

        {currentUser?.organizationRole != 'owner' &&
          <Box>

            <Text fontSize="lg" fontWeight={'bold'}>
              Your organization has been deactivated.
            </Text>
            <Text fontSize="sm" mt={5}>
              Please contact your organization owner to reactivate your account.
            </Text>
          </Box>
        }

        {currentUser?.organizationRole == 'owner' && <Box>
          <Text fontSize="lg" fontWeight={'bold'} >
            Finish downgrading your subscription.
          </Text>
          <Text fontSize="sm" mt={2}>
            Please complete the form below in order to reactivate your organization.
          </Text>
        </Box>}
      </HStack>

      {currentUser?.organizationRole == 'owner' && <Box mt={10}>
        {isLoadingWorkspaces || isLoadingUsers ? <Spinner /> : <></>}

        <HStack alignItems={'flex-start'} spacing={10}>
          <VStack border={'solid 1px'} borderColor={'gray.300'} w={'full'} boxShadow={'base'} p={5} borderRadius={'lg'} spacing={5} alignItems={'flex-start'}>
            <HStack spacing={5}>
              <Icon as={FiTable} />
              <VStack spacing={0} alignItems={'flex-start'}>
                <Text fontWeight={'semibold'}>Workspaces</Text>
                <Text color={'gray.500'} fontSize={'xs'}>Please select 2 workspaces to remain active.</Text>
              </VStack>
            </HStack>

            <Divider />

            {workspaces?.map((workspace) => {
              return (
                <Box key={workspace.id}>
                  <Checkbox onChange={() => handleWorkspaceChange(workspace.id)} isChecked={selectedWorkspaces.some((item) => item == workspace.id)} size={'sm'}>
                    {workspace.name}
                  </Checkbox>
                </Box>
              )
            })}
          </VStack>

          <VStack border={'solid 1px'} borderColor={'gray.300'} w={'full'} boxShadow={'base'} p={5} borderRadius={'lg'} spacing={5} alignItems={'flex-start'}>
            <HStack spacing={5}>
              <Icon as={FiUsers} />

              <VStack spacing={0} alignItems={'flex-start'}>
                <Text fontWeight={'semibold'}>Users</Text>
                <Text color={'gray.500'} fontSize={'xs'}>Please select 2 users to remain active.</Text>

              </VStack>
            </HStack>

            <Divider />


            {users?.map((user) => {
              return (
                <Box key={user.id}>
                  <Checkbox onChange={() => handleUserChange(user.id)} isChecked={selectedUsers.some((item) => item == user.id)} isDisabled={user.id == currentUser?.id} size={'sm'}>
                    {user.email} {user.id == currentUser?.id ? '(You)' : ''}
                  </Checkbox>
                </Box>
              )
            })}
          </VStack>
        </HStack>

        <HStack alignItems={'flex-start'} mt={10}>
          <Box>
            <Button isLoading={isLoading} isDisabled={selectedUsers?.length < 2 || selectedWorkspaces?.length < 2} onClick={() => handleSubmit()} size={'sm'} leftIcon={<Icon as={FiUnlock} />}>Reactivate Organization</Button>
          </Box>
        </HStack>

        <Spacer h={'100px'} />
      </Box>
      }
    </Box>
  );
};

export default Downgrading;
