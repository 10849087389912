import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, Spacer, Text, useToast, VStack, Spinner, Icon } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import ColorPicker from './ColorPicker'
import AssetsLibraryButton from '../AssetsLibrary/Manager/AssetsLibraryButton'
import { useSelector } from 'react-redux'

import slugify from 'slugify'

import { useGetOrganizationQuery, useUpdateOrganizationMutation } from './../../api/services/organizations'
import { FiImage } from 'react-icons/fi'

const BrandingSettings = () => {
    const { data: brandingData, isLoading: isDataLoading, isSuccess: isDataSuccess } = useGetOrganizationQuery({});

    const currentUser = useSelector((state) => state.user.currentUser);

    const { register, getValues, setValue, handleSubmit, formState: { errors, isDirty }, watch } = useForm()

    const displayName = watch('display_name');

    const [brandSlug, setBrandSlug] = useState(brandingData?.organization?.slug != null ? brandingData?.organization?.slug : '');

    useEffect(() => {
        if (brandingData?.organization?.slug) {
            setBrandSlug(brandingData.organization.slug);
        }
    }, [brandingData]);

    const [logo, setLogo] = useState(brandingData?.organization?.organizationLogo != null ? [brandingData.organization.organizationLogo] : []);
    const [favicon, setFavicon] = useState(brandingData?.organization?.organizationFavicon != null ? [brandingData.organization.organizationFavicon] : []);
    const [cover, setCover] = useState(brandingData?.organization?.organizationCover != null ? [brandingData?.organization.organizationCover] : []);

    const [update, { isSuccess, isError }] = useUpdateOrganizationMutation();

    const toast = useToast();

    const onSubmit = (data) => {
        data = {
            ...data,
            id: currentUser.organizationId,
            slug: brandSlug,
            logo_id: logo[0]?.id,
            favicon_id: favicon[0]?.id,
            cover_id: cover[0]?.id
        }

        update(data)
    }

    useEffect(() => {
        if (isSuccess) {
            toast({
                description: "Agency branding data updated successfully! 🚀 Should you need any further assistance or have any questions, feel free to reach out to our support team.",
                status: "success",
            });
        }
        if (isError) {
            toast({
                description: "Agency branding data can not be updated at this moment. Should you need any further assistance or have any questions, feel free to reach out to our support team.",
                status: "error",
            });
        }
    }, [isSuccess || isError]);

    useEffect(() => {
        if (!isDataLoading) {
            setBrandSlug(brandingData?.organization?.slug)
            setLogo([brandingData?.organization.organizationLogo]);
            setFavicon([brandingData?.organization.organizationFavicon]);
            setCover([brandingData?.organization.organizationCover]);
        }
    }, [isDataLoading]);

    return (
        <>
            {(isDataLoading) && <Box p={10} minHeight="100vh" w={'full'}>
                <Spinner />
            </Box>}

            {isDataSuccess &&
                <Box mt={10}>
                    <Flex px={10} flexDir={'column'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                            Branding
                        </Heading>

                        <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
                            Customize your client portal with Agency Name's logo, favicon, and brand colors.
                        </Text>
                    </Flex>

                    <Box maxW='2xl' mt={5} px={10}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <VStack spacing={14} alignItems={'left'} maxW="100%" bg="white">
                                <FormControl>
                                    <FormLabel fontSize={'sm'} mb={2}>Agency Name</FormLabel>
                                    <Text fontSize={'xs'} mb={4} color={'gray.500'}>This name will be displayed on your company public profile</Text>
                                    <Input {...register("display_name")} defaultValue={brandingData?.organization.displayName} maxLength={32} />

                                    <HStack mt={'0.5rem'}>
                                        <Text fontSize={'xs'} color={'gray.500'}>Your organization's custom link for sharing will look like:</Text>
                                        <Text whiteSpace={'nowrap'} fontSize={'xs'} p={2} borderRadius={'md'} bg={'gray.100'} textStyle={'italic'}>https://www.whatcampaign.com/portal/<Text as="span" color="var(--chakra-colors-brand-500)">{brandSlug}</Text></Text>
                                    </HStack>
                                </FormControl>

                                <VStack alignItems={'left'}>
                                    <FormControl>
                                        <FormLabel fontSize={'sm'}>Agency Favicon</FormLabel>
                                        <Text fontSize={'xs'} mb={4} color={'gray.500'}>This image will be displayed as a favicon on your company public profile.</Text>
                                    </FormControl>
                                    <HStack>
                                        <Flex w={'6rem'} justifyContent={'center'} alignItems={'center'}>
                                            {favicon == null || favicon[0]?.fileUrl == null ?
                                                <Flex padding={4} bg={'#C8CBCE'} borderRadius={'100%'} w={'1rem'} h={'1rem'} justifyContent={'center'} alignItems={'center'} alignSelf={'stretch'}>
                                                    <Icon as={FiImage} w={'0.75rem'} h={'0.75rem'} stroke={'#656972'} />
                                                </Flex> :
                                                <Image w={'1rem'} src={favicon[0]?.fileUrl} />
                                            }
                                        </Flex>

                                        <VStack ml={4} alignItems={'flex-start'}>
                                            <FormLabel fontSize={'xs'} color={'gray.500'}>Your favicon size should be less than 10 KB</FormLabel>
                                            <AssetsLibraryButton assets={favicon} setAssets={setFavicon} type={'image'} label="Choose file" />
                                        </VStack>
                                    </HStack>
                                </VStack>

                                <VStack alignItems={'left'}>
                                    <FormControl>
                                        <FormLabel fontSize={'sm'}>Agency Logo</FormLabel>
                                        <Text fontSize={'xs'} mb={4} color={'gray.500'}>This image will be displayed as a logo on your company public profile</Text>
                                    </FormControl>

                                    <HStack>
                                        {logo == null || logo[0]?.fileUrl == null ?
                                            <Flex padding={4} bg={'#C8CBCE'} borderRadius={'full'} w={'80px'} justifyContent={'center'} alignItems={'center'} alignSelf={'stretch'}>
                                                <Icon as={FiImage} w={'full'} stroke={'#656972'} />
                                            </Flex> :
                                            <Image w={'6rem'} src={logo[0]?.fileUrl} />}

                                        <VStack ml={4} alignItems={'flex-start'}>
                                            <FormLabel fontSize={'xs'} color={'gray.500'}>Your logo size should be less than 100 KB</FormLabel>
                                            <AssetsLibraryButton assets={logo} setAssets={setLogo} type={'image'} label="Choose file" />
                                        </VStack>
                                    </HStack>
                                </VStack>

                                {/* <VStack alignItems={'left'}>
                                    <FormLabel fontSize={'lg'}>Agency Cover</FormLabel>
                                    <Text fontSize={'md'} mb={4}>This image will be displayed as a cover on your company public profile</Text>

                                    <Image w="70%" mb={4} src={cover[0]?.fileUrl} />

                                    <HStack>
                                        <VStack alignItems={'flex-start'}>
                                            <FormLabel fontSize={'sm'}>Your cover size should be less than 200 KB</FormLabel>
                                            <AssetsLibraryButton assets={cover} setAssets={setCover} type={'image'} label="Choose file" />
                                        </VStack>
                                    </HStack>
                                </VStack> */}

                                <FormControl>
                                    <FormLabel fontSize={'sm'} mb={2}>Agency colors</FormLabel>
                                    <Text fontSize={'xs'} mb={8} color={'gray.500'}>Choose your company brand colors</Text>
                                    <HStack>
                                        <ColorPicker controlName="primary_color" initColor={brandingData?.organization?.primaryColor ?? "#ffffff"} text={'Primary color'} setValue={setValue} register={register}></ColorPicker>

                                        <ColorPicker controlName="secondary_color" initColor={brandingData?.organization?.secondaryColor ?? "#ffffff"} text={'Secondary color'} setValue={setValue} register={register}></ColorPicker>
                                    </HStack>
                                </FormControl>

                                <HStack gap={6} justifyContent={'right'}>
                                    <Button colorScheme="primary" mr={3} type="submit">
                                        Save
                                    </Button>
                                </HStack>
                            </VStack>
                        </form>

                        <Spacer h={'100px'} />

                    </Box>
                </Box>
            }
        </>
    )
}

export default BrandingSettings;