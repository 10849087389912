export const plans = [
  {
    name: 'Starter',
    subtitle: '(or $19.99/month if billed annually)',
    lookupKey: 'starter_monthly',
    price: '$19.99',
    features: [
      'All features included',
      '2 User seats',
      '2 Workspaces',
      '500 MB memory'
    ]
  },
  {
    name: 'Growth',
    lookupKey: 'growth_monthly',
    subtitle: '(or $49.99/month if billed annually)',
    price: '$59.99',
    features: [
      'All features included',
      '5 User seats',
      '10 Workspaces',
      '1 GB memory'
    ]
  },
  {
    name: 'Enterprise',
    subtitle: 'for custom pricing',
    lookupKey: 'enterprise_monthly',
    price: 'Contact Us',
    features: [
      'All features included',
      'Custom User seats',
      'Custom Workspaces',
      'Custom memory',
      'Dedicated support'
    ]
  }
];