import { Image, Input, Text, Box, HStack, Icon, Button, InputGroup, VStack, FormControl, FormLabel, Select, useDisclosure, IconButton } from "@chakra-ui/react";
import { BiSortAlt2 } from "react-icons/bi";
import React, { useEffect } from "react";
import { FiChevronDown, FiChevronUp, FiImage, FiVideo, FiTrash2 } from "react-icons/fi";
import AssetsLibraryButton from "../../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { toCamelCase } from "../../../../../../helpers/apiHelper";
import { googleDemandgenCallToActions } from "../../../../../../components/Constants/google_demandgen_call_to_actions";

const Slide = ({ ad = null, isOpen, onToggle, getValues, provided, item, remove, register, index, setValue }) => {

  useEffect(() => {
    setValue(`google_demandgen_ad_carousel_items.${index}.priority`, index);
  }, [index]);

  const [asset, setAsset] = React.useState([]);

  const { isOpen: isSlideOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (asset[0] == undefined) return;
    setValue(`google_demandgen_ad_carousel_items.${index}.asset`, asset[0]);
  }, [asset])

  useEffect(() => {
    const adAsset = getValues(`google_demandgen_ad_carousel_items.${index}.asset`);

    if (adAsset == undefined) return;

    setAsset([toCamelCase(adAsset)]);
  }, [])

  return (
    <>
      <Box bg={'white'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
        onMouseOver={() => onOpen()} onMouseOut={() => onClose()}>
        <Box p={5} px={0} borderBottom={'solid'} borderBottomWidth={isOpen ? 0 : '1px'} borderColor={'gray.200'} onClick={(e) => onToggle()}  >
          <HStack justifyContent={'space-between'}>
            <HStack>
              <Icon as={BiSortAlt2} />
              <Text>Slide {getValues(`google_demandgen_ad_carousel_items.${index}.headline`)}</Text>
            </HStack>
            <IconButton visibility={isSlideOpen ? 'visible' : 'hidden'} backgroundColor={'transparent'} color={'gray.600'} marginLeft={'auto'} aria-label='Delete slide' icon={<FiTrash2 />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); remove() }} />

            {isOpen ? <FiChevronUp /> : <FiChevronDown />}
          </HStack>
        </Box>

        <Input type={'hidden'} {...register(`google_demandgen_ad_carousel_items.${index}.uuid`)} defaultValue={item.uuid} />
        <Input type={'hidden'} {...register(`google_demandgen_ad_carousel_items.${index}.priority`)} value={index} />

        <Box display={isOpen ? 'block' : 'none'}>
          <HStack spacing={10} alignItems={'flex-start'}>
            <Box w={'full'}>
              <VStack alignItems={'flex-start'} spacing={7}>
                <HStack>
                  <Box mb={8}>
                    <AssetsLibraryButton assets={asset} setAssets={setAsset} types={['all']} />
                  </Box>
                  <AssetsLibraryPreview assets={asset} setAssets={setAsset} />
                </HStack>
                <FormControl>
                  <FormLabel>Headline</FormLabel>
                  <Input key={item.id} defaultValue={item.headline} {...register(`google_demandgen_ad_carousel_items.${index}.headline`)} placeholder={'Add headline here...'} />
                </FormControl>

                <FormControl>
                  <FormLabel>Call To Action</FormLabel>
                  <Select key={item.id} {...register(`google_demandgen_ad_carousel_items.${index}.call_to_action`)} defaultValue={item.call_to_action}>
                    {googleDemandgenCallToActions.map((cta, index) => (
                      <option value={cta.value} key={index}>{cta.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </Box>
            {/*<Box>
              <InputGroup>
                <IconButton onClick={() => remove()} p={3} cursor={'pointer'} borderColor={'red.300'} color={'red.300'} variant={'outline'} icon={<Icon as={FiTrash2} />} aria-label={""} />
              </InputGroup>
                  </Box>*/}
          </HStack>
        </Box >
      </Box >
    </>
  )
};

export default Slide;