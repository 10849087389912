import { Box, Button, Text, Image, HStack, IconButton, VStack, Icon, Card, CardBody, CardHeader, FormHelperText } from "@chakra-ui/react";
import React from "react";
import { FiChevronRight, FiFileText, FiGlobe, FiHelpCircle, FiMoreHorizontal, FiTrash2 } from "react-icons/fi";

function getBaseUrl(urlString) {
  try {
    const url = new URL(urlString);
    return url.origin;
  } catch (_) {
    return null;
  }
 }

const DataSummary = ({ headlines = [], descriptions = [] }) => {
  
  return (
    <>
      <Card borderRadius={'16px'}>
        <CardHeader>
          <HStack>
            <Text>Headlines</Text>
            <Icon as={FiHelpCircle} />
          </HStack>
        </CardHeader>
        <CardBody fontSize={'sm'} p={3}>
          <VStack spacing={4} px={2} py={2}>
            { headlines.map((arr, index) => (
              <Box w={'full'} key={index}>
                <HStack>
                  <VStack w={'full'} align="start">
                    <Text noOfLines={2} fontWeight={500} fontSize={'16px'} color={'#121723'}>{'Headline '+ (index+1)}</Text>
                    <Text noOfLines={2} fontWeight={500} fontSize={'14px'} color={'#96999F'}>{arr}</Text>
                  </VStack>
                  <Box>
                    {/* <Icon w={'full'} color={'gray.500'} as={FiFileText} /> */}
                  </Box>
                </HStack>
              </Box>
            ))}
          </VStack>   
        </CardBody>
      </Card >
      <Card borderRadius={'16px'} mt={5}>
        <CardHeader>
          <HStack>
            <Text>Descriptions</Text>
            <Icon as={FiHelpCircle} />
          </HStack>
        </CardHeader>
        <CardBody fontSize={'sm'} p={3}>
          <VStack spacing={4} px={2} py={2}>
            { descriptions.map((arr, index) => (
              <Box w={'full'} key={index}>
                <HStack>
                  <VStack w={'full'} align="start">
                    <Text noOfLines={2} fontWeight={500} fontSize={'16px'} color={'#121723'}>{'Description '+ (index+1)}</Text>
                    <Text noOfLines={2} fontWeight={500} fontSize={'14px'} color={'#96999F'}>{arr}</Text>
                  </VStack>
                  <Box>
                    {/* <Icon w={'full'} color={'gray.500'} as={FiFileText} /> */}
                  </Box>
                </HStack>
              </Box>
            ))}
          </VStack>   
        </CardBody>
      </Card >
    </>
  );
}
export default DataSummary;