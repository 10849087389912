import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Button, Image, SkeletonCircle, SkeletonText, Divider } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp } from 'react-icons/fa6';
import { FiCornerUpRight, FiDownload, FiExternalLink, FiMessageSquare, FiMoreVertical, FiRewind, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { googleDisplayCallToActions } from "../../../../../../components/Constants/google_display_call_to_actions";
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const YoutubeAd = ({ variation }) => {
  return (
    <>
      <Phone>
        <Box mb={4} position={'relative'}>
          <Box w={'30%'} position={'absolute'} bottom={0} bg={'yellow.300'} h={'4px'}></Box>

          <Button bg={'black'} right={5} bottom={5} rounded={0} px={3} border={'solid 1px white'} boxShadow={'lg'} py={2} position={'absolute'} fontSize={'x-small'} >Skip Ad</Button>
          {!variation.remoteProvider && <video muted autoPlay width={'300px'} height="auto"><source src={variation.videoUrl} type="video/mp4" /></video>}
          {variation.remoteProvider == 'youtube' && <iframe width="300px" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
        </Box>

        <VStack w={'full'} px={5} spacing={10} my={10} >
          <Image src={variation.logoUrl} w={10} />

          <Text fontSize={'lg'}>{variation.headline?.truncate(30)}</Text>

          <Button rightIcon={<Icon as={FiExternalLink} />} fontSize={'md'} rounded={0} bg={'blue.700'} color={'white'} px={2} py={2}>{googleDisplayCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>
        </VStack>

      </Phone >
    </>
  )
};

export default YoutubeAd 