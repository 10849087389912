import { extendTheme, FormLabel, Textarea, theme as origTheme } from "@chakra-ui/react";
import { inputTheme } from './components/Input'
import { buttonTheme } from "./components/Button";
import { menuTheme } from "./components/Menu";
import { cardTheme } from "./components/Card";
import { selectTheme } from "./components/Select";
import { tableTheme } from "./components/Table";
import { formLabelTheme } from "./components/FormLabel";
import { textareaTheme } from "./components/Textarea";
import '@fontsource/inter'


const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  fontSizes: {
    '2xs': '0.625rem'
  },
  colors: {
    brandPrimary: '#7845DB',
    brand: {
      50: '#0f121a',
      100: '#11151e',
      200: '#131822',
      300: '#151b26',
      400: '#171e2a',
      500: '#7845DB',
      600: '#1d2633',
      700: '#273041',
      800: '#313a4e',
      900: '#3b435b',
    }
  },
  components: {
    Button: buttonTheme,
    Table: tableTheme,
    Input: inputTheme,
    Menu: menuTheme,
    Card: cardTheme,
    Select: selectTheme,
    FormLabel: formLabelTheme,
    Textarea: textareaTheme,
    Alert: {
      variants: {
        "top-accent": (props) => { // only applies to `subtle` variant
          const { colorScheme: c } = props
          let base = {
            container: {
              alignItems: 'center !important',
              borderTopWidth: "6px",
              paddingX: 5,
              paddingY: 3,
              paddingRight: 10,
              fontSize: 'xs',
              display: 'flex',
              gap: 3
            },
            icon: {
              w: '4',
            },
            description: {
              fontSize: 'xs',
              color: 'gray.700'
            }
          };

          if (c == "green") {
            return {
              ...base,
              container: {
                ...base.container,
                borderTopColor: 'green.400',
                bg: `green.100`,
              },
            }
          }

          if (c == "orange") {
            return {
              ...base,
              container: {
                ...base.container,
                borderTopColor: 'yellow.400',
                bg: `yellow.100`,
              },
              icon: {
                ...base.icon,
                color: 'yellow.400'
              }
            }
          }

          return base;
        }
      }
    }
  }
});

export default theme;