import { Image, Input, Text, Box, HStack, Icon, Button, InputGroup, VStack, FormControl, FormLabel, IconButton } from "@chakra-ui/react";
import { BiSortAlt2 } from "react-icons/bi";
import React, { useEffect } from "react";
import { FiChevronDown, FiChevronUp, FiTrash2 } from "react-icons/fi";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { toCamelCase, toSnakeCase, transformKeysTo } from "../../../../../helpers/apiHelper";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const Slide = ({ ad = null, isOpen, onToggle, getValues, provided, item, remove, register, index, setValue, attributeName = 'meta_ad_carousel_items' }) => {

  useEffect(() => {
    setValue(`${attributeName}.${index}.priority`, index);
  }, [index]);

  const [asset, setAsset] = React.useState([]);

  useEffect(() => {
    if (asset[0] == undefined) return;
    const snakeAsset = transformKeysTo('snake', structuredClone(asset[0]))
    setValue(`${attributeName}.${index}.asset`, snakeAsset);
  }, [asset])

  useEffect(() => {
    const adAsset = getValues(`${attributeName}.${index}.asset`);

    if (adAsset == undefined) return;

    setAsset([toCamelCase(adAsset)]);
  }, [])

  return (
    <>
      <Box bg={'white'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <Box p={5} px={0} borderBottom={'solid'} borderBottomWidth={isOpen ? 0 : '1px'} borderColor={'gray.200'} onClick={(e) => onToggle()}  >
          <HStack justifyContent={'space-between'}>
            <HStack>
              <Icon as={BiSortAlt2} />
              <Text>Slide {getValues(`${attributeName}.${index}.headline`)}</Text>
            </HStack>
            {isOpen ? <FiChevronUp /> : <FiChevronDown />}
          </HStack>
        </Box>

        <Input type={'hidden'} {...register(`${attributeName}.${index}.uuid`)} defaultValue={item.uuid} />
        <Input type={'hidden'} {...register(`${attributeName}.${index}.priority`)} value={index} />

        <Box display={isOpen ? 'block' : 'none'}>
          <HStack spacing={10} alignItems={'flex-start'}>
            <Box w={'full'}>
              <VStack alignItems={'flex-start'} spacing={7}>
                <HStack>
                  <Box mb={8}>
                    <AssetsLibraryButton assets={asset} setAssets={setAsset} types={['all']} />
                  </Box>
                  <AssetsLibraryPreview assets={asset} setAssets={setAsset} />
                </HStack>

                <FormControl>
                  <FormLabel>Destination URL (optional)</FormLabel>
                  <Input key={item.id} defaultValue={item.website_url} 
                  {...register(`${attributeName}.${index}.website_url`)} 
                  placeholder={'Add website URL here...'}
                   onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue(`${attributeName}.${index}.website_url`, validatedUrl);
                                    }} 
                  />
                </FormControl>
              </VStack>
            </Box>
            <Box>
              <InputGroup>
                <IconButton onClick={() => remove()} p={3} cursor={'pointer'} borderColor={'red.300'} color={'red.300'} variant={'outline'} icon={<Icon as={FiTrash2} />} aria-label={""} />
              </InputGroup>
            </Box>
          </HStack>
        </Box>
      </Box>
    </>
  )
};

export default Slide;