import React from 'react';
import {
  Editable,
  EditableInput,
  Text,
  EditablePreview,
  Input,
  Icon,
  InputLeftAddon,
  InputGroup,
  HStack,
  InputRightAddon,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import { FiEdit3 } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

const TextInputAddon = ({ value: initialValue, onSubmit, addonLabel, addonLabelPosition = 'left', viewOnly }) => {

  const { register, handleSubmit, watch, formState, reset } = useForm({
    defaultValues: {
      value: initialValue
    }
  });

  const onFormSubmit = (data) => {
    onSubmit(data.value);
    reset({ value: data.value });
  }
  return (
    <>
      {viewOnly && (
        <HStack>
          {addonLabelPosition === 'left' && <Text>{addonLabel}</Text>}
          <Text>{initialValue}</Text>
          {addonLabelPosition === 'right' && <Text>{addonLabel}</Text>}
        </HStack>
      )}
      {!viewOnly && (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <InputGroup>
            {addonLabelPosition === 'left' && <InputLeftAddon fontSize={'xs'} h={'auto'}>{addonLabel}</InputLeftAddon>}
            <Input {...register('value')} fontSize={'xs'} w={'10rem'} type='number' />
            {addonLabelPosition === 'right' && <InputRightAddon fontSize={'xs'} h={'auto'}>{addonLabel}</InputRightAddon>}
            {formState.isDirty && (
              <InputRightElement border={0}>
                <Button type='submit' ml={3} mr={6} fontSize='xs' bg={'gray.100'} color={'gray.700'} h={'1.5rem'}>
                  Save
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
        </form>
      )}
    </>
  );
};


export default TextInputAddon;