
import { Box, HStack, Text, Avatar, Icon, Accordion, AccordionButton, AccordionItem, AccordionPanel, Input, Button, Spacer, useDisclosure, Flex, Divider } from "@chakra-ui/react";
import React, { createRef, useContext, useEffect, useState } from "react";
import { setCurrentWorkspace } from "../../../../api/slices/usersSlice";
import { FiChevronLeft, FiLayers, FiUser, FiUsers, FiCreditCard, FiGrid, FiFeather } from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";
import { useGetWorkspacesQuery } from "../../../../api/services/workspaces";
import Create from "../../../../views/Workspaces/Create";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import SidebarLink from "../SidebarLink";
import { useGetCampaignQuery } from "../../../../api/services/campaigns";

const SettingsSidebar = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const currentUser = useSelector((state) => state.user.currentUser);
  const isOwner = currentUser.organizationRole == 'owner';

  const { data, isSuccess: isSuccessWorkspaces } = useGetWorkspacesQuery({ query: query }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

  const changeWorkspace = (workspace) => {
    dispatch(setCurrentWorkspace(workspace));
    localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
  };

  useEffect(() => {
    if (isSuccessWorkspaces && localStorage.getItem('currentWorkspace') == null && data?.workspaces[0] != null) {
      changeWorkspace(data?.workspaces[0]);
    }
  }, [isSuccessWorkspaces]);

  return (
    <>
      <SidebarLink to={'/campaigns'} icon={FiChevronLeft} text={'Back'} />
      <Divider borderColor={'gray.200'} />

      <Text p={7} pb={4} fontSize={'sm'} fontWeight={'bold'} color={'gray'}>Settings</Text>

      <SidebarLink to="/account-settings" icon={FiUser} text="Account" />
      <SidebarLink to="/team" icon={FiUsers} text="Team" />
      <SidebarLink to="/workspaces" icon={FiGrid} text="Workspaces" />
      <SidebarLink to="/integrations" icon={FiLayers} text="Integrations" />
      {isOwner && <SidebarLink to="/billing" icon={FiCreditCard} text="Billing" />}
      <SidebarLink to="/branding-settings" icon={FiFeather} text="Branding" />

      <Spacer />
    </>
  )
}

export default SettingsSidebar;