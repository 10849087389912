import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Input, Box, Card, CardBody } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiHeart, FiMenu, FiMoreVertical, FiSearch, FiShare, FiShare2, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import GoogleLogo from "../../../../../../assets/icons/pmax/google_logo.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { transformKeysTo } from '../../../../../../helpers/apiHelper';

const DiscoverCarouselAd = ({ variation }) => {

  console.log(variation)

  return (
    <>
      <Phone>

        <HStack mt={4} justifyContent={'center'}>
          <Image src={GoogleLogo} w={20} />
        </HStack>

        <Box p={4}>
          <Input bg={'gray.100'} py={3} borderRadius={'full'} border={'0px'} w={'full'} placeholder="Search" />
        </Box>

        <Divider />

        <Swiper
          centeredSlidesBounds
          centeredSlides
          grabCursor
          slidesPerView={1.25}
          freeMode
          spaceBetween={35}
          style={{ padding: '0rem' }}
        >
          {variation.carouselItems?.map((item) => {
            const transformedItem = transformKeysTo('snake', structuredClone(item));

            return (
              <SwiperSlide style={{ padding: '0rem' }}>
                <Card w={'full'} mt={5} bg={'white'} p={0}>
                  <CardBody p={0} >
                    <Box h={'150px'} overflow={'hidden'}>
                      {transformedItem.asset == null && <Image borderTopRadius={'md'} w={'100%'} src={''} />}
                      {transformedItem.asset?.asset_type == 'image' && <Image borderTopRadius={'md'} w={'100%'} src={transformedItem?.asset?.file_url} />}
                      {transformedItem.asset?.asset_type == 'video' &&
                        <video key={transformedItem?.asset?.uuid} autoPlay controls style={{ borderTopLeftRadius: '0.3rem', borderTopRightRadius: '0.3rem' }} muted width={'100%'} height="auto" loop >
                          <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      }
                    </Box>
                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Text fontSize={'sm'} px={4} mt={5}>
          {variation.headline?.truncate(30)}. {variation.description?.truncate(90)}
        </Text>

        <HStack px={4}>
          <Text fontWeight={'bold'} fontSize={'xx-small'}>Sponsored</Text>
        </HStack>

        <HStack w={'full'} justifyContent={'space-between'} mb={4} px={4}>
          <HStack w={'full'} >
            <Text fontSize={'x-small'} fontWeight={'bold'}>
              <Image borderRadius={'md'} w={4} src={variation.logoUrl} />
            </Text>
            <Text fontSize={'x-small'}>{variation.businessName}</Text>
          </HStack>

          <HStack spacing={5}>
            <Icon as={FiHeart} color={'gray.500'} fontSize={'xs'} />
            <Icon as={FiShare} color={'gray.500'} fontSize={'xs'} />
            <Icon as={FiMoreVertical} color={'gray.500'} fontSize={'xs'} />
          </HStack>
        </HStack>



        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />
      </Phone>
    </>
  )
};

export default DiscoverCarouselAd;