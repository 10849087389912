
import { Box } from "@chakra-ui/react";
import React, { } from "react";

const Sidebar = ({ type }) => {
  return (
    <Box zIndex={'100'} bg={'white'} overflowY={'scroll'} h={'full'} display={{ base: 'none', sm: 'none', md: 'flex' }} position={'fixed'} flexDir={'column'} borderRight={'solid 1px'} minW={'xs'} borderRightColor={'gray.200'}>
      <Box mt={'5.5rem'}></Box>
      {type}
    </Box>
  )
}

export default Sidebar;