import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  list: {
    boxShadow: 'xl'
  },
  item: {
    px: 4,
    py: 1,
    color: 'gray.500',
    fontSize: 'sm',
    _hover: {
      color: 'brand.500',
      bg: 'white'
    },
    _focus: {
      bg: 'white',
    },
  },

})
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })