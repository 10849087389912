import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Input, Box } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreVertical, FiSearch, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import GoogleLogo from "../../../../../../assets/icons/pmax/google_logo.png";

const SearchAd = ({ variation, withImage = false }) => {

  return (
    <>
      <Phone p={3}>
        <HStack justifyContent={'space-between'} px={3} py={1} w={'full'} >
          <Icon as={FiMenu} />
          <Image src={GoogleLogo} w={20} />
          <Avatar size={'xs'} name={' '} bg={'gray.200'} />
        </HStack>

        <HStack mt={5} w={'full'} p={3} border={'solid 1px'} borderColor={'gray.200'} borderRadius={'3xl'}>
          <Icon as={FiSearch} />
        </HStack>

        <HStack mt={5} h={'10px'} w={'full'} spacing={10} justifyContent={'space-between'}>
          <Box w={'full'} bg={'gray.500'} h={'10px'}></Box>
          <Box w={'full'} bg={'gray.300'} h={'10px'}></Box>
          <Box w={'full'} bg={'gray.300'} h={'10px'}></Box>
          <Box w={'full'} bg={'gray.300'} h={'10px'}></Box>
          <Box w={'full'} bg={'gray.300'} h={'10px'}></Box>
        </HStack>

        <VStack alignItems={'flex-start'} mt={10} boxShadow={'base'} p={2}>
          <Text fontWeight={'bold'} fontSize={'xx-small'}>Sponsored</Text>
          <HStack>
            <Avatar w={5} h={5} borderRadius={0} src={variation.logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontSize={'x-small'}>{variation.businessName}</Text>
              <Text wordBreak={'break-all'} noOfLines={1} fontSize={'xx-small'}>{variation.finalUrl} </Text>
            </VStack>
          </HStack>

          <Text fontSize={'md'} color={'#1558d6'} fontWeight={500}>
            {variation.headlines[Math.floor(Math.random() * variation.headlines.length)]?.truncate(30)} - {variation.headlines[Math.floor(Math.random() * variation.headlines.length)]?.truncate(30)}
          </Text>

          <HStack w={'full'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <VStack alignItems={'flex-start'} spacing={2}>
              <Text fontSize={'x-small'}>{variation.description?.truncate(90)} {variation?.snippets?.shuffle()?.join('. ')}</Text>
              <HStack flexWrap={'wrap'}>
                {variation?.sitelinks?.map((link) => (
                  <Text border={'solid 1px'} borderColor={'gray.200'} borderRadius={'md'} px={1} py={1} color={'#1558d6'} fontSize={'x-small'}>{link}</Text>
                ))}
              </HStack>
            </VStack>
            {withImage && <Image rounded={'md'} w={20} src={variation.imageUrl} />}
          </HStack>
        </VStack>


        {[1, 2, 3].map((i) => (<>
          <HStack rounded={'md'} p={3} border={'solid 1px'} borderColor={'gray.200'} mt={4} w={'full'}>
            <SkeletonText w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />
          </HStack>
        </>))}

      </Phone>
    </>
  )
};

export default SearchAd;