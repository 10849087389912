import { baseApi } from "../base/base";

export const publicMediaPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicMediaPlan: builder.query({
      query: (data) => ({ url: `/public/media_plans/${data.uuid}`, params: data }),
      providesTags: ['MediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetPublicMediaPlanQuery
} = publicMediaPlansApi;
