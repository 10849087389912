import React, { useEffect } from 'react';

import { Box, Heading, VStack, Flex, Input, Stack, Button, Text, useDisclosure, HStack, Icon, Tag, IconButton, Menu, MenuButton, MenuItem, MenuList, MenuDivider, Spacer } from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import { useGetMediaPlansQuery } from '../../api/services/mediaPlans';
import { FiCopy, FiEdit, FiFolder, FiMoreVertical, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Paginator from '../../components/Common/Pagination/Paginator';
import Status from './Components/Status';
import Create from './Create';
import Delete from './Delete';
import Rename from './Rename';
import Duplicate from './Duplicate'

const MediaPlans = () => {
  const [search, setSearch] = React.useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMediaPlan, setSelectedMediaPlan] = useState(null);
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const navigate = useNavigate();

  const renameDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const duplicateDisclosure = useDisclosure();

  const { data, isSuccess } = useGetMediaPlansQuery({ per_page: 10, page: currentPage, workspace_id: currentWorkspace?.id, query: search }, { pollingInterval: 10000 });

  return (
    <>
      <Delete mediaPlan={selectedMediaPlan} disclosure={deleteDisclosure} />
      <Rename mediaPlan={selectedMediaPlan} disclosure={renameDisclosure} />
      <Duplicate mediaPlan={selectedMediaPlan} disclosure={duplicateDisclosure} />

      <Box p={10}>
        <Box w='100%'>
          <VStack spacing={0} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
            <Flex mb={10} justifyContent='space-between' alignItems={'center'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                Media Plans
              </Heading>

              <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                <Input onChange={(e) => setSearch(e.target.value)} w={['100%', 'md']} placeholder='Search media plans...' />
                <Create />
              </Stack>
            </Flex>

            {isSuccess && data?.mediaPlans?.map((item) => {
              return (
                <Box
                  onClick={() => navigate(`/media-plans/${item.id}`)}
                  _hover={{ bg: 'gray.50', cursor: 'pointer' }}
                  px={2} py={4}
                  borderBottom={'solid 1px'}
                  borderColor={'gray.200'}
                >
                  <HStack justifyContent={'space-between'}>
                    <HStack>
                      <Icon fontSize={'xl'} as={FiFolder} />
                      <Text fontWeight={'semibold'} pl={3}>{item.name}</Text>
                      <Tag fontSize={'xs'} ml={5} colorScheme='gray'>Version {item.version}</Tag>
                      <Tag fontSize={'xs'} ml={2} colorScheme='purple'>{item.campaignsCount} campaigns</Tag>
                    </HStack>
                    <HStack>
                      <Status status={item.status} />

                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label='Options'
                          icon={<Icon as={FiMoreVertical} />}
                          variant='ghost'
                          onClick={(e) => { e.stopPropagation(); }}
                        />
                        <MenuList>
                          <MenuItem onClick={(e) => { setSelectedMediaPlan(item); renameDisclosure.onOpen(); e.stopPropagation(); }} icon={<Icon as={FiEdit} />} >
                            Rename
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem onClick={(e) => { setSelectedMediaPlan(item); duplicateDisclosure.onOpen(); e.stopPropagation(); }} icon={<Icon as={FiCopy} />} >
                            Duplicate
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem onClick={(e) => { setSelectedMediaPlan(item); deleteDisclosure.onOpen(); e.stopPropagation(); }} icon={<Icon as={FiTrash2} />} >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </HStack>
                </Box>
              )
            })}

            <Spacer mt={10} h={'50px'} />
            <Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />

          </VStack>
        </Box>
      </Box >

    </>
  );
};


export default MediaPlans;