import { createSlice } from '@reduxjs/toolkit';
import { tableColumns } from './../../views/MediaPlans/Simple/columns'

const mediaPlanColumnSettingsSlice = createSlice({
    name: 'mediaPlanColumnSettingsSlice',
    initialState: tableColumns,
    reducers: {
      setColumns(state, action) {
        console.log(action.payload)
        return action.payload
      },
    },
  });


export const { setColumns } = mediaPlanColumnSettingsSlice.actions;

export default mediaPlanColumnSettingsSlice.reducer;