import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
} from '@chakra-ui/react';
import CallCard from './CallCard';
import { v4 as uuidv4 } from 'uuid';

function Calls({ isOpen, onClose, initialCalls, setInitialCalls }) {
  const newCall = { country: '', phoneNumber: '', id: uuidv4() }
  const [calls, setCalls] = useState(initialCalls.length > 0 ? [...initialCalls] : [newCall]);

  const handleInputChange = (index, country, phoneNumber) => {
    const updatedCalls = [...calls];
    updatedCalls[index] = { id: updatedCalls[index].id, ['country']: country, ['phoneNumber']: phoneNumber }
    setCalls(updatedCalls);
  };

  const handleAddCall = () => {
    if (calls.length < 10) {
      setCalls([...calls, newCall]);
    }
  };

  const handleRemoveCall = (index) => {
    const updatedCalls = calls.filter((_, i) => i !== index);
    setCalls(updatedCalls);
  };

  const handleSave = () => {
    setInitialCalls(calls);
    onClose();
  };

  const handleCancel = () => {
    setCalls([...initialCalls]);
    onClose();
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add calls to your campaign</DrawerHeader>
          <DrawerBody>
            <Stack spacing='24px'>
              {calls && calls.map((call, index) => (
                <CallCard
                  call={call}
                  key={call.id}
                  index={index}
                  handleInputChange={handleInputChange}
                  handleRemoveCall={handleRemoveCall}
                />
              ))}
            </Stack>
            <Button
              colorScheme='gray'
              variant={'outline'}
              mt={3}
              onClick={handleAddCall}
              isDisabled={calls.length >= 10}
            >
              Add call
            </Button>
          </DrawerBody>
          <DrawerFooter align="start" borderTopWidth='1px'>
            <Button colorScheme='gray' mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button colorScheme='red' variant='outline' onClick={handleCancel}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Calls;
