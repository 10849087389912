import React from "react";

import { Center, HStack, Icon, IconButton, useSteps } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import SquareAd from "./Display/SquareAd";
import FullscreenAd from "./Display/FullscreenAd";

const Display = ({ variations, displayType }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
      <Center>
        <HStack spacing={10} mb={4}>
          <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
          <IconButton isDisabled={activeStep == 1} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
        </HStack>
      </Center>

      {activeStep == 0 && <SquareAd variation={variations[0]} isDesktop={displayType == 'desktop'} />}
      {activeStep == 1 && <FullscreenAd variation={variations[1]} isDesktop={displayType == 'desktop'} />}
    </>


  )
};

export default Display;