import React from 'react';
import { HStack, Icon, Image, Tag } from '@chakra-ui/react';
import GoogleSearch from '../../../assets/icons/google_search.svg';
import GoogleDisplay from '../../../assets/icons/google_display.svg';
import Meta from '../../../assets/icons/meta.svg';
import Tiktok from '../../../assets/icons/tiktok.svg';
import Linkedin from '../../../assets/icons/linkedin.svg';
import Snapchat from '../../../assets/icons/snapchat.svg';
import GmailIcon from '../../../assets/icons/pmax/gmail.svg';
import DiscoverIcon from '../../../assets/icons/pmax/discover.svg';
import DisplayIcon from '../../../assets/icons/pmax/display.svg';
import LogoIcon from '../../../assets/icons/pmax/logo.webp';

import { SiYoutube } from 'react-icons/si';

const PlatformIcon = ({ platform, simplified = false, ...props }) => {
  const iconMap = {
    'google_display': GoogleSearch,
    'google_search': GoogleDisplay,
    'google_pmax': GoogleDisplay,
    'meta': Meta,
    'tiktok': Tiktok,
    'linkedin': Linkedin,
    'snapchat': Snapchat,
    'meta_instant_experience': Meta,
  };

  const icon = iconMap[platform];

  if (simplified && ['google_search', 'google_display', 'google_pmax', 'google_demandgen'].includes(platform)) {
    return <Image color={'red'} w={props.w != null ? props.w : 5} src={LogoIcon} />;
  }

  if (platform == 'google_pmax') {
    return (<>
      <HStack>
        <Icon color={'red'} fontSize={'xl'} as={SiYoutube} />
        <Image color={'red'} w={6} src={GmailIcon} />
        <Image color={'red'} w={5} src={LogoIcon} />
        <Image color={'red'} w={5} src={DisplayIcon} />
        <Image color={'red'} w={4} src={DiscoverIcon} />
      </HStack>
    </>
    )
  }

  if (platform == 'google_search') {
    return (<>
      <HStack>
        <Image w={5} {...props} src={icon} alt={`platform_icon_${platform}`} />
        <Image color={'red'} w={5} src={LogoIcon} />
      </HStack>
    </>
    )
  }

  if (platform == 'google_display') {
    return (<>
      <HStack>
        <Image w={5} {...props} src={icon} alt={`platform_icon_${platform}`} />
        <Image color={'red'} w={5} src={DisplayIcon} />
      </HStack>
    </>
    )
  }

  if (platform == 'google_demandgen') {
    return (<>
      <HStack>
        <Icon color={'red'} fontSize={'xl'} as={SiYoutube} />
        <Image color={'red'} w={6} src={GmailIcon} />
        <Image color={'red'} w={4} src={DiscoverIcon} />
      </HStack>
    </>
    )
  }


  return (<>
    <Image {...props} src={icon} alt={`platform_icon_${platform}`} />
  </>
  )
};

export default PlatformIcon;