import React from 'react';
import {
  Table,
  Box,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
  useToast,
  Spacer,
  HStack
} from '@chakra-ui/react'
import Mapper from '../../Components/TableFields/Mapper'
import { useDeleteMediaPlanCampaignMutation } from '../../../../api/services/mediaPlanCampaigns';
import { FiTrash2, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { tableColumns } from '../columns'
import { platformSupported } from '../platformSupported'
import { useSelector } from 'react-redux';
import { useGetSharedMediaPlanCampaignsQuery } from '../../../../api/services/shared/mediaPlanCampaigns';
import { useClientToken } from '../../../../components/Hooks/useClientToken';
import SimpleTableContents from '../SimpleTableContents';


const SimpleTable = ({ mediaPlan }) => {
  const { token } = useClientToken();
  const { data } = useGetSharedMediaPlanCampaignsQuery({ uuid: mediaPlan.uuid, token }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

  return (
    <>
      <SimpleTableContents mediaPlan={mediaPlan} mediaPlanCampaigns={data} canDelete={false} readOnly={true} />
    </>
  );
};

export default SimpleTable;