import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSharedOrganizationQuery } from "../../api/services/shared/organizations";
import { useClientToken } from "./useClientToken";

export const useOrganization = () => {
  const { organization } = useParams();
  const { token } = useClientToken();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetSharedOrganizationQuery({ organization: organization, uuid: token });
  const organizationData = data?.organization;

  useEffect(() => {
    if (token == null) {
      navigate('/404');
      return;
    }

    if (localStorage.getItem('token') != token) {
      localStorage.setItem('token', token);
    }

    if (localStorage.getItem('organization') != organization) {
      localStorage.setItem('organization', organization);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      navigate('/404');
      return;
    }
  }, [isError]);

  return {
    organization: organizationData
  }

};