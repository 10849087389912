import React from 'react';
import { EditablePreview, Icon, useColorModeValue, IconButton, Input, useEditableControls, ButtonGroup, Editable, EditableInput as ChakraEditableInput, HStack } from "@chakra-ui/react";
import { FiCheck, FiEdit3, FiX } from 'react-icons/fi';

const EditableControl = ({ styles, isDisabled }) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps
  } = useEditableControls();

  return (
    <HStack spacing={5} w={'full'} justifyContent={'flex-start'}>

      {!isEditing && <HStack border={'solid 1px transparent'} >
        <EditablePreview
          {...styles}
          p={2}
          m={0}
          textwrap={'nowrap'}
          whiteSpace={'nowrap'}
          borderRadius={'md'} _hover={{ background: useColorModeValue("gray.100", "gray.700") }}
        />

        { !isDisabled && <Icon color={'gray.300'} mr={2} as={FiEdit3} /> }
      </HStack>}

      <Input
        isRequired
        p={2}
        m={0}
        w={'auto'}
        minW={'20rem'}
        onFocus={(e) => e.target.style.width = e.target.value.length < 50 ? `${(e.target.value.length * 1)}rem` : '50rem'}
        {...styles}
        as={ChakraEditableInput}
        isDisabled={isDisabled}
      />

      {isEditing &&
        <ButtonGroup size="md" spacing={2}>
          <IconButton h={'auto'} colorScheme='gray' bg={'gray.100'} _hover={{ bg: 'gray.300' }} variant={'ghost'} icon={<Icon as={FiCheck} />} {...getSubmitButtonProps()} />
          <IconButton colorScheme='gray' bg={'gray.100'} _hover={{ bg: 'gray.300' }} variant={'ghost'}
            icon={<Icon as={FiX} />}
            {...getCancelButtonProps()}
          />
        </ButtonGroup>}
    </HStack>
  )
};

const EditableInput = ({ label, onSave, key, isDisabled = false, ...styles }) => {

  return (
    <Editable
      onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
      defaultValue={label}
      isPreviewFocusable={true}
      selectAllOnFocus={false}
      ml={-2}
      onSubmit={onSave}
      key={key}
      isDisabled={isDisabled}
    >
      <EditableControl styles={styles} isDisabled={isDisabled} />
    </Editable>
  )
}

export default EditableInput;
