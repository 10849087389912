import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userReducer from './slices/usersSlice';
import adGroupReducer from './slices/adGroupSlice';
import variationsReducer from './slices/variationsSlice'
import mediaPlanColumnSettingsReducer from './slices/mediaPlanColumnSlice'
import { baseApi } from "./services/base/base";


export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    user: userReducer,
    adGroup: adGroupReducer,
    variation: variationsReducer,
    mediaPlanColumnSettings: mediaPlanColumnSettingsReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat([baseApi.middleware])
});

export const RootState = store.getState();