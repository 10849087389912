import React from 'react';
import SimpleTableContents from '../SimpleTableContents';
import { useParams } from 'react-router-dom';
import { useGetPublicMediaPlanCampaignsQuery } from '../../../../api/services/public/mediaPlanCampaigns';


const SimpleTable = ({ mediaPlan }) => {
  const { uuid } = useParams();
  const { data } = useGetPublicMediaPlanCampaignsQuery({ uuid: uuid }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

  return (
    <>
      <SimpleTableContents mediaPlan={mediaPlan} mediaPlanCampaigns={data} canDelete={false} readOnly={true} />
    </>
  );
};

export default SimpleTable;