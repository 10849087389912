import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { useGetSharedCampaignQuery } from '../../../api/services/shared/campaigns';
import { useParams, useSearchParams } from 'react-router-dom';
import AdGroup from '../../AdGroups/Shared/AdGroup';
import { useSelector } from 'react-redux';
import { FiMessageSquare } from 'react-icons/fi';
import { FaCircle } from 'react-icons/fa';
import Comments from '../../Comments/Comments';
import Breadcrumbs from '../../../components/Common/Breadcrumbs';

const SharedCampaign = () => {
  const { uuid, organization } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');
  const currentUser = useSelector((state) => state.user.currentUser);
  const [hasComments, setHasComments] = useState(false);
  const commentsDisclosure = useDisclosure();


  const { data, isSuccess } = useGetSharedCampaignQuery({ uuid: uuid, token: token }, { refetchOnMountOrArgChange: true });
  const campaign = data?.campaign;

  useEffect(() => {
    if (isSuccess) {
      setHasComments(campaign?.adGroups?.some(adGroup => adGroup?.ads?.some(ad => ad?.hasComments)));
    }
  }, [data])

  return (
    <>
      {isSuccess && <Box p={4} minHeight="100vh" w='100%'>
        <Box px={8}>
          <Breadcrumbs links={[
            { label: 'Home', to: `/portal/${organization}/home` },
            { label: 'Campaigns', to: `/portal/${organization}/campaigns` },
            { label: campaign.name, to: `#` }
          ]} />
        </Box>

        <Flex gap={6} direction={['column', 'row']} alignItems={'center'} px={8} justifyContent={'space-between'}>
          <Text letterSpacing={'-0.04rem'} fontSize={'3xl'} fontWeight={500} >{campaign.name}</Text>
          <Button onClick={() => commentsDisclosure.onOpen()} rightIcon={hasComments ? <Icon color={'orange'} fontSize={'10px'} as={FaCircle} /> : <></>} variant={'outline'} size={'sm'} leftIcon={<Icon as={FiMessageSquare} />}>Comments</Button>
        </Flex>
        {campaign?.adGroups &&
          campaign?.adGroups.map((ad_group) => (
            <AdGroup platform={campaign?.platform} ad_group={ad_group} key={ad_group.uuid} mode={campaign?.mode} isCampaignShared={true} />
          ))
        }
      </Box >
      }

      {commentsDisclosure.isOpen && <Comments campaign={campaign} disclosure={commentsDisclosure} />}
    </>
  );
};

export default SharedCampaign;