import { IconButton, Icon, VStack, Box, Image, Text, Divider, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiMonitor, FiPhone, FiRefreshCw, FiSmartphone } from "react-icons/fi";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { SiYoutube } from "react-icons/si";
import DiscoverIcon from '../../../../../assets/icons/pmax/discover.svg';
import DisplayIcon from '../../../../../assets/icons/pmax/display.svg';
import GmailIcon from '../../../../../assets/icons/pmax/gmail.svg';
import LogoIcon from '../../../../../assets/icons/pmax/logo.webp';

import YouTube from "./Youtube";
import Gmail from "./Gmail";
import Discover from "./Discover";

const AggregatedPreview = ({ metadata }) => {
  const [displayType, setDisplayType] = React.useState('mobile');

  const randomize = () => {
    const headline = metadata?.headlines[Math.floor(Math.random() * metadata.headlines.length)]?.truncate(30);
    const description = metadata?.descriptions[Math.floor(Math.random() * metadata.descriptions.length)];
    const video = metadata?.videos[Math.floor(Math.random() * metadata.videos.length)];
    const longHeadline = metadata?.longHeadlines[Math.floor(Math.random() * metadata.longHeadlines.length)];

    return {
      headline: headline,
      headlines: metadata?.headlines,
      description: description,
      longHeadline: longHeadline,
      businessName: metadata?.businessName,
      finalUrl: metadata?.finalUrl,
      cta: metadata?.callToAction,
      imageUrl: metadata?.images[Math.floor(Math.random() * metadata.images.length)]?.fileUrl,
      logoUrl: metadata?.logos[Math.floor(Math.random() * metadata.logos.length)]?.fileUrl,
      videoUrl: video?.fileUrl,
      remoteProvider: video?.remoteProvider,
      carouselItems: metadata?.carouselItems,
    }
  }

  const [variation, setVariation] = React.useState([randomize(), randomize()]);

  const setRandomVariations = () => {
    setVariation([randomize(), randomize()])
  };

  useEffect(() => {
    setRandomVariations();
  }, [metadata]);

  return (
    <>
      <VStack alignItems={'flex-start'} spacing={10}>
        <HStack h={'55px'} w={'full'} display={'flex'} justifyContent={'flex-end'}>
          <IconButton p={5} variant={'outline'} aria-label="Refresh variations" onClick={() => setRandomVariations()} icon={<Icon fontSize={'sm'} as={FiRefreshCw} />} />
          <Divider mx={10} orientation='vertical' />
          <IconButton p={5} variant={displayType == 'mobile' ? 'solid' : 'outline'} h={'auto'} aria-label="Refresh variations" onClick={() => setDisplayType('mobile')} icon={<Icon fontSize={'sm'} as={FiSmartphone} />} />
          <IconButton p={5} variant={displayType == 'desktop' ? 'solid' : 'outline'} h={'auto'} aria-label="Refresh variations" onClick={() => setDisplayType('desktop')} icon={<Icon fontSize={'sm'} as={FiMonitor} />} />
        </HStack>

        <Tabs w={'full'} h={'full'}>
          <TabList>
            <Tab w={'full'}>
              <VStack spacing={3}>
                <Icon color={'red'} fontSize={'xl'} as={SiYoutube} />
                <Text fontSize={'sm'} color={'black'}>YouTube</Text>
              </VStack>
            </Tab>
            <Tab w={'full'}>
              <VStack spacing={3}>
                <Image color={'red'} w={6} src={GmailIcon} />
                <Text fontSize={'sm'} color={'black'}>Gmail</Text>
              </VStack>
            </Tab>
            <Tab w={'full'}>
              <VStack spacing={3}>
                <Image color={'red'} w={6} src={DiscoverIcon} />
                <Text fontSize={'sm'} color={'black'}>Discover</Text>
              </VStack>
            </Tab>
          </TabList>

          <TabPanels position={'sticky'} top={0}>
            <TabPanel>
              <YouTube adType={metadata.adType} displayType={displayType} variations={variation} />
            </TabPanel>
            <TabPanel>
              <Gmail adType={metadata.adType} variations={variation} displayType={displayType} />
            </TabPanel>
            <TabPanel>
              <Discover adType={metadata.adType} variations={variation} displayType={displayType} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  )
};

export default AggregatedPreview;