import { Input, Text, Box, HStack, Icon, VStack, FormControl, FormLabel, FormHelperText, useDisclosure, IconButton } from "@chakra-ui/react";
import { BiSortAlt2 } from "react-icons/bi";
import React, { useEffect } from "react";
import { FiChevronDown, FiChevronUp, FiTrash2 } from "react-icons/fi";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { toCamelCase } from "../../../../../helpers/apiHelper";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const Slide = ({ ad = null, isOpen, onToggle, getValues, provided, item, remove, register, index, setValue }) => {

    useEffect(() => {
        setValue(`linkedin_ad_carousel_items.${index}.priority`, index);
    }, [index]);

    const [asset, setAsset] = React.useState([]);

    const { isOpen: isSlideOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (asset[0] == undefined) return;
        setValue(`linkedin_ad_carousel_items.${index}.asset`, asset[0]);
    }, [asset])

    useEffect(() => {
        const adAsset = getValues(`linkedin_ad_carousel_items.${index}.asset`);

        if (adAsset == undefined) return;

        setAsset([toCamelCase(adAsset)]);
    }, [])

    return (
        <>
            <Box bg={'white'} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                onMouseOver={() => onOpen()} onMouseOut={() => onClose()}>
                <Box p={5} px={0} borderBottom={'solid'} borderBottomWidth={isOpen ? 0 : '1px'} borderColor={'gray.200'} onClick={(e) => onToggle()}  >
                    <HStack justifyContent={'space-between'}>
                        <HStack>
                            <Icon as={BiSortAlt2} />
                            <Text>Slide {getValues(`linkedin_ad_carousel_items.${index}.headline`)}</Text>
                        </HStack>
                        <IconButton visibility={isSlideOpen ? 'visible' : 'hidden'} backgroundColor={'transparent'} color={'gray.600'} marginLeft={'auto'} aria-label='Delete slide' icon={<FiTrash2 />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); remove() }} />

                        {isOpen ? <FiChevronUp /> : <FiChevronDown />}
                    </HStack>
                </Box>

                <Input type={'hidden'} {...register(`linkedin_ad_carousel_items.${index}.uuid`)} defaultValue={item.uuid} />
                <Input type={'hidden'} {...register(`linkedin_ad_carousel_items.${index}.priority`)} value={index} />

                <Box display={isOpen ? 'block' : 'none'}>
                    <VStack spacing={10} alignItems={'flex-start'}>
                        <Box>
                            <HStack>
                                <Box mb={8}>
                                    <AssetsLibraryButton assets={asset} setAssets={setAsset} types={['all']} />
                                </Box>
                                <AssetsLibraryPreview assets={asset} setAssets={setAsset} />
                            </HStack>
                        </Box>
                        <Box w={'full'}>
                            <VStack alignItems={'flex-start'} spacing={7}>
                                <FormControl>
                                    <FormLabel>Headline</FormLabel>
                                    <Input key={item.id} defaultValue={item.headline} {...register(`linkedin_ad_carousel_items.${index}.headline`)} placeholder={'Add headline here...'} />
                                    <FormHelperText color={(getValues(`linkedin_ad_carousel_items.${index}.headline`)?.length || 0) > 70 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues(`linkedin_ad_carousel_items.${index}.headline`)?.length || 0} / 70</FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Destination URL</FormLabel>
                                    <Input key={item.id} defaultValue={item.destination_url} 
                                    {...register(`linkedin_ad_carousel_items.${index}.destination_url`)} 
                                    placeholder={'Add destination URL here...'} 
                                    onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue(`linkedin_ad_carousel_items.${index}.destination_url`, validatedUrl);
                                    }}/>
                                    <Text fontSize={'xs'} color={'gray.400'}>URLs must have the http:// or https:// prefix.</Text>
                                    <FormHelperText color={(getValues(`linkedin_ad_carousel_items.${index}.destination_url`)?.length || 0) > 2000 ? 'red' : '#96999F'} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues(`linkedin_ad_carousel_items.${index}.destination_url`)?.length || 0} / 2000</FormHelperText>
                                </FormControl>
                            </VStack>
                        </Box>
                    </VStack>
                </Box>
            </Box>
        </>
    )
};

export default Slide;