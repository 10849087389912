
import { ModalHeader, Text, Box, Alert, AlertIcon, Input, Button, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, ScaleFade, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateWorkspaceMutation } from '../../api/services/workspaces';

const EditWorkspace = ({ disclosure, currentWorkspace, workspace, changeWorkspace }) => {
  const { register, handleSubmit, reset, formState: { isDirty } } = useForm();
  const [update, { data, isLoading, isSuccess, isError }] = useUpdateWorkspaceMutation();
  const [errorMessage, setErrorMessage] = React.useState('');
  const toast = useToast();

  const onSubmit = (data) => {
    data.id = workspace.id;
    update(data);
  };

  useEffect(() => {
    reset(workspace);
  }, [workspace, reset]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Workspace updated successfully.',
        description: 'Workspace has been updated successfully! 🚀 Should you need any further assistance or have any questions, feel free to reach out to our support team.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      disclosure.onClose();

      if (currentWorkspace.id === workspace.id) {
        changeWorkspace(data?.workspace);
      }
    }
  }, [isSuccess]);

  return (
    <>
      <Modal key={workspace?.uuid} isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Update workspace</Text>
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>

              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  There was an error updating the workspace. Please try again or contact support.
                </Alert>
              </ScaleFade>}

              <FormControl>
                <FormLabel>Workspace name</FormLabel>
                <Input {...register('name', { required: true })} defaultValue={workspace?.name} type='text' placeholder='Workspace name...' />
              </FormControl>

              <FormControl mt={5}>
                <FormLabel>Describe workspace</FormLabel>
                <Textarea {...register('description', { required: true })} defaultValue={workspace?.description} placeholder='Add your description...' />
              </FormControl>
            </ModalBody>

            <ModalFooter gap={3} justifyContent={'flex-end'}>
              <Button onClick={() => { reset(); disclosure.onClose() }} variant='outline'>Cancel</Button>
              <Button isDisabled={!isDirty} colorScheme='primary' mr={3} type='submit'>
                Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditWorkspace;
