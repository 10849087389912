import { Box, Button, Card, CardBody, HStack, Heading, Image, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import MetaInstantExperienceBlankImage from '../../../../../assets/images/meta_instant_experience_image.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { transformKeysTo } from '../../../../../helpers/apiHelper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './CustomSwiperStyles.css';
import { FiArrowLeft } from 'react-icons/fi';

const StorytellingPreview = ({ instantExperienceData, setInstantExperienceOpenedIfPossible }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const carouselItems = instantExperienceData?.metaInstantExperienceCarouselItems;
  const transformedCarouselItems = carouselItems?.map(item => transformKeysTo('snake_case', structuredClone(item)));
  const instantExperienceAsset_1 = transformKeysTo('snake_case', structuredClone(instantExperienceData?.instantExperienceAsset_1));
  const instantExperienceAsset_2 = transformKeysTo('snake_case', structuredClone(instantExperienceData?.instantExperienceAsset_2));
  const instantExperienceAsset_3 = transformKeysTo('snake_case', structuredClone(instantExperienceData?.instantExperienceAsset_3));

  return (
    <>
      <Box overflow={'hidden'} height='auto'>
        <Button ml={-2} background={'none'} color={'black'} leftIcon={<FiArrowLeft color="gray.700" />} onClick={() => setInstantExperienceOpenedIfPossible(false)}>
        </Button>
        {instantExperienceAsset_1 == null && <Image w={'full'} src={MetaInstantExperienceBlankImage} />}

        {instantExperienceAsset_1?.assetType == 'image' && <Box overflow={'hidden'}>
          <Image w={'full'} src={instantExperienceAsset_1?.fileUrl} />
        </Box>}
        {instantExperienceAsset_1?.assetType == 'video' && !instantExperienceAsset_1?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <video key={instantExperienceAsset_1?.uuid} muted width={'100%'} height="auto" loop autoPlay>
            <source src={instantExperienceAsset_1?.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>}
        {instantExperienceAsset_1?.assetType == 'video' && instantExperienceAsset_1?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <iframe width="100%" height="315" src={instantExperienceAsset_1?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>}
      </Box>
      <Heading size={'md'}>{instantExperienceData?.headline}</Heading>
      <Box overflow={'hidden'} height='auto'>
        {instantExperienceAsset_2 == null && <Image w={'full'} src={MetaInstantExperienceBlankImage} />}

        {instantExperienceAsset_2?.assetType == 'image' && <Box overflow={'hidden'}>
          <Image w={'full'} src={instantExperienceAsset_2?.fileUrl} />
        </Box>}
        {instantExperienceAsset_2?.assetType == 'video' && !instantExperienceAsset_2?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <video key={instantExperienceAsset_2?.uuid} muted width={'100%'} height="auto" loop autoPlay>
            <source src={instantExperienceAsset_2?.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>}
        {instantExperienceAsset_2?.assetType == 'video' && instantExperienceAsset_2?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <iframe width="100%" height="315" src={instantExperienceAsset_2?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>}
      </Box>
      <Box w="100%">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          centeredSlidesBounds
          centeredSlides
          grabCursor
          slidesPerView={1}
          freeMode
          spaceBetween={35}
          scrollbar={{ draggable: true }}
          style={{ padding: '0rem' }}
          navigation
        >
          {transformedCarouselItems && transformedCarouselItems.map((item, index) => {
            const isYouTubeUrl = /youtube/.test(item.asset?.fileUrl);
            const youTubeEmbedUrl = isYouTubeUrl ? item.asset?.fileUrl.replace('watch?v=', 'embed/') : item.asset?.fileUrl;

            return (
              <SwiperSlide style={{ padding: '0rem' }} key={item.uuid}>
                <Box height="auto" overflow={'hidden'}>
                  {item.asset == null && <Image borderTopRadius={'md'} w={'100%'} src={MetaInstantExperienceBlankImage} />}
                  {item.asset?.assetType == 'image' && <Image borderTopRadius={'md'} w={'100%'} h={'400px'} objectFit={'fill'} src={item?.asset?.fileUrl} />}
                  {item.asset?.assetType == 'video' && !isYouTubeUrl && <Box maxH={'400px'} overflow={'hidden'}>
                    <video key={item?.asset?.uuid} controls muted width={'100%'} height="auto" loop>
                      <source src={item?.asset?.fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>}
                  {item.asset?.assetType == 'video' && isYouTubeUrl && <Box maxH={'400px'} overflow={'hidden'}>
                    <iframe width="100%"
                      height="315"
                      src={youTubeEmbedUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Box>}
                </Box>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Box>
      <Text>{instantExperienceData?.carouselText}</Text>
      <Box overflow={'hidden'} height='auto'>
        {instantExperienceAsset_3 == null && <Image w={'full'} src={MetaInstantExperienceBlankImage} />}

        {instantExperienceAsset_3?.assetType == 'image' && <Box overflow={'hidden'}>
          <Image w={'full'} src={instantExperienceAsset_3?.fileUrl} />
        </Box>}
        {instantExperienceAsset_2?.assetType == 'video' && !instantExperienceAsset_2?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <video key={instantExperienceAsset_2?.uuid} muted width={'100%'} height="auto" loop autoPlay>
            <source src={instantExperienceAsset_2?.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>}
        {instantExperienceAsset_2?.assetType == 'video' && instantExperienceAsset_2?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <iframe width="100%" height="315" src={instantExperienceAsset_2?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>}
      </Box>
      <Button variant="outline" w="100%" onClick={() => openInNewTab(instantExperienceData?.buttonDestination_1)}>{instantExperienceData?.buttonLabel_1}</Button>
    </>
  );
};

export default StorytellingPreview;