import React, { useState } from "react";
import { transformKeysTo } from "../../../../../helpers/apiHelper";
import { Button, Icon, Text, HStack, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, IconButton, FormHelperText, Box, Spacer, Tooltip, FormErrorMessage, Flex } from "@chakra-ui/react";
import { FiAlertCircle, FiChevronDown, FiChevronUp, FiEdit3, FiPlus, FiTrash2 } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import flattenObject from "../../../../../helpers/objectHelper";
import Calls from "./Extensions/Calls";
import AggregatedPreview from "./AggregatedPreview";
import Callouts from "./Extensions/Callouts";
import Sitelinks from "./Extensions/Sitelinks";
import Snippets from "./Extensions/Snippets";
import Suggestions from "../../../../Suggestions/Suggestions";
import { HiOutlineChip } from "react-icons/hi";
import SuggestionsButton from "../../../../Suggestions/SuggestionsButton";
import { validateUrl } from "../../../../../components/utils/urlUtils";


function removeKeysFromFormData(formData, keysToRemove) {
  for (const key of keysToRemove) {
    formData.delete(key);
  }
}

const Editor = ({ isLoading, onFormSubmit, ad, refreshFlag, selectedVariation, errors }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [calls, setCalls] = useState(ad?.metadata?.calls || []);
  const [callouts, setCallouts] = useState(ad?.metadata?.callouts || []);
  const [sitelinks, setSitelinks] = useState(ad?.metadata?.sitelinks || []);
  const [snippets, setSnippets] = useState(ad?.metadata?.snippets || []);
  const [displayPath1Length, setDisplayPath1Length] = useState(ad?.metadata?.displayPath_1?.length || 0);
  const [displayPath2Length, setDisplayPath2Length] = useState(ad?.metadata?.displayPath_2?.length || 0);

  const handleDisplayPath1Change = (e) => {
    const { value } = e.target;
    setDisplayPath1Length(value.length);
    setValue('display_path_1', value)
  };

  const handleDisplayPath2Change = (e) => {
    const { value } = e.target;
    setDisplayPath2Length(value.length);
    setValue('display_path_2', value)
  };

  const getDefaultMetadata = () => {
    const defaultData = transformKeysTo('snake', structuredClone(ad?.metadata)) || {};

    if (!defaultData?.headlines) {
      defaultData.headlines = [{ text: "Example Headline 1" }, { text: "Example Headline 2" }, { text: "Example Headline 3" }]
    }

    if (!defaultData?.descriptions) {
      defaultData.descriptions = [{ text: "Example Description 1" }, { text: "Example Description 2" }]
    }

    return defaultData;
  }
  
  const { register, watch, getValues, setValue, setError, clearErrors, control, handleSubmit } = useForm({
      mode: 'onBlur',
    defaultValues: getDefaultMetadata()
  });

  const wAll = watch();

  const { fields: headlinesFields, append: headlinesAppend, remove: headlinesRemove, reset: headlinesReset } = useFieldArray({
    control,
    name: "headlines"
  });

  const { fields: descriptionsFields, append: descriptionsAppend, remove: descriptionsRemove, reset: descriptionsReset } = useFieldArray({
    control,
    name: 'descriptions'
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    const keysToRemove = ["calls[][country]", "calls[][phone_number]", "callouts[][text]", 'sitelinks[][text]', 'sitelinks[][description_line_1]', 'sitelinks[][description_line_2]', 'sitelinks[][final_url]', 'snippets[][text]', 'snippets[][values][]'];
    removeKeysFromFormData(formData, keysToRemove);
    delete data.calls
    calls.forEach((call) => {
      formData.append(`calls[][country]`, call.country);
      formData.append(`calls[][phone_number]`, call.phoneNumber);
    });
    delete data.callouts
    callouts.forEach((callout) => {
      formData.append(`callouts[][text]`, callout.text);
    });
    delete data.sitelinks
    sitelinks.forEach((sitelink) => {
      formData.append(`sitelinks[][text]`, sitelink.text);
      formData.append(`sitelinks[][description_line_1]`, sitelink.descriptionLine_1);
      formData.append(`sitelinks[][description_line_2]`, sitelink.descriptionLine_2);
      formData.append(`sitelinks[][final_url]`, sitelink.finalUrl);
    });
    delete data.snippets
    snippets.forEach((snippet) => {
      formData.append(`snippets[][text]`, snippet.text);
      snippet.values.forEach((value) => {
        formData.append(`snippets[][values][]`, value)
      });
    });

    const flObject = flattenObject(data);
    Object.keys(flObject).forEach((key) => {
      if (flObject[key] == null)
        return;
      const modifiedKey = key.replace(/\[\d+\]/g, '[]');
      formData.append(modifiedKey, flObject[key]);
    });

    onFormSubmit(formData);
  }

  const removeHeadline = (index) => {
    if (headlinesFields.length > 3) {
      headlinesRemove(index);
    }
  };

  const removeDescription = (index) => {
    if (descriptionsFields.length > 2) {
      descriptionsRemove(index);
    }
  };

  const [headlinesVisible, setHeadlinesVisible] = useState(true);
  const [descriptionsVisible, setDescriptionsVisible] = useState(true);
  const [callsVisible, setCallsVisible] = useState(true);
  const [calloutsVisible, setCalloutsVisible] = useState(true);
  const [finalUrlVisible, setFinalUrlVisible] = useState(true);
  const [displayPathVisible, setDisplayPathVisible] = useState(true);
  const [sitelinksVisible, setSitelinksVisible] = useState(true);
  const [snippetsVisible, setSnippetsVisible] = useState(true);

  const toggleFinalUrlVisibility = () => {
    setFinalUrlVisible(!finalUrlVisible);
  };

  const toggleDisplayPathVisibility = () => {
    setDisplayPathVisible(!displayPathVisible);
  };

  const toggleHeadlinesVisibility = () => {
    setHeadlinesVisible(!headlinesVisible);
  };

  const toggleDescriptionsVisibility = () => {
    setDescriptionsVisible(!descriptionsVisible);
  };

  const toggleCallsVisibility = () => {
    setCallsVisible(!callsVisible);
  };

  const toggleCalloutsVisibility = () => {
    setCalloutsVisible(!calloutsVisible);
  };

  const toggleSitelinksVisibility = () => {
    setSitelinksVisible(!sitelinksVisible);
  };

  const [isCallsDrawerOpen, setIsCallsDrawerOpen] = useState(false);
  const openCallsDrawer = () => {
    setIsCallsDrawerOpen(true);
  };

  const closeCallsDrawer = () => {
    setIsCallsDrawerOpen(false);
  };

  const [isCalloutsDrawerOpen, setIsCalloutsDrawerOpen] = useState(false);
  const openCalloutsDrawer = () => {
    setIsCalloutsDrawerOpen(true);
  };

  const closeCalloutsDrawer = () => {
    setIsCalloutsDrawerOpen(false);
  };

  const [isSitelinksDrawerOpen, setIsSitelinksDrawerOpen] = useState(false);
  const openSitelinksDrawer = () => {
    setIsSitelinksDrawerOpen(true);
  };

  const closeSitelinksDrawer = () => {
    setIsSitelinksDrawerOpen(false);
  };

  const [isSnippetsDrawerOpen, setIsSnippetsDrawerOpen] = useState(false);
  const openSnippetsDrawer = () => {
    setIsSnippetsDrawerOpen(true);
  };

  const closeSnippetsDrawer = () => {
    setIsSnippetsDrawerOpen(false);
  };

  const updateHeadline = (headlines) => {
    setValue('headlines', headlines.map(headline => ({ text: headline })));
  };

  const updateDescription = (descriptions) => {
    setValue('descriptions', descriptions.map(description => ({ text: description })));
  };



  return (
    <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
      <Box w={'full'}>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} >

            <Card w={'full'}>
              <CardBody>
                <FormControl isInvalid={errors?.business_name}>
                  <FormLabel>Business name</FormLabel>
                  <HStack spacing={5}>
                    <Input w={'full'} maxLength={25} {...register('business_name')} placeholder={'Business Name'} />
                  </HStack>
                  <FormHelperText color={(watch('business_name') && watch('business_name').length > 25) ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                    {watch('business_name') ? watch('business_name').length : "0"}/ 25
                  </FormHelperText>
                  <FormErrorMessage>{errors?.business_name.message}</FormErrorMessage>
                </FormControl>
              </CardBody>
            </Card>



            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>URL</Text>
                  <Tooltip hasArrow label='The final URL is the URL that people reach after clicking your ad. It should match what your ad promotes. If you use a cross-domain redirect, enter it in a tracking template.'>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={finalUrlVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleFinalUrlVisibility}
                    aria-label={finalUrlVisible ? "Collapse URL" : "Expand URL"}
                  />
                </HStack>
              </CardHeader>
              {finalUrlVisible && (
                <CardBody>
                  <FormControl isInvalid={errors?.final_url}>
                    <FormLabel>Final URL</FormLabel>
                    <HStack spacing={5}>
                      <Input w={'full'}
                        maxLength={2048}
                        {...register('final_url', {
                            required: "Please enter a valid URL",
                            pattern: {
                              value: /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(\/[\w-./?%&=]*)?$/i,
                              message: "Please enter a valid URL"
                            },
                            maxLength: {
                              value: 2048,
                              message: "URL must be less than 2048 characters"
                            }
                          })}
                        placeholder={'Add final URL here...'}
                        onBlur={(e) => {
                            const validatedUrl = validateUrl(e.target.value);
                            setValue('final_url', validatedUrl);
                        }} />

                    </HStack>
                    <FormHelperText color={(watch('final_url') && watch('final_url').length > 2048) ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                      {watch('final_url') ? watch('final_url').length : "0"}/ 2048
                    </FormHelperText>
                    <Text fontSize={'xs'} color={'gray.400'}>This will be used to suggest headlines and descriptions</Text>
                    <FormErrorMessage>{errors?.final_url}
                    </FormErrorMessage>


                  </FormControl>
                </CardBody>
              )}
            </Card>

            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Display Path</Text>
                  <Tooltip hasArrow label={`The "Path" fields are part of your display URL and can be up to 15 characters each. The display URL gives potential customers a clear idea of what webpage they'll reach once they click your ad, so your path text should describe your ad's landing page.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={displayPathVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleDisplayPathVisibility}
                    aria-label={displayPathVisible ? "Collapse Display Path" : "Expand Display Path"}
                  />
                </HStack>
              </CardHeader>
              {displayPathVisible && (
                <CardBody>
                  <FormControl isInvalid={errors?.display_path_1 || errors?.display_path_2}>
                    <HStack spacing={4}>
                      <FormLabel>/</FormLabel>
                      <Input
                        w={'full'}
                        {...register('display_path_1')}
                        placeholder={'Label'}
                        onChange={handleDisplayPath1Change}
                      />
                      <FormLabel>/</FormLabel>
                      <Input
                        w={'full'}
                        {...register('display_path_2')}
                        placeholder={'Label'}
                        onChange={handleDisplayPath2Change}
                      />
                    </HStack>
                    <HStack spacing={4}>
                      <FormHelperText color={displayPath1Length > 15 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{displayPath1Length} / 15</FormHelperText>
                      <FormHelperText color={displayPath2Length > 15 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{displayPath2Length} / 15</FormHelperText>
                    </HStack>
                    <HStack spacing={4} justify="flex-end">
                      <Box display="flex" justifyContent="flex-end" width="100%">
                        <FormErrorMessage>{errors?.display_path_1}</FormErrorMessage>
                      </Box>
                      <Box display="flex" justifyContent="flex-end" width="100%">
                        <FormErrorMessage>{errors?.display_path_2}</FormErrorMessage>
                      </Box>
                    </HStack>
                  </FormControl>
                </CardBody>
              )}
            </Card>

            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Headlines</Text>
                  <Tooltip hasArrow label={`Enter 3 to 15 headlines. Headlines appear at the top of your ad and can be up to 30 characters.
Your headlines and other parts of your ad (including extensions) may show in different configurations based on the device that a potential customer is using, or when Google Ads predicts that it may improve your performance.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <SuggestionsButton updateHeadline={updateHeadline} updateDescription={updateDescription} validations={{
                    headlines: { min: 3, max: 15 },
                    descriptions: { min: 2, max: 15 }
                  }} />                  <IconButton
                    icon={headlinesVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleHeadlinesVisibility}
                    aria-label={headlinesVisible ? "Collapse Headlines" : "Expand Headlines"}
                  />
                </HStack>
              </CardHeader>
              {headlinesVisible && (
                <CardBody>
                  <VStack spacing={10}>
                    {headlinesFields.map((field, index) => {
                      const textLength = getValues(`headlines[${index}].text`)?.length || 0;

                      return (
                        <FormControl isInvalid={textLength > 30} key={field.id}>
                          <FormLabel mr={0}>
                            <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
                              <Text>Headline {index + 1}</Text>
                              {headlinesFields.length > 3 && <IconButton variant={'ghost'} color={'#96999F'} onClick={() => removeHeadline(index)} aria-label={"Remove headline"} icon={<Icon as={FiTrash2} />} />}
                            </HStack>
                          </FormLabel>
                          <Input {...register(`headlines.${index}.text`)} placeholder={'Add headline here...'} maxLength={30} />
                          <FormHelperText color={textLength > 30 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{textLength} / 30</FormHelperText>
                        </FormControl>
                      )
                    })}
                    {headlinesFields.length < 15 &&
                      <FormControl>
                        <Button leftIcon={<Icon as={FiPlus} />} variant={'outline'} onClick={() => headlinesAppend({ text: '' })}>Headline</Button>
                      </FormControl>
                    }
                  </VStack>
                </CardBody>
              )}
            </Card>

            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Descriptions</Text>
                  <Tooltip hasArrow label={`Enter 2 to 4 descriptions. Your ad's description appears below the display URL and can be up to 90 characters.

Your descriptions and other parts of your ad (including extensions) may show in different configurations based on the device that a potential customer is using, or when Google Ads predicts that it may improve your performance.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={descriptionsVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleDescriptionsVisibility}
                    aria-label={descriptionsVisible ? "Collapse Descriptions" : "Expand Descriptions"}
                  />
                </HStack>
              </CardHeader>
              {descriptionsVisible && (
                <CardBody>
                  <VStack spacing={10}>
                    {descriptionsFields.map((field, index) => {
                      const textLength = getValues(`descriptions[${index}].text`)?.length || 0;

                      return (
                        <FormControl isInvalid={textLength > 90} key={field.id}>
                          <FormLabel mr={0}>
                            <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
                              <Text>Description {index + 1}</Text>
                              {descriptionsFields.length > 2 && <IconButton variant={'ghost'} color={'#96999F'} onClick={() => removeDescription(index)} aria-label={"Remove description"} icon={<Icon as={FiTrash2} />} />}
                            </HStack>
                          </FormLabel>
                          <Input {...register(`descriptions.${index}.text`)} placeholder={'Add description here...'} maxLength={90} />
                          <FormHelperText color={textLength > 90 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{textLength} / 90</FormHelperText>
                        </FormControl>
                      )
                    })}
                    {descriptionsFields.length < 4 &&
                      <FormControl>
                        <Button leftIcon={<Icon as={FiPlus} />} variant={'outline'} onClick={() => descriptionsAppend({ text: '' })}>Description</Button>
                      </FormControl>
                    }
                  </VStack>
                </CardBody>
              )}
            </Card>
            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Sitelinks</Text>
                  <Tooltip hasArrow label={`A sitelink shows additional links in your ad that take people to specific pages of your website. People can then click your links to go directly to what they want to know or buy.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={sitelinksVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleSitelinksVisibility}
                    aria-label={sitelinksVisible ? "Collapse Sitelinks" : "Expand Descriptions"}
                  />
                </HStack>
                <Text fontSize={'14px'} fontWeight={'400'} color={'#96999F'}>Add four or more to maximize performance</Text>
                <Flex fontSize={'14px'} fontWeight={'400'} color={'#323232'} backgroundColor={'#C8CBCE52'} borderRadius={'8px'} p='16px' mt='3'>
                  <Icon boxSize={6} as={FiAlertCircle} mt='2' mr='3' /> <Text>You'll need to add at least two sitelinks for them to appear in your ads. To maximise performance, add four or more.</Text>
                </Flex>
              </CardHeader>
              {sitelinksVisible && (
                <CardBody>
                  <VStack align="start">
                    <Text>{sitelinks.map(sitelink => sitelink.text).join(' and ')}</Text>
                    <Button leftIcon={<Icon as={FiEdit3} />} variant={'ghost'} onClick={openSitelinksDrawer}>Edit</Button>
                    <Sitelinks isOpen={isSitelinksDrawerOpen} onClose={closeSitelinksDrawer} initialSitelinks={sitelinks} setInitialSitelinks={setSitelinks} />

                  </VStack>
                </CardBody>
              )}
            </Card>
            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Calls</Text>
                  <Tooltip hasArrow label={`It may improve your performance.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={callsVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleCallsVisibility}
                    aria-label={callsVisible ? "Collapse Calls" : "Expand Calls"}
                  />
                </HStack>
                <Text fontSize={'14px'} fontWeight={'400'} color={'#96999F'}>Add a phone number</Text>
              </CardHeader>
              {callsVisible && (
                <CardBody>
                  <VStack align="start">
                    <Text>{calls.map(call => call.phoneNumber).join(' and ')}</Text>
                    <Button leftIcon={<Icon as={FiEdit3} />} variant={'ghost'} onClick={openCallsDrawer}>Edit</Button>
                    <Calls isOpen={isCallsDrawerOpen} onClose={closeCallsDrawer} initialCalls={calls} setInitialCalls={setCalls} />

                  </VStack>
                </CardBody>
              )}
            </Card>
            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Callouts</Text>
                  <Tooltip hasArrow label={`A callout shows additional text about popular or unique aspects of your business in your ad. You can use them to highlight promotional offers, such as free delivery`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={calloutsVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleCalloutsVisibility}
                    aria-label={calloutsVisible ? "Collapse Callouts" : "Expand Callouts"}
                  />
                </HStack>
                <Text fontSize={'14px'} fontWeight={'400'} color={'#96999F'}>Add more business information</Text>
              </CardHeader>
              {calloutsVisible && (
                <CardBody>
                  <VStack align="start">
                    <Text>{callouts && callouts.filter(snippet => snippet.text != '').map(callout => callout.text).join(' and ')}</Text>
                    <Button leftIcon={<Icon as={FiEdit3} />} variant={'ghost'} onClick={openCalloutsDrawer}>Edit</Button>
                    <Callouts isOpen={isCalloutsDrawerOpen} onClose={closeCalloutsDrawer} initialCallouts={callouts} setInitialCallouts={setCallouts} />

                  </VStack>
                </CardBody>
              )}
            </Card>
            <Card w={'full'}>
              <CardHeader pb="4">
                <HStack>
                  <Text>Snippets</Text>
                  <Tooltip hasArrow label={`A structured snippet gives you more space to highlight the nature and range of your products and services. You can even specify the dates, days of the week, or times of day this info.`}>
                    <Icon />
                  </Tooltip>
                  <Spacer />
                  <IconButton
                    icon={calloutsVisible ? <FiChevronUp /> : <FiChevronDown />}
                    variant="ghost"
                    onClick={toggleCalloutsVisibility}
                    aria-label={calloutsVisible ? "Collapse Callouts" : "Expand Callouts"}
                  />
                </HStack>
                <Text fontSize={'14px'} fontWeight={'400'} color={'#96999F'}>Add snippets to text</Text>
              </CardHeader>
              {calloutsVisible && (
                <CardBody>
                  <VStack align="start">
                    <Text>{snippets && snippets.filter(snippet => snippet.text != '').map(snippet => snippet.text + ": " + snippet.values.join(', ')).join(' and ')}</Text>
                    <Button leftIcon={<Icon as={FiEdit3} />} variant={'ghost'} onClick={openSnippetsDrawer}>Edit</Button>
                    <Snippets isOpen={isSnippetsDrawerOpen} onClose={closeSnippetsDrawer} initialSnippets={snippets} setInitialSnippets={setSnippets} />

                  </VStack>
                </CardBody>
              )}
            </Card>
            <HStack w={'full'} justifyContent={'flex-start'}>
              <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
              <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
            </HStack>
          </VStack>
        </form>

      </Box>
      <Box w={'full'} position={'sticky'} top={'125px'}>
        <VStack spacing={10}>
          <AggregatedPreview
            refreshFlag={refreshFlag}
            selectedVariation={selectedVariation}
            businessName={wAll.business_name}
            baseUrl={wAll?.final_url}
            display_path_1={wAll?.display_path_1}
            display_path_2={wAll?.display_path_2}
            headlines={wAll?.headlines?.map((item) => item.text)}
            descriptions={wAll?.descriptions?.map((item) => item.text)}
            calls={calls}
            callouts={callouts}
            sitelinks={sitelinks}
            snippets={snippets}
          />
        </VStack>
      </Box>
    </HStack>
  )
}

export default Editor;