import React from "react";

import { HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

const Display1 = ({ variation }) => {
  return (
    <>
      <VStack border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <Image w={'full'} src={variation.imageUrl} />

        <VStack alignItems={'flex-start'} w={'full'} px={3} py={1}>
          <Text fontSize={'sm'} fontWeight={600}>{variation.headline?.truncate(30)}</Text>
          <Text color={'gray.600'} fontSize={'x-small'}>{variation.description?.truncate(90)}</Text>

          <HStack mb={2} justifyContent={'space-between'} w={'full'}>
            <Text w={'full'} fontSize={'xs'}>{variation.businessName}</Text>
            <HStack w={'full'} justifyContent={'flex-end'}>
              <Text fontWeight={'bold'} fontSize={'xs'}>Open</Text>
              <Icon as={FiChevronRight} />
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  )
};

export default Display1;