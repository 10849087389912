import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Button, Box } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreHorizontal, FiMoreVertical, FiStar, FiTrash2 } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import { googleDemandgenCallToActions } from "../../../../../../components/Constants/google_demandgen_call_to_actions";

const MobileOpened = ({ variations, adType = 'image' }) => {


  return (
    <>

      <Phone p={3}>

        <HStack w={'full'} justifyContent={'space-between'} p={2}>
          <Icon as={FiChevronLeft} />
          <HStack spacing={5}>
            <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'sm'} px={1}>Ad</Text>
            <Icon as={FiStar} />
            <Icon as={FiTrash2} />
            <Icon as={FiMoreHorizontal} />
          </HStack>
        </HStack>


        <HStack mt={5} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Avatar size={'sm'} src={variations[0].logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <HStack>
                <Text fontWeight={'semibold'} fontSize={'xs'}>{variations[0].businessName}</Text>
              </HStack>
              <Text fontSize={'x-small'}>me</Text>
            </VStack>
          </HStack>
        </HStack>

        {adType != 'carousel' && <>
          <Image mt={5} w={'full'} src={variations[0].imageUrl} />

          <Text mt={5} fontSize={'lg'} fontWeight={'semibold'}>{variations[0].headline?.truncate(30)}</Text>
          <Text mt={3} color={'gray.700'} fontSize={'md'}>{variations[0].description?.truncate(90)}</Text>

          <Button mt={5} borderRadius={'3xl'} fontSize={'sm'} fontWeight={'bold'} py={2} w={'full'} bg={'blue.500'}>{googleDemandgenCallToActions.find((i) => i.value == variations[0]?.cta)?.label || 'Learn more'}</Button>
          <Divider my={5} />
        </>}

        {adType == 'carousel' && <>
          <Box h={'md'} overflowY={'scroll'}>
            {variations[0]?.carouselItems.map((item, index) => (

              <>
                <Image mt={5} w={'full'} src={item?.asset?.fileUrl} />

                <Text mt={5} fontSize={'lg'} fontWeight={'semibold'}>{item?.headline?.truncate(30)}</Text>
                <Text mt={3} color={'gray.700'} fontSize={'md'}>{item?.description?.truncate(90)}</Text>

                <Button mt={5} borderRadius={'3xl'} fontSize={'sm'} fontWeight={'bold'} py={2} w={'full'} bg={'blue.500'}>{googleDemandgenCallToActions.find((i) => i.value == item?.callToAction)?.label || 'Learn more'}</Button>
                <Divider my={5} />
              </>))}
          </Box>
        </>}
      </Phone>
    </>
  )
};

export default MobileOpened;