import React from 'react';

import { Box, Heading, VStack, Flex, Input, Stack, Text, HStack, Icon, Tag, Spacer } from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import { useGetSharedMediaPlansQuery } from '../../../api/services/shared/mediaPlans';
import { FiFolder } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import Paginator from '../../../components/Common/Pagination/Paginator';
import Status from '../../MediaPlans/Components/Status';
import { useClientToken } from '../../../components/Hooks/useClientToken';

const MediaPlans = () => {
  const { token } = useClientToken();
  const { organization } = useParams();
  const [search, setSearch] = React.useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const navigate = useNavigate();

  const { data, isSuccess } = useGetSharedMediaPlansQuery({ per_page: 10, page: currentPage, token, query: search }, { pollingInterval: 10000 });

  return (
    <>
      <Box px={10}>
        <Box w='100%'>
          <VStack spacing={0} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
            <Flex mb={10} justifyContent='space-between' alignItems={'center'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                Media Plans
              </Heading>

              <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                <Input onChange={(e) => setSearch(e.target.value)} w={['100%', 'md']} placeholder='Search media plans...' />
              </Stack>
            </Flex>

            {isSuccess && data?.mediaPlans?.map((item) => {
              return (
                <Box
                  onClick={() => navigate(`/portal/${organization}/media-plans/${item.uuid}`)}
                  _hover={{ bg: 'gray.50', cursor: 'pointer' }}
                  px={2} py={7}
                  borderBottom={'solid 1px'}
                  borderColor={'gray.200'}
                >
                  <HStack justifyContent={'space-between'}>
                    <HStack>
                      <Icon fontSize={'xl'} as={FiFolder} />
                      <Text fontWeight={'semibold'} pl={3}>{item.name}</Text>
                      <Tag fontSize={'xs'} ml={5} colorScheme='gray'>Version {item.version}</Tag>
                      <Tag fontSize={'xs'} ml={2} colorScheme='purple'>{item.campaignsCount} campaigns</Tag>
                    </HStack>
                    <HStack>
                      <Status status={item.status} />
                    </HStack>
                  </HStack>
                </Box>
              )
            })}

            <Spacer mt={10} h={'50px'} />
            <Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />

          </VStack>
        </Box>
      </Box >

    </>
  );
};


export default MediaPlans;