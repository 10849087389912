import { Avatar, Box, HStack, Menu, MenuButton, VStack, Text, Image, Flex } from '@chakra-ui/react';
import LogoHorizontal from '../../../assets/images/logo_horizontal.svg';
import React, { useEffect } from 'react';

const GuestHeader = ({ organization }) => {
  useEffect(() => {
    document.title = organization?.organization?.displayName ?? 'WhatCampaign';

    const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = organization?.organization?.organizationFavicon?.fileUrl;
    document.head.appendChild(favicon);
  }, [organization?.organization?.displayName, organization?.organization?.organizationFavicon?.fileUrl])

  return (
    <Box bg={'white'} zIndex={10000} w={'full'} textAlign={'center'} justifyContent={'space-between'} alignItems={'center'} display={'flex'} p={'1rem'} borderBottom={'solid 1px'} borderBottomColor={'blackAlpha.300'}>
      {(organization?.organization?.organizationLogo != null || (organization?.organization?.displayName != null && organization?.organization?.displayName != '')) &&
        <Flex align="center">
          {organization?.organization?.organizationLogo != null && <Image maxH={'70px'} src={organization?.organization?.organizationLogo?.fileUrl} maxW={'200px'} mr={4} />}
          <Box>
            <Text fontSize={'lg'} fontWeight="bold">{organization?.organization?.displayName}</Text>
          </Box>
        </Flex>}

      {organization == undefined && <Image src={LogoHorizontal} />}

      <Menu>
        <MenuButton
          transition="all 0.3s"
          _focus={{ boxShadow: 'none' }}>
          <HStack spacing={2}>
            <Avatar size={'sm'} name={null} />
            <Box display={'flex'} gap={3} alignItems={'flex-start'}>
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems="flex-start"
                spacing="1px"
                ml="2">
                <Text fontSize="sm">{'Anonymous'}</Text>
                <Text fontSize="xs" color="gray.400">
                  Guest
                </Text>
              </VStack>
            </Box>

          </HStack>
        </MenuButton>
      </Menu>
    </Box>
  );
}

export default GuestHeader;