import React, { useState } from "react";
import AssetsLibraryManager from "./Manager";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Icon,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { FiFile, FiImage, FiVideo } from "react-icons/fi";

const buttonType = {
  all: { icon: FiFile, text: "Add Media" },
  image: { icon: FiImage, text: "Add Image" },
  video: { icon: FiVideo, text: "Add Video" },
};

const AssetsLibraryButton = ({
  type = null,
  types = [],
  multiple = false,
  assets,
  setAssets,
  validations = {},
  label = null,
  rounded = false,
}) => {
  const [selectedAssets, setSelectedAssets] = useState(assets);
  const [selectedType, setSelectedType] = useState("all");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const openType = (type) => {
    setSelectedType(type);
    onOpen();
  };

  const applyAssets = () => {
    if (validations?.max != null) {
      if (selectedAssets.length > validations?.max) {
        return toast({
          title: `You can only select a maximum of ${validations?.max} assets.`,
          status: "error",
        });
      }
    }

    setAssets(selectedAssets);
    onClose();
  };

  return (
    <>
      {/** Used for selecting logo in a circular button */}
      {rounded && type != null && (
        <>
          {selectedAssets?.length == 0 && (
            <>
              <Button
                borderRadius={"full"}
                onClick={onOpen}
                cursor={"pointer"}
                variant={"outline"}
                as="span"
              >
                <Icon as={FiImage} />
              </Button>
            </>
          )}
          {selectedAssets?.length > 0 && (
            <>
              <Avatar
                onClick={onOpen}
                cursor={"pointer"}
                src={selectedAssets[0]?.fileUrl}
              />
            </>
          )}
        </>
      )}

      {/** Used for displaying multiple buttons (Add Image, Add Video, Add Logo..) */}
      {types?.length > 0 && (
        <>
          {types.map((item) => (
            <Button
              key={item}
              cursor={"pointer"}
              onClick={() => openType(item)}
              variant={"outline"}
              leftIcon={<Icon as={buttonType[item].icon} />}
              as="span"
            >
              {buttonType[item].text}
            </Button>
          ))}
        </>
      )}

      {/** Used for displaying a single button (Add Media or Add Image or Add Video..) */}
      {type != null && !rounded && (
        <Button
          cursor={"pointer"}
          onClick={onOpen}
          variant={"outline"}
          leftIcon={label == null ? <Icon as={buttonType[type].icon} /> : null}
          as="span"
        >
          {label || buttonType[type].text}
        </Button>
      )}

      <Modal size={"6xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody maxH={"lg"} overflow={"scroll"} p={4} px={8}>
            <AssetsLibraryManager
              type={type || selectedType}
              multiple={multiple}
              selectedAssets={selectedAssets}
              setSelectedAssets={setSelectedAssets}
            />
          </ModalBody>
          <ModalFooter>
            {selectedAssets?.length > 0 && (
              <Button
                onClick={() => {
                  applyAssets();
                }}
              >
                Add selected ({selectedAssets?.length})
              </Button>
            )}
            <Button ml={4} variant={"outline"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssetsLibraryButton;
