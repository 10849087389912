import React from 'react';
import { Select, CreatableSelect, AsyncSelect } from "chakra-react-select";
import { Box, Text } from '@chakra-ui/react';


const DropdownMultiple = ({ values, defaultValue, onSubmit, readOnly }) => {
  const handleChange = (e) => {
    const values = e.map((item) => item.value)?.join(',');

    onSubmit(values == '' ? null : values);
  };

  const transformedDefaultValue = defaultValue?.split(',').map((item) => ({ value: item, label: values.find(value => value.value === item)?.label }));

  return (<>
    {readOnly && <Text>
      {transformedDefaultValue?.map((item) => item.label)?.join(', ')}
    </Text>}

    {!readOnly &&
      <Box w={'sm'}>
        <Select
          isMulti
          size={'sm'}
          chakraStyles={{ menu: (provided) => ({ ...provided, zIndex: 10000 }) }}
          name="colors"
          options={values}
          selectedOptionStyle='check'
          placeholder="Select options..."
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={transformedDefaultValue}
          onChange={handleChange}
        />
      </Box>}

  </>)
};

export default DropdownMultiple;