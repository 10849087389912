
import {
  ModalHeader, Text, Icon, Alert, AlertIcon, Input, Button, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, ScaleFade, Textarea, Drawer,
  Box,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  useDisclosure,
  HStack,
  VStack,
  Checkbox,
  Radio,
  useSteps,
  FormHelperText
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateMediaPlanMutation } from '../../api/services/mediaPlans';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const Create = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const toast = useToast();
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: 1
  })

  const { register, handleSubmit, reset } = useForm();
  const [create, { isLoading, isSuccess, isError }] = useCreateMediaPlanMutation();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [type, setType] = React.useState('');

  const onSubmit = (data) => {
    data = {
      ...data,
      validity_start_date: '2024-01-01',
      validity_end_date: '2024-12-12',
      summary_budget: 0,
      agency_fee: 0,
      taxes: 0,
      currency_code: 'USD',
      workspace_id: currentWorkspace?.id?.toString(),
      status: 'draft'
    }
    create(data).unwrap().then((response) => {
      // successful response
    }).catch((error) => {
      setErrorMessage(error.data.errors[''])
    });
  };

  useEffect(() => {
    setActiveStep(0);
    setType('');
  }, [isOpen])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Media Plan created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      reset();
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <Button onClick={() => onOpen()} colorScheme={'purple'} leftIcon={<Icon as={FiPlus} />}>Create new</Button>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Create a new media plan</Text>
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>

              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  Media Plan could not be created. Please contact support.
                </Alert>
              </ScaleFade>}

              {activeStep == 0 && <>
                <Box cursor={'pointer'} onClick={() => setType('simple')} border={'solid 1px'} borderColor={type == 'simple' ? 'brand.500' : 'gray.200'} px={7} py={5} borderRadius={'10px'}>
                  <HStack spacing={10} alignItems={'flex-start'}>
                    <VStack alignItems={'flex-start'}>
                      <Text fontSize={'md'} fontWeight={'semibold'}>Simple view</Text>
                      <Text fontSize={'sm'} color={'gray.500'}>Streamlined interface for quick and easy campaign setup with essential options</Text>
                    </VStack>
                    <Radio isChecked={type == 'simple'} colorScheme='brand' size={'lg'} />
                  </HStack>
                </Box>

                <Box bg={'gray.100'} mt={5} cursor={'not-allowed'} border={'solid 1px'} borderColor={type == 'detailed' ? 'brand.500' : 'gray.200'} px={7} py={5} borderRadius={'10px'}>
                  <HStack spacing={10} alignItems={'flex-start'}>
                    <VStack alignItems={'flex-start'}>
                      <HStack spacing={5}>
                        <Text fontSize={'md'} fontWeight={'semibold'}>Detailed view</Text>
                        <Text fontSize={'xs'} color={'brand.500'} fontWeight={'semibold'}>COMING SOON</Text>
                      </HStack>

                      <Text fontSize={'sm'} color={'gray.500'}>Comprehensive interface offering extensive customization and control over all aspects of the campaign</Text>
                    </VStack>
                    <Radio isChecked={type == 'detailed'} colorScheme='brand' size={'lg'} />
                  </HStack>
                </Box>
              </>}

              {activeStep == 1 && <>
                <FormControl mb={5}>
                  <FormLabel>Media Plan name</FormLabel>
                  <FormHelperText color={'gray.400'}>Enter a name for your media plan.</FormHelperText>
                  <Input mt={3} {...register('name', { required: true })} placeholder='eg. Summer Campaign' />
                </FormControl>
              </>}


            </ModalBody>

            <ModalFooter gap={3} justifyContent={'flex-start'}>
              <Button onClick={() => activeStep == 0 ? onClose() : goToPrevious()} variant='outline'>{activeStep == 0 ? 'Cancel' : 'Previous'}</Button>

              {activeStep == 0 && <>
                <Button isDisabled={type == ''} colorScheme='primary' mr={3} type='button' onClick={() => goToNext()}>
                  Next
                </Button>
              </>}

              {activeStep == 1 && <Button isDisabled={isLoading} colorScheme='primary' mr={3} type='submit'>
                Create
              </Button>}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Create;
