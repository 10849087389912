import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CommonEditor from "./CommonEditor";
import Story from "../../Preview/Instagram/Story";

const StoryEditor = ({ isLoading, onFormSubmit, ad }) => {
  const { register, watch, getValues, setValue, handleSubmit, control } = useForm({
    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
  });

  const asset = ad?.metadata?.asset;
  const assetsState = React.useState(asset == null ? [] : [asset]);

  const instagramLogoAsset = ad?.metadata?.instagramLogo;
  const facebookLogoState = React.useState([]);
  const instagramLogoState = React.useState(instagramLogoAsset == null ? [] : [instagramLogoAsset])

  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <CommonEditor
          getValues={getValues}
          setValue={setValue}
          ad={ad}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          onFormSubmit={onFormSubmit}
          register={register}
          watch={watch}
          control={control}
          assetsState={assetsState}
          facebookLogoState={facebookLogoState}
          instagramLogoState={instagramLogoState} />
        <Box w={'full'} display={'flex'} justifyContent={'center'}>
          <VStack spacing={10}>

            <Story ad={{
              primaryText: getValues('primary_text'),
              headline: getValues('headline'),
              description: getValues('description'),
              websiteUrl: getValues('website_url'),
              callToAction: getValues('call_to_action'),
              asset: assetsState[0][0],
              instagramAccountName: getValues('instagram_account_name'),
              instagramLogo: instagramLogoState[0][0],
            }} 
            instantExperienceData={getValues('meta_instant_experience')} />

          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default StoryEditor;