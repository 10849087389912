import { Box, Button, Flex, HStack, Icon, IconButton, Select, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { previewVariations } from '../../components/Constants/previewVariations';
import EditableInput from '../../components/Common/Input/EditableInput';
import { FiMessageSquare, FiRefreshCcw } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useUpdateAdMutation } from '../../api/services/ads';
import { FaCircle } from 'react-icons/fa';
import Comments from '../Comments/Comments';
import { transformKeysTo } from '../../helpers/apiHelper';
import Previews from './Ad/Previews'

const Ad = ({ ad, platform, uuid, adGroupUuid, mode, disableEdit = false, isCampaignShared }) => {
  const [selectedPreviewVariation, setSelectedPreviewVariation] = useState(previewVariations.find((option) => option.default));
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [variationEnabled, setVariationEnabled] = useState(false);
  const [updateAd] = useUpdateAdMutation();

  const commentsDisclosure = useDisclosure();

  const handleSelectPreviewVariationChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPreviewVariation(previewVariations.find((option) => option.value === selectedValue));
  };

  const handleRefreshClick = () => {
    setRefreshFlag(!refreshFlag);
  };

  const onAdNameSave = (name) => {
    const data = new FormData();
    data.append('uuid', ad.uuid);
    data.append('ad_group_uuid', ad.adGroupUuid);
    data.append('name', name);

    updateAd(data);
  };

  const snake_case_ad = transformKeysTo('snake', structuredClone(ad?.metadata?.metaInstantExperience))

  useEffect(() => {
    if (ad?.metadata?.headlines?.length >= 3 || ad?.metadata?.descriptions?.length > 3) {
      setVariationEnabled(true)
    }
  }, [ad?.metadata?.headlines, ad?.metadata?.descriptions]);

  return (
    <Box p={8} py={0} mt={6}>
      <Box>
        <HStack gap={6} direction={['column', 'row']} alignItems={'center'} m={4} mx={0} justifyContent={'space-between'}>
          <Flex>
            <EditableInput
              letterSpacing={"-0.04rem"}
              color={'gray.500'}
              fontSize={'xl'}
              fontWeight={500}
              label={ad?.name}
              key={ad?.name}
              onSave={(data) => onAdNameSave(data)}
              isDisabled={disableEdit}
              mr="1" />

            <Box style={{ position: "relative", top: 3 }}>
              <IconButton
                onClick={commentsDisclosure.onOpen}
                icon={<Icon as={FiMessageSquare} sx={{ fontSize: 18 }} />}
                border="none"
                variant={"raised"}
                style={{ backgroundColor: 'transparent' }} />
              {ad?.hasComments && <FaCircle
                color="red"
                size="10px"
                style={{ position: "absolute", top: 0, right: 0, margin: 7 }} />}

            </Box>
          </Flex>

          {platform === 'google_search' && (
            <HStack alignItems={'stretch'}>
              <Select disabled={!variationEnabled} size='lg' value={selectedPreviewVariation?.value} onChange={handleSelectPreviewVariationChange}>
                {previewVariations.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <IconButton isDisabled={!variationEnabled} p={5} px={6} variant={'outline'} aria-label="Refresh variations" onClick={handleRefreshClick} icon={<Icon fontSize={'sm'} as={FiRefreshCcw} />} />
              {mode == "mock" && !isCampaignShared && (
                <Button variant={'outline'} as={Link} px={7} to={`/campaigns/${uuid}/ad_groups/${adGroupUuid}/ads/${ad.uuid}`}>
                  Edit
                </Button>
              )}
            </HStack>
          )}
          {platform !== 'google_search' && mode == "mock" && !isCampaignShared && (
            <Button variant={'outline'} as={Link} to={`/campaigns/${uuid}/ad_groups/${adGroupUuid}/ads/${ad.uuid}`} >
              Edit
            </Button>
          )}
        </HStack>
      </Box>

      <Previews platform={platform} ad={ad} selectedPreviewVariation={selectedPreviewVariation} refreshFlag={refreshFlag} snake_case_ad={snake_case_ad} />

      <Comments allowCommenting adId={ad?.id} disclosure={commentsDisclosure} />
    </Box>
  );
};

export default Ad;
