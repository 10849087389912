import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import LinkedinSingleImage from "../../Preview/Linkedin/SingleImageVideo";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CommonEditor from "./CommonEditor";

const SingleImageVideoEditor = ({ isLoading, onFormSubmit, ad }) => {
  const { register, watch, getValues, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
  });

  const asset = ad?.metadata?.asset;
  const assetsState = React.useState(asset == null ? [] : [asset]);

  const linkedinLogoAsset = ad?.metadata?.linkedinLogo;
  const linkedinLogoState = React.useState(linkedinLogoAsset == null ? [] : [linkedinLogoAsset])

  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <CommonEditor getValues={getValues} setValue={setValue} ad={ad} isLoading={isLoading} handleSubmit={handleSubmit} onFormSubmit={onFormSubmit} register={register} watch={watch} assetsState={assetsState} linkedinLogoState={linkedinLogoState} inputErrors={errors} />
        <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'125px'}>
          <VStack spacing={10}>
            <LinkedinSingleImage ad={{
              introductoryText: getValues('introductory_text'),
              headline: getValues('headline'),
              description: getValues('description'),
              destinationUrl: getValues('destination_url'),
              callToAction: getValues('call_to_action'),
              asset: assetsState[0][0],
              linkedinPageName: getValues('linkedin_page_name'),
              linkedinLogo: linkedinLogoState[0][0],
            }} />
          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default SingleImageVideoEditor;