import { Box, HStack, Image, Spacer, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isLoggedIn } from "../../helpers/sessionHelper";
import LogoHorizontal from '../../assets/images/logo_horizontal.svg';
import Footer from "../../views/Landing/components/Footer";
import Onboarding from '../../views/OnboardingWizard/Onboarding';

const AuthenticationLayout = () => {
  const location = useLocation();
  const store = useStore();

  const storeAuthState = store.getState().auth;
  const isLoggedInStore = storeAuthState?.accessToken?.length > 0

  if (isLoggedIn && isLoggedInStore)
    return <Navigate to="/campaigns" replace state={{ from: location }} />;

  return (
    <>
      <Box display={'flex'} flexDir={'column'} w={'100%'} h={'100vh'}>
        <Box display={'flex'} justifyContent={'center'} w={'full'} textAlign={'center'} p={'2rem'} borderBottom={'solid 1px'} borderBottomColor={'blackAlpha.300'}>
          <Image src={LogoHorizontal} />
        </Box>

        <Outlet />

        <Spacer />
        <Box display={'flex'} justifyContent={'center'} w={'full'} p={'2rem'} borderTop={'solid 1px'} borderColor={'blackAlpha.300'}>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default AuthenticationLayout;