import { baseApi } from "./base/base";

export const clientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: (data) => ({ url: '/clients', params: data }),
      providesTags: ['Client']
    }),

    createClient: builder.mutation({
      query: (data) => ({ url: `/clients`, method: 'POST', body: data }),
      invalidatesTags: ['Client']
    }),

    deleteClient: builder.mutation({
      query: (data) => ({ url: `/clients/${data.id}`, method: 'DELETE', params: data }),
      invalidatesTags: ['Client']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetClientsQuery, useCreateClientMutation, useDeleteClientMutation
} = clientsApi;
