import { Text, Image, useSteps, VStack, HStack, Stack, SkeletonCircle, SkeletonText, Spinner, Radio, RadioGroup, Center, Flex, Icon } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useImportFacebookCampaignMutation } from '../../../../api/services/integrations';
import Confetti from '../../../../assets/icons/confetti.png'
import { useSelector } from 'react-redux';
import { useFolder } from '../../Contexts/FolderContext';
import { FiAlertCircle } from 'react-icons/fi';

const Import = ({ integration, campaign, adAccount }) => {
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const [importCampaign, { isLoading, isSuccess, isError }] = useImportFacebookCampaignMutation();
  const { folder } = useFolder();

  useEffect(() => {
    importCampaign({
      id: integration.id,
      campaign_id: campaign.id,
      account_id: adAccount.id,
      workspace_id: currentWorkspace.id,
      folder_id: folder?.id
    })
  }, []);

  return (
    <>
      {isLoading && <>
        <Flex alignItems="center">
          <Spinner />
          <Text ml={2}>Importing</Text>
        </Flex>
      </>}

      {isSuccess && <>
        <Center>
          <Image src={Confetti} w={'36px'} />
        </Center>

        <Text mt={6} textAlign={'center'} fontWeight={400} mb={4}>We're importing your campaign at the moment. Please check back in a minute.</Text>
      </>}

      {isError && <>
        <Center>
          <Icon as={FiAlertCircle} w={6} h={6} color={'red.500'} />
        </Center>

        <Text mt={6} textAlign={'center'} fontWeight={400} mb={4}>We’re unable to complete the import right now due to a temporary issue with Facebook’s Ads system. Please try again later.</Text>
      </>}
    </>
  )
};

export default Import;