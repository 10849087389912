import { baseApi } from "./base/base";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccessToken: builder.mutation({
      query: (data) => ({
        url: '/auth/token',
        method: 'POST',
        body: data
      })
    }),
    startOrganizationDetails: builder.mutation({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/start_organization_details`,
        method: 'POST',
      }),
    }),
    startPaymentDetails: builder.mutation({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/start_payment_details`,
        method: 'POST',
      }),
    }),
    completeSession: builder.mutation({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/complete`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false
});

export const {
  useGetAccessTokenMutation,
  useStartOrganizationDetailsMutation,
  useStartPaymentDetailsMutation,
  useCompleteSessionMutation,
} = authApi;
