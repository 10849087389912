import { Box, Button, Flex, HStack, Heading, VStack, Text, Icon, Link, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useCreateStripePortalSessionMutation } from '../../api/services/subscriptions';
import Pricing from './Pricing';
import { FiExternalLink, FiTable } from 'react-icons/fi';

const Billing = () => {
  const [customerPortalUrl, setCustomerPortalUrl] = useState('');
  const [createSession, { isLoading, isSuccess }] = useCreateStripePortalSessionMutation();

  useEffect(() => {
    createSession().then((response) => {
      setCustomerPortalUrl(response?.data?.url)
    })
  }, []);

  return (
    <Box px={10} mt={10}>
      <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
        <HStack w={'full'} justifyContent={'space-between'}>
          <Flex flexDir={'column'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
            <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
              Plans that fit your need
            </Heading>

            <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
              Explore our tailored plans designed to meet your specific needs. <br />We partner up with Stripe to ensure your payments are secure.
            </Text>
          </Flex>
          {isLoading && <Spinner />}
          {isSuccess && <Button onClick={() => window.open(customerPortalUrl, '_blank')} h={'auto'} variant={'ai'} p={3} leftIcon={<Icon as={FiTable} />} rightIcon={<Icon as={FiExternalLink} />} >
            <VStack mx={3} alignItems={'flex-start'} spacing={0}>
              <Text fontWeight={'bold'}>Customer Portal</Text>
              <Text fontSize={'x-small'}>Manage billing & payment details.</Text>
            </VStack>
          </Button>}
        </HStack>


        <Pricing />
      </VStack>
    </Box>
  );
};

export default Billing;
