import React from 'react';
import { Link, Flex, Spacer, Text, HStack } from "@chakra-ui/react";
import { DateTime } from 'luxon';

const Footer = () => {
  return (

    <HStack w={'full'} justifyContent={'space-between'} >
      <Text fontSize="sm" mb={{ base: '2', md: '0' }}>&copy; {DateTime.now().year} WhatCampaign</Text>
      <HStack direction={{ base: 'column', md: 'row' }} align="center">
        <Link href="https://whatcampaign.com/privacy-policy" fontSize="sm" mr="4">Privacy Policy</Link>
        <Text fontSize="sm" mr="4">|</Text>
        <Link href="https://whatcampaign.com/terms-of-service" fontSize="sm">Terms of Service</Link>
      </HStack>
    </HStack>
  );
};

export default Footer;