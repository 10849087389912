import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Box,
  FormLabel,
  Input,
  FormControl,
  HStack,
  Collapse,
  Icon,
  InputRightAddon,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  ScaleFade,
} from '@chakra-ui/react'
import React, { useEffect } from 'react';
import { FiPlus, FiShare } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCreateAdGroupMutation } from '../../api/services/adGroups';

const CreateAdGroup = ({ campaign_id }) => {
  const { register, handleSubmit, setValue, reset } = useForm();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [create, { isLoading, isSuccess, isError }] = useCreateAdGroupMutation();

  const onSubmit = (data) => {
    data.campaign_id = campaign_id;

    create(data);

    reset();
  };

  useEffect(() => {
    // TODO: Add redirect to the Campaign overview

    onClose();
  }, [isSuccess]);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<Icon as={FiPlus} />} variant='outline'>Create ad group</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Create a new ad group</Text>
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>

              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  Ad group could not be created.
                </Alert>
              </ScaleFade>}

              <FormControl>
                <FormLabel>Ad Group Name</FormLabel>
                <Input {...register('name', { required: true })} type='text' placeholder='Untitled Ad Group' />
              </FormControl>
            </ModalBody>

            <ModalFooter gap={3} justifyContent={'flex-start'}>
              <Button onClick={onClose} variant='outline'>Cancel</Button>
              <Button isDisabled={isLoading} colorScheme='primary' mr={3} type='submit'>
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateAdGroup;