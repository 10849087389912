import React, { useState } from 'react';
import { Box, Button, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { useGetPublicCampaignQuery } from '../../api/services/public/campaigns';
import { useParams } from 'react-router-dom';
import AdGroup from '../AdGroups/Shared/AdGroup';
import { FiMessageSquare } from 'react-icons/fi';
import { FaCircle } from 'react-icons/fa';
import Comments from '../Comments/Comments';

const PublicCampaign = () => {
  const { uuid, campaign_uuid } = useParams();

  const { data, isSuccess } = useGetPublicCampaignQuery({ uuid: campaign_uuid, token: uuid }, { refetchOnMountOrArgChange: true });
  const campaign = data?.campaign;

  return (
    <>
      {isSuccess && <Box p={4} minHeight="100vh" w='100%'>
        <Flex gap={6} direction={['column', 'row']} alignItems={'center'} p={8} justifyContent={'space-between'}>
          <Text letterSpacing={'-0.04rem'} fontSize={'3xl'} fontWeight={500} >{campaign.name}</Text>
        </Flex>
        {campaign?.adGroups &&
          campaign?.adGroups.map((ad_group) => (
            <AdGroup disableComments platform={campaign?.platform} ad_group={ad_group} key={ad_group.uuid} mode={campaign?.mode} isCampaignShared={true} />
          ))
        }
      </Box >
      }
    </>
  );
};

export default PublicCampaign;