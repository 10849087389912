import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Button, Image, SkeletonCircle, SkeletonText, Divider } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp } from 'react-icons/fa6';
import { FiCornerUpRight, FiDownload, FiExternalLink, FiMessageSquare, FiMoreVertical, FiRewind, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { googlePmaxCallToActions } from '../../../../../../components/Constants/google_pmax_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const VideoInStreamAd = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Video in-stream ad</Text>

      <Phone>
        <Box mb={4} position={'relative'}>
          <Box w={'30%'} position={'absolute'} bottom={0} bg={'yellow.300'} h={'4px'}></Box>

          <Button bg={'black'} right={5} bottom={5} rounded={0} px={3} border={'solid 1px white'} boxShadow={'lg'} py={2} position={'absolute'} fontSize={'x-small'} >Skip Ad</Button>
          {!variation.remoteProvider && <video muted autoPlay width={'300px'} height="auto"><source src={variation.videoUrl} type="video/mp4" /></video>}
          {variation.remoteProvider == 'youtube' && <iframe width="300px" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
        </Box>

        <HStack w={'full'} px={5} justifyContent={'space-between'}>
          <HStack >
            <Image src={variation.logoUrl} w={7} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontSize={'xs'}>{variation.headline?.truncate(30)}</Text>
              <Text fontSize={'xs'}>Sponsored</Text>
            </VStack>
          </HStack>

          <Button w={'auto'} fontSize={'sm'} rounded={0} bg={'blue.600'} color={'white'} px={2} py={1}>{googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>
        </HStack>

        <SkeletonText px={5} mt={5} w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />


        <Box p={5}>
          <HStack justifyContent={'space-between'}>
            <VStack>
              <Icon as={FiThumbsUp} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>
            <VStack>
              <Icon as={FiThumbsDown} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiMessageSquare} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiCornerUpRight} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>

            <VStack>
              <Icon as={FiDownload} fontSize={'xl'} color={'gray.400'} />
              <Box w={'full'} h={'5px'} bg={'gray.100'}></Box>
            </VStack>
          </HStack>
        </Box>


        <Divider my={2} />
        <HStack px={5} mt={0} w={'full'}>
          <Box>
            <SkeletonCircle startColor="gray.400" endColor="gray.400" size='10' />
          </Box>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
        </HStack>
        <Divider my={2} />

        <SkeletonText p={5} w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />

      </Phone >
    </>
  )
};

export default VideoInStreamAd;