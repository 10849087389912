
import { Box, HStack, Text, Avatar, Button, Spacer, useDisclosure, IconButton, Collapse, Divider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { setCurrentWorkspace } from "../../../../api/slices/usersSlice";
import { FiChevronDown, FiEdit2, FiFileText, FiFolder, FiInbox, FiPlus, FiSettings } from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarLink from "../SidebarLink";
import EditWorkspace from "../../../../views/Workspaces/Edit";

const WorkspaceSidebar = () => {
  const dropdownDisclosure = useDisclosure();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const currentUser = useSelector((state) => state.user.currentUser);

  const updateWorkspaceDisclosure = useDisclosure();

  const changeWorkspace = (workspace) => {
    dispatch(setCurrentWorkspace(workspace));
    localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
  };

  const handleEditWorkspace = (e, workspace) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedWorkspace(workspace);
    updateWorkspaceDisclosure.onOpen();
  }

  useEffect(() => {
    if (localStorage.getItem('currentWorkspace') == null && currentUser?.workspaces[0] != null) {
      changeWorkspace(currentUser?.workspaces[0]);
    }
  }, [currentUser]);

  useEffect(() => {
    dropdownDisclosure.onClose();
  }, [location]);

  return (
    <>
      {selectedWorkspace && <EditWorkspace disclosure={updateWorkspaceDisclosure} currentWorkspace={currentWorkspace} workspace={selectedWorkspace} changeWorkspace={changeWorkspace} />}


      <Box m={4}>
        <Box>
          <Button _hover={{ bg: 'gray.50' }} borderRadius={'md'} onClick={() => dropdownDisclosure.onToggle()} w={'full'} color={'black'} bg={'white'} p={4}>
            <HStack textAlign={'left'} w={'full'} justifyContent={'space-between'} alignItems={'center'} >
              <Box w={'full'}>
                <Text mb={2} fontSize={'xs'} color={'gray.500'}>WORKSPACE</Text>
                <HStack justifyContent={'space-between'} w={'full'}>
                  <HStack spacing={3}>
                    <Avatar size={'xs'} name={currentWorkspace?.name} />
                    {currentWorkspace && <Text fontWeight={500} fontSize={'sm'}>{currentWorkspace.name}</Text>}
                    {!currentWorkspace && <Text fontWeight={500} fontSize={'sm'}>No workspace selected</Text>}
                  </HStack>
                  <FiChevronDown />
                </HStack>

              </Box>
            </HStack>
          </Button>
        </Box>

        <Collapse animateOpacity in={dropdownDisclosure.isOpen}>
          <Box p={3} mt={2} rounded={'md'}>
            {currentUser?.workspaces?.map((workspace) => {
              return (
                <Box key={workspace.uuid}>
                  <HStack onClick={() => { changeWorkspace(workspace); navigate('/campaigns'); }} bg={currentWorkspace?.id == workspace?.id ? 'gray.50' : ''} color={currentWorkspace?.id == workspace?.id ? 'gray.600' : 'gray.400'} cursor={'pointer'} p={3} borderBottom={'solid 1px'} borderColor={'gray.200'} justifyContent={'space-between'}>
                    <HStack spacing={3}>
                      <Avatar {...(currentWorkspace?.id != workspace?.id ? { bg: 'gray.200' } : {})} size={'xs'} name={workspace.name} />
                      <Text fontSize={'sm'}>{workspace.name}</Text>4
                    </HStack>
                    {currentUser?.organizationRole == 'owner' && <IconButton size={'sm'} color={'gray.500'} variant={'ghost'} _hover={{ bg: 'gray.200' }} icon={<FiEdit2 />} onClick={(e) => handleEditWorkspace(e, workspace)} aria-label={""} />}
                  </HStack>
                </Box>
              )
            })}

            {currentUser?.organizationRole == 'owner' && <HStack mt={4} py={2} justifyContent={'center'} cursor={'pointer'} _hover={{ bg: 'gray.50' }} onClick={(e) => { e.preventDefault(); navigate('/workspaces?dialog=create'); }} border={'solid 1px'} borderColor={'gray.300'} rounded={'md'}>
              <FiPlus />
              <Text fontSize={'sm'} fontWeight={500}>New Workspace</Text>
            </HStack>}
          </Box>
        </Collapse>

      </Box>


      <SidebarLink to="/campaigns" icon={FiFolder} text="Campaigns" />
      <SidebarLink to="/media-plans" icon={FiFileText} text="Media Plans" />
      <SidebarLink to={'/assets-library'} icon={FiInbox} text="Assets Library" />

      <Divider my={3} w={'85%'} mx={'auto'} />
      <SidebarLink to={'/account-settings'} icon={FiSettings} text="Settings" />
    </>
  )
}

export default WorkspaceSidebar;