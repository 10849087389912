import React, { useState } from 'react';
import {
 Box,
 FormControl,
 FormLabel,
 Input,
 Button,
 HStack,
 IconButton,
 Spacer,
 Text,
 Card,
 CardBody,
 CardHeader,
 VStack,
 Icon,
 Select,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiTrash2, FiPlus, FiMinus } from 'react-icons/fi';

const options = [
  { value: "Amenities", label: "Amenities"},
  { value: "Brands", label: "Brands" },
  { value: "Courses", label: "Courses" },
  { value: "Degree Programs", label: "Degree Programs" },
  { value: "Destinations", label: "Destinations" },
  { value: "Featured Hotels", label: "Featured Hotels" },
  { value: "Insurance Coverage", label: "Insurance Coverage" },
  { value: "Models", label: "Models" },
  { value: "Neighbourhoods", label: "Neighbourhoods" },
  { value: "Service Catalog", label: "Service Catalog" },
  { value: "Shows", label: "Shows" },
  { value: "Styles", label: "Styles" },
  { value: "Types", label: "Types" },
 ];

function SnippetCard({
 snippet,
 index,
 handleInputChange,
 handleRemoveSnippet,
 error
}) {
 const [cardVisible, setCardVisible] = useState(true);

 const toggleCardVisibility = () => {
  setCardVisible(!cardVisible);
 };

 const handleValueChange = (e, valueIndex) => {
  const { name, value } = e.target;
  const updatedValues = [...snippet.values];
  updatedValues[valueIndex] = value;
  const updatedSnippet = { ...snippet, values: updatedValues };
  handleInputChange(updatedSnippet, index, valueIndex);
 };
 
 const handleTextChange = (e) => {
  const { name, value } = e.target;
  const updatedSnippet = { ...snippet, [name]: value };
  handleInputChange(updatedSnippet, index);
 };

 const handleAddValue = () => {
  if (snippet.values.length < 10) {
   const updatedValues = [...snippet.values, '' ];
   const updatedSnippet = { ...snippet, values: updatedValues };
   handleInputChange(updatedSnippet, index);
  }
 };
 
 const handleRemoveValue = (valueIndex) => {
  if (snippet.values.length > 3) {
    const updatedValues = snippet.values.filter((_, i) => i !== valueIndex);
    const updatedSnippet = { ...snippet, values: updatedValues };
    handleInputChange(updatedSnippet, index);
  }
 };

 return (
  <Card w={'full'}>
    <CardHeader pb="4">
      <HStack>
        <Text>Snippet {index + 1}</Text>
        <Spacer />
        <IconButton variant={'ghost'} color={'#96999F'} onClick={() => handleRemoveSnippet(index)} aria-label={"Remove snippet"} icon={<Icon as={FiTrash2} />} />
        <IconButton
          icon={cardVisible ? <FiChevronUp /> : <FiChevronDown />}
          variant="ghost"
          onClick={toggleCardVisibility}
          aria-label={cardVisible ? "Collapse Descriptions" : "Expand Descriptions"}
        />
      </HStack>
    </CardHeader>
    <CardBody>
      {cardVisible && (<Box key={index} p={2} pt={0} borderRadius='md'>
        <VStack spacing={5} align={'start'}>
          <Box >Header</Box>
          <FormControl>
            <Select placeholder="Choose an amenity" name="text" value={snippet.text} onChange={handleTextChange} required>
              {options.map((option) => (
                <option key={option.value} value={option.value} >
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <Text textAlign={'start'}>Values</Text>
          {snippet.values.map((value, valueIndex) => (
            <FormControl key={valueIndex}>
              <HStack>
                <Input
                  type='text'
                  name="text"
                  placeholder={"Value "+(valueIndex + 1)}
                  value={value}
                  onChange={(e) => handleValueChange(e, valueIndex)}
                />
                <Spacer />
                <IconButton isDisabled={snippet.values.length < 4} variant={'ghost'} color={'#96999F'} onClick={() => handleRemoveValue(valueIndex)} aria-label={"Remove value "+(valueIndex + 1)} icon={<Icon as={FiTrash2} />} />
              </HStack>
            </FormControl>
          ))}
        </VStack>
        <Button
            colorScheme='gray'
            variant={'ghost'}
            mt={3}
            onClick={handleAddValue}
            isDisabled={snippet.values.length >= 10}
          >
            Add value
          </Button>
          
      </Box>)}
      {error && <span style={{color: "red"}}>{error}</span>}
    </CardBody>
  </Card>
 );
}

export default SnippetCard;
