import { createSlice } from '@reduxjs/toolkit';

const adGroupSlice = createSlice({
 name: 'adGroup',
 initialState: '',
 reducers: {
   setVisibleAdGroup: (state, action) => {
     return action.payload;
   },
 },
});

export const { setVisibleAdGroup } = adGroupSlice.actions;

export default adGroupSlice.reducer;