import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useCreateExternalAssetMutation } from "../../../../api/services/assets";
import { useSelector } from "react-redux";

import { FiDownloadCloud, FiYoutube } from "react-icons/fi";
import { useForm } from "react-hook-form";

const YouTube = ({ type }) => {
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const toast = useToast();

  const { onOpen, isOpen, onClose } = useDisclosure();

  const [upload, { isSuccess, isError, isLoading }] = useCreateExternalAssetMutation();

  const [videoId, setVideoId] = useState("");

  const { register, watch, reset, getValues } = useForm();
  const watchRemoteUrl = watch("remote_url");

  useEffect(() => {
    setVideoId(getYouTubeVideoId(watchRemoteUrl));
  }, [watchRemoteUrl]);

  const onSubmit = () => {
    let data = getValues();

    data = {
      remote_url: `https://www.youtube.com/embed/${videoId}`,
      workspace_id: currentWorkspace?.id.toString(),
      filename: `YouTube ${videoId}`,
      remote_thumbnail_url:
        videoId != null && videoId != ""
          ? `https://img.youtube.com/vi/${videoId}/0.jpg`
          : "",
      remote_provider: "youtube",
    };

    upload(data);

    reset();
    onClose();
  };

  const getYouTubeVideoId = (url) => {
    if (!url) {
      return null; // Return null if url is empty or undefined
    }

    // Match the video ID using a regular expression for both regular YouTube videos and YouTube Shorts
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);

    if (match && match[1]) {
      return match[1]; // Return the video ID
    }

    return null; // Return null if no match found
  }
  useEffect(() => {
    if (isSuccess) {
      toast({
        description: "Asset uploaded!",
        status: "success",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast({
        description: "Error during upload!",
        status: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      toast({
        description: "Uploading asset... Please wait.",
        status: "info",
      });
    }
  }, [isLoading]);

  return (
    <>
      {type == "all" || type == "video" || type == "shorts" ? (
        <Button
          cursor={"pointer"}
          variant={"outline"}
          as="span"
          leftIcon={<Icon as={FiYoutube} />}
          onClick={onOpen}
        >
          Add YouTube Video/Shorts
        </Button>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              <Text borderBottom={"solid 1px"} borderColor={"gray.300"} pb={2}>
                Add YouTube Video
              </Text>
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <form>
            <ModalBody>
              <VStack gap={"6"}>
                <FormControl>
                  <FormLabel>Link</FormLabel>
                  <Text color={'gray.400'} fontSize={'xs'}>Please provide the full YouTube URL to the video you want to import.</Text>
                  <Input
                    mt={3}

                    {...register("remote_url", { required: true })}
                    type="text"
                    placeholder="https://www.youtube.com/watch?v=..."
                  />
                </FormControl>

                {videoId && <Image
                  src={
                    videoId != ""
                      ? `https://img.youtube.com/vi/${videoId}/0.jpg`
                      : ""
                  }
                  alt=""
                />}
              </VStack>
            </ModalBody>

            <ModalFooter gap={3} justifyContent={"flex-start"}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                isDisabled={isLoading}
                colorScheme="primary"
                mr={3}
                type="button"
                leftIcon={<Icon as={FiDownloadCloud} />}
                onClick={onSubmit}
              >
                Import
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default YouTube;

