export const linkedinCallToActions = [
    { value: 'APPLY', label: 'Apply' },
    { value: 'DOWNLOAD', label: 'Download' },
    { value: 'VIEW QUOTE', label: 'View quote' },
    { value: 'LEARN MORE', label: 'Learn more' },
    { value: 'SIGN UP', label: 'Sign up' },
    { value: 'SUBSCRIBE', label: 'Subscribe' },
    { value: 'REGISTER', label: 'Register' },
    { value: 'JOIN', label: 'Join' },
    { value: 'ATTEND', label: 'Attend' },
    { value: 'REQUEST DEMO', label: 'Request demo' }
]