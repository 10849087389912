import React from "react";

import { Button, HStack, Icon, Image, ScaleFade, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { googlePmaxCallToActions } from "../../../../../../../components/Constants/google_pmax_call_to_actions";

const Display1 = ({ variation }) => {
  return (
    <>
      <VStack p={3} spacing={0} bg={'gray.600'} border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <ScaleFade initialScale={0.9} in={true}>
          <Image borderTopRadius={'lg'} w={'full'} src={variation.imageUrl} />

          <VStack pt={4} alignItems={'center'} w={'full'} bg={'white'} px={3}>
            <Image src={variation.logoUrl} w={50} />
            <Text textAlign={'center'} fontSize={'2xl'} fontWeight={600}>{variation.headline?.truncate(30)}</Text>
            <Text textAlign={'center'} color={'gray.600'} fontSize={'xl'}>{variation.description?.truncate(90)}</Text>

            <HStack spacing={10} mt={5} mb={2} justifyContent={'space-between'} w={'full'}>
              <Button border={'solid 1px gray'} color={'black'} px={3} py={2} w={'full'} fontSize={'xs'} bg={'white'}>Close</Button>
              <Button border={'solid 1px gray'} color={'white'} bg={'black'} px={3} py={2} w={'full'} fontSize={'xs'}>
                {googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}
              </Button>

            </HStack>
          </VStack>
        </ScaleFade>
      </VStack>
    </>
  )
};

export default Display1;