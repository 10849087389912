
import React, { } from 'react';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text, HStack, Icon, Tag } from '@chakra-ui/react';
import { FiCornerDownRight, FiFolder } from 'react-icons/fi';
import PlatformIcon from '../../Campaigns/Components/PlatformIcon';
import { campaignPlatforms } from '../../../components/Constants/campaigns'
import { useNavigate, useParams } from 'react-router-dom';

const SidebarFoldersItem = ({ uuid, organization, openIndex, setOpenIndex, folderItem, index, isPublic = false }) => {
  const navigate = useNavigate();
  const { uuid: folderUuid } = useParams();

  return (
    <>
      <AccordionItem border={0}>
        <AccordionButton onClick={(n) => { setOpenIndex(openIndex == index ? -1 : index); }} justifyContent={'space-between'} _hover={{ bg: 'transparent' }} px={3} py={3} borderBottom={'solid 1px'} borderColor={'gray.200'}>
          <HStack>
            <Text fontWeight={'semibold'} fontSize={'sm'} pl={3}>{folderItem.name}</Text>
          </HStack>

        </AccordionButton>
        <AccordionPanel p={0}>
          {folderItem.campaigns.map(campaign => {
            return (
              <>
                <HStack bg={campaign.uuid == uuid ? 'gray.50' : 'white'} _hover={{ bg: 'gray.50' }} cursor={'pointer'} onClick={() => navigate(isPublic ? `/public/${organization}/folders/${folderUuid}/campaigns/${campaign.uuid}` : `/portal/${organization}/campaign/${campaign.uuid}`)} borderBottom={'solid 1px'} borderColor={'gray.200'} px={4} py={4} justifyContent={'space-between'}>
                  <HStack w={'full'} justifyContent={'space-between'} px={3} spacing={3}>
                    <HStack spacing={3}>
                      <PlatformIcon simplified w={'16px'} platform={campaign.platform} />
                      <Text fontWeight={'light'} fontSize={'xs'}>{campaign.name}</Text>
                    </HStack>

                    {campaign.platform.includes('google') && <Tag size={'sm'} colorScheme={'purple'} fontSize={'x-small'}>
                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label?.replace('Google ', '')}
                    </Tag>}

                    {campaign.platform.includes('meta_instant_experience') && <Tag fontSize={'x-small'} size={'sm'}>
                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label}
                    </Tag>}
                  </HStack>
                </HStack >
              </>
            )
          })}

        </AccordionPanel>
      </AccordionItem >
    </>
  );
}

export default SidebarFoldersItem;