import React from 'react';
import { HStack, Text, SimpleGrid } from '@chakra-ui/react';
import StatBox from './StatBox';
import { formatPrice } from '../../../helpers/currencyHelper';
import { FiCalendar, FiDatabase, FiDivide, FiLoader } from 'react-icons/fi';

const Budget = ({ mediaPlan }) => {
  return (
    <SimpleGrid 
      columns={{ 
        base: 1, 
        md: mediaPlan.total > 999 ? 1 : (mediaPlan.total > 99 ? 2 : 4) , 
        xl: mediaPlan.total > 99999 ? 1 : (mediaPlan.total > 9999 ? 2 : 4) 
        }} 
        w={'full'} spacing={10} mb={10} alignContent={"center"}>
      <StatBox label={'Total'} amount={formatPrice(mediaPlan.currencyCode, mediaPlan.total)} icon={FiDatabase}/>
      <StatBox label={`Taxes ${mediaPlan.taxes}%`} amount={formatPrice(mediaPlan.currencyCode, mediaPlan.taxTotal)} icon={FiDivide}  />
      <StatBox label={`Agency Fee ${mediaPlan.agencyFee}%`} amount={formatPrice(mediaPlan.currencyCode, mediaPlan.agencyFeeTotal)} icon={FiLoader}  />
      <StatBox label={'Duration'} amount={`${mediaPlan.duration || 0}`} amountLabel='days' icon={FiCalendar}/>
    </SimpleGrid>
  );
};

export default Budget;