import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";

const DesktopClosed = ({ variations }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
      <Center>
        <HStack spacing={10} mb={4}>
          <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
          <IconButton isDisabled={activeStep == 1} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
        </HStack>
      </Center>

      {activeStep == 0 && <>
        <HStack mt={5} border={'solid 1px'} borderColor={'gray.300'} p={3} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Icon as={FiStar} color={'gray.300'} />
            <Text fontWeight={'bold'} fontSize={'xs'}>{variations[0].businessName}</Text>
          </HStack>

          <HStack>
            <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'x-small'} px={1}>Ad</Text>
            <Text fontWeight={'semibold'} fontSize={'xs'}>{variations[0].headline?.truncate(30)} - </Text>
            <Text fontSize={'xs'} color={'gray.600'}>{variations[0].description?.truncate(90)}</Text>
          </HStack>

          <Icon as={FiMoreVertical} />
        </HStack>
      </>}


      {activeStep == 1 && <HStack mt={5} boxShadow={'base'} p={3} justifyContent={'space-between'} w={'full'}>
        <VStack alignItems={'flex-start'} spacing={0}>
          <Text fontSize={'x-small'} fontWeight={'bold'}>Ad</Text>
          <HStack>
            <Avatar w={4} h={4} src={variations[0].logoUrl} />
            <Text fontWeight={'bold'} fontSize={'xs'}>{variations[0].businessName}</Text>
          </HStack>
        </VStack>

        <HStack>
          <Image h={10} src={variations[0].imageUrl} borderRadius={'lg'} />
          <VStack alignItems={'flex-start'} spacing={0}>
            <Text fontWeight={'semibold'} fontSize={'x-small'}>{variations[0].headline?.truncate(30)}</Text>
            <Text fontSize={'xx-small'}>{variations[0].description?.truncate(90)}</Text>
          </VStack>
        </HStack>

        <VStack>
          <Icon as={FiMoreVertical} />
          <Icon as={FiExternalLink} />
        </VStack>
      </HStack>}
    </>
  )
};

export default DesktopClosed;