import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useGetPublicFolderQuery } from '../../../api/services/public/folders';
import { Accordion, Box, VStack } from '@chakra-ui/react';
import Paginator from '../../../components/Common/Pagination/Paginator';
import SidebarFoldersItem from '../../Client/Components/SidebarFoldersItem';

const SidebarFolders = () => {
  const { uuid, campaign_uuid, organization } = useParams();

  const { data: folders, isSuccess } = useGetPublicFolderQuery(uuid, { pollingInterval: 10000 });

  return (
    <>
      <Box p={0}>
        <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
          <Accordion index={[0]} allowMultiple>
            {isSuccess && <SidebarFoldersItem isPublic organization={organization} folderItem={folders?.folder} index={0} uuid={campaign_uuid} openIndex={0} setOpenIndex={() => { }} />}

          </Accordion>
        </VStack>
      </Box>
    </>
  );
};

export default SidebarFolders;