import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import SnapchatSingleImage from "../../Preview/Snapchat/SingleImageVideo";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CommonEditor from "./CommonEditor";

const SingleImageVideoEditor = ({ isLoading, onFormSubmit, ad }) => {
  const { register, watch, getValues, setValue, handleSubmit } = useForm({
    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
  });

  const asset = ad?.metadata?.asset;
  const assetsState = React.useState(asset == null ? [] : [asset]);

  const snapchatLogoAsset = ad?.metadata?.snapchatLogo;
  const snapchatLogoState = React.useState(snapchatLogoAsset == null ? [] : [snapchatLogoAsset])

  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <CommonEditor getValues={getValues} setValue={setValue} ad={ad} isLoading={isLoading} handleSubmit={handleSubmit} onFormSubmit={onFormSubmit} register={register} watch={watch} assetsState={assetsState} snapchatLogoState={snapchatLogoState} />
        <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'125px'}>
          <VStack spacing={10}>
            <SnapchatSingleImage ad={{
              headline: getValues('headline'),
              brandName: getValues('brand_name'),
              websiteUrl: getValues('website_url'),
              callToAction: getValues('call_to_action'),
              asset: assetsState[0][0],
              snapchatAccountName: getValues('snapchat_account_name'),
              snapchatLogo: snapchatLogoState[0][0],
            }} />
          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default SingleImageVideoEditor;