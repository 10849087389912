import { baseApi } from "./base/base";

export const subscriptionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation({
      query: (data) => ({ url: `/subscriptions`, method: 'POST', body: data })
    }),
    createStripePortalSession: builder.mutation({
      query: (data) => ({ url: `/subscriptions/stripe_portal_session`, method: 'POST', body: data })
    }),
    createEnterpriseSubsciption: builder.mutation({
      query: (data) => ({ url: `/subscriptions/enterprise`, method: 'POST', body: data })
    }),
    completeSubscription: builder.mutation({
      query: (data) => ({ url: `/subscriptions/success`, method: 'POST', body: data })
    }),
  }),
  overrideExisting: false
});

export const {
  useCreateSubscriptionMutation,
  useCreateEnterpriseSubsciptionMutation,
  useCreateStripePortalSessionMutation,
  useCompleteSubscriptionMutation
} = subscriptionsApi;
