import { Card, Text, Image, Divider, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, AvatarBadge, Button, Spacer, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiRefreshCw, FiMoreVertical, FiChevronRight } from "react-icons/fi";
import BlankTiles from '../../../../assets/images/meta_instagram_empty_story.png';
import Wifi from '../../../../assets/icons/tiktok/wifi.svg';
import Mobile from '../../../../assets/icons/tiktok/mobile.svg';
import Battery from '../../../../assets/icons/tiktok/battery.svg';
import Camera from '../../../../assets/icons/snapchat/camera.svg';
import Send from '../../../../assets/icons/snapchat/send.svg';
import { snapchatCallToActions } from "../../../../components/Constants/snapchat_call_to_actions";

const SingleImageVideo = ({ ad }) => {

    return (
        <Card bg={'black'} w={'sm'} overflow={'hidden'} h={'3xl'} borderRadius={0}>

            {ad.asset == null && <Box backgroundColor="#434343" zIndex={0} w={'full'} position={'absolute'} h={'full'} />}

            {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
                <Image src={ad.asset?.fileUrl} zIndex={0} w={'full'} position={'absolute'} h={'full'} objectFit={'cover'} />
            </Box>}

            {ad.asset?.assetType == 'video' && !/youtube/.test(ad.asset?.fileUrl) &&
                <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                    <video key={ad.asset?.uuid} muted playsInline width={'100%'} height="100%" loop autoPlay style={{ objectFit: 'cover' }}>
                        <source src={ad.asset?.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            }

            {ad.asset?.assetType == 'video' && /youtube/.test(ad.asset?.fileUrl) &&
                <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                    <iframe width="100%" height="100%" src={`${ad.asset?.fileUrl.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ objectFit: 'cover', pointerEvents: 'auto' }}></iframe>
                </Box>
            }

            <CardHeader textShadow={'0 0 7px black'} zIndex={100}>
                <HStack px={4} color={'white'} justifyContent={'space-between'}>
                    <Text fontWeight={600} fontSize={'sm'}>9:41</Text>
                    <HStack>
                        <Image w={'18px'} src={Mobile} />
                        <Image w={'18px'} src={Wifi} />
                        <Image w={'18px'} src={Battery} />
                    </HStack>
                </HStack>

                <HStack mt={6} spacing={4} alignItems={'center'}>
                    <Avatar size={'md'} bg={'gray.300'} src={ad.snapchatLogo?.fileUrl} icon={<></>} name='' />
                    <VStack alignItems={'start'} fontWeight={'bold'} color={'white'} spacing={1} fontSize={'sm'}>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.brandName || ad.snapchatAccountName || 'username')}</Text>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.headline || 'Headline')}</Text>
                    </VStack>
                    <Spacer />
                    <Icon as={FiMoreVertical} color={'white'} boxSize={7} />
                </HStack>
            </CardHeader>
            <CardBody flexDirection={'column'} justifyContent={'flex-end'} display={'flex'} zIndex={100} fontSize={'sm'} p={0} pt={4}>

                <HStack alignItems={'flex-end'} p={4}>
                    <Box textShadow={'0 0 7px rgba(0,0,0,0.2)'} color={'white'} w={'full'}>
                        <Text wordBreak={'break-all'} noOfLines={4}>
                            {ad?.description}
                        </Text>
                        <HStack mt={6} spacing={4} alignItems={'center'} bg={'#65697280'} p={3} width={'80%'} borderRadius={'10px'}>
                            <Avatar size={'md'} bg={'gray.300'} src={ad.snapchatLogo?.fileUrl} icon={<></>} name='' />
                            <VStack alignItems={'start'} fontWeight={'bold'} color={'white'} spacing={1} fontSize={'sm'}>
                                <Text maxW={'140px'}>{(ad.brandName || ad.snapchatAccountName || 'Profile Name')}</Text>
                                <Text fontWeight={400} maxW={'140px'}>{(ad.headline?.truncate(34) || 'Lorem Ipsum Color')}</Text>
                                <Text fontWeight={400} maxW={'140px'}> {(ad.websiteUrl && ad.websiteUrl.length > 50) ? new URL(ad.websiteUrl).hostname : (ad.websiteUrl || '')}</Text>
                            </VStack>
                            <Icon as={FiChevronRight} color={'white'} boxSize={6} ml={7} />
                        </HStack>
                    </Box>
                </HStack>

                <Box bg={'black'}>
                    <HStack justifyContent={'space-between'} p={6}>
                        <VStack spacing={1}>
                            <Image w={'15'} src={Camera} />
                        </VStack>
                        <Flex justifyContent={'space-between'} width={'100%'} maxW={'200px'}>
                            {ad?.callToAction &&
                                <Button size={'lg'} py={3} fontWeight={'bold'} bg={'#FFFC00'} color={'#000000'} borderRadius={'full'} minW={'200px'}>
                                    {snapchatCallToActions.find((c) => c.value == ad?.callToAction)?.label || 'CTA'}
                                </Button>
                            }
                        </Flex>
                        <VStack spacing={1}>
                            <Image w={'15'} src={Send} />
                        </VStack>
                    </HStack>
                </Box>

            </CardBody>
        </Card >
    )
};

export default SingleImageVideo;