import { baseApi } from "../base/base";

export const sharedMediaPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedMediaPlans: builder.query({
      query: (data) => ({ url: `/shared/media_plans`, params: data }),
      providesTags: ['MediaPlan']
    }),
    getSharedMediaPlan: builder.query({
      query: (data) => ({ url: `/shared/media_plans/${data.uuid}`, params: data }),
      providesTags: ['MediaPlan']
    }),
    approveSharedMediaPlan: builder.mutation({
      query: (data) => ({ url: `/shared/media_plans/${data.uuid}/approve`, method: 'POST', body: data }),
      invalidatesTags: ['MediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetSharedMediaPlanQuery, useGetSharedMediaPlansQuery, useApproveSharedMediaPlanMutation
} = sharedMediaPlansApi;
