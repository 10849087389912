import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure, useToast } from "@chakra-ui/react";

import { useFolder } from "./Contexts/FolderContext";
import { useUpdateFolderMutation } from '../../api/services/folders';

const Rename = ({ disclosure = useDisclosure() }) => {
    const { isOpen, onClose } = disclosure;
    const toast = useToast();

    const [updateFolder, { isLoading, isSuccess, isError }] = useUpdateFolderMutation();

    const { folder, setFolder } = useFolder();

    const { register, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            name: folder?.name
        }
    });

    const onSubmit = (data) => {
        updateFolder({
            id: folder.id,
            name: data.name
        });
    }

    useEffect(() => {
        if (!disclosure.isOpen) {
            reset();
            setFolder(null);
        } else {
            setValue('name', folder?.name);
        }
    }, [disclosure.isOpen])

    useEffect(() => {
        if (isError) {
            toast({
                description: "Error during rename!",
                status: "error",
            });
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: 'Folder renamed.',
                description: 'The folder was successfully renamed.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            disclosure.onClose();
        }
    }, [isSuccess]);

    return (
        <>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader p={0}>
                        <Box p={5}>
                            <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Rename folder {folder != null ? ` ${folder?.name}` : ''}</Text>
                        </Box>
                        <ModalCloseButton mt={2} />
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl mt={0}>
                                <FormLabel>Name</FormLabel>

                                <Input {...register('name', { required: true })} defaultValue={folder?.name} type='text' placeholder='Name' />
                            </FormControl>

                            <Spacer h={'20px'} />

                            <HStack mb={5}>
                                <Button onClick={onClose} variant='outline'>Cancel</Button>
                                <Button type='submit' colorScheme='primary' mr={3} >
                                    Rename
                                </Button>
                            </HStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Rename;