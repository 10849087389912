
import React, { useEffect } from 'react';
import { Box, Button, HStack, Icon, Input, Text, VStack, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useUpdateMediaPlanMutation } from '../../../api/services/mediaPlans';
import { setColumns } from '../../../api/slices/mediaPlanColumnSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FiMenu, FiPlus, FiPlusCircle, FiStopCircle, FiXCircle } from 'react-icons/fi';


const CustomFieldsForm = ({ mediaPlanId, setShownItems }) => {
  const [visible, setVisible] = React.useState(false);
  const toast = useToast();
  const { register, handleSubmit, setValue, reset } = useForm();
  const [update, { isSuccess, isLoading }] = useUpdateMediaPlanMutation();

  const onSubmit = (data) => {
    update({
      id: mediaPlanId,
      customField: {
        label: data.label,
        action: 'add'
      }
    }).then((response) => {
      if (response) {
        const customFields = response?.data?.mediaPlan?.customFields;
        const customField = customFields[customFields?.length - 1];

        setShownItems((prev) => {
          return [
            ...prev,
            customField
          ]
        });
      }
    });

  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Custom field added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
    }
  }, [isSuccess])

  return (<>
    <HStack w={'full'} justifyContent={'space-between'}>
      <VStack spacing={2} alignItems={'flex-start'}>
        <Text fontSize={'sm'} fontWeight={'bold'}>Custom fields</Text>
        <Text fontSize={'x-small'} color={'gray.400'} >Add your own custom fields to the table.</Text>
      </VStack>
      <Box>
        {!visible && <Button onClick={() => setVisible(true)} size={'sm'} leftIcon={<Icon as={FiPlus} />} variant={'outline'}>Add column</Button>}
      </Box>
    </HStack>

    {visible && <form onSubmit={handleSubmit(onSubmit)}>
      <HStack mt={2}>
        <Input borderRadius={'md'} fontSize={'sm'} {...register('label')} placeholder='Custom field name' />
        <Box>
          <Button leftIcon={<Icon as={FiPlusCircle} />} size={'sm'} isLoading={isLoading} onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
        <Box>
          <Button variant={'outline'} leftIcon={<Icon as={FiXCircle} />} size={'sm'} isLoading={isLoading} onClick={() => setVisible(false)}>Cancel</Button>
        </Box>
      </HStack>
    </form>}
  </>)
};

export default CustomFieldsForm;