import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Skeleton, Image, SkeletonCircle, SkeletonText, Button, Card, CardBody } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder } from 'react-icons/fa6';
import { FiExternalLink, FiMoreVertical } from 'react-icons/fi';
import { googleDemandgenCallToActions } from '../../../../../../components/Constants/google_demandgen_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';
import { Swiper, SwiperSlide } from 'swiper/react';
import { transformKeysTo } from '../../../../../../helpers/apiHelper';

const HomePageAdCarousel = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Home page ad</Text>

      <Phone p={3}>
        <HStack justifyContent={'space-between'}>
          <Image w={20} src={YoutubeLogo} />
          <Avatar size={'sm'} />
        </HStack>

        <HStack mt={4} w={'full'}>
          <Box>
            <SkeletonCircle startColor="gray.400" endColor="gray.400" size='10' />
          </Box>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
        </HStack>

        <Swiper
          centeredSlidesBounds
          centeredSlides
          grabCursor
          slidesPerView={1.25}
          freeMode
          spaceBetween={35}
          style={{ padding: '0rem' }}
        >
          {variation.carouselItems?.map((item) => {
            const transformedItem = transformKeysTo('snake', structuredClone(item));

            return (
              <SwiperSlide style={{ padding: '0rem' }}>
                <Card w={'full'} mt={5} bg={'white'} p={0}>
                  <CardBody p={0} >
                    <VStack w={'full'}>
                      <Box h={'150px'} overflow={'hidden'}>
                        {transformedItem.asset == null && <Image borderTopRadius={'md'} w={'100%'} src={''} />}
                        {transformedItem.asset?.asset_type == 'image' && <Image borderTopRadius={'md'} w={'100%'} src={transformedItem?.asset?.file_url} />}
                        {transformedItem.asset?.asset_type == 'video' &&
                          <video key={transformedItem?.asset?.uuid} autoPlay controls style={{ borderTopLeftRadius: '0.3rem', borderTopRightRadius: '0.3rem' }} muted width={'100%'} height="auto" loop >
                            <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        }
                      </Box>

                      <HStack w={'full'} px={3} py={2} justifyContent={'space-between'} >
                        <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
                          <Text noOfLines={1} color={'gray'} fontWeight={500} fontSize={'sm'}>{item?.headline || 'Headline'}</Text>
                        </VStack>
                      </HStack>
                    </VStack>

                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>


        <HStack justifyContent={'space-between'} mt={2} p={1}>
          <Text fontWeight={'bold'} fontSize={'sm'}>{variation.headline?.truncate(30)}</Text>
          <Icon color={'black.500'} fontSize={'sm'} as={FiMoreVertical} />
        </HStack>

        <Text p={1} fontSize={'xs'} color={'gray.600'}>
          {variation.description?.truncate(90)}
        </Text>
        <HStack p={1}>
          <Text fontSize={'xs'} fontWeight={'bold'}>Sponsored</Text>
          <Text fontSize={'xs'}>• {variation.businessName}</Text>
        </HStack>

        <Skeleton mt={4} startColor="gray.400" endColor="gray.400" height='50px' />

        <HStack mt={10} px={5} w={'full'} justifyContent={'space-between'}>
          <Icon color={'red.500'} as={FaHouse} />
          <Icon color={'gray.500'} as={FaFire} />
          <Icon color={'gray.500'} as={FaVideo} />
          <Icon color={'gray.500'} as={FaBell} />
          <Icon color={'gray.500'} as={FaFolder} />
        </HStack>

      </Phone>
    </>
  )
};

export default HomePageAdCarousel;