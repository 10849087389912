import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

// Base style
const baseStyle = definePartsStyle({
  th: {
    color: 'gray.600',
    fontWeight: 400,
    fontSize: 'xs',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  td: {
    fontWeight: '400',
    fontSize: 'sm',
  },
});

// Bordered variant
const bordered = definePartsStyle({
  table: {
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid',
    borderColor: 'gray.200',
  },
  td: {
    border: '1px solid',
    borderColor: 'gray.200',
  },
});

// Define the table theme with the base style and variants
export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    bordered,
  },
});
