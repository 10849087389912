import { baseApi } from "../base/base";

export const publicCampaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicCampaign: builder.query({
      query: (data) => ({ url: `/public/campaigns/${data.uuid}`, params: data }),
      providesTags: ['Campaign']
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPublicCampaignQuery
} = publicCampaignsApi;
