export const googlePmaxCallToActions = [
  { value: "LEARN_MORE", label: "Learn More" },
  { value: "GET_QUOTE", label: "Get Quote" },
  { value: "APPLY_NOW", label: "Apply Now" },
  { value: "SIGN_UP", label: "Sign Up" },
  { value: "CONTACT_US", label: "Contact Us" },
  { value: "SUBSCRIBE", label: "Subscribe" },
  { value: "DOWNLOAD", label: "Download" },
  { value: "BOOK_NOW", label: "Book Now" },
  { value: "SHOP_NOW", label: "Shop Now" },
];
