
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Heading, Image, Text, VStack, Button, Link as ChakraLink } from '@chakra-ui/react';
import Oops from '../../assets/images/errors/oops.svg'
const NotFound = () => {

  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/'); // Navigate to homepage
  };

  return (
    <Box textAlign="center" mt="20vh">
      <VStack alignItems="center" spacing={10} justifyContent="center" minH="60vh">
        <Image src={Oops} alt="404 error" mx="auto" mb="8" />
        <div>
          <Text textAlign={'center'}>
            <Box>The link you provided is not valid. </Box>
            <Box>Please contact the organization memberistrator for more details.</Box>
          </Text>
          <Button onClick={goToHome} mt={5} bg={'#7845DB'}>Take me home</Button>
        </div>
      </VStack>
    </Box>
  );
};

export default NotFound;
