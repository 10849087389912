import { baseApi } from "./base/base";

export const adGroupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdGroups: builder.query({
      query: (data) => ({ url: '/ad_groups', params: data }),
      providesTags: ['AdGroup']
    }),

    getAdGroup: builder.query({
      query: (uuid) => ({ url: `/ad_groups/${uuid}` }),
      providesTags: ['AdGroup']
    }),

    createAdGroup: builder.mutation({
      query: (data) => ({ url: `/ad_groups`, method: 'POST', body: data }),
      invalidatesTags: ['AdGroup', 'Campaign']
    }),

    deleteAdGroup: builder.mutation({
      query: (uuid) => ({ url: `/ad_groups/${uuid}`, method: 'DELETE' }),
      invalidatesTags: ['AdGroup', 'Campaign', 'Folder']
    }),

    updateAdGroup: builder.mutation({
      query: (data) => ({ url: `/ad_groups/${data.uuid}`, method: 'PATCH', body: data }),
      invalidatesTags: ['AdGroup', 'Campaign']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetAdGroupsQuery, useGetAdGroupQuery, useCreateAdGroupMutation, useDeleteAdGroupMutation, useUpdateAdGroupMutation
} = adGroupsApi;
