import { baseApi } from "./base/base";

export const mediaPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaPlans: builder.query({
      query: (data) => ({ url: '/media_plans', params: data }),
      providesTags: ['MediaPlan']
    }),
    getMediaPlan: builder.query({
      query: (id) => ({ url: `/media_plans/${id}` }),
      providesTags: ['MediaPlan']
    }),
    createMediaPlan: builder.mutation({
      query: (data) => ({ url: `/media_plans`, method: 'POST', body: data }),
      invalidatesTags: ['MediaPlan']
    }),
    updateMediaPlan: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['MediaPlan']
    }),
    deleteMediaPlan: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.id}`, method: 'DELETE', params: data }),
      invalidatesTags: ['MediaPlan']
    }),
    duplicateMediaPlan: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.id}/duplicate`, method: 'POST', body: data }),
      invalidatesTags: ['MediaPlan']
    }),
    approveMediaPlan: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.id}/approve`, method: 'POST', body: data }),
      invalidatesTags: ['MediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetMediaPlansQuery,
  useGetMediaPlanQuery,
  useUpdateMediaPlanMutation,
  useCreateMediaPlanMutation,
  useDeleteMediaPlanMutation,
  useDuplicateMediaPlanMutation,
  useApproveMediaPlanMutation
} = mediaPlansApi;
