import { Box, Stack, Spinner } from "@chakra-ui/react";
import React from "react";

const CampaignMode = ({ campaign }) => {
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        {campaign.mode === 'importing' ? (
          <Spinner size={'sm'} emptyColor={'gray.200'} color="orange" />
        ) : (
          <Box
            w="12px"
            h="12px"
            fontSize="10px"
            bg={campaign.mode === 'imported' ? '#12B669' : '#F79009'}
            borderRadius="12px"
          />
        )}
        <Box fontSize="12px">{campaign.mode.charAt(0).toUpperCase() + campaign.mode.slice(1)}</Box>
      </Stack>
    </>
  )
};


export default CampaignMode;
