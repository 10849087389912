import { baseApi } from "./base/base";

export const clientsMediaPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientsMediaPlan: builder.query({
      query: (data) => ({ url: '/clients_media_plans', params: data }),
      providesTags: ['ClientMediaPlan']
    }),

    createClientsMediaPlan: builder.mutation({
      query: (data) => ({ url: `/clients_media_plans`, method: 'POST', body: data }),
      invalidatesTags: ['ClientMediaPlan', 'MediaPlan']
    }),

    deleteClientsMediaPlan: builder.mutation({
      query: (data) => ({ url: `/clients_media_plans/${data.id}`, method: 'DELETE', params: data }),
      invalidatesTags: ['ClientMediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetClientsMediaPlanQuery, useCreateClientsMediaPlanMutation, useDeleteClientsMediaPlanMutation
} = clientsMediaPlansApi;
