import React from 'react';
import {
  Box,
  Stack,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Center,
  Divider,
} from '@chakra-ui/react'
import { Tabs, TabList, Tab } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { useCreateStripePortalSessionMutation } from '../../api/services/subscriptions';

const PriceWrapper = ({ children, active }) => {
  return (
    <Box
      mb={4}
      bg={'white'}
      shadow="base"
      w={'full'}
      borderWidth={active ? '2px' : '1px'}
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={active ? 'purple.500' : 'gray.200'}
      borderRadius={'xl'}>
      {children}
    </Box>
  )
}

const Active = () => {
  return (
    <Box
      position="absolute"
      top="-16px"
      left="50%"
      style={{ transform: 'translate(-50%)' }}>
      <Text
        textTransform="uppercase"
        bg={'brand.500'}
        px={3}
        py={1}
        color={useColorModeValue('white', 'gray.300')}
        fontSize="sm"
        fontWeight="600"
        borderRadius="full">
        Active
      </Text>
    </Box>
  )
}

const Pricing = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [billingPeriod, setBillingPeriod] = React.useState('monthly');

  const [createSession, { isLoading, isSuccess }] = useCreateStripePortalSessionMutation();

  const handleSubscriptionChange = () => {
    createSession({ flow_data_type: 'subscription_update' }).then((response) => {
      window.open(response?.data?.url, '_blank');
    })
  }
  return (

    <Box py={0}>

      <Center>
        <Tabs w={'max-content'} size={'sm'} variant='soft-rounded' colorScheme='purple'>
          <TabList border={'solid 1px'} borderRadius={'full'} borderColor={'gray.200'} p={2}>
            <Tab _selected={{ bg: 'brand.500', color: 'white' }} onClick={() => setBillingPeriod('monthly')}>Monthly</Tab>
            <Tab _selected={{ bg: 'brand.500', color: 'white' }} onClick={() => setBillingPeriod('yearly')}>Annually</Tab>
          </TabList>
        </Tabs>
      </Center>

      <Stack
        mt={3}
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={3}>
        <PriceWrapper active={currentUser.organizationPlan == 'starter'}>
          <Box w={'full'} position="relative">
            {currentUser.organizationPlan == 'starter' && <Active />}
            <Box display={'flex'} alignItems={'flex-start'} flexDir={'column'} py={4} px={8}>
              <Text fontWeight="400" fontSize="xl" color={'gray.600'}>
                Starter
              </Text>
              <HStack mt={1} justifyContent="flex-start" alignItems={'baseline'}>
                <Text fontSize="3xl" color={'gray.600'} fontWeight={'semibold'}>
                  $
                  {billingPeriod == 'monthly' && 24.99}
                  {billingPeriod == 'yearly' && 19.99}
                </Text>
                <Text fontSize="md" color="gray.500">
                  /month
                </Text>
              </HStack>
              {billingPeriod == 'monthly' && <Text color={'gray.500'} fontSize={'xs'}>(or $19.99/month if billed annually)</Text>}
              {billingPeriod == 'yearly' && <Text color={'gray.500'} fontSize={'xs'}>(or $24.99/month if billed monthly)</Text>}

              <Divider mt={4} />
            </Box>
            <VStack
              py={4}
              borderBottomRadius={'xl'}>
              <List w={'full'} fontSize={'sm'} spacing={3} textAlign="start" px={8}>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>All features included</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>2 User seats</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>2 Workspaces</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>500 MB memory</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                {currentUser?.organizationPlan == 'growth' && <Button isLoading={isLoading} size={'sm'} w="full" py={2} onClick={() => handleSubscriptionChange()} colorScheme="red" variant="outline">
                  Downgrade
                </Button>}
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>

        <PriceWrapper active={currentUser.organizationPlan == 'growth'}>
          <Box position="relative">
            {currentUser.organizationPlan == 'growth' && <Active />}
            <Box display={'flex'} alignItems={'flex-start'} flexDir={'column'} py={4} px={8}>
              <Text fontWeight="400" fontSize="xl" color={'gray.600'}>
                Growth
              </Text>
              <HStack mt={1} justifyContent="flex-start" alignItems={'baseline'}>
                <Text fontSize="3xl" color={'gray.600'} fontWeight={'semibold'}>
                  $
                  {billingPeriod == 'monthly' && 59.99}
                  {billingPeriod == 'yearly' && 49.99}
                </Text>
                <Text fontSize="md" color="gray.500">
                  /month
                </Text>
              </HStack>
              {billingPeriod == 'monthly' && <Text color={'gray.500'} fontSize={'xs'}>(or $49.99/month if billed annually)</Text>}
              {billingPeriod == 'yearly' && <Text color={'gray.500'} fontSize={'xs'}>(or $59.99/month if billed monthly)</Text>}


              {currentUser?.organizationPlan == 'starter' && <>
                <Button fontSize={'xs'} mt={3} py={2} borderRadius={'full'} bgGradient={'linear(115.04deg, #7845DB -69.4%, #E08AFF 3.11%, #9747FF 76.91%)'}>14 day free trial included</Button>
              </>}
              <Divider mt={4} />
            </Box>
            <VStack
              py={4}
              borderBottomRadius={'xl'}>
              <List w={'full'} fontSize={'sm'} spacing={3} textAlign="start" px={8}>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>All features included</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>5 User seats</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>10 Workspaces</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>1 GB memory</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                {currentUser?.organizationPlan == 'starter' && <Button h={'auto'} py={3} isLoading={isLoading} onClick={() => handleSubscriptionChange()} size={'sm'} w="full" colorScheme="green">
                  Upgrade
                </Button>}
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper active={currentUser.organizationPlan == 'enterprise'}>
          <Box position={'relative'}>
            {currentUser.organizationPlan == 'enterprise' && <Active />}
            <Box display={'flex'} alignItems={'flex-start'} flexDir={'column'} py={4} px={8}>
              <Text fontWeight="400" fontSize="xl" color={'gray.600'}>
                Enterprise
              </Text>
              <HStack mt={1} justifyContent="flex-start" alignItems={'baseline'}>
                <Text fontSize="3xl" color={'gray.600'} fontWeight={'semibold'}>
                  Contact us
                </Text>

              </HStack>
              <Text fontSize="xs" color="gray.500">
                for custom pricing
              </Text>
              <Divider mt={4} />
            </Box>
            <VStack
              py={4}
              borderBottomRadius={'xl'}>
              <List w={'full'} fontSize={'sm'} spacing={3} textAlign="start" px={8}>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>All features included</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>Customer user seats</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>Custom workspaces</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>Custom memory</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
                <ListItem display={'flex'} justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'gray.600'}>Dedicated support</Text>
                  <ListIcon as={FaCheckCircle} color="brand.500" />
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Text w="full" fontSize={'sm'} variant="outline">
                  Send us an email to: <b><a href="mailto:amanda@wemanagedigital.com">amanda@wemanagedigital.com</a></b> for more details.
                </Text>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
      </Stack>
    </Box>
  )
}

export default Pricing;