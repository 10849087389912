import { Card, Text, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, AvatarBadge, Button, Spacer, Flex, IconButton, AspectRatio } from "@chakra-ui/react";
import React from "react";
import { FiRefreshCw, FiMoreVertical, FiChevronRight } from "react-icons/fi";
import Wifi from '../../../../assets/icons/tiktok/wifi.svg';
import Mobile from '../../../../assets/icons/tiktok/mobile.svg';
import Battery from '../../../../assets/icons/tiktok/battery.svg';
import Camera from '../../../../assets/icons/snapchat/camera.svg';
import Send from '../../../../assets/icons/snapchat/send.svg';
import { snapchatCallToActions } from "../../../../components/Constants/snapchat_call_to_actions";
import { transformKeysTo } from "../../../../helpers/apiHelper";

const Collection = ({ ad }) => {

    return (
        <Card bg={'black'} w={'sm'} overflow={'hidden'} h={'3xl'} borderRadius={0}>

            {ad.asset == null && <Box backgroundColor="#434343" zIndex={0} w={'full'} position={'absolute'} h={'full'} />}

            {ad.asset?.assetType == 'image' &&
                <Image src={ad.asset?.fileUrl} w="full" h="full" position="absolute" zIndex={0} objectFit="fill" />
            }

            {ad.asset?.assetType == 'video' && !/youtube/.test(ad.asset?.fileUrl) &&
                <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                    <video key={ad.asset?.uuid} muted playsInline width={'100%'} height="100%" loop autoPlay style={{ objectFit: 'cover' }}>
                        <source src={ad.asset?.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            }

            {ad.asset?.assetType == 'video' && /youtube/.test(ad.asset?.fileUrl) &&
                <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                    <iframe width="100%" height="100%" src={`${ad.asset?.fileUrl.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ objectFit: 'cover', pointerEvents: 'auto' }}></iframe>
                </Box>
            }
            <CardHeader textShadow={'0 0 7px black'} zIndex={100}>
                <HStack px={4} color={'white'} justifyContent={'space-between'}>
                    <Text fontWeight={600} fontSize={'sm'}>9:41</Text>
                    <HStack>
                        <Image w={'18px'} src={Mobile} />
                        <Image w={'18px'} src={Wifi} />
                        <Image w={'18px'} src={Battery} />
                    </HStack>
                </HStack>

                <HStack mt={6} spacing={4} alignItems={'center'}>
                    <Avatar size={'md'} bg={'gray.300'} src={ad.snapchatLogo?.fileUrl} icon={<></>} name='' />
                    <VStack alignItems={'start'} fontWeight={'bold'} color={'white'} spacing={1} fontSize={'sm'}>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.brandName || ad.snapchatAccountName || 'username')}</Text>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.headline || 'Headline')}</Text>
                    </VStack>
                    <Spacer />
                    <Icon as={FiMoreVertical} color={'white'} boxSize={7} />
                </HStack>
            </CardHeader>

            <CardBody fontSize={'sm'} p={0} zIndex={100}>

                <Flex direction={'column'} justifyContent={'flex-end'} height={'100%'}>
                    <HStack
                        spaceBetween={1}
                        style={{ padding: '1rem' }}>
                        {ad?.snapchatAdCollectionItems?.map((item) => {
                            const transformedItem = transformKeysTo('snake', structuredClone(item));
                            return (
                                <Card w="85px" h="85px" objectFit="fill" mt={5} bg={'gray.100'} p={0}>
                                    <CardBody p={0}>
                                        <AspectRatio ratio={1} w="100%" h="100%">
                                            <>
                                                {transformedItem.asset == null && <Image borderTopRadius={'md'} w={'100%'} />}
                                                {transformedItem.asset?.asset_type == 'image' && <Image borderTopRadius={'md'} w={'100%'} h={'100%'} objectFit={'cover'} src={transformedItem?.asset?.file_url} />}
                                                {transformedItem.asset?.asset_type == 'video' &&
                                                    <video key={transformedItem?.asset?.uuid} autoPlay controls style={{ borderTopLeftRadius: '0.3rem', borderTopRightRadius: '0.3rem', height: '100%', }} muted width={'100%'} loop >
                                                        <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                }
                                            </>
                                        </AspectRatio>
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </HStack>
                    <Box bg={'black'}>
                        <HStack justifyContent={'space-between'} p={6}>
                            <VStack spacing={1}>
                                <Image w={'15'} src={Camera} />
                            </VStack>
                            <Flex justifyContent={'space-between'} width={'100%'} maxW={'200px'}>
                                {ad?.callToAction &&
                                    <Button size={'lg'} py={3} fontWeight={'bold'} bg={'#FFFC00'} color={'#000000'} borderRadius={'full'} minW={'200px'}>
                                        {snapchatCallToActions.find((c) => c.value == ad?.callToAction)?.label || 'CTA'}
                                    </Button>
                                }
                            </Flex>
                            <VStack spacing={1}>
                                <Image w={'15'} src={Send} />
                            </VStack>
                        </HStack>
                    </Box>
                </Flex>
            </CardBody>
        </Card >
    )
};

export default Collection;