import { baseApi } from "./base/base";

export const adsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAd: builder.query({
      query: (uuid) => ({ url: `/ads/${uuid}` }),
      providesTags: ['Ad']
    }),
    createAd: builder.mutation({
      query: (data) => ({ url: `/ads`, method: 'POST', body: data }),
      invalidatesTags: ['Ad', 'Folder']
    }),
    updateAd: builder.mutation({
      query: (data) => ({ url: `/ads/${data.get('uuid')}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Ad', 'Folder']
    }),
    deleteAd: builder.mutation({
      query: (uuid) => ({ url: `/ads/${uuid}`, method: 'DELETE' }),
      invalidatesTags: ['Ad', 'Folder']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetAdQuery, useCreateAdMutation, useUpdateAdMutation, useDeleteAdMutation
} = adsApi;
