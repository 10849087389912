import React, { useEffect } from 'react';

import HomePageAd from "./YouTube/HomePageAd1";
import WatchPageAd from "./YouTube/WatchPageAd";
import VideoInStreamAd from "./YouTube/DesktopVideoInStreamAd";
import YoutubeAd from "./YouTube/YoutubeAd";
import { Box, Center, HStack, Icon, IconButton, useSteps, Text } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import DesktopHomeAd from "./YouTube/DesktopHomeAd";
import DesktopSearchAd from "./YouTube/DesktopSearchAd";
import DesktopVideoInStreamAd from "./YouTube/DesktopVideoInStreamAd";
import HomePageAdVideo from "./YouTube/HomePageAdVideo";
import ShortsAd from "./YouTube/ShortsAd";
import HomePageAdCarousel from "./YouTube/HomePageAdCarousel";

const Youtube = ({ variations, displayType, adType }) => {
  
  const stepsCount = {
    mobile: {
      image: 1,
      video: 2,
      carousel: 0
    },
    desktop: {
      image: 2,
      video: 0,
      carousel: 0
    }
  };

  const adTypeCount = stepsCount[displayType][adType];



  
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0,
    count: adTypeCount
  });

  useEffect(() => {
    // Reset to the first step whenever the displayType or adType changes
    setActiveStep(0);
  }, [displayType, adType, setActiveStep]);

  
  return (
    <>
      <Center>
        <HStack spacing={10} mb={4}>
        <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
        <IconButton isDisabled={activeStep == adTypeCount} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
        
       </HStack>
      </Center>

      {displayType == 'mobile' && <>
        {adType == 'image' && <>
          {activeStep == 0 && <HomePageAd variation={variations[0]} />}
          {activeStep == 1 && <WatchPageAd variation={variations[1]} />}
        </>}

        {adType == 'video' && <>
          {activeStep == 0 && <HomePageAdVideo variation={variations[0]} />}
          {activeStep == 1 && <YoutubeAd variation={variations[1]} />}
          {activeStep == 2 && <ShortsAd variation={variations[0]} />}
        </>}

        {adType == 'carousel' && <>
          <HomePageAdCarousel variation={variations[0]} carouselItems={variations.carouselItems} />
        </>}
      </>}

      {displayType == 'desktop' && <>
        {adType == 'image' && <>
          {activeStep == 0 && <DesktopHomeAd variation={variations[0]} />}
          {activeStep == 1 && <DesktopSearchAd variation={variations[1]} />}
          {activeStep == 2 && <DesktopVideoInStreamAd variation={variations[0]} />}
        </>}

        {adType == 'video' && <>
          {activeStep == 0 && <VideoInStreamAd variation={variations[0]} />}
        </>}

        {adType == 'carousel' && <>
          <Text>The carousel image ad format for YouTube serves on mobile devices only.</Text>
        </>}
      </>}
    </>
  )
};

export default Youtube;