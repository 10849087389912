import React, { useEffect, useState } from 'react';
import { Box, Button, Textarea, useToast, VStack, HStack, useDisclosure, Text, Avatar, Divider, Spinner } from "@chakra-ui/react";
import { useCreateReplyMutation, useGetRepliesQuery } from '../../api/services/replies';
import { useCreateSharedReplyMutation, useGetSharedRepliesQuery } from '../../api/services/shared/replies';
import { format } from 'timeago.js';

import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const Replies = ({ comment, campaign, mediaPlan }) => {
    const { uuid } = useParams();
    const currentUser = useSelector((state) => state.user.currentUser);

    const [createReply, { isSuccess, isLoading }] = currentUser != null ? useCreateReplyMutation() : useCreateSharedReplyMutation();
    const { data, isLoading: isLoadingData } = (currentUser != null ? useGetRepliesQuery : useGetSharedRepliesQuery)({ comment_id: comment.id, campaign_uuid: uuid }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

    const [replyText, setReplyText] = useState('');
    const { isOpen, onToggle } = useDisclosure();
    const toast = useToast();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = localStorage.getItem('token') || queryParams.get('token');

    const handleReplySubmit = () => {
        const trimmedReplyText = replyText.trim();
        if (trimmedReplyText) {
            createReply({ text: replyText, comment_id: comment.id, campaign_uuid: campaign != null ? uuid : undefined, media_plan_uuid: mediaPlan != null ? uuid : undefined, token: token });
        } else {
            toast({
                title: "Error",
                description: "Your comment cannot be empty.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            toast({
                title: "Reply added.",
                description: "Your reply has been successfully added.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setReplyText('');
            onToggle();
        }
    }, [isSuccess]);


    return (
        <VStack mt={6} fontSize={'sm'} px={1.5} align="stretch" opacity={comment.resolved ? '0.65' : 'initial'}>
            {isLoadingData && <Spinner />}

            {data?.replies?.map((reply) => (
                <>
                    <HStack key={reply.id} alignItems={'flex-start'}>
                        <Avatar size={'xs'} name={reply.user?.email || reply.client?.email} />
                        <VStack alignItems={'flex-start'} spacing={0}>
                            <Text fontSize={'xs'}>{reply.user?.email || reply.client?.email || 'Anonymous Guest'}</Text>
                            <Text fontSize={'2xs'} color={'gray.500'}>{format(reply.createdAt)}</Text>
                        </VStack>
                    </HStack>
                    <VStack mt={2} alignItems={'flex-start'}>
                        <Text color={'gray.600'} fontSize={'sm'}>{reply.text}</Text>
                    </VStack>

                    <Divider my={2} />
                </>
            ))}

            <Box>
                <Textarea fontSize={'sm'} value={replyText} onChange={(e) => setReplyText(e.target.value)} placeholder="Add a reply..." mb="4" />
                <HStack spacing="24px" float={'right'}>
                    <Button size={'sm'} onClick={handleReplySubmit} isDisabled={replyText.length == 0 || isLoading}>Add reply</Button>
                </HStack>
            </Box>
        </VStack>
    );
};

export default Replies;
