import { Card, Text, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box } from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal, FiMessageCircle, FiChevronRight, FiHeart, FiBookmark } from "react-icons/fi";
import MetaBlankImage from '../../../../assets/images/meta_empty_image.png';
import { IoPaperPlaneOutline } from "react-icons/io5";
import { metaCallToActions } from "../../../../components/Constants/meta_call_to_actions";
import CustomerAcquisitionPreview from "../Facebook/InstantExperience/CustomerAcquisitionPreview";
import StorytellingPreview from "../Facebook/InstantExperience/StorytellingPreview";

const SingleImageVideo = ({ ad, instantExperienceData }) => {
  const [instantExperienceOpened, setInstantExperienceOpened] = React.useState(false);

  const instantExperienceAsset = instantExperienceData?.fields_data?.instant_experience_asset;
  const instantExperienceState = React.useState(instantExperienceAsset == null ? [] : [instantExperienceAsset]);

  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setInstantExperienceOpened(false);
      }
    };

    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const setInstantExperienceOpenedIfPossible = (value) => {
    if (instantExperienceData) {
      setInstantExperienceOpened(value);
    }
  }

  return (
    <Card maxW={'sm'} borderRadius={0}>
      {!instantExperienceOpened &&
        <>
          <CardHeader>
            <HStack justifyContent={'space-between'}>
              <HStack spacing={5}>
                <Avatar bg={'gray.300'} src={ad.instagramLogo?.fileUrl} icon={<></>} name='' />
                <VStack spacing={0} alignItems={'flex-start'}>
                  <Text fontSize={'sm'}>{ad.instagramAccountName || 'whatcampaign'}</Text>
                  <Text fontSize={'sm'} fontWeight={400}>Sponsored</Text>
                </VStack>
              </HStack>
              <Icon as={FiMoreHorizontal} />
            </HStack>
          </CardHeader>
          <CardBody fontSize={'sm'} p={0} pt={4}>
            {ad.asset == null && <Image w={'full'} src={MetaBlankImage} />}

            {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
              <Image w={'full'} src={ad.asset?.fileUrl} />
            </Box>}

            {ad.asset?.assetType == 'video' && !ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
              <video key={ad.asset?.uuid} muted width={'100%'} height="auto" loop autoPlay>
                <source src={ad.asset?.fileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>}
            {ad.asset?.assetType == 'video' && ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
              <iframe width="100%"
                height="315"
                src={ad.asset?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>}

            {ad.callToAction != '' && <HStack cursor={'pointer'} justifyContent={'space-between'} p={4} borderBottom={'solid 1px'} borderBottomColor={'gray.200'}>
              <Text onClick={() => setInstantExperienceOpenedIfPossible(true)} fontWeight={'bold'} color={'blue.400'}>{metaCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}</Text>
              <Icon as={FiChevronRight} color={'blue.400'} />
            </HStack>}

            <HStack justifyContent={'space-between'} p={4} fontSize={'xl'}>
              <HStack spacing={5}>
                <Icon as={FiHeart} />
                <Icon as={FiMessageCircle} />
                <Icon as={IoPaperPlaneOutline} />
              </HStack>
              <Icon as={FiBookmark} />
            </HStack>

            <Text whiteSpace={'pre-wrap'} p={4}>
              {ad.primaryText || 'Post intro content...'}
            </Text>
          </CardBody>
        </>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'customer_acquisition' &&
        <VStack spacing={5}>
          <CustomerAcquisitionPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            description: instantExperienceData?.fields_data?.description,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            buttonLabel_2: instantExperienceData?.fields_data?.button_label_2,
            buttonDestination_2: instantExperienceData?.fields_data?.button_destination_2,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset: instantExperienceData?.fields_data?.instant_experience_asset
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible} />

        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'storytelling' &&
        <VStack spacing={5}>
          <StorytellingPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset_1: instantExperienceData?.fields_data?.instant_experience_asset_1,
            destination_url_1: instantExperienceData?.fields_data?.destination_url_1,
            instantExperienceAsset_2: instantExperienceData?.fields_data?.instant_experience_asset_2,
            destination_url_2: instantExperienceData?.fields_data?.destination_url_2,
            instantExperienceAsset_3: instantExperienceData?.fields_data?.instant_experience_asset_3,
            destination_url_3: instantExperienceData?.fields_data?.destination_url_3
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible} />
        </VStack>
      }
    </Card>
  )
};

export default SingleImageVideo;