import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const brandPrimary = defineStyle({
  background: 'brand.500',
  color: 'white',
  h: 'auto',
  fontSize: 'sm'
})

const outline = defineStyle({
  borderColor: 'gray.300',
  h: 'auto',
  fontSize: 'sm',

  _hover: {
    color: 'white',
    bg: 'brand.500',
    borderColor: 'brand.500'
  }
})

const ghost = defineStyle({
  fontSize: 'sm',
  _hover: {
    bg: 'transparent'
  },
  _active: {
    bg: 'gray.100'
  }
})

const ai = defineStyle({
  bgGradient: 'linear(to-r, #7845db, #6a1b9a)',
  color: 'white',
  transition: 'all 0.3s ease',
  fontSize: 'sm',
  _hover: {
    _before: {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-50%',
      width: '200%',
      height: '100%',
      background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.5), transparent)',
      transition: 'all 0.5s ease',
      transform: 'translateX(100%)',
    },
  },
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '0',
    height: '100%',
    background: 'linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.5), transparent)',
    transition: 'all 0.5s ease',
    transform: 'translateX(-100%)',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary, outline, ghost, ai },
  baseStyle: {
    fontWeight: 400,
    paddingX: 4,
    paddingY: 2,
    fontSize: 'sm',
    width: 'auto',
    lineHeight: 'auto',
    _hover: {
      _disabled: {
        background: 'gray.600',
        color: 'white',
      }
    }
  },
  defaultProps: {
    variant: 'brandPrimary',
    colorScheme: 'brand',
  },
})
