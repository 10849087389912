import React from "react";

import { Box, HStack, Icon, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

const Display3 = ({ variation }) => {
  return (
    <>
      <VStack border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <Image w={'full'} src={variation.imageUrl} />

        <VStack alignItems={'flex-start'} w={'full'} px={3} py={1}>
          <Text fontSize={'sm'} fontWeight={600}>{variation.headline?.truncate(30)}</Text>

          <Box w={'full'}>
            <HStack w={'full'} justifyContent={'space-between'}>
              <HStack>
                <Box overflow={'hidden'} w={'45px'} h={'45px'}>
                  <Image src={variation.logoUrl} />
                </Box>
                <Text noOfLines={2} fontSize={'x-small'} color={'gray.500'} maxW={'10rem'}>{variation.description?.truncate(90)}</Text>
              </HStack>
              <IconButton bg={'black'} color={'white'} borderRadius={'full'} p={4} icon={<Icon as={FiChevronRight} />} aria-label={""} />
            </HStack>
          </Box>

        </VStack>
      </VStack >
    </>
  )
};

export default Display3;