import React from 'react';
import { Box } from "@chakra-ui/react";
import MediaPlan from '../MediaPlans/MediaPlan';
import { useParams } from 'react-router-dom';
import { useGetMediaPlanQuery } from '../../api/services/mediaPlans';
import { useGetMediaPlanCampaignsQuery } from '../../api/services/mediaPlanCampaigns';

const Editor = () => {
    const { id } = useParams();

    const { data, error, isLoading, isSuccess } = useGetMediaPlanQuery(id);
    const mediaPlan = data?.mediaPlan;

    const { data: campaignsData, isLoading: isLoadingMediaPlanCampaigns } = useGetMediaPlanCampaignsQuery({ media_plan_id: id }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

    return (
        <>
            <Box p={4} minHeight="100vh" w='100%'>
                {!isLoading && !isLoadingMediaPlanCampaigns && <MediaPlan mediaPlan={mediaPlan} campaignsData={campaignsData} />}
            </Box >
        </>
    );
};

export default Editor;