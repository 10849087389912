import React, { } from 'react';
import { Box, Heading, Flex, VStack } from '@chakra-ui/react';

const Settings = () => {

  return (
    <Box p={10}>
      <Box w='100%'>
        <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
          <Flex justifyContent='space-between' alignItems={'center'}>
            <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
              Settings
            </Heading>
          </Flex>

        </VStack>
      </Box>
    </Box>
  );
};

export default Settings;
