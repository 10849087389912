import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Input, Box } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiHeart, FiMenu, FiMoreVertical, FiSearch, FiShare, FiShare2, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import GoogleLogo from "../../../../../../assets/icons/pmax/google_logo.png";

const DiscoverAdVideo = ({ variation }) => {

  return (
    <>
      <Phone>

        <HStack mt={4} justifyContent={'center'}>
          <Image src={GoogleLogo} w={20} />
        </HStack>

        <Box p={4}>
          <Input bg={'gray.100'} py={3} borderRadius={'full'} border={'0px'} w={'full'} placeholder="Search" />
        </Box>

        <Divider />

        <VStack mx={3} alignItems={'flex-start'} borderRadius={'lg'} mt={5} boxShadow={'md'} p={0}>
          {!variation.remoteProvider && <video muted style={{ borderRadius: 'lg' }} autoPlay width={'100%'} height="auto"><source src={variation.videoUrl} type="video/mp4" /></video>}
          {variation.remoteProvider == 'youtube' && <iframe style={{ borderRadius: 'lg' }} width="100%" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}

          <Text fontSize={'sm'} px={4} mt={5}>
            {variation.headline?.truncate(30)}. {variation.description?.truncate(90)}
          </Text>

          <HStack px={4}>
            <Text fontSize={'xx-small'}>Sponsored</Text>
          </HStack>

          <HStack w={'full'} justifyContent={'space-between'} mb={4} px={4}>
            <HStack w={'full'} >
              <Text fontSize={'x-small'} fontWeight={'bold'}>
                <Image borderRadius={'md'} w={4} src={variation.logoUrl} />
              </Text>
              <Text fontSize={'x-small'}>{variation.businessName}</Text>
            </HStack>

            <HStack spacing={5}>
              <Icon as={FiHeart} color={'gray.500'} fontSize={'xs'} />
              <Icon as={FiShare} color={'gray.500'} fontSize={'xs'} />
              <Icon as={FiMoreVertical} color={'gray.500'} fontSize={'xs'} />
            </HStack>
          </HStack>


        </VStack>

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />
      </Phone>
    </>
  )
};

export default DiscoverAdVideo;