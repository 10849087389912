
import React from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { Box, Heading, Image, Text, VStack, Button, Link as ChakraLink } from '@chakra-ui/react';
import Illustration_404 from '../../assets/images/errors/Illustration_404.svg'
const NotFound = () => {
 
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/'); // Navigate to homepage
  };

  return (
    <Box textAlign="center" mt="20vh">
         <VStack alignItems="center" spacing={10} justifyContent="center" minH="60vh">
            <Image src={Illustration_404} alt="404 error" mx="auto" mb="8" /> 
            <div>
            <Text textAlign={'center'}>
              <Box>Oops! The page you are looking for might have been removed, had its name</Box>
              <Box>changed, or is temporarily unavailable.</Box>
            </Text>
            <Button onClick={goToHome} mt={5} bg={'#7845DB'}>Take me home</Button>
            </div>
          </VStack>
    </Box>
  );
};

export default NotFound;
