
import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import Suggestions from './Suggestions';
import { HiOutlineChip } from 'react-icons/hi';

const SuggestionsButton = ({ updateHeadline = null, updateDescription = null, validations = { headlines: { min: 1, max: 1 }, descriptions: { min: 1, max: 1 } } }) => {

  const disclosure = useDisclosure();

  return (
    <>
      <Button variant={'ai'} leftIcon={<HiOutlineChip />} onClick={disclosure.onOpen}>AI Suggestions</Button>

      <Suggestions
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        updateHeadline={updateHeadline}
        updateDescription={updateDescription}
        minNumberOfHeadlines={validations.headlines.min}
        maxNumberOfHeadlines={validations.headlines.max}
        minNumberOfDescriptions={validations.descriptions.min}
        maxNumberOfDescriptions={validations.descriptions.max} />
    </>
  )
};

export default SuggestionsButton;