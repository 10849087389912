import { Box, Button, Card, CardBody, HStack, Heading, Image, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import MetaInstantExperienceBlankImage from '../../../../../assets/images/meta_instant_experience_image.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { transformKeysTo } from '../../../../../helpers/apiHelper';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import './CustomSwiperStyles.css';
import { FiArrowLeft } from 'react-icons/fi';

const StorefrontPreview = ({ instantExperienceData, setInstantExperienceOpenedIfPossible }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const carouselItems = instantExperienceData?.metaInstantExperienceProductItems;
  const transformedCarouselItems = carouselItems?.map(item => transformKeysTo('snake_case', structuredClone(item)));

  const goToDestination = (url) => {
    if (url == null) return;
    window.open(url, '_blank');
  };

  return (
    <>
      <Button alignSelf={'flex-start'} background={'none'} color={'black'} leftIcon={<FiArrowLeft color="gray.700" />} onClick={() => setInstantExperienceOpenedIfPossible(false)}>
      </Button>
      <SimpleGrid columns={2} spacing={4} width="100%">
        {transformedCarouselItems && transformedCarouselItems.map((item, index) => {
          const isYouTubeUrl = /youtube/.test(item.asset?.fileUrl);
          const youTubeEmbedUrl = isYouTubeUrl ? item.asset?.fileUrl.replace('watch?v=', 'embed/') : item.asset?.fileUrl;

          return (
            <VStack w='100%' key={index}>
              <Box onClick={() => goToDestination(item.website_url)} width="100%" height="150px" position="relative">
                {item.asset == null &&
                  <Image
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    src={MetaInstantExperienceBlankImage}
                  />
                }
                {item.asset?.assetType === 'image' &&
                  <Image
                    objectFit="fill"
                    width="100%"
                    height="100%"
                    src={item?.asset?.fileUrl}
                  />
                }
                {item.asset?.assetType === 'video' && !isYouTubeUrl &&
                  <Box
                    as="video"
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    autoPlay
                    controls
                    muted
                    loop
                  >
                    <source src={item?.asset?.fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </Box>
                }
                {item.asset?.assetType === 'video' && isYouTubeUrl &&
                  <Box maxH={'150px'} overflow={'hidden'}>
                    <iframe width="100%"
                      height="100%"
                      src={youTubeEmbedUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Box>
                }
              </Box>
              <Box p='2'>
                <Text mb='2' color='gray' fontWeight={'bold'} textAlign={'left'}>{item.headline}</Text>
                <Text fontWeight={'bold'} textAlign={'left'}>{item.currency} {item.price}</Text>
              </Box>
            </VStack>
          );
        })}
      </SimpleGrid>
      <Button variant="outline" w="100%" onClick={() => openInNewTab(instantExperienceData?.buttonDestination_1)}>{instantExperienceData?.buttonLabel_1}</Button>
    </>
  );
};

export default StorefrontPreview;