
export const metaCallToActions = [
  { value: 'OPEN_LINK', label: 'Open link' },
  { value: 'LIKE_PAGE', label: 'Like page' },
  { value: 'SHOP_NOW', label: 'Shop now' },
  { value: 'PLAY_GAME', label: 'Play game' },
  { value: 'INSTALL_APP', label: 'Install app' },
  { value: 'USE_APP', label: 'Use app' },
  { value: 'CALL', label: 'Call' },
  { value: 'CALL_ME', label: 'Call me' },
  { value: 'VIDEO_CALL', label: 'Video call' },
  { value: 'INSTALL_MOBILE_APP', label: 'Install mobile app' },
  { value: 'USE_MOBILE_APP', label: 'Use mobile app' },
  { value: 'MOBILE_DOWNLOAD', label: 'Mobile download' },
  { value: 'BOOK_TRAVEL', label: 'Book travel' },
  { value: 'LISTEN_MUSIC', label: 'Listen music' },
  { value: 'WATCH_VIDEO', label: 'Watch video' },
  { value: 'LEARN_MORE', label: 'Learn more' },
  { value: 'SIGN_UP', label: 'Sign up' },
  { value: 'DOWNLOAD', label: 'Download' },
  { value: 'WATCH_MORE', label: 'Watch more' },
  { value: 'NO_BUTTON', label: 'No button' },
  { value: 'VISIT_PAGES_FEED', label: 'Visit pages feed' },
  { value: 'CALL_NOW', label: 'Call now' },
  { value: 'APPLY_NOW', label: 'Apply now' },
  { value: 'CONTACT', label: 'Contact' },
  { value: 'BUY_NOW', label: 'Buy now' },
  { value: 'GET_OFFER', label: 'Get offer' },
  { value: 'GET_OFFER_VIEW', label: 'Get offer view' },
  { value: 'BUY_TICKETS', label: 'Buy tickets' },
  { value: 'UPDATE_APP', label: 'Update app' },
  { value: 'GET_DIRECTIONS', label: 'Get directions' },
  { value: 'BUY', label: 'Buy' },
  { value: 'SEND_UPDATES', label: 'Send updates' },
  { value: 'MESSAGE_PAGE', label: 'Message page' },
  { value: 'DONATE', label: 'Donate' },
  { value: 'SUBSCRIBE', label: 'Subscribe' },
  { value: 'SAY_THANKS', label: 'Say thanks' },
  { value: 'SELL_NOW', label: 'Sell now' },
  { value: 'SHARE', label: 'Share' },
  { value: 'DONATE_NOW', label: 'Donate now' },
  { value: 'GET_QUOTE', label: 'Get quote' },
  { value: 'CONTACT_US', label: 'Contact us' },
  { value: 'ORDER_NOW', label: 'Order now' },
  { value: 'START_ORDER', label: 'Start order' },
  { value: 'ADD_TO_CART', label: 'Add to cart' },
  { value: 'VIDEO_ANNOTATION', label: 'Video annotation' },
  { value: 'RECORD_NOW', label: 'Record now' },
  { value: 'INQUIRE_NOW', label: 'Inquire now' },
  { value: 'CONFIRM', label: 'Confirm' },
  { value: 'REFER_FRIENDS', label: 'Refer friends' },
  { value: 'REQUEST_TIME', label: 'Request time' },
  { value: 'GET_SHOWTIMES', label: 'Get showtimes' },
  { value: 'LISTEN_NOW', label: 'Listen now' },
  { value: 'WOODHENGE_SUPPORT', label: 'Woodhenge support' },
  { value: 'SOTTO_SUBSCRIBE', label: 'Sotto subscribe' },
  { value: 'FOLLOW_USER', label: 'Follow user' },
  { value: 'RAISE_MONEY', label: 'Raise money' },
  { value: 'EVENT_RSVP', label: 'Event RSVP' },
  { value: 'WHATSAPP_MESSAGE', label: 'WhatsApp message' },
  { value: 'FOLLOW_NEWS_STORYLINE', label: 'Follow news storyline' },
  { value: 'SEE_MORE', label: 'See more' },
  { value: 'BOOK_NOW', label: 'Book now' },
  { value: 'FIND_A_GROUP', label: 'Find a group' },
  { value: 'FIND_YOUR_GROUPS', label: 'Find your groups' },
  { value: 'PAY_TO_ACCESS', label: 'Pay to access' },
  { value: 'PURCHASE GIFT CARDS', label: 'Purchase gift cards' },
  { value: 'FOLLOW_PAGE', label: 'Follow page' },
  { value: 'SEND_A_GIFT', label: 'Send a gift' },
  { value: 'SWIPE_UP_SHOP', label: 'Swipe up shop' },
  { value: 'SWIPE_UP_PRODUCT', label: 'Swipe up product' },
  { value: 'SEND_GIFT_MONEY', label: 'Send gift money' },
  { value: 'PLAY_GAME_ON_FACEBOOK', label: 'Play game on Facebook' },
  { value: 'GET_STARTED', label: 'Get started' },
  { value: 'OPEN_INSTANT_APP', label: 'Open instant app' },
  { value: 'AUDIO_CALL', label: 'Audio call' },
  { value: 'GET_PROMOTIONS', label: 'Get promotions' },
  { value: 'JOIN_CHANNEL', label: 'Join channel' }
]