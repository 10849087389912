import { baseApi } from "./base/base";

export const organizationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query({
      query: () => ({ url: `/organizations` }),
      providesTags: []
    }),
    updateOrganization: builder.mutation({
      query: (data) => ({ url: `/organizations/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Organization']
    }),
    downgradeOrganization: builder.mutation({
      query: (data) => ({ url: `/organization/downgrade`, method: 'POST', body: data }),
      invalidatesTags: ['Organization']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetOrganizationQuery, useUpdateOrganizationMutation, useDowngradeOrganizationMutation
} = organizationsApi;
