import React from "react";

import { Center, HStack, Icon, IconButton, useSteps } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import SearchAd from "./Search/SearchAd";
import DesktopSearchAd from "./Search/DesktopSearchAd";

const Search = ({ variations, displayType }) => {
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
      <Center>
        <HStack spacing={10} mb={4}>
          <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
          <IconButton isDisabled={activeStep == 1} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
        </HStack>
      </Center>

      {displayType == 'mobile' && <>
        {activeStep == 0 && <SearchAd variation={variations[0]} />}
        {activeStep == 1 && <SearchAd variation={variations[0]} withImage />}
      </>}

      {displayType == 'desktop' && <>
        {activeStep == 0 && <DesktopSearchAd variation={variations[0]} />}
        {activeStep == 1 && <DesktopSearchAd variation={variations[0]} withImage />}
      </>}
    </>
  )
};

export default Search;