import { baseApi } from "./base/base";

export const repliesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReplies: builder.query({
            query: (data) => ({ url: `/comments/${data.comment_id}/replies`, params: data }),
            providesTags: ['Reply']
        }),
        createReply: builder.mutation({
            query: (data) => ({ url: `/comments/${data.comment_id}/replies`, method: 'POST', body: data }),
            invalidatesTags: ['Reply']
        }),
    }),

    overrideExisting: false
});

export const {
    useGetRepliesQuery, useCreateReplyMutation
} = repliesApi;
