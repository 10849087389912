import React, { useState, useEffect } from 'react';
import { Button, Editable, EditableInput, EditablePreview, Icon, Input, InputGroup, InputRightAddon, InputRightElement, Text, Tooltip } from '@chakra-ui/react';
import { FiCheck, FiEdit3, FiSave } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

const TextInput = ({ value: initialValue, onSubmit, readOnly }) => {
  const { register, handleSubmit, watch, formState, reset } = useForm({
    defaultValues: {
      value: initialValue
    }
  });

  const onFormSubmit = (data) => {
    onSubmit(data.value);
    reset({ value: data.value });
  }

  useEffect(() => {
    reset({ value: initialValue });
  }, []);

  return (
    <>
      {readOnly && <Text>{initialValue}</Text>}
      {!readOnly && <>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <InputGroup>
            <Input {...register('value')} minW={'15rem'} fontSize={'xs'} w={20} type='text' />
            {formState.isDirty && <InputRightElement border={0}>
              <Button type='submit' mr={5} fontSize='xs' bg={'gray.100'} color={'gray.700'} h={'1.5rem'}>
                Save
              </Button>
            </InputRightElement>}
          </InputGroup>
        </form>
      </>}
    </>
  );
};

export default TextInput;