// some-inner-component.jsx
import { Icon, IconButton } from '@chakra-ui/react';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useSwiper } from 'swiper/react';

export default function Next() {
  const swiper = useSwiper();

  return (
    <IconButton bg={'rgba(0, 0, 0, 0.5)'} color={'white'} fontSize={'4xl'} onClick={() => swiper.slideNext()} icon={<Icon as={FiChevronRight} />} borderRadius="full" _hover={{ bg: 'rgba(0, 0, 0, 0.7)' }} aria-label={""} />);
}