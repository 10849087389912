import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Button, Card, CardHeader, CardBody, Heading, Text, VStack, HStack, Link, useColorModeValue, Flex, Stack, Divider, List, ListItem, ListIcon, Tag, Center } from '@chakra-ui/react';
import { useCreateStripePortalSessionMutation } from '../../api/services/subscriptions';
import { useCreateSubscriptionMutation, useCreateEnterpriseSubsciptionMutation } from './../../api/services/subscriptions'
import SubscriptionPlans from './SubscriptionPlans';
import { useSelector } from 'react-redux';

const PaymentInformation = ({ goToStep }) => {
    const currentUser = useSelector((state) => state.user.currentUser);
    const [selectedPlan, setSelectedPlan] = useState('starter_monthly');
    const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();
    const [createEnterpriseSubsciption, { isLoading: isEnterpriseLoading, isSuccess }] = useCreateEnterpriseSubsciptionMutation();

    const handlePayment = () => {
        createSubscription({ lookupKey: selectedPlan }).then((response) => {
            const url = response?.data?.url;
            if (url) {
                window.location.href = url;
            }
        });
    }

    const handleEnterprise = () => {
        createEnterpriseSubsciption({
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            email: currentUser?.email
        });
    }

    useEffect(() => {
        if (isSuccess) {
            goToStep(2);
        }
    }, [isSuccess])

    return (
        <>
            <SubscriptionPlans selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
            <Center flexDir={'column'}>
                {selectedPlan != 'enterprise_monthly' && <Text fontSize="xs" color="gray.500">Note: You will be redirected to the payment gateway to complete the payment.</Text>}

                <HStack mt={5}>
                    {selectedPlan != 'enterprise_monthly' && <>
                        <Button onClick={() => goToStep(0)} variant="outline" color="black">
                            Back
                        </Button>
                        {selectedPlan != null && <Button isLoading={isLoading} onClick={() => handlePayment()}>Next</Button>}
                    </>}

                    {selectedPlan == 'enterprise_monthly' && <>
                        <HStack mt={3}>
                            <Button onClick={() => goToStep(0)} variant="outline" color="black">
                                Back
                            </Button>
                            <Button isLoading={isLoading} onClick={() => handleEnterprise()}>
                                Next
                            </Button>
                        </HStack>
                    </>}

                </HStack>
            </Center>
        </>
    );
};
export default PaymentInformation;