import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Button, Image, SkeletonCircle, SkeletonText, Divider } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp, FaThumbsDown, FaComment, FaShare } from 'react-icons/fa6';
import { FiCornerUpRight, FiDownload, FiExternalLink, FiMessageSquare, FiMoreVertical, FiRewind, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { googleDemandgenCallToActions } from '../../../../../../components/Constants/google_demandgen_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const ShortsAd = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Example of your ad on Shorts</Text>

      <Phone>
        <VStack position={'relative'} justifyContent={'center'} minH={'500px'} w={'full'} overflow={'hidden'} h={'full'}>
          <VStack spacing={5} zIndex={1000} w={'50px'} h={'full'} justifyContent={'flex-end'} bottom={'80px'} right={'20px'} position={'absolute'}>
            <Icon as={FaThumbsUp} color={'white'} fontSize={'x-large'} />
            <Icon as={FaThumbsDown} color={'white'} fontSize={'x-large'} />
            <Icon as={FaComment} color={'white'} fontSize={'x-large'} />
            <Icon as={FaShare} color={'white'} fontSize={'x-large'} />
          </VStack>

          <VStack spacing={5} zIndex={1000} w={'100px'} h={'full'} justifyContent={'flex-end'} bottom={'80px'} left={'20px'} position={'absolute'}>
            <HStack spacing={5}>
              <Image borderRadius={'full'} src={variation.logoUrl} w={7} />
              <VStack spacing={0} alignItems={'flex-start'}>
                <Text fontSize={'sm'} color={'white'}>{variation.businessName}</Text>
                <Text fontSize={'xs'} color={'white'} fontWeight={'bold'}>Ad</Text>
              </VStack>
            </HStack>
          </VStack>

          <Box _before={{ backdropFilter: 'blur(5px)', w: '100%', h: '100%', top: 0, left: 0, right: 0, content: '""', position: 'absolute' }} position={'absolute'}>
            {!variation.remoteProvider && <video muted key={variation.videoUrl} autoPlay width={'300px'} height="500px"><source src={variation.videoUrl} type="video/mp4" /></video>}
            {variation.remoteProvider == 'youtube' && <iframe key={variation.videoUrl} width="300px" height="500px" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
          </Box>

          <Box mb={4} position={'relative'}>
            {!variation.remoteProvider && <video muted key={variation.videoUrl} autoPlay width={'300px'} height="auto"><source src={variation.videoUrl} type="video/mp4" /></video>}
            {variation.remoteProvider == 'youtube' && <iframe key={variation.videoUrl} width="300px" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
          </Box>

          <Button bg={'blue.600'} w={'90%'} bottom={5} borderRadius={'md'} px={3} boxShadow={'lg'} py={2} position={'absolute'} fontSize={'sm'} >
            {googleDemandgenCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}
          </Button>
        </VStack>

      </Phone >
    </>
  )
};

export default ShortsAd 