
export const flattenObject = (obj, parentKey = "") => {
  let result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively flatten nested objects
        if (obj[key] instanceof FileList) {
          result[newKey] = obj[key][0];
        } else {
          result = { ...result, ...flattenObject(obj[key], newKey) };
        }
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};

export default flattenObject;