import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Button } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreHorizontal, FiMoreVertical, FiStar, FiTrash2 } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import { googleDisplayCallToActions } from "../../../../../../components/Constants/google_display_call_to_actions";

const MobileOpened = ({ variation }) => {


  return (
    <>

      <Phone p={3}>

        <HStack w={'full'} justifyContent={'space-between'} p={2}>
          <Icon as={FiChevronLeft} />
          <HStack spacing={5}>
            <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'sm'} px={1}>Ad</Text>
            <Icon as={FiStar} />
            <Icon as={FiTrash2} />
            <Icon as={FiMoreHorizontal} />
          </HStack>
        </HStack>


        <HStack mt={5} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Avatar size={'sm'} src={variation.logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <HStack>
                <Text fontWeight={'semibold'} fontSize={'xs'}>{variation.businessName}</Text>
              </HStack>
              <Text fontSize={'x-small'}>me</Text>
            </VStack>
          </HStack>
        </HStack>

        <Image mt={5} w={'full'} src={variation.imageUrl} />

        <Text mt={5} fontSize={'lg'} fontWeight={'semibold'}>{variation.headline?.truncate(30)}</Text>
        <Text mt={3} color={'gray.700'} fontSize={'md'}>{variation.description?.truncate(90)}</Text>

        <Button mt={5} borderRadius={'3xl'} fontSize={'sm'} fontWeight={'bold'} py={2} w={'full'} bg={'blue.500'}>{googleDisplayCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>

        <Divider my={5} />
      </Phone>
    </>
  )
};

export default MobileOpened;