function getRandomArrays(arrayOfArrays, X) {
  if (X > arrayOfArrays.length) {
    return arrayOfArrays;
  }

  // Clone the original array to avoid modifying it
  const shuffledArrays = [...arrayOfArrays];

  // Shuffle the cloned array randomly (Fisher-Yates shuffle algorithm)
  for (let i = shuffledArrays.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArrays[i], shuffledArrays[j]] = [shuffledArrays[j], shuffledArrays[i]];
  }

  // Pick the first X arrays from the shuffled array
  return shuffledArrays.slice(0, X);
}

function generateCombinations(strings, arraySize) {
  const results = [];

  function combine(current, start) {
    if (current.length === arraySize) {
      results.push([...current]);
      return;
    }

    for (let i = start; i < strings.length; i++) {
      current.push(strings[i]);
      combine(current, i + 1);
      current.pop();
    }
  }

  combine([], 0);
  return results
}

export { getRandomArrays, generateCombinations };