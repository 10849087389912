import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Input, Flex, VStack, Table, Thead, Tbody, Tr, Th, Td, HStack, Icon, Spacer, Skeleton, IconButton, Link } from '@chakra-ui/react';
import { useDeleteIntegrationMutation, useGetIntegrationsQuery } from '../../api/services/integrations';
import Paginator from '../../components/Common/Pagination/Paginator';
import { FaCircle } from "react-icons/fa6";
import { FiEdit3, FiLink, FiTrash2 } from 'react-icons/fi';
import PlatformIcon from '../Campaigns/Components/PlatformIcon';
import ProviderLabel from './Components/ProviderLabel';
import { useSelector } from 'react-redux';

const Integrations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');

  const { data, isLoading } = useGetIntegrationsQuery({ per_page: 10, page: currentPage, query: query }, { refetchOnMountOrArgChange: true });
  const [deleteIntegration] = useDeleteIntegrationMutation();

  const connectedIntegrations = data?.integrations?.map((integration) => integration.provider);
  const currentUser = useSelector((state) => state.user.currentUser);

  const providers = [
    {
      name: 'google',
      url: `/auth/google_oauth2?uuid=${currentUser?.uuid}`
    },
    {
      name: 'facebook',
      url: `/auth/facebook?uuid=${currentUser?.uuid}`
    },
    {
      name: 'tiktok',
      url: `/auth/tiktok/prompt?uuid=${currentUser?.uuid}`
    },
  ]

  return (
    <Box px={10} mt={10}>
      <VStack spacing={10} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
        <Flex flexDir={'column'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
          <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
            Integrations
          </Heading>

          <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
            Connect your social media accounts to manage your campaigns.
          </Text>
        </Flex>


        <Skeleton isLoaded={!isLoading}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color={'xagray.600'} fontWeight={400} fontSize={'xs'} textTransform={'capitalize'}>Provider</Th>
                <Th color={'gray.600'} fontWeight={400} fontSize={'xs'} textTransform={'capitalize'}>Account</Th>
                <Th color={'gray.600'} fontWeight={400} fontSize={'xs'} textTransform={'capitalize'}>Account ID</Th>
                <Th color={'gray.600'} fontWeight={400} fontSize={'xs'} textTransform={'capitalize'}>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={500}>
              {data?.integrations?.map((integration) => (
                <Tr key={integration.id} _hover={{ bg: 'gray.50' }}>
                  <Td>
                    <ProviderLabel platform={integration.provider} />
                  </Td>
                  <Td>{integration.name} {integration.email?.length > 0 && `(${integration.email})`}</Td>
                  <Td>{integration.accountId?.length > 0 ? integration.accountId : '--'} </Td>
                  <Td>
                    <HStack>
                      <Icon as={FaCircle} w={2} h={2} color='green' />
                      <Text fontSize={'xs'} color={'green'}>Active</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <IconButton onClick={() => deleteIntegration(integration.id)} _hover={{ bg: 'black', color: 'white' }} variant={'ghost'} icon={<Icon as={FiTrash2} />} aria-label={''} />
                  </Td>
                </Tr>
              ))}

              {
                providers.filter((provider) => !connectedIntegrations?.includes(provider.name)).map((provider) => {
                  return (
                    <Tr key={provider.name} _hover={{ bg: 'gray.50' }} >
                      <Td>
                        <ProviderLabel platform={provider.name} />
                      </Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>
                        <HStack>
                          <Icon as={FaCircle} w={2} h={2} color='gray' />
                          <Text fontSize={'xs'} color={'gray'}>Not Connected</Text>
                        </HStack>
                      </Td>
                      <Td>
                        <IconButton as={Link} href={provider.url} _hover={{ bg: 'black', color: 'white' }} variant={'ghost'} icon={<Icon as={FiLink} />} aria-label={''} />
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Skeleton>

        <Spacer h={'30px'} />

        {data?.meta?.pagination?.totalAmount > data?.meta?.pagination?.perPage && <Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />}
      </VStack>
    </Box>
  );
};

export default Integrations;
