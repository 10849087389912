import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Button, Image, SkeletonCircle, SkeletonText, Divider, Skeleton } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder, FaThumbsUp } from 'react-icons/fa6';
import { FiArrowLeft, FiArrowRight, FiCornerUpRight, FiDownload, FiExternalLink, FiMenu, FiMessageSquare, FiMoreVertical, FiRefreshCw, FiRewind, FiRotateCw, FiThumbsDown, FiThumbsUp, FiUser } from 'react-icons/fi';
import { googleDemandgenCallToActions } from '../../../../../../components/Constants/google_demandgen_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const DesktopHomeAd = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Youtube Home Feed</Text>

      <HStack mt={4} px={4} w={'full'} bg={'gray.200'} roundedTopLeft={'md'} roundedTopRight={'md'}>
        <HStack>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
          <Box borderRadius={'full'} w={3} h={3} bg={'white'} border={'solid 1px'} borderColor={'gray.300'}></Box>
        </HStack>

        <HStack px={3} borderRight={'16px solid transparent'} pt={4} borderLeft={'16px solid transparent'} h={0} borderBottom={'solid 28px #f1f3f4'} marginLeft={10} spacing={3} w={'200px'}>
          <Box mt={7} w={3} h={3} bg={'gray.400'}></Box>
          <Box mt={7} w={'full'} h={2} bg={'gray.300'}></Box>
        </HStack>
      </HStack>
      <HStack bg={'#f1f3f4'} spacing={10} w={'full'} px={5} py={4}>
        <HStack spacing={5}>
          <Icon as={FiArrowLeft} color={'gray.700'} />
          <Icon as={FiArrowRight} color={'gray.700'} />
          <Icon as={FiRotateCw} color={'gray.700'} />
        </HStack>
        <Box w={'full'} h={'25px'} border={'solid 1px'} borderColor={'gray.300'} bg={'white'}></Box>
        <Icon as={FiMoreVertical} color={'gray.700'} />
      </HStack>

      <HStack borderBottom={'solid 1px'} borderColor={'gray.200'} bg={'white'} spacing={20} w={'full'} px={5} py={4}>
        <HStack spacing={5}>
          <Icon as={FiMenu} color={'gray.700'} />
          <Image src={YoutubeLogo} w={20} />
        </HStack>
        <Box w={'full'} h={'25px'} bg={'gray.200'} ></Box>
        <Icon as={FiUser} color={'gray.700'} />
      </HStack>

      <HStack px={5} mt={5} spacing={5} alignItems={'flex-start'}>
        <VStack w={'35%'} spacing={3}>
          {[1, 2, 3, 4].map(() => <>
            <HStack w={'full'}>
              <Box w={4} h={3} bg={'gray.300'} borderRadius={'full'}></Box>
              <Box w={'full'} h={3} bg={'gray.300'} borderRadius={'full'}></Box>
            </HStack>
          </>)}

          <Divider />

          {[1, 2, 3, 4].map(() => <>
            <HStack w={'full'}>
              <Box w={4} h={3} bg={'gray.300'} borderRadius={'full'}></Box>
              <Box w={'full'} h={3} bg={'gray.300'} borderRadius={'full'}></Box>
            </HStack>
          </>)}

          <Divider />
        </VStack>

        <VStack w={'full'} alignItems={'flex-start'} spacing={5} p={1}>
          <HStack alignItems={'flex-start'}>
            <Box w={'full'}>
              <Image w={'full'} mt={4} src={variation.imageUrl} />

              <HStack justifyContent={'space-between'} mt={2} p={0}>
                <Text fontWeight={'bold'} fontSize={'xx-small'}>{variation.headline?.truncate(30)}</Text>
                <Icon color={'black.500'} fontSize={'xx-small'} as={FiMoreVertical} />
              </HStack>

              <Text p={0} fontSize={'xx-small'} color={'gray.600'}>
                {variation.description?.truncate(90)}
              </Text>
              <HStack p={0}>
                <Text fontSize={'xx-small'} fontWeight={'bold'}>Ad</Text>
                <Text fontSize={'xx-small'}>• {variation.businessName}</Text>
              </HStack>
            </Box>

            <Box w={'full'}>
              <Skeleton mt={4} w={'full'} startColor="gray.400" endColor="gray.400" height='70px' />
              <SkeletonText mt={2} noOfLines={2} spacing={3} startColor='gray.400' endColor='gray.400' />
            </Box>
          </HStack>

          <HStack w={'full'} alignItems={'flex-start'}>
            <Box w={'full'}>
              <Skeleton mt={4} w={'full'} startColor="gray.400" endColor="gray.400" height='70px' />
              <SkeletonText mt={2} noOfLines={2} spacing={3} startColor='gray.400' endColor='gray.400' />
            </Box>

            <Box w={'full'}>
              <Skeleton mt={4} w={'full'} startColor="gray.400" endColor="gray.400" height='70px' />
              <SkeletonText mt={2} noOfLines={2} spacing={3} startColor='gray.400' endColor='gray.400' />
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </>
  )
};

export default DesktopHomeAd;