import React, { useEffect } from "react";
import { Alert, AlertIcon, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormErrorMessage, ScaleFade, FormLabel, HStack, Icon, Input, Select, useDisclosure, useToast } from '@chakra-ui/react';
import { FiUserPlus } from 'react-icons/fi';
import { useForm } from "react-hook-form";
import { useInviteUserMutation } from "../../api/services/users";

const Invite = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm();
  const toast = useToast();

  const [invite, { isLoading, isSuccess, isError, error }] = useInviteUserMutation();
  const errors = error?.data?.errors;

  console.log(errors)

  const onSubmit = (data) => {
    invite(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "User invited.",
        description: "We've sent an invitation to the user.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onClose();
    }
  }, [isSuccess])

  return (
    <>
      <Button onClick={onOpen} leftIcon={<Icon as={FiUserPlus} />} variant={'outline'}>Invite user</Button>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Invite a team member</DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  {errors['']}
                </Alert>
              </ScaleFade>}
              <HStack>
                <FormControl>
                  <FormLabel fontSize={'sm'}>First Name</FormLabel>
                  <Input type='text' placeholder="John" {...register('firstName', { required: true })} />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'sm'}>Last Name</FormLabel>
                  <Input type='text' placeholder="Doe" {...register('lastName', { required: true })} />
                </FormControl>
              </HStack>

              <FormControl isInvalid={isError && errors?.email} mt={4}>
                <FormLabel fontSize={'sm'}>Email</FormLabel>
                <Input type='email' placeholder="example@domain.com" {...register('email', { required: true })} />
                <FormErrorMessage>{errors?.email && errors?.email[0] == 'already exists' && <>E-Mail already exists.</>}</FormErrorMessage>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel fontSize={'sm'}>Role</FormLabel>
                <Select {...register('organizationRole', { required: true })}>
                  <option value="member">Member</option>
                </Select>
              </FormControl>
            </form>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit(onSubmit)} isLoading={isLoading} isDisabled={isLoading}>Invite</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Invite;