import React from 'react';
import { Input, Text, Button, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

const NumberInput = ({ value: initialValue, onSubmit, readOnly }) => {
    const { register, handleSubmit, watch, formState, reset } = useForm({
        defaultValues: {
            value: initialValue
        }
    });

    const onFormSubmit = (data) => {
        onSubmit(data.value);
        reset({ value: data.value });
    }

    return (
        <>
            {readOnly && <Text>{initialValue}</Text>}
            {!readOnly && <>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <InputGroup>
                        <Input {...register('value')} fontSize={'xs'} w={'10rem'} type='number' />
                        {formState.isDirty && <InputRightElement border={0}>
                            <Button type='submit' mr={3} fontSize='xs' bg={'gray.100'} color={'gray.700'} h={'1.5rem'}>
                                Save
                            </Button>
                        </InputRightElement>}
                    </InputGroup>
                </form>
            </>}
        </>
    );
};

export default NumberInput;