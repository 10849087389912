import { createSlice } from '@reduxjs/toolkit';

export const variationsSlice = createSlice({
  name: 'variations',
  initialState: {
    toggleValue: false,
  },
  reducers: {
    toggle: state => {
      state.toggleValue = !state.toggleValue;
    },
  },
});

export const { toggle } = variationsSlice.actions;

export default variationsSlice.reducer;
