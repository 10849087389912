import { Button, HStack, Input, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useCreateAssetMutation } from "../../../api/services/assets";
import { useSelector } from "react-redux";

import YouTube from "./External/YouTube";

const Upload = ({ type }) => {
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const toast = useToast();
  const [upload, { isSuccess, isError, isLoading }] = useCreateAssetMutation();

  const handleUpload = (e) => {
    const files = e.target.files;

    const data = new FormData();
    data.append("workspace_id", currentWorkspace?.id);

    Array.from(files).forEach((file) => {
      data.append("files[]", file);
    });

    upload(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: "Asset uploaded!",
        status: "success",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast({
        description: "Error during upload!",
        status: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      toast({
        description: "Uploading asset... Please wait.",
        status: "info",
      });
    }
  }, [isLoading]);

  return (
    <>
      <Input
        type="file"
        display="none"
        id="media-asset"
        multiple
        onChange={handleUpload}
        accept="image/*,video/*"
      />
      <HStack gap={"6"}>
        <YouTube type={type} />

        <label htmlFor="media-asset">
          <Button cursor={"pointer"} variant={"outline"} as="span">
            Upload File
          </Button>
        </label>
      </HStack>
    </>
  );
};

export default Upload;
