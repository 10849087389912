import { Box, Button, Flex, Icon, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, FormErrorMessage, Stack, RadioGroup, Radio, FormHelperText } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { FiEdit3, FiHelpCircle } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { metaCallToActions } from "../../../../components/Constants/meta_call_to_actions";
import { useNavigate, useParams } from "react-router-dom";
import EditorContext from "../../../../contexts/EditorContext";
import AssetsLibraryButton from "../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { HiOutlineChip } from "react-icons/hi";
import Suggestions from "../../../Suggestions/Suggestions";
import InstantExperience from "./InstantExperience/InstantExperience";
import flattenObject from "../../../../helpers/objectHelper";
import SuggestionsButton from "../../../Suggestions/SuggestionsButton";
import { validateUrl } from "../../../../components/utils/urlUtils";

const CommonEditor = ({ ad, onFormSubmit, isLoading, register, watch, control, handleSubmit, getValues, setValue, assetsState, instagramLogoState, facebookLogoState, activeTab }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { errors } = useContext(EditorContext);

  const [assets, setAssets] = assetsState;
  const [facebookLogo, setFacebookLogo] = facebookLogoState;
  const [instagramLogo, setInstagramLogo] = instagramLogoState;
  const primaryTextMaxLength = activeTab === 0 ? 80 : 125;
  const headlineMaxLength = activeTab === 0 ? 27 : 40;


  const wAll = watch();

  const camelToSnake = (camelCaseStr) => {
    return camelCaseStr.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('asset_id', assets[0]?.id);
    formData.append('facebook_logo_asset_id', facebookLogo[0]?.id);
    formData.append('instagram_logo_asset_id', instagramLogo[0]?.id);

    const flObject = flattenObject(data);
    Object.keys(flObject).forEach((key) => {
      if (flObject[key] == null)
        return;

      const modifiedKey = key.replace(/\[\d+\]/g, '[]');
      formData.append(modifiedKey, flObject[key]);
    });

    onFormSubmit(formData);
  }

  const updateHeadline = (headlines) => {
    setValue('headline', headlines[0]);
  };

  const updateDescription = (descriptions) => {
    setValue('description', descriptions[0]);
  };

  const clearInstantExperienceValues = () => {
    setValue('meta_instant_experience', null);
  }

  return (
    <>
      <form style={{ width: '100%' }} mode={onblur} onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} >

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Identity</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
            </CardHeader>
            <CardBody>
              <FormControl isInvalid={errors?.facebook_page_name}>
                <FormLabel>Facebook Page</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={facebookLogo} setAssets={setFacebookLogo} type={'image'} rounded />

                  <Input w={'full'} {...register('facebook_page_name')} placeholder={'Page name'} />
                </HStack>
                <FormErrorMessage justifyContent={'flex-end'}>{errors?.facebook_page_name}</FormErrorMessage>
              </FormControl>

              <FormControl mt={10} isInvalid={errors?.instagram_account_name}>
                <FormLabel>Instagram Account</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={instagramLogo} setAssets={setInstagramLogo} type={'image'} rounded />

                  <Input {...register('instagram_account_name')} w={'full'} placeholder={'Account name'} />
                </HStack>
                <FormErrorMessage justifyContent={'flex-end'}>{errors?.instagram_account_name}</FormErrorMessage>
              </FormControl>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Media</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>
              <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

              <HStack>
                <AssetsLibraryButton assets={assets} setAssets={setAssets} types={['all']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={assets} setAssets={setAssets} />
              </Stack>
            </CardBody>
          </Card>


          <Card w={'full'}>
            <CardHeader>
              <Flex>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                  <Text>Content</Text>
                  <Icon as={FiHelpCircle} />
                </Flex>
                <SuggestionsButton updateHeadline={updateHeadline} updateDescription={updateDescription} />
              </Flex>
            </CardHeader>
            <CardBody>
              <VStack spacing={5}>
                <FormControl>
                  <FormLabel>Primary Text</FormLabel>
                  <Textarea {...register('primary_text')} placeholder={'Add primary text here...'} maxLength={primaryTextMaxLength} />
                  <FormHelperText color={(getValues('primary_text')?.length || 0) > primaryTextMaxLength ? 'red' : 'gray.500'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                    {`${getValues('primary_text')?.length || 0} / ${primaryTextMaxLength}`}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Headline</FormLabel>
                  <Textarea {...register('headline')} placeholder={'Add headline here...'} maxLength={headlineMaxLength} />
                  <FormHelperText color={(getValues('headline')?.length || 0) > headlineMaxLength ? 'red' : 'gray.500'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                    {`${getValues('headline')?.length || 0} / ${headlineMaxLength}`}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Input {...register('description')} placeholder={'Add description here...'} />
                </FormControl>

                <FormControl>
                  <FormLabel>Call To Action</FormLabel>
                  <Select {...register('call_to_action')}>
                    <option value={''}>No Button</option>
                    {metaCallToActions.map((cta, index) => (
                      <option value={cta.value} key={index}>{cta.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Destination</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide the destination URL for your ad.</Text>
            </CardHeader>
            <CardBody>
              <VStack spacing={5}>
                <FormControl>
                  <VStack align={'start'} mb='4'>
                    <Text>Website URL</Text>
                    <Input
                      {...register('website_url')}
                      placeholder={'Enter your website URL...'}
                      onBlur={(e) => {
                        const validatedUrl = validateUrl(e.target.value);
                        setValue('website_url', validatedUrl);
                      }} />
                  </VStack>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <HStack w={'full'} justifyContent={'flex-start'}>
            <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
            <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
          </HStack>
        </VStack>
      </form>
    </>
  )
}

export default CommonEditor;