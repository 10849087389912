import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn, logoutSession } from "../../helpers/sessionHelper";
import { setCurrentUser } from "../../api/slices/usersSlice";
import { useGetCurrentUserQuery } from "../../api/services/users";
import Sidebar from "./ApplicationLayout/Sidebar";
import Header from "./ApplicationLayout/Header";
import WorkspaceSidebar from "./ApplicationLayout/Sidebar/WorkspaceSidebar";
import { current } from "@reduxjs/toolkit";

const ApplicationLayout = ({ sidebar = <WorkspaceSidebar /> }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDispatchFinished, setDispatchFinished] = useState(false);

  const { data: currentUserData } = useGetCurrentUserQuery({}, { extra: { dispatch } });
  const currentUser = currentUserData?.user;
  const isLoggedInStore = useSelector((state) => state.auth.accessToken)?.length > 0;


  if (!isLoggedIn && !isLoggedInStore) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (currentUser?.organization?.state == 'deactivated' && location.pathname != '/deactivated') {
    navigate('/deactivated');
  }

  if (
    ['created', 'organization_details', 'payment_details'].includes(currentUser?.organization?.state) &&
    !location.pathname.startsWith('/onboarding') &&
    !location.pathname.startsWith('/success')
  ) {
    navigate(`/onboarding`);
  }

  if (currentUser?.organization?.state == 'downgrading' && location.pathname != '/downgrading') {
    navigate('/downgrading');
  }

  useEffect(() => {
    if (currentUserData) {
      dispatch(setCurrentUser(currentUserData.user));
      setDispatchFinished(true);

      if (typeof tidioChatApi !== 'undefined') {
        tidioChatApi.setVisitorData({
          id: currentUserData?.user?.id,
          email: currentUserData?.user?.email,
          firstName: currentUserData?.user?.firstName,
          lastName: currentUserData?.user?.lastName,
        });
      }
    }

  }, [currentUserData, dispatch]);

  return (
    <>
      {isDispatchFinished && (
        <>
          {sidebar === null ? (
            <Outlet />
          ) : (
            <>
              <Header />
              <Box w={'full'} minH="100vh" display={'flex'} gap={2} overflow={'visible'}>
                <Sidebar type={sidebar} />
                <Box mt={'5rem'} ml={{ base: 0, md: 80 }} w={'full'} overflow={'scroll'}>
                  <Outlet />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ApplicationLayout;
