import { baseApi } from "./base/base";

export const mediaPlanCampaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMediaPlanCampaigns: builder.query({
      query: (data) => ({ url: `/media_plans/${data.mediaPlanId}/media_plan_campaigns`, params: data }),
      providesTags: ['MediaPlanCampaign']
    }),
    createMediaPlanCampaign: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.mediaPlanId}/media_plan_campaigns`, method: 'POST', body: data }),
      invalidatesTags: ['MediaPlanCampaign']
    }),
    updateMediaPlanCampaign: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.mediaPlanId}/media_plan_campaigns/${data.mediaPlanCampaignId}`, method: 'PATCH', body: data }),
      invalidatesTags: ['MediaPlanCampaign', 'MediaPlan']
    }),
    deleteMediaPlanCampaign: builder.mutation({
      query: (data) => ({ url: `/media_plans/${data.mediaPlanId}/media_plan_campaigns/${data.mediaPlanCampaignId}`, method: 'DELETE', params: data }),
      invalidatesTags: ['MediaPlanCampaign']
    }),

  }),
  overrideExisting: false
});

export const {
  useGetMediaPlanCampaignsQuery,
  useUpdateMediaPlanCampaignMutation,
  useCreateMediaPlanCampaignMutation,
  useDeleteMediaPlanCampaignMutation,
} = mediaPlanCampaignsApi;
