import { Box, Icon, Text, Flex, Button } from '@chakra-ui/react';
import React from 'react';
import { FiAlertTriangle, FiArrowRightCircle } from 'react-icons/fi';

const Failure = () => {
  return (
    <Box textAlign="center" fontSize="xl" mt={10}>
      <Icon fontSize={'2rem'} color={'red'} as={FiAlertTriangle} />
      <Text fontSize="lg" fontWeight={'bold'} mt={10}>
        Subscription failed.
      </Text>
      <Text fontSize="sm" mt={5}>
        It seems that there was an error processing your payment. Please try again or contact support.
      </Text>
      <Flex mt={10} justifyContent="center">
        <Button leftIcon={<Icon as={FiArrowRightCircle} />} variant="ai" onClick={() => window.location.href = '/billing'}>
          Back to billing
        </Button>
      </Flex>
    </Box>
  );
};

export default Failure;