import React from "react";

import { Box, Button, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { googlePmaxCallToActions } from "../../../../../../../components/Constants/google_pmax_call_to_actions";

const Display2 = ({ variation }) => {
  return (
    <>
      <VStack position={'relative'} p={3} backgroundSize={'cover'} backgroundImage={variation.imageUrl} border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backdropFilter="blur(7px)"
          backgroundColor="rgba(255, 255, 255, 0.25)"
          zIndex={0}
        ></Box>
        <VStack zIndex={5} my={2} spacing={5} w={'full'} px={3} py={5} bg={'white'} boxShadow={'md'} rounded={'md'}>
          <Image src={variation.logoUrl} w={50} />
          <Text fontSize={'md'} fontWeight={600}>{variation.headline?.truncate(30)}</Text>
          <Text color={'gray.600'} fontSize={'sm'}>{variation.description?.truncate(90)}</Text>
          <Button py={2} w={'full'} bg={'black'} color={'white'} borderRadius={'2xl'} textAlign={'center'} fontSize={'md'} fontWeight={600}>
            {googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}
          </Button>
        </VStack>
      </VStack>
    </>
  )
};

export default Display2;