import { setCurrentUser } from "../slices/usersSlice";
import { baseApi } from "./base/base";

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: (params) => ({ url: '/user', params }),
      providesTags: ['User'],
      transformResponse: (response, meta, arg) => {
        if (arg.extra && arg.extra.dispatch) {
          arg.extra.dispatch(setCurrentUser(response.user));
        }
        return response;
      }
    }),
    getUsers: builder.query({
      query: (data) => ({ url: '/users', params: data }),
      providesTags: ['User']
    }),
    createUser: builder.mutation({
      query: (data) => ({ url: `/users`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation({
      query: (data) => ({ url: `/user`, method: 'PATCH', body: data }),
      invalidatesTags: ['User']
    }),
    confirmUser: builder.mutation({
      query: (data) => ({ url: `/users/confirm`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    resendConfirmation: builder.mutation({
      query: (data) => ({ url: `/users/resend`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    inviteUser: builder.mutation({
      query: (data) => ({ url: `/users/invite`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    updateTeamUser: builder.mutation({
      query: (data) => ({ url: `/users/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['User']
    }),
    deleteUser: builder.mutation({
      query: (data) => ({ url: `/users/${data.id}`, method: 'DELETE' }),
      invalidatesTags: ['User']
    }),
    resetPassword: builder.mutation({
      query: (data) => ({ url: `/users/reset_password`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    savePassword: builder.mutation({
      query: (data) => ({ url: `/users/save_password`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
  }),
  overrideExisting: false
});


export const { useUpdateTeamUserMutation, useResetPasswordMutation, useSavePasswordMutation, useInviteUserMutation, useGetUsersQuery, useCreateUserMutation, useGetCurrentUserQuery, useUpdateUserMutation, useConfirmUserMutation, useResendConfirmationMutation, useDeleteUserMutation } = usersApi;
