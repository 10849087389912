import { VStack, Flex, Heading, Box, Text, TableContainer, Checkbox, Table, Tbody, Th, Thead, Tr, Td, HStack, Circle, Button, Icon, Input, IconButton, Menu, MenuButton, MenuItem, MenuList, Divider, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDeleteUserMutation, useGetUsersQuery } from "../../api/services/users";
import Paginator from "../../components/Common/Pagination/Paginator";
import { FiEdit, FiMenu, FiMoreHorizontal, FiTrash, FiTrash2, FiUserPlus, FiCheckCircle, FiStopCircle } from "react-icons/fi";
import { useUpdateTeamUserMutation } from "../../api/services/users";
import Invite from "./Invite";
import Edit from "./Edit";
import { useSelector } from "react-redux";

const TeamMembers = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const isOwner = currentUser.organizationRole == 'owner';

  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');

  const editDisclosure = useDisclosure();
  const [editUser, setEditUser] = useState(null);

  const [updateUserStatus, { isSuccess }] = useUpdateTeamUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const toast = useToast();

  const handleStatusChange = (user) => {
    const newStatus = user.status === 'active' ? 'inactive' : 'active';
    updateUserStatus({ id: user.id, status: newStatus });
  };

  const deleteInvitationUser = (user) => {
    deleteUser({ id: user.id });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "User status changed!",
        description: "You successfully updated user status.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

    }
  }, [isSuccess])

  const { data, isLoading } = useGetUsersQuery({ per_page: 20, page: currentPage, query: query }, { refetchOnMountOrArgChange: true });

  return (
    <Box px={10} mt={10}>
      <Edit editDisclosure={editDisclosure} user={editUser} />

      <Box w='100%' >
        <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
          <Flex justifyContent='space-between' alignItems={'center'}>
            <HStack w={'full'} justifyContent={'space-between'}>
              <VStack>
                <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                  Team Members
                </Heading>
                <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
                  Manage your team members here.
                </Text>
              </VStack>

              <HStack>
                <Input maxW={'md'} placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
                <Box>
                  {isOwner && <Invite />}

                </Box>
              </HStack>
            </HStack>
          </Flex>


          <TableContainer mt={4}>
            <Table variant="simple">
              <Thead>
                <Tr >
                  <Th>Full Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  <Th>Status</Th>
                  {isOwner && <Th></Th>}
                </Tr>
              </Thead>
              <Tbody>
                {data?.users?.map((user) => (
                  <Tr key={user.id} _hover={{ bg: 'gray.50' }}>
                    <Td fontWeight={'semibold'}>{user.firstName + ' ' + user.lastName}</Td>
                    <Td>{user.email}</Td>
                    <Td textTransform={'capitalize'}>{user.organizationRole}</Td>
                    <Td textTransform={'capitalize'}>
                      <HStack>
                        {user.status == 'active' && <Circle size={2} bg={'green.500'} />}
                        {user.status == 'pending' && <Circle size={2} bg={'yellow.500'} />}
                        {(user.status == 'inactive' || user.status == 'disabled') && <Circle size={2} bg={'red.500'} />}
                        <Text>{user.status}</Text>
                      </HStack>
                    </Td>
                    {isOwner && <Td>
                      {user.organizationRole != 'owner' && <Menu>
                        <MenuButton
                          as={IconButton}
                          _active={{ bg: 'black', color: 'white' }}
                          _hover={{ bg: 'black', color: 'white' }} variant={'ghost'} icon={<Icon as={FiMoreHorizontal} />} aria-label={''}
                        />
                        <MenuList>
                          <MenuItem onClick={() => { setEditUser(user); editDisclosure.onOpen() }} icon={<Icon as={FiEdit} />}>
                            Edit
                          </MenuItem>
                          {user.status != 'pending' && (
                            <>
                              <Divider my={2} />
                              <MenuItem color={user.status == 'active' ? 'red.500' : 'gray.500'} onClick={() => handleStatusChange(user)} icon={<Icon as={user.status == 'active' ? FiStopCircle : FiCheckCircle} />}>
                                {user.status === 'active' ? 'Deactivate' : 'Activate'}
                              </MenuItem>
                            </>
                          )}
                          {user.status == 'pending' && (
                            <>
                              <Divider my={2} />
                              <MenuItem color={'red.500'} onClick={() => deleteInvitationUser(user)} icon={<Icon as={FiTrash2} />}>
                                Cancel invitation
                              </MenuItem>
                            </>
                          )}
                        </MenuList>
                      </Menu>}
                    </Td>}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />

        </VStack>
      </Box>
    </Box>
  );
};

export default TeamMembers;