import React from 'react';
import { Box, HStack, Icon, IconButton, VStack, Text } from '@chakra-ui/react';


const StatBox = ({ label, amount, amountLabel = '', icon }) => {
  return (
    <Box w={'full'} border={'solid 1px'} borderColor={'purple.100'} p={4} borderRadius={8} overflow="hidden"> 
      <HStack justifyContent={'space-between'} spacing={10}>
        <VStack alignItems={'flex-start'}>
          <Text color={'gray.400'} fontSize={'sm'}>{label}</Text>
          <HStack alignItems={'baseline'}>
            <Text fontSize={'2xl'} color={'purple.500'} fontWeight={'bold'}>{amount}</Text>
            <Text color={'gray.400'} fontSize={'xs'}>{amountLabel}</Text>
          </HStack>
        </VStack>
        <IconButton p={5} borderRadius={'full'} bg={'purple.100'} color={'purple.500'} icon={<Icon as={icon} />} aria-label={''}></IconButton>
      </HStack>
    </Box>
  )
}

export default StatBox;