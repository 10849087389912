import React from 'react';
import { set, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { HStack, VStack, Text, Flex, Box, Heading, Divider, Spacer } from "@chakra-ui/react";
import { Input, FormControl, FormLabel, Button, Alert, AlertIcon, ScaleFade } from '@chakra-ui/react';
import { useUpdateUserMutation } from '../../api/services/users';
import { useToast, useDisclosure } from '@chakra-ui/react';
import UpdatePassword from './UpdatePassword';
import { FiKey } from 'react-icons/fi';


function AccountSettings() {
  const { register, handleSubmit, watch, formState: { errors, isDirty }, reset } = useForm();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [updateUser, { isLoading, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] = useUpdateUserMutation();

  const disclosure = useDisclosure();

  const toast = useToast();
  const onSubmit = data => {
    updateUser(data);
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      toast({
        description: "User data updated successfully! 🚀 Should you need any further assistance or have any questions, feel free to reach out to our support team.",
        status: "success",
      });
    }
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (isErrorUpdate) {
      toast({
        description: "User data can not be updated at this moment. Should you need any further assistance or have any questions, feel free to reach out to our support team.",
        status: "error",
      });
    }
  }, [isSuccessUpdate]);

  return (
    <>
      <Flex mt={10} px={10} flexDir={'column'} gap={2} justifyContent='space-between' alignItems={'center'}>
        <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
          Account Settings
        </Heading>
        <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
          Your personal informations and settings.
        </Text>
      </Flex>

      <Box mt={10} maxW='2xl' px={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} alignItems="left" maxW="100%" bg="white" borderRadius={8}>
            <HStack gap={6} direction={['column', 'row']} justifyContent={'left'}>
              <FormControl>
                <FormLabel fontSize={'sm'}>First Name</FormLabel>
                <Input {...register("firstName", { required: true })} defaultValue={currentUser?.firstName} />
                {errors.firstName && <p>This field is required</p>}
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'sm'}>Last Name</FormLabel>
                <Input {...register("lastName", { required: true })} defaultValue={currentUser?.lastName} />
                {errors.lastName && <p>This field is required</p>}
              </FormControl>
            </HStack>

            <FormControl id="email">
              <FormLabel fontSize={'sm'}>Email</FormLabel>
              <Input value={currentUser?.email} bg={'gray.100'} color={'gray.800'} isReadOnly isDisabled />
            </FormControl>

            <FormControl id="password">
              <FormLabel fontSize={'sm'}>Password</FormLabel>

              <HStack gap={6} justifyContent={'space-between'}>
                <Text fontSize="xs" color="gray.500">In order to enhance account security, please note that if you wish to change your password, you will be required to provide your old password for verification purposes.</Text>
                <Box>
                  <Button size={'sm'} leftIcon={<FiKey />} onClick={disclosure.onOpen}>Change Password</Button>
                </Box>
              </HStack>
            </FormControl>

            <FormControl id="role">
              <FormLabel fontSize={'sm'}>Role</FormLabel>
              <Input textTransform={'capitalize'} value={currentUser?.organizationRole} bg={'gray.100'} color={'gray.800'} isReadOnly isDisabled />
            </FormControl>

            <HStack mt={4} gap={6} justifyContent={'left'}>
              <Button colorScheme='primary' isDisabled={!isDirty} mr={3} type="submit">Save</Button>
            </HStack>

          </VStack>
        </form>

        <Spacer h={'100px'} />

        <UpdatePassword disclosure={disclosure} />
      </Box>
    </>
  );
}

export default AccountSettings;
