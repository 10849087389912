import { baseApi } from "./base/base";

export const integrationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrations: builder.query({
      query: (data) => ({ url: '/integrations', params: data }),
      providesTags: ['Integration']
    }),
    deleteIntegration: builder.mutation({
      query: (id) => ({ url: `/integrations/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Integration']
    }),

    /** Google related mutations */
    getGoogleAdAccounts: builder.query({
      query: (id) => ({ url: `/integrations/${id}/google/ad_accounts` }),
      providesTags: ['GoogleAdAccount']
    }),
    getGoogleCampaigns: builder.query({
      query: (data) => ({ url: `/integrations/${data?.id}/google/campaigns`, params: data }),
      providesTags: ['GoogleCampaign']
    }),
    importGoogleCampaign: builder.mutation({
      query: (data) => ({ url: `/integrations/${data?.id}/google/import`, method: 'POST', body: data }),
      invalidatesTags: ['GoogleAdAccount', 'GoogleCampaign', 'Campaign', 'Folder']
    }),

    /** Facebook related mutations */
    getFacebookAdAccounts: builder.query({
      query: (id) => ({ url: `/integrations/${id}/facebook/ad_accounts` }),
      providesTags: ['FacebookAdAccount']
    }),
    getFacebookCampaigns: builder.query({
      query: (data) => ({ url: `/integrations/${data?.id}/facebook/campaigns`, params: data }),
      providesTags: ['FacebookCampaign']
    }),
    importFacebookCampaign: builder.mutation({
      query: (data) => ({ url: `/integrations/${data?.id}/facebook/import`, method: 'POST', body: data }),
      invalidatesTags: ['FacebookAdAccount', 'FacebookCampaign', 'Campaign', 'Folder']
    }),

    /** Tiktok Related mutations */
    getTiktokAdAccounts: builder.query({
      query: (id) => ({ url: `/integrations/${id}/tiktok/ad_accounts` }),
      providesTags: ['TiktokAdAccount']
    }),
    getTiktokCampaigns: builder.query({
      query: (data) => ({ url: `/integrations/${data?.id}/tiktok/campaigns`, params: data }),
      providesTags: ['TiktokCampaign']
    }),
    importTiktokCampaign: builder.mutation({
      query: (data) => ({ url: `/integrations/${data?.id}/tiktok/import`, method: 'POST', body: data }),
      invalidatesTags: ['TiktokAdAccount', 'TiktokCampaign', 'Campaign', 'Folder']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetIntegrationsQuery, useDeleteIntegrationMutation, useGetGoogleAdAccountsQuery, useGetGoogleCampaignsQuery, useImportGoogleCampaignMutation, useGetFacebookAdAccountsQuery, useGetFacebookCampaignsQuery, useImportFacebookCampaignMutation,
  useGetTiktokAdAccountsQuery, useGetTiktokCampaignsQuery, useImportTiktokCampaignMutation
} = integrationsApi;
