import { baseApi } from "./base/base";

export const commentsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getComments: builder.query({
            query: (data) => ({ url: '/comments', params: data }),
            providesTags: ['Comment']
        }),
        createComment: builder.mutation({
            query: (data) => ({ url: `/comments`, method: 'POST', body: data }),
            invalidatesTags: ['Campaign', 'Comment']
        }),
        updateComment: builder.mutation({
            query: (data) => ({ url: `/comments/${data.id}`, method: 'PATCH', body: data }),
            invalidatesTags: ['Comment', 'Campaign']
        }),
    }),

    overrideExisting: false
});

export const {
    useGetCommentsQuery, useCreateCommentMutation, useUpdateCommentMutation
} = commentsApi;
