import React, { useState, useEffect } from 'react';
import { Box, Text, Divider, Button, Flex, Input, useToast, Icon, HStack, Spinner, Center } from '@chakra-ui/react';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from './../../api/services/organizations'
import { FiArrowRight } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

const BusinessInformation = ({ goToStep }) => {
    const { data, isFetching } = useGetOrganizationQuery({}, { refetchOnMountOrArgChange: true });
    const organization = data?.organization;

    const { register, handleSubmit, reset } = useForm();
    const [update, { isSuccess, isLoading }] = useUpdateOrganizationMutation();

    const onSubmit = (data) => {
        update({ displayName: data.name, state: 'payment_details' });
    };

    useEffect(() => {
        if (isSuccess) {
            goToStep(1);
        }
    }, [isSuccess]);

    useEffect(() => {
        reset();
    }, [])

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            px={4}
        >
            <Box width="100%" textAlign="center" mb={8}>
                <Text fontSize="4xl" mb={2}>👋</Text>
                <Text fontSize="xl" fontWeight="bold" mb={2}>Welcome to WhatCampaign!</Text>
                <Text fontSize="sm" color="gray.600">
                    Let's get started by setting up your business information.
                </Text>
            </Box>
            <Box width="100%" maxWidth="md" bg="white" p={8}>
                {isFetching && <Center><Spinner /></Center>}
                {!isFetching && <form onSubmit={handleSubmit(onSubmit)}>
                    <Text mb={4} fontSize="sm" fontWeight="medium">
                        What's the name of your business?
                    </Text>
                    <HStack>
                        <Input placeholder="Enter your business name" {...register('name', { required: true })} defaultValue={organization?.displayName} />
                        <Box>
                            <Button type={'submit'} isLoading={isLoading} h={'auto'} leftIcon={<Icon as={FiArrowRight} />} fontSize={'sm'}>Next</Button>
                        </Box>
                    </HStack>
                </form>}
            </Box>
        </Flex>
    );
};

export default BusinessInformation;