import React from 'react';
import { FormControl, Select } from '@chakra-ui/react'




const Dropdown = ({ values, defaultValue, onSubmit, readOnly }) => {

  return (<>
    {readOnly && <p>{values.find(value => value.name === defaultValue)?.label}</p>}
    {!readOnly &&
      <FormControl w={'max-content'}>
        <Select onChange={(e) => onSubmit(e.target.value)}  >
          <option value="select_an_option" disabled={true} selected>
            Select an option&nbsp;&nbsp;&nbsp;
          </option>

          {values.map((item) => {
            const isSelected = item.name == defaultValue;

            return (
              <>
                {isSelected && <option value={item.name} selected>{item.label}</option>}
                {!isSelected && <option value={item.name}>{item.label}</option>}
              </>
            )
          })}
        </Select>
      </FormControl>}
  </>)
};

export default Dropdown;