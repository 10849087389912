import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import TiktokSingleImageVideo from "../../Preview/Tiktok/SingleImageVideo";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import SingleImageVideo from "./SingleImageVideo";

const Editor = ({ isLoading, onFormSubmit, ad }) => {
  const { register, watch, getValues, setValue, handleSubmit } = useForm({
    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
  });

  const asset = ad?.metadata?.asset;
  const assetsState = React.useState(asset == null ? [] : [asset]);

  const tiktokLogoAsset = ad?.metadata?.tiktokLogo;
  const tiktokLogoState = React.useState(tiktokLogoAsset == null ? [] : [tiktokLogoAsset])
  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <SingleImageVideo getValues={getValues} setValue={setValue} ad={ad} isLoading={isLoading} handleSubmit={handleSubmit} onFormSubmit={onFormSubmit} register={register} watch={watch} assetsState={assetsState} tiktokLogoState={tiktokLogoState} />
        <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'125px'}>
          <VStack spacing={10}>
            <TiktokSingleImageVideo ad={{
              description: getValues('description'),
              websiteUrl: getValues('website_url'),
              callToAction: getValues('call_to_action'),
              asset: assetsState[0][0],
              tiktokAccountName: getValues('tiktok_account_name'),
              tiktokLogo: tiktokLogoState[0][0],
            }} />
          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default Editor;