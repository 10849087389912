import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Input, Box } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreVertical, FiSearch, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import GoogleLogo from "../../../../../../assets/icons/pmax/google_logo.png";

const DiscoverAd = ({ variation }) => {

  return (
    <>
      <Phone>

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={2} />

        <HStack px={3} mt={2}>
          <Avatar size={'sm'} name={' '} bg={'gray.400'} />
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={1} />
        </HStack>

        <VStack mx={3} alignItems={'flex-start'} borderRadius={'lg'} mt={5} boxShadow={'md'} p={0}>
          <Image rounded={'md'} w={'full'} src={variation.imageUrl} />

          <Text fontSize={'sm'} px={4} mt={5}>
            {variation.headline?.truncate(30)}. {variation.description?.truncate(90)}
          </Text>

          <HStack mb={4} px={4}>
            <Text fontSize={'x-small'} fontWeight={'bold'}>Ad • </Text>
            <Text fontSize={'x-small'}>{variation.businessName}</Text>
          </HStack>
        </VStack>

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={3} />
      </Phone>
    </>
  )
};

export default DiscoverAd;