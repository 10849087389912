import { Card, Text, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, AvatarBadge, Button, Spacer, Flex, IconButton, AspectRatio } from "@chakra-ui/react";
import React from "react";
import { FiGlobe, FiMoreHorizontal, FiThumbsUp, FiMessageCircle, FiShare, FiHeart, FiChevronRight, FiBookmark } from "react-icons/fi";
import MetaBlankImage from '../../../../assets/images/meta_empty_image.png';
import { metaCallToActions } from "../../../../components/Constants/meta_call_to_actions";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CustomerAcquisitionPreview from "../Facebook/InstantExperience/CustomerAcquisitionPreview";
import StorytellingPreview from "../Facebook/InstantExperience/StorytellingPreview";
import StorefrontPreview from "../Facebook/InstantExperience/StorefrontPreview";
import { IoPaperPlaneOutline } from "react-icons/io5";
import SellProductsPreview from "../Facebook/InstantExperience/SellProductsPreview";

const Collection = ({ ad, instantExperienceData }) => {
  const [instantExperienceOpened, setInstantExperienceOpened] = React.useState(false);

  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setInstantExperienceOpened(false);
      }
    };

    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const setInstantExperienceOpenedIfPossible = (value) => {
    if (instantExperienceData) {
      setInstantExperienceOpened(value);
    }
  }

  return (
    <Card maxW={'sm'} borderRadius={0}>
      {!instantExperienceOpened &&
        <>
          <CardHeader zIndex={100} mb="4">
            <HStack justifyContent={'space-between'}>
              <HStack spacing={5}>
                <Avatar bg={'gray.300'} src={ad.instagramLogo?.fileUrl} icon={<></>} name='' />
                <VStack alignItems={'flex-start'}>
                  <Text fontSize={'sm'}>{ad.instagramAccountName || 'Account Name'}</Text>
                  <HStack color={'gray'} fontWeight={400} fontSize={'sm'}>
                    <Text>Sponsored</Text>
                    <Text>·</Text>
                    <Icon as={FiGlobe} />
                  </HStack>
                </VStack>
              </HStack>
              <Icon as={FiMoreHorizontal} />
            </HStack>
          </CardHeader>

          <CardBody fontSize={'sm'} p={0} zIndex={100}>

            <Flex direction={'column'} justifyContent={'flex-end'} height={'100%'}>
              <HStack>
                {ad.asset == null && <Image w={'full'} src={MetaBlankImage} />}

                {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
                  <Image w={'full'} src={ad.asset?.fileUrl} />
                </Box>}
                {ad.asset?.assetType == 'video' && !/youtube/.test(ad.asset?.fileUrl) &&
                  <Box w={'full'} h={'full'} maxH={'400px'} overflow={'hidden'}>
                    <video key={ad.asset?.uuid} muted playsInline width={'100%'} height="100%" loop autoPlay style={{ objectFit: 'cover' }}>
                      <source src={ad.asset?.fileUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                }

                {ad.asset?.assetType == 'video' && /youtube/.test(ad.asset?.fileUrl) &&
                  <Box w={'full'} h={'full'} maxH={'400px'} overflow={'hidden'}>
                    <iframe width="100%" height="100%" src={`${ad.asset?.fileUrl.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ objectFit: 'cover', pointerEvents: 'auto' }}></iframe>
                  </Box>
                }
              </HStack>
              <HStack gap={2}>
                {ad?.metaAdCollectionItems?.map((item, index) => {
                  const transformedItem = transformKeysTo('snake', structuredClone(item));
                  return (
                    <Card w="33%" h="100%" p={0} gap={0}>
                      <AspectRatio ratio={1} w="100%" h="100%">
                        <>
                          {transformedItem.asset == null && <Image w={'100%'} />}
                          {transformedItem.asset?.asset_type == 'image' &&
                            <Box position="relative">
                              <Image w={'100%'} h={'100%'} objectFit={'cover'} src={transformedItem?.asset?.file_url} />
                              {index === 2 && ad.callToAction != '' &&
                                <Box position="absolute" top="0" left="0" right="0" bottom="0" display="flex" alignItems="center" justifyContent="center" backgroundColor="rgba(0,0,0,0.5)" color="white" opacity="0" _hover={{ opacity: "1" }}>
                                  <Text onClick={() => setInstantExperienceOpenedIfPossible(true)} fontWeight={'bold'} cursor={'pointer'}>
                                    {metaCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}
                                  </Text>
                                </Box>
                              }
                            </Box>
                          }
                          {transformedItem.asset?.asset_type == 'video' && !transformedItem.asset?.file_url.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                            <video key={transformedItem?.asset?.uuid} controls muted width={'100%'} height="auto" loop>
                              <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </Box>}
                          {transformedItem.asset?.asset_type == 'video' && transformedItem.asset?.file_url.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                            <iframe width="100%"
                              height="315"
                              src={transformedItem?.asset?.file_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                          </Box>}
                        </>
                      </AspectRatio>
                    </Card>
                  )
                })}
              </HStack>

              <HStack justifyContent={'space-between'} p={4} fontSize={'xl'}>
                <HStack spacing={5}>
                  <Icon as={FiHeart} />
                  <Icon as={FiMessageCircle} />
                  <Icon as={IoPaperPlaneOutline} />
                </HStack>
                <Icon as={FiBookmark} />
              </HStack>

              <Text whiteSpace={'pre-wrap'} p={4}>
                {ad.primaryText || 'Post intro content...'}
              </Text>
            </Flex>
          </CardBody>
        </>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'customer_acquisition' &&
        <VStack spacing={5}>
          <CustomerAcquisitionPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            description: instantExperienceData?.fields_data?.description,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            buttonLabel_2: instantExperienceData?.fields_data?.button_label_2,
            buttonDestination_2: instantExperienceData?.fields_data?.button_destination_2,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset: instantExperienceData?.fields_data?.instant_experience_asset
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}

          />
        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'storytelling' &&
        <VStack spacing={5}>
          <StorytellingPreview instantExperienceData={{
            headline: instantExperienceData?.fields_data?.headline,
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            carouselText: instantExperienceData?.fields_data?.carousel_text,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset_1: instantExperienceData?.fields_data?.instant_experience_asset_1,
            destination_url_1: instantExperienceData?.fields_data?.destination_url_1,
            instantExperienceAsset_2: instantExperienceData?.fields_data?.instant_experience_asset_2,
            destination_url_2: instantExperienceData?.fields_data?.destination_url_2,
            instantExperienceAsset_3: instantExperienceData?.fields_data?.instant_experience_asset_3,
            destination_url_3: instantExperienceData?.fields_data?.destination_url_3
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}
          />
        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'storefront' &&
        <VStack spacing={5}>
          <StorefrontPreview instantExperienceData={{
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            metaInstantExperienceProductItems: instantExperienceData?.fields_data?.meta_instant_experience_product_items,
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}
          />
        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'sell_products' &&
        <VStack spacing={5}>
          <SellProductsPreview instantExperienceData={{
            buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
            buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
            buttonLabel_2: instantExperienceData?.fields_data?.button_label_2,
            buttonDestination_2: instantExperienceData?.fields_data?.button_destination_2,
            description_1: instantExperienceData?.fields_data?.description_1,
            description_2: instantExperienceData?.fields_data?.description_2,
            metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
            instantExperienceAsset: instantExperienceData?.fields_data?.instant_experience_asset
          }}
            setInstantExperienceOpenedIfPossible={setInstantExperienceOpenedIfPossible}
          />
        </VStack>
      }
    </Card >
  )
};

export default Collection;