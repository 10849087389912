import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, Text, Input, VStack, HStack, Button, Stack, Icon, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import { useGetCampaignsQuery } from '../../api/services/campaigns';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSession } from '../../helpers/sessionHelper';
import Create from './Create';
import { FiPlus } from 'react-icons/fi';
import { useGetWorkspacesQuery } from '../../api/services/workspaces';
import { formatDate } from '../../helpers/dateHelper';
import { useNavigate } from 'react-router-dom';

const Workspaces = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const createWorkspaceDisclosure = useDisclosure();

  const { data } = useGetWorkspacesQuery({ query: query }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dialog = urlParams.get('dialog');
    if (dialog === 'create') {
      createWorkspaceDisclosure.onOpen();

      const url = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, url);
    }
  }, [])

  return (
    <Box px={10} mt={10}>
      <Box w='100%'>
        <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
          <Flex justifyContent='space-between' alignItems={'center'}>
            <VStack w={'full'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                Workspaces
              </Heading>
              <Text fontSize={'sm'} w={'full'} color={'gray'} mb={4} pb={0}>
                Manage your workspaces here.
              </Text>
            </VStack>


            <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
              <Input onChange={(e) => setQuery(e.target.value)} placeholder='Search workspaces' />
              <Box>
                <Create disclosure={createWorkspaceDisclosure} />
                {currentUser?.organizationRole == 'owner' && <Button onClick={createWorkspaceDisclosure.onOpen} leftIcon={<Icon as={FiPlus} />} variant='outline'>Add workspace</Button>}

              </Box>
            </Stack>
          </Flex>

          <Stack mt={6} flexDir={'row'} flexWrap={'wrap'} justifyContent={'flex-start'} gap={8}>
            {data?.workspaces?.map((workspace) => {
              return (
                <Box w={'xs'} rounded={'md'} p={6} boxShadow={'lg'} border={'solid 1px'} borderColor={'gray.300'}>
                  <Text fontSize={'2xl'}>{workspace.name}</Text>
                  <HStack mt={4} gap={7} justifyContent={'space-between'}>
                    <HStack>
                      <Text fontSize={'2xs'} color={'gray.500'} fontWeight={'medium'}>Last Modified Date</Text>
                      <Text fontSize={'2xs'} color={'gray.500'} fontWeight={'normal'}>{formatDate(workspace.updatedAt)}</Text>
                    </HStack>

                    <HStack>
                      {workspace.active && <>
                        <Box rounded={'full'} bg={'green.400'} w={'3'} h={'3'} />
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'medium'}>Active</Text>
                      </>}

                      {!workspace.active && <>
                        <Box rounded={'full'} bg={'red.400'} w={'3'} h={'3'} />
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'medium'}>Inactive</Text>
                      </>}
                    </HStack>
                  </HStack>
                </Box>
              )
            })}
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
};

export default Workspaces;
