import React, { useEffect, useState } from "react";
import { Button, Text, useDisclosure, useToast, Avatar, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, Icon, HStack, Radio, VStack, InputGroup, Input, InputLeftAddon, InputRightAddon, useClipboard, Flex, Box, Spacer, Divider, InputLeftElement, IconButton, Collapse, FormControl, FormLabel, InputRightElement, Checkbox, Tag, Stack, Skeleton, Tooltip } from "@chakra-ui/react";
import { useShareCampaignMutation, useUpdateCampaignMutation } from './../../api/services/campaigns'
import { set, useForm } from "react-hook-form";
import { FiAlertTriangle, FiChevronDown, FiChevronUp, FiGlobe, FiLink, FiMail, FiSearch, FiTrash2 } from "react-icons/fi";
import { PiGlobeHemisphereEastLight, PiUserCircle, PiUserCircleLight, PiUserCircleThin } from "react-icons/pi";
import { HiOutlineUserCircle } from "react-icons/hi";
import { register } from "timeago.js";
import PlatformIcon from "./Components/PlatformIcon";
import { campaignPlatforms } from "../../components/Constants/campaigns";
import { useCreateClientMutation, useGetClientsQuery, useDeleteClientMutation } from "../../api/services/clients";
import Paginator from "../../components/Common/Pagination/Paginator";

const Share = ({ disclosure, folder }) => {
  const { register, handleSubmit, reset } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [campaigns, setCampaigns] = useState([]);
  const campaignsDisclosure = useDisclosure();
  const { onCopy, value, setValue, hasCopied } = useClipboard(folder?.shareUrl)

  const { data, isLoading: isLoadingClients, isFetching, isSuccess } = useGetClientsQuery({ per_page: 7, page: currentPage, folder_id: folder?.id, }, { pollingInterval: 10000, refetchOnMountOrArgChange: true });
  const [updateCampaign, { isLoading }] = useUpdateCampaignMutation();
  const [createClient, { isSuccess: isSuccessCreateClient, isLoading: isLoadingCreateClient }] = useCreateClientMutation();
  const [removeClient, { isLoading: isLoadingRemoveClient }] = useDeleteClientMutation();

  const onCreateClient = (data) => {
    createClient({ ...data, folder_id: folder.id });
  };

  const findUuid = (id) => {
    return folder?.campaigns?.find((i) => i.id == id)?.uuid;
  }

  const handleShareCampaign = (campaignId) => {
    if (campaigns.includes(campaignId)) {
      setCampaigns(campaigns.filter((i) => i != campaignId));

      updateCampaign({ uuid: findUuid(campaignId), isShared: false })
      return;
    }

    setCampaigns([...campaigns, campaignId]);
    updateCampaign({ uuid: findUuid(campaignId), isShared: true })
  };

  useEffect(() => {
    setCampaigns(folder?.campaigns?.filter((i) => i.isShared)?.map((i) => i.id) || []);
  }, [folder])

  useEffect(() => {
    if (isSuccessCreateClient) {
      reset();
    }
  }, [isSuccessCreateClient])

  return (
    <>
      <Drawer
        size={'sm'}
        isOpen={disclosure.isOpen}
        placement='right'
        onClose={disclosure.onClose}>

        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Share Campaign</DrawerHeader>

          <DrawerBody>
            <Text fontSize={'sm'} color={'gray.500'}>Invite your client to review and collaborate on this project.</Text>

            <Divider my={5} />

            <Text fontWeight={'semibold'} fontSize={'sm'} mb={2}>Campaigns to be shared.</Text>

            <FormControl mt={5}>
              <InputGroup alignItems={'center'} cursor={'pointer'} onClick={campaignsDisclosure.onToggle}>
                <Input _focus={{ borderColor: 'gray.400' }} cursor={'pointer'} type='text' readOnly placeholder={campaigns?.length > 0 ? `${campaigns?.length} campaigns selected.` : 'Select campaigns to share...'} />
                <InputRightElement p={5}>
                  {campaignsDisclosure.isOpen ? <FiChevronUp /> : <FiChevronDown />}
                </InputRightElement>
              </InputGroup>
            </FormControl>


            <Box display={campaignsDisclosure.isOpen ? 'block' : 'none'} zIndex={1000} position={'absolute'} w={'90%'} bg={'white'} my={5} overflow={'visible'} maxH={'lg'} overflowY={'scroll'} borderRadius={'md'} border={'solid 1px'} borderColor={'gray.300'} boxShadow={'lg'}>
              {folder?.campaigns?.map((item, index) => {
                const isChecked = campaigns?.some((campaign) => campaign == item.id);
                return (
                  <HStack bg={isChecked ? 'purple.50' : 'white'} justifyContent={'space-between'} key={index} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleShareCampaign(item.id) }} cursor={'pointer'} color={'gray.600'} borderBottom={index == 8 ? '' : 'solid 1px'} borderColor={'gray.200'} gap={3} p={4}>
                    <HStack>
                      <PlatformIcon w={4} simplified platform={item.platform} />
                      <Text fontSize={'sm'}>{item.name}</Text>
                      {item.platform.includes('google') && <Tag fontSize={'x-small'} size={'sm'}>
                        {campaignPlatforms.find((i) => i.value == item.platform)?.label?.replace('Google ', '')}
                      </Tag>}

                      {item.platform.includes('meta_instant_experience') && <Tag fontSize={'x-small'} size={'sm'}>
                        {campaignPlatforms.find((i) => i.value == item.platform)?.label}
                      </Tag>}
                    </HStack>
                    <Checkbox colorScheme={'purple'} isChecked={isChecked}></Checkbox>

                  </HStack>
                )
              })}
            </Box>

            <Divider my={5} />

            <Text fontWeight={'semibold'} fontSize={'sm'} mb={2}>Private access</Text>
            <Text fontSize={'xs'} mb={3} color={'gray.500'}>People with private access can view and comment the content shared with them.</Text>

            <form onSubmit={handleSubmit(onCreateClient)}>
              <HStack mt={5}>
                <InputGroup>
                  <InputLeftElement w={12}>
                    <Icon color={'gray.500'} as={FiMail} />
                  </InputLeftElement>
                  <Input isDisabled={isLoadingCreateClient} {...register('email', { required: true })} pl={12} type='email' placeholder='Enter e-mail address' />
                </InputGroup>
                <Box h={'full'}>
                  <Tooltip isDisabled={campaigns.length > 0} hasArrow placement={'left'} label={'Please select campaigns to share before inviting.'}>
                    <Button isLoading={isLoadingCreateClient} isDisabled={isLoadingCreateClient || campaigns.length == 0} type={'submit'} px={5} fontSize={'sm'} colorScheme='purple'>Invite</Button>
                  </Tooltip>
                </Box>
              </HStack>
            </form>


            <Spacer h={'20px'} />

            {data?.clients?.map((client) => {
              return (
                <>
                  <HStack justifyContent={'space-between'}>
                    <HStack pl={3} spacing={3}>
                      <Avatar size={'xs'} name={client.email} />
                      <Text fontSize={'sm'}>{client.email}</Text>
                    </HStack>
                    <IconButton onClick={() => removeClient({ id: client.id, folder_id: folder.id })} p={3} cursor={'pointer'} borderColor={'white'} color={'black'} variant={'outline'} icon={<Icon fontSize={'sm'} as={FiTrash2} />} aria-label={""} />
                  </HStack>
                  <Divider my={2} />
                </>
              )
            })}

            <Spacer h={'20px'} />

            {isSuccess && data?.clients?.length > 0 && data?.meta?.pagination?.perPage < data?.meta?.pagination?.totalAmount && (<Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />)}


            <Divider my={10} />

            <HStack spacing={5}>
              <Box bg={'#E9E5FD'} borderRadius={'7px'} px={5} py={4}>
                <Icon fontSize={'x-large'} as={PiGlobeHemisphereEastLight} />
              </Box>
              <VStack alignItems={'flex-start'}>
                <Text fontSize={'sm'} fontWeight={'semibold'}>Share a public link</Text>
                <Text fontSize={'xs'} color={'gray.500'}>
                  Anyone with the link can view the campaign but cannot edit content.
                </Text>
              </VStack>
            </HStack>

            {campaigns.length > 0 && <HStack>
              <Input readOnly value={value} colorScheme='purple' borderRadius={'7px'} size={'sm'} mt={5} />
              <Button onClick={onCopy} mt={5} size={'sm'} variant={'outline'} colorScheme={'purple'} borderColor={'purple.500'} px={7} leftIcon={<Icon as={FiLink} />}>{hasCopied ? 'Copied!' : 'Copy link'}</Button>
            </HStack>}

            {campaigns.length == 0 && <>
              <HStack mt={5}>
                <Icon fontSize={'xs'} as={FiAlertTriangle} color={'gray.500'} />
                <Text fontSize={'xs'} color={'gray.500'} >Please select campaigns to share before generating public link.</Text>
              </HStack>
            </>}

            <Spacer h={'100px'} />
          </DrawerBody>
        </DrawerContent>
      </Drawer >
    </>
  )
};

export default Share;