
import { Center, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FcHome } from 'react-icons/fc';
import Wave from '../../assets/icons/wave.png'


const Home = () => {
  return (
    <div>
      <Center>
        <VStack mt={10} spacing={10}>
          <Image src={Wave} w={'24px'} />
          <Text fontSize={'sm'}>Welcome to the home page of your client portal.</Text>

        </VStack>
      </Center>
    </div>
  )
}

export default Home;