import { Image, Button, Icon, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, InputGroup, Avatar, Textarea, FormErrorMessage, Stack, FormHelperText, useDisclosure } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { FiHelpCircle, FiImage, FiVideo } from "react-icons/fi";
import { linkedinCallToActions } from "../../../../components/Constants/linkedin_call_to_actions";
import { useNavigate, useParams } from "react-router-dom";
import EditorContext from "../../../../contexts/EditorContext";
import AssetsLibraryButton from "../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import Suggestions from "../../../Suggestions/Suggestions";
import { HiOutlineChip } from "react-icons/hi";
import SuggestionsButton from "../../../Suggestions/SuggestionsButton";
import { validateUrl } from "../../../../components/utils/urlUtils";

const CommonEditor = ({ ad, onFormSubmit, isLoading, register, watch, handleSubmit, getValues, setValue, assetsState, linkedinLogoState, inputErrors }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { errors } = useContext(EditorContext);

  const [assets, setAssets] = assetsState;
  const [linkedinLogo, setlinkedinLogo] = linkedinLogoState;

  const wAll = watch();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('asset_id', assets[0]?.id);
    formData.append('linkedin_logo_asset_id', linkedinLogo[0]?.id);

    Object.keys(data).forEach((key) => {
      if (data[key] == null)
        return;

      formData.append(key, data[key]);
    });
    onFormSubmit(formData);
  }

  /** AI Suggestions */

  const updateHeadline = (headlines) => {
    setValue('headline', headlines[0]);
  };

  const updateDescription = (descriptions) => {
    setValue('description', descriptions[0]);
  };


  return (
    <>
      <form style={{ width: '100%' }} mode="onBlur" onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} >

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Identity</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
            </CardHeader>
            <CardBody>
              <FormControl isInvalid={errors?.linkedin_page_name}>
                <FormLabel>Linkedin Page</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={linkedinLogo} setAssets={setlinkedinLogo} type={'image'} rounded />

                  <Input w={'full'} {...register('linkedin_page_name')} placeholder={'Page name'} />
                </HStack>
                <FormErrorMessage justifyContent={'flex-end'}>{errors?.linkedin_page_name}</FormErrorMessage>
              </FormControl>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Media</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>
              <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

              <HStack>
                <AssetsLibraryButton assets={assets} setAssets={setAssets} types={['all']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={assets} setAssets={setAssets} />
              </Stack>
            </CardBody>
          </Card>


          <Card w={'full'}>
            <CardHeader>
              <HStack justifyContent={'space-between'}>
                <HStack>
                  <Text>Content</Text>
                  <Icon as={FiHelpCircle} />
                </HStack>

                <SuggestionsButton updateHeadline={updateHeadline} updateDescription={updateDescription} />
              </HStack>
            </CardHeader>
            <CardBody>

              <VStack spacing={5}>
                <FormControl>
                  <FormLabel>Introductory Text</FormLabel>
                  <Textarea {...register('introductory_text')} placeholder={'Add introductory text here...'} />
                  <FormHelperText color={(getValues('introductory_text')?.length || 0) > 150 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('introductory_text')?.length || 0} / 150</FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Headline</FormLabel>
                  <Input {...register('headline')} placeholder={'Add headline here...'} />
                  <FormHelperText color={(getValues('headline')?.length || 0) > 70 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('headline')?.length || 0} / 70</FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Input {...register('description')} placeholder={'Add description here...'} />
                  <Text fontSize={'xs'} color={'gray.400'}>Only required if using LinkedIn Audience Network (LAN).</Text>
                  <FormHelperText color={(getValues('description')?.length || 0) > 70 ? 'red' : '#96999F'} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('description')?.length || 0} / 70</FormHelperText>
                </FormControl>

                <FormControl isInvalid={inputErrors.destination_url}>
                  <FormLabel>Destination URL</FormLabel>
                  <Input
                    {...register('destination_url', 
                    { pattern: { value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i, } })} 
                    type='text'
                     placeholder='Enter destination URL' 
                     onBlur={(e) => {
                            const validatedUrl = validateUrl(e.target.value);
                            setValue('destination_url', validatedUrl);
                        }}/>
                  <FormHelperText color={(getValues('destination_url')?.length || 0) > 2000 ? 'red' : '#96999F'} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('destination_url')?.length || 0} / 2000</FormHelperText>
                  <FormErrorMessage>{inputErrors.destination_url && 'URLs must have the http:// or https:// prefix.'}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Call To Action</FormLabel>
                  <Select {...register('call_to_action')}>
                    <option value={''}>No Button</option>
                    {linkedinCallToActions.map((cta, index) => (
                      <option value={cta.value} key={index}>{cta.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <HStack w={'full'} justifyContent={'flex-start'}>
            <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
            <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
          </HStack>
        </VStack>
      </form>

    </>
  )
}

export default CommonEditor;