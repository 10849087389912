import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    paddingX: 4,
    paddingY: 2,
    fontSize: 'sm',
    height: 'auto',
    lineHeight: 'auto'
  },
})

const clear = definePartsStyle({
  field: {
    p: 0,
    pr: 5,
    fontSize: 'sm',
    height: 'auto',
    lineHeight: 'auto'
  },
})

export const selectTheme = defineMultiStyleConfig({
  baseStyle, variants: { clear }
})