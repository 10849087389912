import { Button, Icon, Box, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, FormHelperText } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiHelpCircle, FiImage } from "react-icons/fi";
import { useFieldArray } from "react-hook-form";
import { linkedinCallToActions } from "../../../../../components/Constants/linkedin_call_to_actions";
import { useNavigate, useParams } from "react-router-dom";
import Slide from "./Slide";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import flattenObject from "../../../../../helpers/objectHelper";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const CarouselCommonEditor = ({ ad, control, getValues, setValue, onFormSubmit, isLoading, register, handleSubmit, linkedinLogoState }) => {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [openIndex, setOpenIndex] = useState(null);

    const [linkedinLogo, setLinkedinLogo] = linkedinLogoState;

    const handleToggleSlide = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "linkedin_ad_carousel_items"
    });

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(getValues('linkedin_ad_carousel_items'));

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setValue('linkedin_ad_carousel_items', items);
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('linkedin_logo_asset_id', linkedinLogo[0]?.id);

        const flObject = flattenObject(data);
        Object.keys(flObject).forEach((key) => {
            if (flObject[key] == null)
                return;

            const modifiedKey = key.replace(/\[\d+\]/g, '[]');
            formData.append(modifiedKey, flObject[key]);
        });

        onFormSubmit(formData);
    }


    return (
        <>
            <form style={{ width: '100%' }} mode="onBlur" onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={5} >

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Identity</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                            <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
                        </CardHeader>
                        <CardBody>
                            <FormControl>
                                <FormLabel>Linkedin Page</FormLabel>
                                <HStack spacing={5}>
                                    <AssetsLibraryButton assets={linkedinLogo} setAssets={setLinkedinLogo} type={'image'} rounded />

                                    <Input w={'full'} {...register('linkedin_page_name')} placeholder={'Page name'} />
                                </HStack>
                            </FormControl>
                        </CardBody>
                    </Card>

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Media</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="characters">
                                    {(provided) => (
                                        <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                            {fields.map((item, index) => {
                                                return (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided) => (
                                                            <Slide
                                                                isOpen={index === openIndex}
                                                                onToggle={() => handleToggleSlide(index)}
                                                                setValue={setValue} getValues={getValues}
                                                                register={register} index={index}
                                                                provided={provided} item={item}
                                                                remove={() => remove(index)} />
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Box w={'full'} display={'flex'} justifyContent={'flex-end'}>
                                <Button mt={5} right={0} variant={'outline'} onClick={() => append({})} leftIcon={<Icon as={FiImage} />}>Add new slide</Button>
                            </Box>
                        </CardBody>
                    </Card>

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Content</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                        </CardHeader>
                        <CardBody>

                            <VStack spacing={5}>
                                <FormControl>
                                    <FormLabel>Introductory Text</FormLabel>
                                    <Textarea {...register('introductory_text')} placeholder={'Add introductory text here...'} />
                                    <FormHelperText color={(getValues('introductory_text')?.length || 0) > 150 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('introductory_text')?.length || 0} / 150</FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Call To Action</FormLabel>
                                    <Select {...register('call_to_action')}>
                                        <option value={''}>No Button</option>
                                        {linkedinCallToActions.map((cta, index) => (
                                            <option value={cta.value} key={index}>{cta.label}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </VStack>
                        </CardBody>
                    </Card>

                    <HStack w={'full'} justifyContent={'flex-start'}>
                        <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
                        <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
                    </HStack>
                </VStack>
            </form>
        </>
    )
}

export default CarouselCommonEditor;