import React from "react";
import { HStack, Icon, Link } from '@chakra-ui/react';
import { FiChevronRight } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

const BreadcrumbsLink = ({ to, label }) => {
  return <Link color={'gray.600'} bg={'gray.50'} borderRadius={'md'} py={1} px={2} _hover={{ textDecoration: 'none' }} as={RouterLink} fontSize={'xs'} to={to}>{label}</Link>
}

const Breadcrumbs = ({ links }) => {
  return (
    <>
      <HStack mb={5}>
        {links.map((link, index) => {
          return (
            <>
              {index != 0 && <Icon color={'gray.600'} as={FiChevronRight} fontSize={'xs'} />}
              <BreadcrumbsLink to={link.to} label={link.label} />
            </>
          )
        })}
      </HStack >
    </>
  )
};

export default Breadcrumbs;