import React, { useState } from 'react';
import {
 Button,
 Drawer,
 DrawerOverlay,
 DrawerContent,
 DrawerCloseButton,
 DrawerHeader,
 DrawerBody,
 Stack,
 DrawerFooter,
} from '@chakra-ui/react';
import SnippetCard from './SnippetCard';

function Snippets({ isOpen, onClose, initialSnippets, setInitialSnippets }) {
  const newSnippet = { text: '', values: ['', '', ''] }
 const [snippets, setSnippets] = useState(initialSnippets.length > 0 ? [...initialSnippets] : [newSnippet]);
 const [errors, setErrors] = useState([]);

 const handleInputChange = (snippet, index) => {
  const updatedSnips = [...snippets];
  updatedSnips[index] = snippet;
  setSnippets(updatedSnips);
 };

 const handleAddSnippet = () => {
   if (snippets.length < 20) {
     setSnippets([...snippets, newSnippet]);
   }
 };

 const validateSnippets = (snippets) => {
  const errors = [];
  snippets.forEach((snippet, index) => {
    if (snippet.values.some(val => val === '')) {
      errors[index] = 'All values are required';
    }
  });
  return errors;
 }; 

 const handleRemoveSnippet = (index) => {
    const updatedSnips = snippets.filter((_, i) => i !== index);
    setSnippets(updatedSnips);
 };

 const handleSave = () => {
  const newErrors = validateSnippets(snippets);
  setErrors(newErrors);
  if (newErrors.every(err => err === undefined)) {
    setInitialSnippets(snippets);
    onClose();
  }
 }; 

 const handleCancel = () => {
   setSnippets([...initialSnippets]);
   onClose();
 };

 return (
   <>
     <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
       <DrawerOverlay />
       <DrawerContent>
         <DrawerCloseButton />
         <DrawerHeader>Add more snippets</DrawerHeader>
         <DrawerBody>
           <Stack spacing='24px'>
             {snippets.map((snippet, index) => (
               <SnippetCard
                key={index}
                snippet={snippet}
                index={index}
                handleInputChange={handleInputChange}
                handleRemoveSnippet={handleRemoveSnippet}
                error={errors[index]}
                />
             ))}
           </Stack>
           <Button
               colorScheme='gray'
               variant={'outline'}
               mt={3}
               onClick={handleAddSnippet}
               isDisabled={snippets.length >= 20}
             >
               Add snippet
             </Button>
         </DrawerBody>
         <DrawerFooter align="start" borderTopWidth='1px'>
         <Button colorScheme='gray' mr={3} onClick={handleSave}>
             Save
           </Button>
           <Button colorScheme='red' variant='outline' onClick={handleCancel}>
             Cancel
           </Button>
         </DrawerFooter>
       </DrawerContent>
     </Drawer>
   </>
 );
}

export default Snippets;
