import React, { useState, useEffect } from 'react';
import { Box, Icon, Text, Button, VStack, Spinner, HStack } from "@chakra-ui/react";
import { FiTable, FiExternalLink, FiLock, FiPackage } from 'react-icons/fi';
import { Flex } from '@chakra-ui/react';
import { useCreateStripePortalSessionMutation } from '../../api/services/subscriptions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Deactivated = () => {
  const navigate = useNavigate();
  const [customerPortalUrl, setCustomerPortalUrl] = useState('');
  const [createSession, { isLoading, isSuccess }] = useCreateStripePortalSessionMutation();

  const currentUser = useSelector((state) => state.user.currentUser);

  if (currentUser?.organization?.state != 'deactivated') {
    navigate('/billing');
  }

  const handleSubscriptionChange = () => {
    createSession({ flow_data_type: 'subscription_update' }).then((response) => {
      window.open(response?.data?.url, '_blank');
    })
  }

  useEffect(() => {
    createSession().then((response) => {
      setCustomerPortalUrl(response?.data?.url)
    })
  }, []);

  return (
    <Box textAlign="center" fontSize="xl" mt={10}>

      <Icon fontSize={'2rem'} color={'black'} as={FiLock} />

      {currentUser?.organizationRole != 'owner' && <>
        <Text fontSize="lg" fontWeight={'bold'} mt={10}>
          Your organization has been deactivated.
        </Text>
        <Text fontSize="sm" mt={5}>
          Please contact your organization owner to reactivate your account.
        </Text>
      </>}

      {currentUser?.organizationRole == 'owner' && <>
        <Text fontSize="lg" fontWeight={'bold'} mt={10}>
          Your subscription has been canceled, or the payment failed.
        </Text>
        <Text fontSize="sm" mt={5}>
          If this was unintentional or you'd like to reactivate your account, please visit our partner’s Customer Portal.
        </Text>
        <Flex mt={10} justifyContent="center">
          {isLoading && <Spinner />}
          {isSuccess &&
            <HStack>

              <Button h={'auto'} variant={'ai'} p={3} onClick={() => window.open(customerPortalUrl, '_blank')} leftIcon={<Icon as={FiTable} />} rightIcon={<Icon as={FiExternalLink} />} >
                <VStack mx={3} alignItems={'flex-start'} spacing={0}>
                  <Text fontWeight={'bold'}>Customer Portal</Text>
                  <Text fontSize={'x-small'}>Manage billing & payment details.</Text>
                </VStack>
              </Button>
            </HStack>}
        </Flex>
      </>}
    </Box>
  );
};

export default Deactivated;
