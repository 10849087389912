import { Box, Text, HStack, VStack, Icon, Card, CardBody, CardHeader, Divider, Badge, Link, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiGlobe, FiPhoneCall } from "react-icons/fi";
import { FaGlobeAmericas } from "react-icons/fa";
import { formatCall } from "../../../../../helpers/googleSearchHelper";

function getBaseUrl(urlString) {
  try {
    if (!urlString.includes('://')) {
      // If not, assume it's a domain without the protocol, and add 'http'
      urlString = 'http://' + urlString;
    }
    const url = new URL(urlString);
    return url.origin;
  } catch (_) {
    return null;
  }
}

function getRandomString(arr) {
  if (arr === undefined || arr.length == 0) {
    return ''
  }
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex].phoneNumber;
}

function getRandomSnippet(snippets) {
  return snippets[Math.floor(Math.random() * snippets.length)];
}

const SimpleAd = ({ 
    businessName = '',
    baseUrl = 'http://example.com',
    display_path_1 = 'path1',
    display_path_2 = 'path2',
    headlines = ['Example headline 1', 'Example headline 2', 'Example headline 3'],
    descriptions = ['Example description 1', 'Example description 2'],
    calls = [{ country: '+490', phoneNumber: '4564 56' }],
    callouts = [{text: 'Test 5'}, {text: 'Test 4'}],
    sitelinks = [{text: 'Test 1', descriptionLine_1: 'desc 1', descriptionLine_2: 'desc 2', finalUrl: 'http://example.com'}],
    snippets = [{ text: 'Brands', values: ['Nike', 'Adidas', 'Puma'], valid: false }],
    display_type='mobile'
  }) => {
  const realBase = getBaseUrl(baseUrl)
  const call = getRandomString(calls)
  const google_blue="#2163D9"
  const [urlForOutput, setUrlForOutput] = useState('');



  useEffect(() => {
    const realestBase = getBaseUrl(realBase)
    const dp1 = display_path_1 == '' || display_path_1 == null ? '' : '/' + display_path_1
    const dp2 = display_path_2 == '' || display_path_2 == null ? '' : '/' + display_path_2

    setUrlForOutput((realestBase || 'http://example.com') + dp1 + dp2)
  }, [baseUrl, display_path_1, display_path_2]);
  
  return (
    <Card w="full" >
      <CardHeader m={1} p={1}>
        <Text fontSize="14px" fontWeight={700} color="#121723">Sponsored</Text>
        <HStack align="center" mt={"0.5rem"}>
              <Icon as={FaGlobeAmericas} fontSize="24px" color={google_blue} bg={"#E2EEFF"} p={1} borderRadius={"50%"}/> 
              <Text fontWeight={400} fontSize={'sm'} noOfLines={1} 
                  color={"#5B5E60"} whiteSpace="normal" wordBreak="break-all">{urlForOutput}</Text>
        </HStack>
      </CardHeader>
      <CardBody m={1} p={1}>
        <Text whiteSpace={'pre-wrap'} fontWeight={500} fontSize={'lg'} color={"#1558D6"} noOfLines={2} >{headlines.join(' - ')}</Text>
        <Box  whiteSpace={'pre-wrap'} fontWeight={400} fontSize={'sm'} color={'#656972'} >
          {descriptions.join('. ')}
          {' '}
          { snippets && getRandomSnippet(snippets.filter(snippet => snippet.text != '').map(snippet => snippet.text + ": " + snippet.values.join(', ')))}
          {'. '}
          { callouts && callouts.filter(callout => callout.text != '').map(callout => callout.text).join('. ') }
        </Box>
      </CardBody>
      <Box p={4}>
       <HStack>
          {sitelinks && sitelinks.filter(snippet => snippet.text != '').map((sitelink, index, arr) => (
            <>
              {display_type === 'desktop' ? (
                <Text fontWeight={'400'} size="xs" color={"#1558D6"} key={index}>
                  {sitelink.text}
                </Text>
              ) : (
                <Box cursor={'pointer'} px={1} py={1} fontWeight={'400'} color={"#1558D6"}  
                  style={{ borderRadius: '3px', border: '1px solid #C8CBCE' }} key={index}>
                  {sitelink.text}
                </Box>
              )}
              {display_type === 'desktop' && index !== arr.length - 1 && <Text size="xs" color={"#1558D6"}>&bull;</Text>}
            </>
          ))}
        </HStack>
  
        { call && 
          <span><Divider mx={0} my={2} />
          <Icon as={FiPhoneCall} marginRight={2}/> Call {call}
          </span>
        }
      </Box>
    </Card >
  );
}
export default SimpleAd;