import { Stack, Box, Icon, IconButton, Image, useDisclosure, Badge } from "@chakra-ui/react";
import React from "react";
import { FiTrash2 } from "react-icons/fi";

const AssetsLibraryPreview = ({ assets, setAssets, assetType }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();



  return (
    <>
      {assets?.map((item, index) => {
        return (
          <Box key={item.uuid} mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'} position="relative" bg={'whitesmoke'}>
            <Box width={100} height={100} position="relative" overflow="hidden" cursor="pointer" justifyContent={'center'} onMouseOver={() => onOpen()} onMouseOut={() => onClose()}>
              {item.assetType == 'video' && !item.remoteProvider && <video muted loop style={{ zIndex: 0, position: 'absolute', objectFit: 'cover' }} width={'100%'} height="auto"><source src={item.fileUrl} type="video/mp4" /></video>}
              {item.assetType == 'video' && item.remoteProvider == 'youtube' && <Image src={item?.thumbnailUrl} width={100} />}
              {(item.assetType == 'image' || item.assetType == 'logo') && <Image src={item?.thumbnailUrl} width={100} />}

              <Badge width={'full'} position={'absolute'} bottom={'0'} textAlign={'center'} visibility={isOpen ? 'visible' : 'hidden'} backgroundColor={'transparent'} color={'gray.600'} marginLeft={'auto'}>{assetType}</Badge>
              {/* Based on design maybe we would nede to use icon */}
              {/* <Icon as={BiLogoCss3} position={'absolute'} bottom={'0'} right={'0'} visibility={isOpen ? 'visible' : 'hidden'} backgroundColor={'transparent'} color={'gray.600'} marginLeft={'auto'}></Icon> */}
              
              {/*<IconButton onClick={() => setAssets(assets.filter((asset) => asset.uuid !== item.uuid))} variant={'ghost'}
                color={'white'} aria-label={"Remove"} icon={<Icon as={FiTrash2} />}
                zIndex={100} position="absolute" bg={'blackAlpha.500'} top={0}
                left={0} w={'full'} h={'full'}
                opacity={0} transition="opacity 0.2s" _hover={{ opacity: 1 }}
        />*/}
            </Box>
          </Box>
        );
      })}
    </>
  )
};

export default AssetsLibraryPreview;