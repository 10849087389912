import { Box, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Text, VStack, Avatar, Icon, MenuDivider, Flex, Tag, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../helpers/sessionHelper";
import { FiChevronDown, FiClock, FiCreditCard, FiLayers, FiLogOut, FiShare2, FiUser, FiUserX, FiUsers } from 'react-icons/fi';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import LogoHorizontal from '../../../assets/images/logo_horizontal.svg';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const organization = useSelector((state) => state.user.currentUser.organization);

  const logout = () => {
    logoutSession(dispatch);
  };

  return (
    <Box bg={'white'} zIndex={1000} w={'full'} position={'fixed'} textAlign={'center'} justifyContent={'space-between'} alignItems={'center'} display={'flex'} p={'1rem'} py={'1.5rem'} borderBottom={'solid 1px'} borderBottomColor={'gray.200'}>
      <Image src={LogoHorizontal} />

      <HStack spacing={7}>
        {organization?.freeTrialRemainingDays > 0 && <Box>
          <Tooltip label={'Thank you for using our free trial! We hope you enjoyed exploring our features. If you have any questions or would like to continue using our services, we\'re here to help.'} hasArrow>
            <Tag onClick={() => navigate('/billing')} cursor={'pointer'} colorScheme="orange" borderRadius={'full'} fontSize={'xs'} fontWeight={'normal'}>
              <HStack>
                <Icon as={FiClock} fontSize={'xs'} />
                <Text>Trial ends in {organization?.freeTrialRemainingDays} days</Text>
              </HStack>
            </Tag>
          </Tooltip>
        </Box>}
        <Menu>
          <MenuButton
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}>
            <HStack spacing={2}>
              <Avatar size={'sm'} name={currentUser?.firstName + " " + currentUser?.lastName} />
              <Box display={'flex'} gap={3} alignItems={'flex-start'}>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm"> {currentUser?.firstName + " " + currentUser?.lastName}</Text>
                  <Text fontSize="xs" color="gray.400">
                    {currentUser?.email}
                  </Text>
                </VStack>
                <Box pt={1} display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </Box>

            </HStack>
          </MenuButton>
          <MenuList>
            <HeaderMenuItem to={'/account-settings'} icon={FiUser} label={'Account settings'} />
            <MenuDivider />

            <HeaderMenuItem to={'#'} icon={FiLogOut} label={'Logout'} onClick={() => logout()} />
          </MenuList>
        </Menu>
      </HStack >
    </Box >
  )
}

const HeaderMenuItem = ({ to, icon, label, ...props }) => {
  const location = useLocation();

  return (
    <MenuItem cursor={to == null ? 'default' : 'pointer'} {...props} as={RouterLink} to={to} color={location.pathname == to ? 'gray.700' : 'gray.500'} icon={<Icon as={icon} fontSize={'xl'} />} >{label}</MenuItem>
  )
}

export default Header;
