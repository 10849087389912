import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CarouselCommonEditor from "./Carousel/CommonEditor";
import LinkedinCarousel from "../../Preview/Linkedin/Carousel";

const Carousel = ({ isLoading, onFormSubmit, ad }) => {
    const getDefaultMetadata = () => {
        const defaultData = transformKeysTo('snake', structuredClone(ad?.metadata));

        if (defaultData?.linkedin_ad_carousel_items?.length == 0) {
            defaultData.linkedin_ad_carousel_items = [
                {
                    headline: 'Headline',
                    description: 'Description'
                }
            ]
        }

        return defaultData;
    }

    const { register, watch, getValues, handleSubmit, control, setValue } = useForm({
        defaultValues: getDefaultMetadata()
    });

    const linkedinLogoAsset = ad?.metadata?.linkedinLogo;
    const linkedinLogoState = React.useState(linkedinLogoAsset == null ? [] : [linkedinLogoAsset])

    const wAll = watch();

    return (
        <>
            <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
                <CarouselCommonEditor getValues={getValues} setValue={setValue} control={control} ad={ad} isLoading={isLoading} handleSubmit={handleSubmit} onFormSubmit={onFormSubmit} register={register} linkedinLogoState={linkedinLogoState} />
                <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'125px'}>
                    <VStack spacing={10}>
                        <LinkedinCarousel ad={{
                            introductoryText: getValues('introductory_text'),
                            callToAction: getValues('call_to_action'),
                            linkedinPageName: getValues('linkedin_page_name'),
                            linkedinLogo: linkedinLogoState[0][0],
                            linkedinAdCarouselItems: getValues('linkedin_ad_carousel_items')
                        }} />
                    </VStack>
                </Box>
            </HStack>
        </>
    )
}

export default Carousel;