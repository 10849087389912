import { Box, Heading, Text, Flex, Button, Icon, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useCompleteSubscriptionMutation } from '../../api/services/subscriptions';
import { useGetCurrentUserQuery } from '../../api/services/users';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../api/slices/usersSlice';
import { FiArrowRightCircle, FiCheckCircle, FiCircle } from 'react-icons/fi';

const Success = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');

  const [completeSubscriptions, { isLoading, isSuccess }] = useCompleteSubscriptionMutation();

  useEffect(() => {
    completeSubscriptions({ sessionId });
  }, []);

  return (
    <Box textAlign="center" fontSize="xl" mt={10}>
      {isLoading && <>
        <Spinner />
      </>
      }
      {isSuccess && <>
        <Icon fontSize={'2rem'} color={'green'} as={FiCheckCircle} />
        <Text fontSize="lg" fontWeight={'bold'} mt={10}>
          You finished onboarding and your plan is upgraded.
        </Text>
        <Text fontSize="sm" mt={5}>
          Thank you for subscribing. <br />You should automatically see your payment plan updated. In case of errors please contact support.
        </Text>
        <Flex mt={10} justifyContent="center" gap={10}>
          <Button leftIcon={<Icon as={FiArrowRightCircle} />} variant="ai" onClick={() => window.location.href = '/campaigns'}>
            Home
          </Button>
          <Button leftIcon={<Icon as={FiArrowRightCircle} />} variant="ai" onClick={() => window.location.href = '/billing'}>
            Go to billing
          </Button>
        </Flex>
      </>}
    </Box>
  );
};

export default Success;