export const currencies = [
    { value: "AED", label: "United Arab Emirates Dirham" },
    { value: "USD", label: "US Dollar" },
    { value: "EUR", label: "Euro" },
    { value: "JPY", label: "Japanese Yen" },
    { value: "GBP", label: "British Pound Sterling" },
    { value: "AUD", label: "Australian Dollar" },
    { value: "CAD", label: "Canadian Dollar" },
    { value: "CHF", label: "Swiss Franc" },
    { value: "CNY", label: "Chinese Yuan" },
    { value: "SEK", label: "Swedish Krona" },
    { value: "NZD", label: "New Zealand Dollar" },
    { value: "KRW", label: "South Korean Won" },
    { value: "SGD", label: "Singapore Dollar" },
    { value: "HKD", label: "Hong Kong Dollar" },
    { value: "INR", label: "Indian Rupee" },
    { value: "BRL", label: "Brazilian Real" },
    { value: "RUB", label: "Russian Ruble" },
    { value: "TRY", label: "Turkish Lira" },
    { value: "ZAR", label: "South African Rand" },
    { value: "MXN", label: "Mexican Peso" },
    { value: "NOK", label: "Norwegian Krone" }
];
