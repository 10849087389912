import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Stack, Button } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import { googleDisplayCallToActions } from "../../../../../../components/Constants/google_display_call_to_actions";

const DesktopOpened = ({ variation , activeStep}) => {

  return (
    <>
      <HStack mt={5} justifyContent={'space-between'} w={'full'}>
        <HStack>
          <Avatar size={'sm'} src={variation.logoUrl} />
          <VStack alignItems={'flex-start'} spacing={0}>
            <HStack>
              <Text fontWeight={'semibold'} fontSize={'xs'}>{variation.businessName}</Text>
            </HStack>
            <Text fontSize={'x-small'}>me</Text>
          </VStack>
        </HStack>

        <HStack>
          <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'x-small'} px={1}>Ad</Text>
          <Icon as={FiMoreVertical} />
        </HStack>
      </HStack>

      <Stack gap={10} alignItems={'center'} direction={activeStep == 0 ? 'column' : 'row'}>
        <Image mt={5} borderRadius={'lg'} w={'xs'} src={variation.imageUrl} />

        <VStack alignItems={activeStep == 0 ? 'center' : 'flex-start'} w={'full'}>
          <Text fontWeight={'semibold'} fontSize={'2xl'}>{variation.headline?.truncate(30)}</Text>
          <Text color={'gray.500'}>{variation.description?.truncate(90)}</Text>

          <Button mt={5} borderRadius={'md'} w={'fit-content'} fontSize={'sm'} fontWeight={'bold'} py={2} bg={'blue.500'}>{googleDisplayCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>
        </VStack>

      </Stack>
    </>
  )
};

export default DesktopOpened;