import { baseApi } from "../base/base";

export const sharedMediaPlanCampaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedMediaPlanCampaigns: builder.query({
      query: (data) => ({ url: `/shared/media_plans/${data.uuid}/media_plan_campaigns`, params: data }),
      providesTags: ['MediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetSharedMediaPlanCampaignsQuery
} = sharedMediaPlanCampaignsApi;
