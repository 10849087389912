import React, { useEffect, useState } from 'react';
import { useGetCommentsQuery } from '../../api/services/comments';
import { useCreateSharedCommentMutation, useGetSharedCommentsQuery } from '../../api/services/shared/comments';
import { useCreateCommentMutation } from '../../api/services/comments';
import { Drawer, DrawerBody, Text, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, useToast, Textarea, Button, VStack, Icon, HStack, Divider, Select, IconButton, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Box } from "@chakra-ui/react";
import { FiChevronDown, FiChevronUp, FiFilter, FiArrowLeft, FiLock, FiMoreVertical, FiCheck, FiArrowUp, FiX, FiArrowDown, FiSettings } from 'react-icons/fi';
import Comment from './Comments/Comment';
import Replies from './Replies';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const Comments = ({ disclosure, campaign = null, adId = null, allowCommenting = false, mediaPlan = null, externalOnly = false }) => {
    const { uuid } = useParams();
    const currentUser = useSelector((state) => state.user.currentUser);

    const [commentText, setCommentText] = useState('');
    const [commentType, setCommentType] = useState(mediaPlan != null ? 'MediaPlan' : 'Ad');
    const [commentDirectionType, setCommentDirectionType] = useState(currentUser != null ? 'internal' : 'external');

    const commentBoxDisclosure = useDisclosure();
    const [filterAdId, setFilterAdId] = useState(adId || '');
    const [selectedComment, setSelectedComment] = useState(null);
    const repliesDisclosure = useDisclosure();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = localStorage.getItem('token') || queryParams.get('token');

    const commentDirectionTypes = [{
        id: 'internal',
        value: "Internal"
    }, {
        id: 'external',
        value: "External"
    }]

    const [sortOption, setSortOption] = useState('desc');
    const [showResolved, setShowResolved] = useState(false);

    const [filters, setFilters] = useState({
        sort_direction: sortOption,
        sort_field: 'created_at', ad_id: filterAdId, campaign_id: campaign?.id, campaign_uuid: mediaPlan == null ? uuid : undefined, token: token, media_plan_id: mediaPlan?.id, media_plan_uuid: campaign == null ? uuid : undefined
    });

    const getCommentsQuery = currentUser != null ? useGetCommentsQuery : useGetSharedCommentsQuery;

    const { data, isSuccess: isSuccessComments, refetch } = getCommentsQuery(filters, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });
    const [createComment, { isSuccess, isLoading }] = currentUser != null ? useCreateCommentMutation() : useCreateSharedCommentMutation();

    const toast = useToast();

    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            sort_direction: sortOption,
            sort_field: 'created_at',
            resolved: showResolved,
        }));
        refetch();
    }, [sortOption, showResolved]);

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: "Comment added.",
                description: "Your comment has been successfully added.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setCommentText('');
            commentBoxDisclosure.onClose();
        }
    }, [isSuccess]);

    const handleCommentSubmit = () => {
        const trimmedCommentText = commentText.trim();
        if (trimmedCommentText) {
            createComment({ text: trimmedCommentText, commentableId: (mediaPlan == null ? adId : mediaPlan?.id), campaignUuid: uuid, token: token, commentableType: commentType, directionType: commentDirectionType });
        } else {
            toast({
                title: "Error",
                description: "Your comment cannot be empty.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleOpenReplies = (comment) => {
        setSelectedComment(selectedComment?.id === comment.id ? null : comment);
    };
    useEffect(() => {
        if (isSuccessComments) {
            setSelectedComment(data?.comments?.find((comment) => comment?.id == selectedComment?.id));
        }
    }, [data])

    const handleClose = () => {
        disclosure.onClose();
        repliesDisclosure.onClose();
    };

    const handleChange = (event) => {
        setCommentDirectionType(event.target.value);
    };

    return (
        <Drawer isOpen={disclosure.isOpen || repliesDisclosure.isOpen} onClose={handleClose} placement='right' size='sm'>
            <DrawerOverlay />
            <DrawerContent>
                {!selectedComment && <DrawerCloseButton mt={'6px'} />}
                {selectedComment && <IconButton ml={6} mt={6} icon={<FiArrowLeft />} onClick={() => setSelectedComment(null)} variant="unstyled" margin={3} fontSize="xl" />}
                <DrawerHeader display={'flex'} alignItems={'center'} gap={'1rem'}>
                    <HStack w={'full'} mr={5} justifyContent={'space-between'}>
                        <HStack>
                            <Text>{selectedComment ? 'Replies' : 'Comments'}</Text>
                            <Box as={'span'} fontSize={'sm'} bg={'var(--chakra-colors-brand-500)'} borderRadius={'0.25rem'} padding={'0.25rem 0.5rem'} color={'white'}>
                                {data?.meta?.pagination?.totalAmount ?? 0}
                            </Box>
                        </HStack>

                        {!selectedComment &&
                            <Menu closeOnSelect={false}>
                                <MenuButton mr={5} transition="all 0.3s" _focus={{ boxShadow: 'none' }} display={'flex'}>
                                    <Icon fontSize={'md'} as={FiSettings} />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => setSortOption(sortOption == 'asc' ? 'desc' : 'asc')} color={'gray.500'} icon={<Icon as={sortOption == 'asc' ? FiArrowUp : FiArrowDown} fontSize={'xl'} />} >Sort by date</MenuItem>
                                    <MenuDivider />
                                    <MenuItem onClick={() => setShowResolved(!showResolved)} color={'gray.500'} icon={<Icon visibility={showResolved ? 'visible' : 'hidden'} as={showResolved ? FiCheck : FiX} fontSize={'xl'} />} >Show resolved comments</MenuItem>
                                </MenuList>
                            </Menu>


                        }
                    </HStack>
                </DrawerHeader>

                <DrawerBody>
                    {selectedComment && (
                        <>
                            <Comment key={selectedComment.id} comment={selectedComment} onOpenReplies={handleOpenReplies} />
                            <Replies comment={selectedComment} campaign={campaign} mediaPlan={mediaPlan} />
                        </>
                    )}

                    {!selectedComment && isSuccessComments && (
                        <>
                            {campaign && (
                                <>
                                    <Select value={filterAdId} onChange={(e) => { setFilterAdId(e.target.value); }} icon={<Icon as={FiFilter} fontSize={'sm'} />} mb={4}>
                                        <option value={''}>Unresolved comments</option>
                                        {campaign?.adGroups?.map((adGroup) => {
                                            if (!adGroup?.ads?.some(ad => ad?.hasComments)) { return null; }

                                            return (
                                                <optgroup key={adGroup.uuid} label={adGroup.name}>
                                                    {adGroup?.ads?.map((ad) => {
                                                        if (!ad?.hasComments) { return null; }

                                                        return (
                                                            <option key={ad.id} value={ad.id}>{ad.name}</option>
                                                        )
                                                    })}
                                                </optgroup>
                                            )
                                        })}
                                    </Select>
                                    <Divider my={3} />
                                </>
                            )}

                            {allowCommenting && (
                                <>
                                    {data?.comments?.length > 0 && (
                                        <HStack>
                                            <Button paddingLeft={'0'} paddingRight={'0'} paddingTop={'0.5rem'} paddingBottom={'0.5rem'} w={'full'} bg={'transparent'} color={'#000000'} onClick={commentBoxDisclosure.onToggle}>
                                                <HStack w={'full'} justifyContent={'space-between'}>
                                                    <Text fontSize={'sm'}>Add new comment</Text>
                                                    <Icon as={commentBoxDisclosure.isOpen ? FiChevronUp : FiChevronDown} />
                                                </HStack>
                                            </Button>
                                        </HStack>
                                    )}

                                    {(commentBoxDisclosure.isOpen || data?.comments?.length == 0) && (
                                        <VStack mt={4} spacing={4} alignItems={'flex-start'}>
                                            <Textarea fontSize={'sm'} value={commentText} onChange={(e) => setCommentText(e.target.value)} placeholder="Write your comment here..." />

                                            {mediaPlan != null && commentDirectionType == 'internal' && <HStack w={'full'} bg={'#FFFAEB'} padding={'0.5rem 1rem'}>
                                                <Text fontSize={'0.75rem'}>
                                                    <Icon as={FiLock} marginRight={'1rem'} marginBottom={'-2px'} />
                                                    Internal comments are only viewable by your team
                                                </Text>
                                            </HStack>}

                                            <HStack w={'full'} justifyContent={'flex-end'}>
                                                {mediaPlan != null && !externalOnly && <Select w={'100px'} size='lg' border={'none'} borderColor={'none'} outline={'none'} value={commentDirectionType} onChange={handleChange}>
                                                    {commentDirectionTypes.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </Select>}
                                                <Button size={'sm'} onClick={handleCommentSubmit} alignSelf={'end'} isDisabled={commentText?.length == 0 || isLoading}>Add Comment</Button>
                                            </HStack>
                                        </VStack>
                                    )}

                                    <Divider my={5} />
                                </>
                            )}

                            <VStack gap={0}>
                                {data?.comments?.map((comment) => (
                                    <>
                                        <Comment key={comment.id} comment={comment} showRepliesButton onOpenReplies={handleOpenReplies} />
                                        <Divider my={2} marginBlockStart={0} marginBlockEnd={0} />
                                    </>
                                ))}
                            </VStack>
                        </>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
export default Comments;


