import React, { useEffect } from "react";
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Icon, Input, Select, useDisclosure, useToast } from '@chakra-ui/react';
import { FiUserPlus } from 'react-icons/fi';
import { useForm } from "react-hook-form";
import { useInviteUserMutation, useUpdateTeamUserMutation } from "../../api/services/users";

const Edit = ({ editDisclosure, user }) => {
  const { isOpen, onClose } = editDisclosure;
  const { register, handleSubmit, reset } = useForm();
  const toast = useToast();

  const [update, { isLoading, isSuccess, isError, error }] = useUpdateTeamUserMutation();
  const errors = error?.data?.errors;

  const onSubmit = (data) => {
    data.id = user.id;
    update(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "User updated.",
        description: "You successfully updated the user.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onClose();
    }
  }, [isSuccess])

  useEffect(() => {
    reset();
  }, [user])

  return (
    <>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit a team member</DrawerHeader>

          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <HStack>
                <FormControl>
                  <FormLabel fontSize={'sm'}>First Name</FormLabel>
                  <Input type='text' defaultValue={user?.firstName} placeholder="John" {...register('firstName', { required: true })} />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'sm'}>Last Name</FormLabel>
                  <Input type='text' defaultValue={user?.lastName} placeholder="Doe" {...register('lastName', { required: true })} />
                </FormControl>
              </HStack>

              <FormControl isInvalid={isError && errors?.email} mt={4}>
                <FormLabel fontSize={'sm'}>Email</FormLabel>
                <Input type='email' value={user?.email} readOnly isDisabled />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel fontSize={'sm'}>Role</FormLabel>
                <Select defaultValue={user?.organizationRole} {...register('organizationRole', { required: true })}>
                  <option value="member">Member</option>
                </Select>
              </FormControl>
            </form>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit(onSubmit)} isLoading={isLoading} isDisabled={isLoading}>Update</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Edit;