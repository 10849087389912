import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { FaFacebook, FaGoogle, FaTiktok } from 'react-icons/fa';

export const iconMap = {
  'facebook': FaFacebook,
  'google': FaGoogle,
  'tiktok': FaTiktok,
};


const ProviderLabel = ({ platform, ...props }) => {

  const colorMap = {
    'facebook': '#3b5998',
    'google': '#4285f4',
    'tiktok': '#69c9d0',
  };

  const icon = iconMap[platform];

  return (
    <HStack spacing={5} {...props}>
      <Icon color={colorMap[platform]} {...props} as={icon} alt={`platform_icon_${platform}`} />
      <Text textTransform={'capitalize'}>{platform}</Text>
    </HStack>
  )
};

export default ProviderLabel;