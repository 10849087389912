import { Card, Text, Image, Divider, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, AvatarBadge, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch, FiPlus, FiMusic, FiRefreshCw, FiChevronRight } from "react-icons/fi";
import BlankTiles from '../../../../assets/images/meta_instagram_empty_story.png';

import Wifi from '../../../../assets/icons/tiktok/wifi.svg';
import Mobile from '../../../../assets/icons/tiktok/mobile.svg';
import Battery from '../../../../assets/icons/tiktok/battery.svg';
import Heart from '../../../../assets/icons/tiktok/heart.svg';
import Comments from '../../../../assets/icons/tiktok/comments.svg';
import Share from '../../../../assets/icons/tiktok/share.svg';
import Disc from '../../../../assets/icons/tiktok/disc.svg';
import Home from '../../../../assets/icons/tiktok/home.svg';
import Search from '../../../../assets/icons/tiktok/search.svg';
import Plus from '../../../../assets/icons/tiktok/plus.svg';
import Account from '../../../../assets/icons/tiktok/account.svg';
import Message from '../../../../assets/icons/tiktok/message.svg';
import { tiktokCallToActions } from "../../../../components/Constants/tiktok_call_to_actions";
import { useReloadAssetsMutation } from "../../../../api/services/assets";

const SingleImageVideo = ({ ad }) => {
  const [videoError, setVideoError] = useState(false);
  const [reload, { isLoading }] = useReloadAssetsMutation();

  useEffect(() => {
    const checkVideoUrl = async () => {
      try {
        const response = await fetch(ad?.asset?.fileUrl);
        if (!response.ok) {
          setVideoError(true);
        } else {
          setVideoError(false);
        }
      } catch (error) {
        setVideoError(true);
      }
    };

    checkVideoUrl();
  }, [ad]);

  return (
    <Card bg={'black'} w={'sm'} overflow={'hidden'} h={'2xl'} borderRadius={0}>

      {ad.asset == null && <Image filter={'blur(10px)'} src={BlankTiles} zIndex={0} w={'full'} position={'absolute'} h={'full'} objectFit={'cover'} />}

      {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
        <Image src={ad.asset?.fileUrl} zIndex={0} w={'full'} position={'absolute'} h={'full'} objectFit={'cover'} />
      </Box>}

      {ad.asset?.assetType == 'video' && !/youtube/.test(ad.asset?.fileUrl) &&
        <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
          <video key={ad.asset?.uuid} muted playsInline width={'100%'} height="100%" loop autoPlay style={{ objectFit: 'cover' }}>
            <source src={ad.asset?.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      }

      {ad.asset?.assetType == 'video' && /youtube/.test(ad.asset?.fileUrl) &&
        <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
          <iframe width="100%" height="100%" src={`${ad.asset?.fileUrl.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ objectFit: 'cover', pointerEvents: 'auto' }}></iframe>
        </Box>
      }

      <CardHeader textShadow={'0 0 7px black'} zIndex={100}>
        <HStack px={4} color={'white'} justifyContent={'space-between'}>
          <Text fontWeight={600} fontSize={'sm'}>9:41</Text>
          <HStack>
            <Image w={'18px'} src={Mobile} />
            <Image w={'18px'} src={Wifi} />
            <Image w={'18px'} src={Battery} />
          </HStack>
        </HStack>

        <HStack mt={6} justifyContent={'space-between'}>
          <Icon opacity={0} as={FiSearch} color={'white'} />
          <HStack fontWeight={'bold'} color={'white'} justifyContent={'center'} spacing={3} fontSize={'sm'}>
            <Text opacity={0.75}>Following</Text>
            <Box h={'15px'}>
              <Divider color={'gray'} orientation="vertical" />
            </Box>
            <Text>For You</Text>
          </HStack>
          <Icon as={FiSearch} color={'white'} />
        </HStack>
      </CardHeader>
      <CardBody flexDirection={'column'} justifyContent={'flex-end'} display={'flex'} zIndex={100} fontSize={'sm'} p={0} pt={4}>

        <HStack alignItems={'flex-end'} p={4}>
          <Box textShadow={'0 0 7px rgba(0,0,0,0.2)'} color={'white'} w={'full'}>
            <Text fontWeight={'bold'}>{ad?.tiktokAccountName}</Text>
            <Text wordBreak={'break-all'} noOfLines={4}>
              {ad?.description?.truncate(100)}
            </Text>
            <HStack mt={4}>
              <Icon as={FiMusic} />
              <Text>Promoted Music</Text>
            </HStack>
            {ad?.callToAction &&
              <Button mt={3} size={'lg'} py={3} px={16} fontWeight={'bold'} fontSize={'md'} bg={'#1798AF'} color={'white'} borderRadius={'sm'} rightIcon={<Icon as={FiChevronRight} />}>
                {tiktokCallToActions.find((c) => c.value == ad?.callToAction)?.label || 'CTA'}
              </Button>
            }
          </Box>
          <Box>
            <VStack spacing={6}>
              <Avatar border={'solid 1px white'} src={ad?.tiktokLogo?.fileUrl}>
                <AvatarBadge left={'9px'} bottom={'-2px'} boxSize='1em' border={'0px solid'} bg='#EA4359'>
                  <Icon as={FiPlus} fontSize={'xs'} fontWeight={'bold'} color={'white'} />
                </AvatarBadge>
              </Avatar>

              <VStack spacing={0}>
                <Image w={'32px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Heart} />
                <Text fontSize={'xs'} fontWeight={600} color={'white'} textShadow={'1.191px 1.191px 0px rgba(0, 0, 0, 0.30)'}>328.7K</Text>
              </VStack>

              <VStack spacing={0}>
                <Image w={'32px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Comments} />
                <Text fontSize={'xs'} fontWeight={600} color={'white'} textShadow={'1.191px 1.191px 0px rgba(0, 0, 0, 0.30)'}>578</Text>
              </VStack>

              <VStack spacing={0}>
                <Image w={'32px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Share} />
                <Text fontSize={'xs'} fontWeight={600} color={'white'} textShadow={'1.191px 1.191px 0px rgba(0, 0, 0, 0.30)'}>Share</Text>
              </VStack>
            </VStack>

            <Image mt={4} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Disc} />
          </Box>
        </HStack>

        <Box bg={'black'}>
          <HStack justifyContent={'space-between'} p={4}>
            <VStack spacing={1}>
              <Image w={'18px'} src={Home} />
              <Text color={'white'} fontWeight={'bold'} fontSize={'xs'}>Home</Text>
            </VStack>

            <VStack opacity={0.8} spacing={1}>
              <Image w={'18px'} src={Search} />
              <Text color={'white'} fontSize={'xs'}>Discover</Text>
            </VStack>

            <Image src={Plus} />

            <VStack opacity={0.8} spacing={1}>
              <Image w={'18px'} src={Message} />
              <Text color={'white'} fontSize={'xs'}>Inbox</Text>
            </VStack>

            <VStack opacity={0.8} spacing={1}>
              <Image w={'18px'} src={Account} />
              <Text color={'white'} fontSize={'xs'}>Me</Text>
            </VStack>
          </HStack>
        </Box>

      </CardBody>
    </Card >
  )
};

export default SingleImageVideo;