
import { Text, Spacer } from "@chakra-ui/react";
import React, { } from "react";
import { FiLock } from 'react-icons/fi';
import SidebarLink from "../SidebarLink";

const DeactivatedSidebar = () => {

  return (
    <>

      <Text p={7} pb={4} fontWeight={'bold'} color={'gray'}>Settings</Text>

      <SidebarLink to="/deactivated" icon={FiLock} text="Recover account" />

      <Spacer />
    </>
  )
}

export default DeactivatedSidebar;