import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    boxShadow: '0 0 0 0',
    border: 'solid 1px lightgray'
  },
  header: {
    fontSize: 'lg',
    fontWeight: 500,
    pb: 1,
    mb: 0
  }
})

export const cardTheme = defineMultiStyleConfig({
  baseStyle
})