import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";

const DesktopClosed1 = ({ variation }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
        <HStack mt={5} border={'solid 1px'} borderColor={'gray.300'} p={3} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Icon as={FiStar} color={'gray.300'} />
            <Text fontWeight={'bold'} fontSize={'xs'}>{variation.businessName}</Text>
          </HStack>

          <HStack>
            <Text color={'green.500'} border={'solid 1px'} borderColor={'green.500'} rounded={'md'} fontSize={'x-small'} px={1}>Ad</Text>
            <Text fontWeight={'semibold'} fontSize={'xs'}>{variation.headline?.truncate(30)} - </Text>
            <Text fontSize={'xs'} color={'gray.600'}>{variation.description?.truncate(90)}</Text>
          </HStack>

          <Icon as={FiMoreVertical} />
        </HStack>
    
    </>
  )
};

export default DesktopClosed1;