import { Flex, Heading, Stack, Box, Text, HStack, Select, useToast, Spinner, FormControl, FormLabel, Input, IconButton, Icon, FormHelperText, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCampaignQuery } from "../../api/services/campaigns";
import PlatformIcon from "../Campaigns/Components/PlatformIcon";
import CampaignMode from "../Campaigns/Components/CampaignMode";
import MetaSingleImageVideoEditor from "./Editors/Meta/SingleImageVideo";
import MetaStoryEditor from './Editors/Meta/Story';
import MetaCarouselEditor from './Editors/Meta/Carousel';
import MetaCollectionEditor from './Editors/Meta/Collection';
import { previewVariations } from '../../components/Constants/previewVariations';

import { useCreateAdMutation, useGetAdQuery, useUpdateAdMutation } from "../../api/services/ads";
import { FiMonitor, FiRefreshCcw, FiSmartphone } from "react-icons/fi";
import GoogleSearchEditor from "./Editors/Google/Search/Editor";
import GoogleDisplayEditor from './Editors/Google/Display/Editor';
import GooglePMaxEditor from './Editors/Google/PMax/Editor';
import GoogleDemandgenEditor from './Editors/Google/DemandGen/Editor';
import { FiRefreshCw } from "react-icons/fi";
import { campaignPlatforms } from "../../components/Constants/campaigns";
import EditorContext from "../../contexts/EditorContext";
import { useForm } from "react-hook-form";
import TiktokEditor from './Editors/Tiktok/Editor';
import SnapchatSingleImageVideoEditor from './Editors/Snapchat/SingleImageVideo';
import SnapchatStoryEditor from './Editors/Snapchat/Story';
import SnapchatCollectionEditor from './Editors/Snapchat/Collection';
import LinkedinSingleImageVideoEditor from "./Editors/Linkedin/SingleImageVideo";
import LinkedinCarouselEditor from './Editors/Linkedin/Carousel';
import { useSelector } from "react-redux";

const Editor = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { uuid, ad_group_uuid, ad_uuid } = useParams();
  const { register, getValues, setValue, watch } = useForm();
  const [adNameLength, setAdNameLength] = useState(0);
  const variationsValue = useSelector(state => state.variation.toggleValue);

  const { data, isSuccess, isLoading } = useGetCampaignQuery(uuid);
  const { data: dataAd, isSuccess: isSuccessAd, isLoading: isLoadingAd } = useGetAdQuery(ad_uuid);

  const [createAd, { isLoading: isLoadingCreateAd, isSuccess: isSuccessCreate, isError: isErrorCreate, error: createError }] = useCreateAdMutation();
  const [updateAd, { isLoading: isLoadingUpdateAd, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: updateError }] = useUpdateAdMutation();

  const errors = createError?.data?.errors || updateError?.data?.errors;

  const [selectedPreviewVariation, setSelectedPreviewVariation] = useState(previewVariations.find((option) => option.default));
  const [refreshFlag, setRefreshFlag] = useState(false);

  const [adType, setAdType] = useState('single_image_video');

  const name = watch('name');

  const campaign = data?.campaign;
  const ad = dataAd?.ad;

  const handleRefreshClick = () => {
    setRefreshFlag(!refreshFlag);
  };

  useEffect(() => {
    if (isSuccess) {
      if (campaign.platform == 'google_demandgen') {
        setAdType('image');
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSuccess) {
      if (campaign.platform == 'meta_instant_experience') {
        setAdType('collection');
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSuccessAd) {
      setValue('name', ad?.name);
      setAdType(ad?.metadata?.adType);
      setValue('ad_group_uuid', ad_group_uuid);
    }
  }, [isSuccessAd])

  const handleSelectPreviewVariationChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPreviewVariation(previewVariations.find((option) => option.value === selectedValue));
  };

  const onSubmit = (formData) => {
    formData.append('name', getValues('name'));
    formData.append('ad_group_uuid', getValues('ad_group_uuid'));
    formData.append('ad_type', adType);

    if (ad_uuid) {
      formData.append('uuid', ad_uuid);

      updateAd(formData);
    } else {
      createAd(formData);
    }
  };

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      toast({
        description: "Ad Submitted Successfully! 🚀 Should you need any further assistance or have any questions, feel free to reach out to our support team.",
        status: "success",
      });

      navigate(`/campaigns/${campaign.uuid}`);
    }
  }, [isSuccessCreate, isSuccessUpdate]);

  useEffect(() => {
    if (isErrorCreate || isErrorUpdate) {
      toast({
        description: "Something went wrong! Please validate form data and try again.",
        status: "error",
      });
    }
  }, [isErrorCreate, isErrorUpdate]);

  return (
    <>
      <EditorContext.Provider value={{ errors }}>
        {(isLoading || isLoadingAd) && <Box p={10} minHeight="100vh" w={'full'}>
          <Spinner />
        </Box>}

        {isSuccess &&
          <Box p={10} minHeight="100vh" w={'full'}>
            <Flex gap={6} direction={['column', 'row']} alignItems={'center'} p={0} justifyContent={'space-between'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                {campaign?.name}
              </Heading>

              <Stack alignItems={'center'} justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                <PlatformIcon w={10} platform={campaign?.platform} />
                <Text fontWeight={'semibold'}>{campaignPlatforms.find((item) => item.value == campaign?.platform)?.label}</Text>
                <CampaignMode campaign={campaign} />
              </Stack>
            </Flex>

            <Flex mt={5} borderBottom={'solid 1px'} borderColor={'gray.300'} gap={6} direction={['column', 'row']} alignItems={'center'} py={4} justifyContent={'space-between'}>
              <HStack w={'full'} spacing={10} alignItems={'center'}>
                <FormControl isInvalid={errors?.ad_group_uuid}>
                  <FormLabel>Ad Group</FormLabel>
                  <Select {...register('ad_group_uuid')} defaultValue={ad?.adGroupUuid || ad_group_uuid}>
                    {campaign?.adGroups?.map((adGroup) => <option key={adGroup.uuid} value={adGroup.uuid}>{adGroup.name}</option>)}
                  </Select>
                </FormControl>

                <FormControl isInvalid={errors?.name}>
                  <FormLabel mt={6}>Ad Name</FormLabel>
                  <VStack spacing={0} align="stretch">
                    <Input {...register('name')} placeholder={"Untitled"} defaultValue={ad?.name} />
                    <FormHelperText color={(name?.length || 0) > 255 ? 'red' : '#96999F'} fontSize={'xs'} textAlign={'right'} w={'full'}>
                      {name?.length || 0} / 255
                    </FormHelperText>
                  </VStack>
                </FormControl>

                {campaign.platform == 'google_search' &&
                  <>
                    <Select disabled={!variationsValue} mt="30px" value={selectedPreviewVariation?.value} onChange={handleSelectPreviewVariationChange}>
                      {previewVariations.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>

                    <IconButton isDisabled={!variationsValue} mt="30px" p={5} px={6} variant={'outline'} aria-label="Refresh variations" onClick={handleRefreshClick} icon={<Icon fontSize={'sm'} as={FiRefreshCcw} />} />

                  </>
                }

                {campaign.platform == 'meta' &&
                  <FormControl>
                    <FormLabel>Select Ad Format</FormLabel>

                    <Select onChange={(e) => { setAdType(e.target.value); }} color={'gray'} fontSize={'sm'}>
                      {[{ value: 'single_image_video', label: 'Single Image/Video' }, { value: 'story', label: 'Story' }, { value: 'carousel', label: 'Carousel' }].map((option, index) =>
                        <option key={index} selected={option.value == ad?.metadata?.adType} value={option.value}>{option.label}</option>
                      )}
                    </Select>
                  </FormControl>
                }

                {campaign.platform == 'meta_instant_experience' &&
                  <FormControl>
                    <FormLabel>Select Ad Format</FormLabel>

                    <Select onChange={(e) => { setAdType(e.target.value); }} color={'gray'} fontSize={'sm'}>
                      {[{ value: 'collection', label: 'Collection' }].map((option, index) =>
                        <option key={index} selected={option.value == ad?.metadata?.adType} value={option.value}>{option.label}</option>
                      )}
                    </Select>
                  </FormControl>
                }

                {campaign.platform == 'google_demandgen' &&
                  <FormControl>
                    <FormLabel>Select Ad Format</FormLabel>

                    <Select onChange={(e) => { setAdType(e.target.value); }} color={'gray'} fontSize={'sm'}>
                      {[{ value: 'image', label: 'Single Image ad' }, { value: 'video', label: 'Video ad' }, { value: 'carousel', label: 'Carousel image ad' }].map((option) =>
                        <option selected={option.value == ad?.metadata?.adType} value={option.value}>{option.label}</option>
                      )}
                    </Select>
                  </FormControl>
                }

                {campaign.platform == 'linkedin' &&
                  <FormControl>
                    <FormLabel>Select Ad Format</FormLabel>

                    <Select onChange={(e) => { setAdType(e.target.value); }} color={'gray'} fontSize={'sm'}>
                      {[{ value: 'single_image_video', label: 'Single Image/Video' }, { value: 'carousel', label: 'Carousel' }].map((option) =>
                        <option selected={option.value == ad?.metadata?.adType} value={option.value}>{option.label}</option>
                      )}
                    </Select>
                  </FormControl>
                }

                {campaign.platform == 'snapchat' &&
                  <FormControl>
                    <FormLabel>Select Ad Format</FormLabel>

                    <Select onChange={(e) => { setAdType(e.target.value); }} color={'gray'} fontSize={'sm'}>
                      {[{ value: 'single_image_video', label: 'Single Image/Video' }, { value: 'story', label: 'Story' }, { value: 'collection', label: 'Collection' }].map((option) =>
                        <option selected={option.value == ad?.metadata?.adType} value={option.value}>{option.label}</option>
                      )}
                    </Select>
                  </FormControl>
                }
              </HStack>

            </Flex>

            {((isSuccessAd && ad_uuid != null) || (ad_uuid == null)) && <>
              {campaign.platform == 'meta' && <>
                {adType == 'single_image_video' && <MetaSingleImageVideoEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
                {adType == 'story' && <MetaStoryEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
                {adType == 'carousel' && <MetaCarouselEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
              </>}

              {campaign.platform == 'meta_instant_experience' && <>
                {adType == 'collection' && <MetaCollectionEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
              </>}

              {campaign.platform == 'google_search' && <>
                <GoogleSearchEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} selectedVariation={selectedPreviewVariation?.value} refreshFlag={refreshFlag} onFormSubmit={onSubmit} />              </>}

              {campaign.platform == 'google_display' && <>
                <GoogleDisplayEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />
              </>}

              {campaign.platform == 'google_pmax' && <>
                <GooglePMaxEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />
              </>}

              {campaign.platform == 'google_demandgen' && <>
                <GoogleDemandgenEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} adType={adType.replace('single_image_video', 'image')} />
              </>}

              {campaign.platform == 'tiktok' && <TiktokEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}

              {campaign.platform == 'snapchat' && <>
                {adType == 'single_image_video' && <SnapchatSingleImageVideoEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
                {adType == 'story' && <SnapchatStoryEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
                {adType == 'collection' && <SnapchatCollectionEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
              </>}

              {campaign.platform == 'linkedin' && <>
                {adType == 'single_image_video' && <LinkedinSingleImageVideoEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
                {adType == 'carousel' && <LinkedinCarouselEditor isLoading={isLoadingCreateAd || isLoadingUpdateAd} ad={ad} onFormSubmit={onSubmit} />}
              </>}
            </>}

          </Box>
        }
      </EditorContext.Provider>
    </>
  )
}

export default Editor;