import { IconButton, Icon, VStack, Box, Image, Text, Divider, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiMonitor, FiPhone, FiRefreshCw, FiSmartphone } from "react-icons/fi";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

 
import YouTubeandGmail from "./YouTubeandGmail";
import Display from "./Display";

const AggregatedPreview = ({ metadata }) => {
  const [displayType, setDisplayType] = React.useState('mobile');
  const [showYouTube, setShowYouTube] = React.useState(Math.random() > 0.5);

  const randomize = () => {
    const headline = metadata?.headlines[Math.floor(Math.random() * metadata.headlines.length)]?.truncate(30);
    const description = metadata?.descriptions[Math.floor(Math.random() * metadata.descriptions.length)];
    const video = metadata?.videos[Math.floor(Math.random() * metadata.videos.length)];

    return {
      headline: headline,
      headlines: metadata?.headlines,
      description: description,
      longHeadline: metadata?.longHeadline,
      businessName: metadata?.businessName,
      finalUrl: metadata?.finalUrl,
      cta: metadata?.callToAction,
      imageUrl: metadata?.images[Math.floor(Math.random() * metadata.images.length)]?.fileUrl,
      logoUrl: metadata?.logos[Math.floor(Math.random() * metadata.logos.length)]?.fileUrl,
      videoUrl: video?.fileUrl,
      remoteProvider: video?.remoteProvider
    }
  }

  const [variation, setVariation] = React.useState([randomize(), randomize()]);

  const setRandomVariations = () => {
    setVariation([randomize(), randomize()]);
     setShowYouTube(Math.random() > 0.5);
  };


  useEffect(() => {
      setRandomVariations();
  }, [metadata]);

  return (
    <>
      <VStack alignItems={'flex-start'} spacing={10}>
        <HStack h={'55px'} w={'full'} display={'flex'} justifyContent={'flex-end'}>
          <IconButton p={5} variant={'outline'} aria-label="Refresh variations" onClick={() => setRandomVariations()} icon={<Icon fontSize={'sm'} as={FiRefreshCw} />} />
          <Divider mx={10} orientation='vertical' />
          <IconButton p={5} variant={displayType == 'mobile' ? 'solid' : 'outline'} h={'auto'} aria-label="Refresh variations" onClick={() => setDisplayType('mobile')} icon={<Icon fontSize={'sm'} as={FiSmartphone} />} />
          <IconButton p={5} variant={displayType == 'desktop' ? 'solid' : 'outline'} h={'auto'} aria-label="Refresh variations" onClick={() => setDisplayType('desktop')} icon={<Icon fontSize={'sm'} as={FiMonitor} />} />
        </HStack>

        <Tabs w={'full'} >
          <TabList>
            <Tab w={'full'}>
                <Text fontSize={'sm'} color={'black'}>Websites and Apps </Text>
            </Tab>
            <Tab w={'full'}>
                <Text fontSize={'sm'} color={'black'}>Youtube and Gmail</Text>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Display displayType={displayType} variations={variation} />
            </TabPanel>
             <TabPanel>
                <YouTubeandGmail displayType={displayType} variations={variation} /> 
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  )
};

export default AggregatedPreview;