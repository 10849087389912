import { Image, Flex, Spacer, Button, Icon, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, Box, IconButton, FormHelperText, Stack, Tooltip, ListItem, UnorderedList, Divider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiHelpCircle, FiImage, FiPlus, FiTrash2, FiVideo } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { transformKeysTo } from "../../../../../helpers/apiHelper";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { googleDisplayCallToActions } from "../../../../../components/Constants/google_display_call_to_actions";
import MediaInput from "./MediaInput";
import AggregatedPreview from "../../../Preview/Google/Display/AggregatedPreview";
import flattenObject from "../../../../../helpers/objectHelper";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { validateUrl } from "../../../../../components/utils/urlUtils";

import Suggestions from "../../../../Suggestions/Suggestions";
import { HiOutlineChip } from "react-icons/hi";
import SuggestionsButton from "../../../../Suggestions/SuggestionsButton";


const Editor = ({ ad, onFormSubmit, isLoading }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [imageAssets, setImageAssets] = useState(ad?.metadata?.images || []);
  const [videoAssets, setVideoAssets] = useState(ad?.metadata?.videos || []);
  const [logoAssets, setLogoAssets] = useState(ad?.metadata?.logos || []);


  const { register, watch, getValues, setValue, setError, clearErrors, control, handleSubmit } = useForm({
    mode: 'onBlur',

    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata)) || {
      headlines: [{ text: '' }],
      descriptions: [{ text: '' }],
      final_url: 'https://',
    }


  });
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headlines'
  });

  const descriptions = useFieldArray({
    control,
    name: 'descriptions'
  });

  const wAll = watch();

  const onSubmit = (data) => {
    const formData = new FormData();

    imageAssets.forEach((item) => {
      formData.append(`assets[][id]`, item.id);
      formData.append(`assets[][category]`, 'image');
    });

    videoAssets.forEach((item) => {
      formData.append(`assets[][id]`, item.id);
      formData.append(`assets[][category]`, 'video');
    });

    logoAssets.forEach((item) => {
      formData.append(`assets[][id]`, item.id);
      formData.append(`assets[][category]`, 'logo');
    });

    const flObject = flattenObject(data);
    Object.keys(flObject).forEach((key) => {
      if (flObject[key] == null)
        return;

      const modifiedKey = key.replace(/\[\d+\]/g, '[]');
      formData.append(modifiedKey, flObject[key]);
    });

    onFormSubmit(formData);
  }

  const updateHeadline = (headlines) => {
    setValue('headlines', headlines.map(headline => ({ text: headline })));
  };

  const updateDescription = (descriptions) => {
    setValue('descriptions', descriptions.map(description => ({ text: description })));
  };

  return (
    <>

      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5}>
        <Box minW={["50%"]}  >
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5} >

              <Card w={'full'}>
                <CardHeader>
                  <HStack>
                    <Text>Business name & URL</Text>
                    <Icon as={FiHelpCircle} />
                  </HStack>
                </CardHeader>
                <CardBody>
                  <VStack spacing={10}>
                    <FormControl>
                      <FormLabel>Business Name</FormLabel>
                      <Input {...register('business_name')} placeholder={'Add business name here...'} maxLength={25} />
                      <FormHelperText fontSize={'xs'} color={'gray.400'}>The business name is the name of your business or brand. In certain layouts, it may appear in the text of your ad.</FormHelperText>

                    </FormControl>

                    <FormControl>
                      <FormLabel>Final URL</FormLabel>
                      <Input
                        {...register('final_url')}
                        placeholder={'Add final URL here...'}
                        onBlur={(e) => {
                            const validatedUrl = validateUrl(e.target.value);
                            setValue('final_url', validatedUrl);
                        }}
                      />
                      <FormHelperText fontSize={'xs'} color={'gray.400'}>The final URL is where people go after clicking your ad. It can be any page of your website and should match what your ad promotes.</FormHelperText>

                    </FormControl>

                    <FormControl>
                      <FormLabel>CTA</FormLabel>
                      <Select {...register('call_to_action')}>
                        <option value={''}>No Button</option>
                        {googleDisplayCallToActions.map((cta, index) => (
                          <option value={cta.value} key={index}>{cta.label}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </VStack>
                </CardBody>
              </Card>

              <Card w={'full'}>
                <CardHeader>
                  <HStack mb={4} alignItems={'center'}>
                    <Text m={0}>Media</Text>
                    <Tooltip label={<>
                      <HStack>
                        <Box>
                          <Text>Image guidelines:</Text>
                          <Text>Landscape image (1.91:1)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 1200 x 628</ListItem>
                            <ListItem>Min. size: 600 x 314</ListItem>
                          </UnorderedList>
                          <Text>Square image (1:1)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 1200 x 1200</ListItem>
                            <ListItem>Min. size: 300 x 300</ListItem>
                          </UnorderedList>
                          <Text>Portrait (9:16)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 900 x 1600</ListItem>
                            <ListItem>Min. size: 600 x 1067</ListItem>
                          </UnorderedList>
                          <Text>Note: The maximum file size for any image is 5120 KB.</Text>
                          <Text>Note: Selected images may be auto-cropped. You can always edit afterwards.</Text>

                        </Box>

                        <Box>
                          <Text>Logo guidelines:</Text>
                          <Text>Landscape logo (4:1)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 1200 x 300</ListItem>
                            <ListItem>Min. size: 512 x 128</ListItem>
                          </UnorderedList>
                          <Text>Square logo (1:1)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 1200 x 1200</ListItem>
                            <ListItem>Min. size: 300 x 300</ListItem>
                          </UnorderedList>
                          <Text>Portrait (9:16)</Text>
                          <UnorderedList ml={7}>
                            <ListItem>Recommended size: 900 x 1600</ListItem>
                            <ListItem>Min. size: 600 x 1067</ListItem>
                          </UnorderedList>
                          <Text>Note: The maximum file size for any image is 5120 KB.</Text>
                          <Text>Note: Selected images may be auto-cropped. You can always edit afterwards.</Text>
                        </Box>
                      </HStack>
                    </>}>
                      <IconButton h={'auto'} _hover={{ bg: 'none', color: 'black' }} alignItems={'center'} p={0} m={0} variant={'ghost'} icon={<Icon as={FiHelpCircle} />} />
                    </Tooltip>
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

                  <HStack w={'full'} display={'flex'} direction={'row'}>
                    <VStack alignSelf={'flex-start'}>
                      <AssetsLibraryButton multiple validations={{ max: 15 }} type={'image'} assets={imageAssets} setAssets={setImageAssets} />
                      <AssetsLibraryPreview assets={imageAssets} setAssets={setImageAssets} assetType={'image'} />
                    </VStack>
                    <VStack alignSelf={'flex-start'}>
                      <AssetsLibraryButton multiple validations={{ max: 5 }} type={'image'} assets={logoAssets} setAssets={setLogoAssets} label={'Add Logo'} />
                      <AssetsLibraryPreview assets={logoAssets} setAssets={setLogoAssets} assetType={'logo'} />
                    </VStack>
                    <VStack alignSelf={'flex-start'}>
                      <AssetsLibraryButton multiple validations={{ max: 5 }} type={'video'} assets={videoAssets} setAssets={setVideoAssets} />
                      <AssetsLibraryPreview assets={videoAssets} setAssets={setVideoAssets} assetType={'video'} />
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>


              <Card w={'full'}>
                <CardHeader>

                  <HStack mb={4} alignItems={'center'}>
                    <Text m={0}>Headlines</Text>
                    <Tooltip label={<Text>The headline is the first line of your ad and can be up to 30 characters. Add up to 5 headlines that will be combined with your other assets to generate your ads. The length of the rendered headline will depend on the site it appears on. If it's shortened, it will end with an ellipsis.</Text>}>
                      <IconButton h={'auto'} _hover={{ bg: 'none', color: 'black' }} alignItems={'center'} p={0} m={0} variant={'ghost'} icon={<Icon as={FiHelpCircle} />} />
                    </Tooltip>
                    <Spacer />
                    <SuggestionsButton updateHeadline={updateHeadline} updateDescription={updateDescription} validations={{
                      headlines: { min: 3, max: 15 },
                      descriptions: { min: 2, max: 15 }
                    }} />
                  </HStack>
                </CardHeader>
                <CardBody>

                  <VStack spacing={5}>
                    {fields.map((field, index) => {
                      const textLength = getValues(`headlines.${index}.text`)?.length;

                      return (
                        <>
                          <FormControl isInvalid={textLength > 30} key={field.id}>
                            <FormLabel>
                              <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
                                <Text>Headline {index + 1}</Text>
                                {fields.length > 1 && <IconButton variant={'ghost'} color={'gray.500'} onClick={() => remove(index)} aria-label={"Remove headline"} icon={<Icon as={FiTrash2} />} />}
                              </HStack>
                            </FormLabel>
                            <Input {...register(`headlines.${index}.text`)} placeholder={'Add headline here...'} maxLength={30} />
                            <FormHelperText color={textLength > 30 ? 'red' : 'black'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{textLength} / 30</FormHelperText>
                          </FormControl>
                        </>
                      )
                    })}

                    {fields.length < 5 &&
                      <FormControl>
                        <Button leftIcon={<Icon as={FiPlus} />} variant={'outline'} onClick={() => append({ text: '' })}>Headline</Button>
                      </FormControl>
                    }

                    <FormControl mt={10}>
                      <HStack mb={4} alignItems={'center'}>
                        <FormLabel m={0}>Long Headline</FormLabel>
                        <Tooltip label={<Text>The long headline is the first line of your ad, and appears instead of your short headline in larger ads. Long headlines can be up to 90 characters, and may appear with or without your description.<br /><br />
                          The length of the rendered headline will depend on the site it appears on. If shortened, it will end with an ellipsis.</Text>}>
                          <IconButton h={'auto'} _hover={{ bg: 'none', color: 'black' }} alignItems={'center'} p={0} m={0} variant={'ghost'} icon={<Icon as={FiHelpCircle} />} />
                        </Tooltip>
                      </HStack>

                      <Textarea fontSize={'sm'} {...register('long_headline')} placeholder={'Add long headline here...'} maxLength={90} />
                      <FormHelperText color={getValues(`long_headline`)?.length > 90 ? 'red' : 'black'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues(`long_headline`)?.length} / 90</FormHelperText>

                    </FormControl>

                  </VStack>
                </CardBody>
              </Card>

              <Card w={'full'}>
                <CardHeader>
                  <HStack mb={4} alignItems={'center'}>
                    <Text m={0}>Descriptions</Text>

                    <Tooltip label={<Text>The description adds to the headline and provides additional context or details. It can be up to 90 characters, and may appear after the headline. Add up to 5 descriptions that will be combined with any one of your headlines to generate your ads.
                      <br /><br />
                      The length of the rendered description will depend on the site it appears on. If it's shortened, it will end with an ellipsis. The description doesn't show in all sizes and formats.</Text>}>
                      <IconButton h={'auto'} _hover={{ bg: 'none', color: 'black' }} alignItems={'center'} p={0} m={0} variant={'ghost'} icon={<Icon as={FiHelpCircle} />} />
                    </Tooltip>
                  </HStack>

                </CardHeader>
                <CardBody>

                  <VStack spacing={5}>
                    {descriptions.fields.map((field, index) => {
                      const textLength = getValues(`descriptions.${index}.text`)?.length;

                      return (
                        <>
                          <FormControl isInvalid={textLength > 90} key={field.id}>
                            <FormLabel>
                              <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
                                <Text>Description {index + 1}</Text>
                                {descriptions.fields.length > 1 && <IconButton variant={'ghost'} color={'gray.500'} onClick={() => descriptions.remove(index)} aria-label={"Remove description"} icon={<Icon as={FiTrash2} />} />}
                              </HStack>
                            </FormLabel>
                            <Input {...register(`descriptions.${index}.text`)} placeholder={'Add description here...'} maxLength={90} />
                            <FormHelperText color={textLength > 90 ? 'red' : 'black'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{textLength} / 90</FormHelperText>
                          </FormControl>
                        </>
                      )
                    })}

                    {descriptions.fields.length < 5 &&
                      <FormControl>
                        <Button leftIcon={<Icon as={FiPlus} />} variant={'outline'} onClick={() => descriptions.append({ text: '' })}>Description</Button>
                      </FormControl>
                    }

                  </VStack>
                </CardBody>
              </Card>

              <HStack w={'full'} justifyContent={'flex-start'}>
                <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
                <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
              </HStack>
            </VStack>
          </form>
        </Box>

        <Box w={'full'} justifyContent={"center"} position={'sticky'} top={'125px'}>
          <AggregatedPreview metadata={{
            headlines: wAll.headlines?.map((item) => item.text),
            descriptions: wAll.descriptions?.map((item) => item.text),
            longHeadline: wAll.long_headline,
            businessName: wAll.business_name,
            callToAction: wAll.call_to_action,
            images: imageAssets,
            logos: logoAssets,
            videos: videoAssets,
          }} />
        </Box>
      </HStack >


    </>
  )
}

export default Editor;