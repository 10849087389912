import { Text, Image, Spinner, Center, Flex, Icon } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useImportTiktokCampaignMutation } from '../../../../api/services/integrations';
import Confetti from '../../../../assets/icons/confetti.png'
import { useSelector } from 'react-redux';
import { useFolder } from '../../Contexts/FolderContext';
import { FiAlertCircle } from 'react-icons/fi';

const Import = ({ integration, campaign, adAccount }) => {
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const [importCampaign, { isLoading, isSuccess, isError }] = useImportTiktokCampaignMutation();
  const { folder } = useFolder();

  useEffect(() => {
    importCampaign({
      id: integration.id,
      campaign_id: campaign.campaignId,
      advertiser_id: adAccount.advertiserId,
      workspace_id: currentWorkspace.id,
      folder_id: folder?.id
    })
  }, []);

  return (
    <>
      {isLoading && <>
        <Flex alignItems="center">
          <Spinner />
          <Text ml={2}>Importing</Text>
        </Flex>
      </>}

      {isSuccess && <>
        <Center>
          <Image src={Confetti} w={'36px'} />
        </Center>

        <Text mt={6} textAlign={'center'} fontWeight={400} mb={4}>We're importing your campaign at the moment. Please check back in a minute.</Text>
      </>}

      {isError && <>
        <Center>
          <Icon as={FiAlertCircle} w={6} h={6} color={'red.500'} />
        </Center>

        <Text mt={6} textAlign={'center'} fontWeight={400} mb={4}>Sorry, we're experiencing a temporary issue with TikTok Ads. Please try again in couple of minutes.</Text>
      </>}
    </>
  )
};

export default Import;