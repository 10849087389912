import { baseApi } from "../base/base";

export const sharedClientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClient: builder.query({
      query: (data) => ({ url: `/shared/clients/${data.token}`, params: data }),
      providesTags: ['Client']
    }),

  }),
  overrideExisting: false
});

export const {
  useGetClientQuery
} = sharedClientsApi;
