
import React, { useEffect } from 'react';
import { useGetPublicFolderQuery } from '../../api/services/public/folders';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

const Folder = () => {
  const { uuid, organization } = useParams();
  const navigate = useNavigate();

  const { data: folders, isSuccess } = useGetPublicFolderQuery(uuid, { pollingInterval: 10000 });

  useEffect(() => {
    if (isSuccess) {
      navigate(`/public/${organization}/folders/${uuid}/campaigns/${folders?.folder?.campaigns[0]?.uuid}`)
    }
  }, [isSuccess])

  return (<>
    <Spinner m={10} />
  </>)
}

export default Folder;