import { Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, HStack, Icon, Input, Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FiHelpCircle, FiImage } from 'react-icons/fi';
import { IoIosWarning } from "react-icons/io";
import AssetsLibraryButton from '../../../../AssetsLibrary/Manager/AssetsLibraryButton';
import AssetsLibraryPreview from '../../../../AssetsLibrary/Preview/AssetsLibraryPreview';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import StorefrontSlide from './StorefrontSlide';
import StorefrontPreview from '../../../Preview/Facebook/InstantExperience/StorefrontPreview';
import { useFieldArray } from 'react-hook-form';
import { toSnakeCase, toCamelCase, transformKeysTo } from '../../../../../helpers/apiHelper';

const warningIcon = <Icon alignSelf={'end'} color={'red.500'} as={IoIosWarning} />;

const Storefront = ({register, setValue, getValues, control, missingFields, setRequiredFields}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "meta_instant_experience.fields_data.meta_instant_experience_product_items"
  });

  const instantExperienceDefaultValues = { 
    'meta_instant_experience.instant_experience_type': 'storefront',
    'meta_instant_experience.fields_data.button_label_1': 'Write something...',
    'meta_instant_experience.fields_data.button_destination_1': 'https://www.example.com',
    'meta_instant_experience.fields_data.meta_instant_experience_product_items': null
  };

  useEffect(() => {
    setRequiredFields([
      'meta_instant_experience.instant_experience_type',
      'meta_instant_experience.fields_data.button_label_1',
      'meta_instant_experience.fields_data.button_destination_1',
      'meta_instant_experience.fields_data.meta_instant_experience_product_items'
    ]);
  }, [setRequiredFields]);

  useEffect(() => {
    instantExperienceDefaultValues && Object.entries(instantExperienceDefaultValues).forEach(([key, value]) => {
      if (getValues(key) === undefined || getValues(key) == [])
        setValue(key, value);
    });
  }, []);

  const handleToggleSlide = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(getValues('meta_instant_experience.fields_data.meta_instant_experience_product_items'));

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue('meta_instant_experience.fields_data.meta_instant_experience_product_items', items);
  }

  return (
    <Flex direction="row" gap="5">
      <VStack w="50%" spacing={5} >
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Carousel Media</Text>
              <Icon as={FiHelpCircle} />
            </HStack>
          </CardHeader>
          <CardBody>
            <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement. { missingFields.includes('meta_instant_experience.fields_data.meta_instant_experience_product_items') && warningIcon }</Text>
            <Input type="hidden" {...register('meta_instant_experience.instant_experience_type')} value="storefront" />

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((item, index) => {
                      return (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <StorefrontSlide
                              isOpen={index === openIndex}
                              onToggle={() => handleToggleSlide(index)}
                              setValue={setValue} getValues={getValues}
                              register={register} index={index}
                              provided={provided} item={item}
                              remove={() => remove(index)}
                              attributeName='meta_instant_experience.fields_data.meta_instant_experience_product_items' />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
            <Box w={'full'} display={'flex'} justifyContent={'flex-end'}>
              <Button mt={5} right={0} variant={'outline'} onClick={() => append({})} leftIcon={<Icon as={FiImage} />}>Add new slide</Button>
            </Box>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Fixed button</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <FormControl>
              <FormLabel>Label { missingFields.includes('meta_instant_experience.fields_data.button_label_1') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.button_label_1')} placeholder={'Add label here...'} />
            </FormControl>
            <FormControl>
              <FormLabel>Destination { missingFields.includes('meta_instant_experience.fields_data.button_destination_1') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.button_destination_1')} placeholder={'Add destination here...'} />
            </FormControl>
          </CardBody>
        </Card>
      </VStack>
      <VStack w="50%" spacing={5}>
        <StorefrontPreview instantExperienceData={{
          buttonLabel_1: getValues('meta_instant_experience.fields_data.button_label_1'),
          buttonDestination_1: getValues('meta_instant_experience.fields_data.button_destination_1'),
          metaInstantExperienceProductItems: getValues('meta_instant_experience.fields_data.meta_instant_experience_product_items')
        }} />
      </VStack>
    </Flex>
  );
};

export default Storefront;