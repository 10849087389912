import { Box, HStack, Icon, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import React from "react";
import FacebookSingleImage from "../../Preview/Facebook/SingleImageVideo";
import InstagramSingleImage from "../../Preview/Instagram/SingleImageVideo";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CommonEditor from "./CommonEditor";
import { SiFacebook, SiInstagram } from "react-icons/si";

const SingleImageVideoEditor = ({ isLoading, onFormSubmit, ad }) => {
  const { register, watch, getValues, setValue, handleSubmit, control } = useForm({
    defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
  });

  const asset = ad?.metadata?.asset;
  const assetsState = React.useState(asset == null ? [] : [asset]);

  const facebookLogoAsset = ad?.metadata?.facebookLogo;
  const facebookLogoState = React.useState(facebookLogoAsset == null ? [] : [facebookLogoAsset])

  const instagramLogoAsset = ad?.metadata?.instagramLogo;
  const instagramLogoState = React.useState(instagramLogoAsset == null ? [] : [instagramLogoAsset])

  const [activeTab, setActiveTab] = React.useState(0); // 0 for Facebook, 1 for Instagram

  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <CommonEditor
          getValues={getValues}
          setValue={setValue}
          ad={ad}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          onFormSubmit={onFormSubmit}
          register={register}
          watch={watch}
          control={control}
          assetsState={assetsState}
          facebookLogoState={facebookLogoState}
          instagramLogoState={instagramLogoState}
          activeTab={activeTab}
        />
        <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'115px'}>
          <VStack spacing={10}>
            <Tabs w={'full'} onChange={(index) => setActiveTab(index)}>
              <TabList>
                <Tab w={'full'}>
                  <VStack spacing={3}>
                    <Icon color={'blue.500'} fontSize={'xl'} as={SiFacebook} />
                    <Text fontSize={'sm'} color={'black'}>Facebook</Text>
                  </VStack>
                </Tab>
                <Tab w={'full'}>
                  <VStack spacing={3}>
                    <Image color={'purple.500'} w={6} as={SiInstagram} />
                    <Text fontSize={'sm'} color={'black'}>Instagram</Text>
                  </VStack>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FacebookSingleImage ad={{
                    primaryText: getValues('primary_text'),
                    headline: getValues('headline'),
                    description: getValues('description'),
                    websiteUrl: getValues('website_url'),
                    callToAction: getValues('call_to_action'),
                    asset: assetsState[0][0],
                    facebookPageName: getValues('facebook_page_name'),
                    facebookLogo: facebookLogoState[0][0]
                  }}
                    instantExperienceData={getValues('meta_instant_experience')} />
                </TabPanel>
                <TabPanel>
                  <InstagramSingleImage ad={{
                    primaryText: getValues('primary_text'),
                    headline: getValues('headline'),
                    description: getValues('description'),
                    websiteUrl: getValues('website_url'),
                    callToAction: getValues('call_to_action'),
                    asset: assetsState[0][0],
                    instagramAccountName: getValues('instagram_account_name'),
                    instagramLogo: instagramLogoState[0][0],
                  }}
                    instantExperienceData={getValues('meta_instant_experience')} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default SingleImageVideoEditor;