import React, { useEffect, useState } from 'react';
import { Box, Stack, Heading, Input, Button, Text, Link, Alert, AlertIcon, IconButton, ScaleFade, Checkbox, Grid, Spacer, Flex, HStack, VStack, InputGroup, InputRightElement, Center, Icon } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { useConfirmUserMutation, useCreateUserMutation } from '../../api/services/users';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Link as ReachLink } from 'react-router-dom';
import { FiLock, FiLogIn, FiUnlock } from 'react-icons/fi';

const Register = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
  const [create, { isLoading, isError, isSuccess }] = useCreateUserMutation();
  const [confirmUser] = useConfirmUserMutation();
  const [formData, setFormData] = useState(null);
  const invitation_token = searchParams.get('invitation_token');
  const email = searchParams.get('email');
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  useEffect(() => {
    if (invitation_token) {
      confirmUser({ confirmationToken: invitation_token, update: false })
        .unwrap()
        .catch(() => {
          navigate('/oops');
        });
    }

  }, [searchParams, confirmUser]);

  const onSubmit = (data) => {
    if (invitation_token) {
      data.confirmationToken = invitation_token;
      data.update = true;
      setFormData(data);
      confirmUser(data)
        .unwrap()
        .then(() => {
          navigate(`/login`)
        })
    }
    else {
      setFormData(data);
      create(data);
    }
  };

  return (
    <>
      <Spacer />
      <Box w={'full'} justifyContent={'center'} p={10} display={'flex'}>
        <Stack flexDir={['column-reverse', 'row']} maxW={'container.xl'} direction={['column', 'row']} gap={'7rem'}>
          <Box w={['full', '2xl']} mt={10} >
            <Heading as="h3" fontWeight={400} size="xl">Simplify Your Digital Advertising Journey - From Concept to Launch</Heading>
            <Text color="gray.500" mt={5}>Navigate the complexities of digital ad creation with ease. Our platform bridges the gap between advertising agencies and their clients, ensuring a seamless and understandable journey from the initial concept to the final launch.</Text>
          </Box>

          <Box w={'full'}>
            <Heading as="h2" fontWeight={400} textAlign="center" mb={6}>
              Sign Up
            </Heading>

            {isError &&
              <ScaleFade initialScale={0.9} in={isError}>
                <Alert mt={5} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  Error creating user.
                </Alert>
              </ScaleFade>
            }
            {isSuccess &&
              <ScaleFade initialScale={0.9} in={isSuccess}>
                <Alert mt={5} fontSize={'sm'} status='success'>
                  <AlertIcon />
                  You are almost registered. Navigating....
                </Alert>
                <Navigate to={`/confirmation?email=${formData?.email}`} />
              </ScaleFade>
            }

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <Box>
                  <Text fontSize={'sm'} mb={3}>First Name</Text>
                  <Input placeholder="First Name" {...register("firstName", { required: 'First Name is required' })} name="firstName" />
                  {errors.firstName && <Text fontSize="xs" color="red" mt={1}>{errors.firstName.message}</Text>}
                </Box>

                <Box>
                  <Text fontSize={'sm'} mb={3}>Last Name</Text>
                  <Input placeholder="Last Name" {...register("lastName", { required: 'Last Name is required' })} name="lastName" />
                  {errors.lastName && <Text fontSize="xs" color="red" mt={1}>{errors.lastName.message}</Text>}
                </Box>
                <Spacer />
              </Grid>

              <Box mb={7}>
                <Text fontSize={'sm'} mb={3}>Email address</Text>
                <Input value={email} placeholder="Email" {...register("email",
                  {
                    required: "Email is required",
                    pattern: { value: /\S+@\S+\.\S+/, message: "Entered value does not match email format" }
                  })}
                  readOnly={invitation_token} name="email" />
                {errors.email && <Text fontSize="xs" color="red" mt={1}>{errors.email.message}</Text>}
              </Box>

              <Grid templateColumns="repeat(2, 1fr)" gap={7}>
                <Box>
                  <Text fontSize={'sm'} mb={3}>Password</Text>
                  <Box>
                    <InputGroup size='md' >
                      <Input
                        placeholder='Password'
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register("password", {
                          required: true, minLength: { value: 8, message: "Minimum length is 8 characters" },
                          pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).+$/, message: "Must include at least one lowercase letter, one uppercase letter, a number, and a special character." }
                        })}
                        name="password"
                        type={show ? 'text' : 'password'}
                      />
                      <InputRightElement height="full" width='4.5rem'>
                        <IconButton size={'l'} onClick={handleClick} border="none"
                          variant={"raised"}
                          style={{ backgroundColor: 'transparent' }}>
                          {show ? <FiLock /> : <FiUnlock />}
                        </IconButton>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && (<Text fontSize="xs" color="red" mt={1}>{errors.password.message}</Text>)}
                  </Box>
                </Box>
                <Box>
                  <Text fontSize={'sm'} mb={3}>Confirm Password</Text>
                  <Input placeholder="Confirm Password"
                    {...register("confirmPassword",
                      { required: 'Confirm Password is required', validate: (value) => value === watch('password') || 'Passwords do not match' })}
                    name="confirmPassword"
                    type={show ? 'text' : 'password'} />
                  {errors.confirmPassword && <Text fontSize="xs" color="red" mt={1}>{errors.confirmPassword.message}</Text>}
                </Box>
              </Grid>
              <Checkbox size={'sm'} color={'gray.500'} fontSize={'xs'} {...register("termsAndPrivacy", { required: "You must agree to the terms and privacy policy." })}
                mt={7}>
                By checking this box, you agree to our <Link color="blue.500" href="https://whatcampaign.com/terms-of-service">Terms of Service</Link> and confirm that you have read our <Link color="blue.500" href="https://whatcampaign.com/privacy-policy">Privacy Policy</Link>.
              </Checkbox>
              {errors.termsAndPrivacy && <Text fontSize="xs" color="red" mt={1}>{errors.termsAndPrivacy.message}</Text>}

              {invitation_token &&
                <HStack mt={5} justifyContent="flex-start">
                  <Button isLoading={isLoading} type="submit" variant={'solid'} width="auto" mr={3} isDisabled={!isValid}>
                    Create Account
                  </Button>
                  <Text mt={2} ml={4}><Link color="gray.500" href="/register">Cancel</Link></Text>
                </HStack>
              }
              {!invitation_token &&
                <HStack mt={5} justifyContent="flex-start">
                  <Button leftIcon={<Icon as={FiLogIn} />} isLoading={isLoading} type="submit" width="auto" mr={3} isDisabled={!isValid}>
                    Sign Up
                  </Button>
                  <Text fontSize={'sm'} mt={1} ml={4}> I have an account! <Link as={ReachLink} color="brand.500" to="/login">Sign In</Link></Text>
                </HStack>
              }
            </form>
          </Box>
        </Stack>
      </Box>
    </>
  );

}
export default Register;