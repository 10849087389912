import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context with default values
const FolderContext = createContext({
  folder: null,
  setFolder: (folder) => { },
});

export const useFolder = () => useContext(FolderContext);

export const FolderProvider = ({ children }) => {
  const [folder, setFolder] = useState(null);

  return (
    <FolderContext.Provider value={{ folder, setFolder }}>
      {children}
    </FolderContext.Provider>
  );
};
