import { Card, Text, Image, Divider, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, AvatarBadge, Button, Spacer, Flex, IconButton, CardFooter } from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal, FiX, FiChevronUp, FiRefreshCw, FiMoreVertical, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Wifi from '../../../../assets/icons/tiktok/wifi.svg';
import Mobile from '../../../../assets/icons/tiktok/mobile.svg';
import Battery from '../../../../assets/icons/tiktok/battery.svg';
import Camera from '../../../../assets/icons/snapchat/camera.svg';
import Send from '../../../../assets/icons/snapchat/send.svg';
import { snapchatCallToActions } from "../../../../components/Constants/snapchat_call_to_actions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Next from "./Controls/Next";
import Previous from "./Controls/Previous";
import { transformKeysTo } from "../../../../helpers/apiHelper";

const Story = ({ ad }) => {

    const [currentSlide, setCurrentSlide] = React.useState(0);
    return (
        <Card bg={'black'} w={'sm'} overflow={'hidden'} h={'3xl'} borderRadius={0}>

            {ad.asset == null && <Box backgroundColor="black" zIndex={0} w={'full'} position={'absolute'} h={'full'} />}

            {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
                <Image src={ad.asset?.fileUrl} zIndex={0} w={'full'} position={'absolute'} h={'full'} objectFit={'cover'} />
            </Box>}

            {ad.asset?.assetType == 'video' && <Box maxH={'400px'} overflow={'hidden'}>
                <video key={ad.asset?.fileUrl} muted loop autoPlay style={{ zIndex: 0, position: 'absolute', objectFit: 'cover' }} width={'100%'} height="auto">
                    <source src={ad.asset?.fileUrl} key={ad.asset?.fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>}
            <CardHeader textShadow={'0 0 7px black'} zIndex={100}>
                <HStack px={4} color={'white'} justifyContent={'space-between'}>
                    <Text fontWeight={600} fontSize={'sm'}>9:41</Text>
                    <HStack>
                        <Image w={'18px'} src={Mobile} />
                        <Image w={'18px'} src={Wifi} />
                        <Image w={'18px'} src={Battery} />
                    </HStack>
                </HStack>

                <HStack spacing={2} mt={4}>
                    {ad?.snapchatAdStoryItems?.length > 1 &&
                        Array.from({ length: ad.snapchatAdStoryItems.length || 0 }).map((_, i) =>
                            <Box
                                key={i}
                                w={'full'}
                                h={1.5}
                                borderRadius={'full'}
                                bg={i <= currentSlide ? '#F9D939' : '#96999F'}
                            />
                        )
                    }
                </HStack>
                <HStack mt={6} spacing={4} alignItems={'center'}>
                    <Avatar size={'md'} bg={'gray.300'} src={ad.snapchatLogo?.fileUrl} icon={<></>} name='' />
                    <VStack alignItems={'start'} fontWeight={'bold'} color={'white'} spacing={1} fontSize={'sm'}>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.brandName || ad.snapchatAccountName || 'username')}</Text>
                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.headline || 'Headline')}</Text>
                    </VStack>
                    <Spacer />
                    <Icon as={FiMoreVertical} color={'white'} boxSize={7} />
                </HStack>

            </CardHeader>
            <CardBody fontSize={'sm'} p={0} pt={4}>

                <Swiper
                    centeredSlides
                    allowTouchMove={false}
                    modules={[Navigation]}
                    slidesPerView={1}
                    loop
                    spaceBetween={0}
                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
                >
                    {ad?.snapchatAdStoryItems?.length > 1 &&
                        <HStack w={'full'} marginTop={'55%'} justifyContent={'space-between'} top={0} position={'absolute'} zIndex={2}>
                            <Previous />
                            <Next />
                        </HStack>
                    }
                    {ad?.snapchatAdStoryItems?.map((item) => {
                        const transformedItem = transformKeysTo('snake', structuredClone(item));
                        return (
                            <SwiperSlide style={{ zIndex: 1, padding: '0rem' }}>
                                {transformedItem.asset == null && <Image w={'100%'} />}
                                <Box position="relative" h="500px" background={'#434343'}>
                                    {transformedItem.asset == null && <Image borderTopRadius={'md'} w={'100%'} />}
                                    {transformedItem.asset?.asset_type == 'image' && <Image w="full" h="full" position="absolute" zIndex={0} objectFit="fill" src={transformedItem?.asset?.file_url} />}
                                    <Box display="flex" flexDirection="column" h="full">
                                        {transformedItem.asset?.asset_type == 'video' && !transformedItem?.asset?.file_url.includes('youtube') &&
                                            <video key={transformedItem?.asset?.uuid} autoPlay controls style={{ borderTopLeftRadius: '0.3rem', borderTopRightRadius: '0.3rem', height: '100%', }} muted width={'100%'} loop >
                                                <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                        {transformedItem.asset?.asset_type == 'video' && transformedItem?.asset?.file_url.includes('youtube') &&
                                            <iframe width="100%" height="100%" src={`${transformedItem?.asset?.file_url.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderTopLeftRadius: '0.3rem', borderTopRightRadius: '0.3rem' }}></iframe>
                                        }
                                        <HStack position="absolute" alignItems={'flex-end'} p={4} h={'full'}>
                                            <Box textShadow={'0 0 7px rgba(0,0,0,0.2)'} color={'white'} w={'full'}>
                                                <HStack mt={6} spacing={4} alignItems={'center'} bg={'#65697280'} p={3} width={'100%'} borderRadius={'10px'}>
                                                    <Avatar size={'md'} bg={'gray.300'} src={ad.snapchatLogo?.fileUrl} icon={<></>} name='' />
                                                    <VStack alignItems={'start'} fontWeight={'bold'} color={'white'} spacing={1} fontSize={'sm'}>
                                                        <Text fontSize={'sm'} maxW={'250px'}>{(ad.brandName || ad.snapchatAccountName || 'username')}</Text>
                                                        <Text fontWeight={400} maxW={'140px'}>{(ad.headline || 'Lorem Ipsum Color')}</Text>
                                                        <Text fontWeight={400} maxW={'140px'}> {(transformedItem.website_url && transformedItem.website_url.length > 50) ? new URL(transformedItem.website_url).hostname : (transformedItem.website_url || '')}</Text>
                                                    </VStack>
                                                    <Icon as={FiChevronRight} color={'white'} boxSize={6} ml={7} />
                                                </HStack>
                                            </Box>
                                        </HStack>
                                    </Box>
                                </Box>
                                <Box bg={'black'} pt={3}>
                                    <HStack alignItems={'flex-end'} p={4} mb={4}>
                                        <VStack spacing={1}>
                                            <Image w={'15'} src={Camera} />
                                        </VStack>
                                        <Flex justifyContent={'space-between'} width={'100%'} maxW={'200px'}>
                                            {transformedItem.call_to_action &&
                                                <Button size={'lg'} py={3} fontWeight={'bold'} bg={'#FFFC00'} color={'#000000'} borderRadius={'full'} minW={'200px'}>
                                                    {snapchatCallToActions.find((c) => c.value == transformedItem.call_to_action)?.label || 'CTA'}
                                                </Button>
                                            }
                                        </Flex>
                                        <VStack spacing={1}>
                                            <Image w={'15'} src={Send} />
                                        </VStack>
                                    </HStack>
                                </Box>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </CardBody>
        </Card>
    )
};

export default Story;