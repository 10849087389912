import { baseApi } from "../base/base";

export const sharedCommentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedComments: builder.query({
      query: (data) => ({ url: '/shared/comments', params: data }),
      providesTags: ['Comment']
    }),
    createSharedComment: builder.mutation({
      query: (data) => ({ url: `/shared/comments`, method: 'POST', body: data }),
      invalidatesTags: ['Comment', 'Campaign']
    }),
  }),

  overrideExisting: false
});

export const {
  useGetSharedCommentsQuery, useCreateSharedCommentMutation
} = sharedCommentsApi;
