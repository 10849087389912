import React from "react";
import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { FiFolder, FiInbox } from "react-icons/fi";
import { Link as RouterLink, useLocation } from "react-router-dom";

const SidebarLink = ({ to, icon, text, ...props }) => {
  const location = useLocation();
  const isCurrentPath = location.pathname === to || location.pathname.includes(to);

  return (
    <RouterLink to={to}>
      <Box
        cursor={to == null ? 'default' : 'pointer'}
        py={5}
        pr={5}
        {...props}
      >
        <HStack spacing={4}>
          <Box mr={2} w={'6px'} h={'18px'} borderRightRadius={'md'} bg={isCurrentPath ? "brand.500" : "white"}></Box>
          <Icon
            color={isCurrentPath ? "brand.500" : "gray.500"}
            as={icon}
            fontSize={"1.2rem"}
          />
          <Text
            fontSize={'sm'}
            color={isCurrentPath ? "brand.500" : "gray.500"}
            fontWeight={isCurrentPath ? 700 : 400}
          >
            {text}
          </Text>
        </HStack>
      </Box>
    </RouterLink>
  );
};

export default SidebarLink;
