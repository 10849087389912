import React, { useEffect } from 'react';
import { LuCheckCircle } from "react-icons/lu";
import { Button, Box, Text, Heading, Icon, Spacer, Center, useToast, Flex, Divider, HStack } from "@chakra-ui/react";
import { useResendConfirmationMutation } from '../../api/services/users';
import { useLocation } from 'react-router-dom';
import { FiArrowRightCircle, FiCheckCircle, FiRefreshCw } from 'react-icons/fi';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';

const Confirmation = ({ }) => {
    const [resendConfirmation, { isSuccess, isLoading, isError }] = useResendConfirmationMutation();
    const location = useLocation();
    const toast = useToast();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: "Confirmation email sent again!",
                description: "Your confirmation email has been sent again to: " + email,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            toast({
                title: "Warning",
                description: "Email not found or already confirmed",
                status: "warning",
                duration: 5000,
                isClosable: true,
            })
        }
    }, [isError]);


    return (
        <Center mt={10}>
            <Box textAlign="center">
                <Icon fontSize={'2rem'} color={'green'} as={FiCheckCircle} />
                <Text fontSize="lg" fontWeight={'bold'} mt={10}>
                    Thank you for registering. Please check your email.
                </Text>
                <Text fontSize="sm" mt={5}>
                    We have sent you an email to confirm your account. If didn't receive it, you can request a new one.
                </Text>

                <Spacer height={10} />
                <Button leftIcon={<Icon as={FiRefreshCw} />} variant={'ai'} onClick={() => resendConfirmation({ email: email })} isLoading={isLoading}>Send again</Button>
                <Divider my={10} />

                <HStack justifyContent={'center'}>
                    <Button _hover={{ bg: 'gray.50' }} fontSize={'xs'} py={3} variant={'outline'} leftIcon={<Icon color={'blue.500'} as={FaMicrosoft} />} onClick={() => window.open('https://outlook.com/', '_blank')}>Open Outlook</Button>
                    <Button _hover={{ bg: 'gray.50' }} fontSize={'xs'} py={3} variant={'outline'} leftIcon={<Icon color={'red.500'} as={SiGmail} />} onClick={() => window.open('https://gmail.com/', '_blank')}>Open Gmail</Button>
                </HStack>
                <Spacer height={10} />

            </Box>
        </Center>
    );
};

export default Confirmation;
