import React, { useEffect, useState } from "react";
import { Button, Text, useDisclosure, useToast, Avatar, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Icon, HStack, VStack, InputGroup, Input, useClipboard, Box, Spacer, Divider, InputLeftElement, IconButton, InputRightElement, Tag, Tooltip, Select } from "@chakra-ui/react";
import { useUpdateMediaPlanMutation } from './../../api/services/mediaPlans'
import { useForm } from "react-hook-form";
import { FiCheckSquare, FiLink, FiMail, FiTrash2 } from "react-icons/fi";
import { PiGlobeHemisphereEastLight } from "react-icons/pi";
import { useCreateClientsMediaPlanMutation, useDeleteClientsMediaPlanMutation, useGetClientsMediaPlanQuery } from "../../api/services/clientsMediaPlans";
import Paginator from "../../components/Common/Pagination/Paginator";

const ApprovalRequest = ({ mediaPlan }) => {
    const approvalDisclosure = useDisclosure();

    const permissions = [
        {
            key: 'canview',
            value: 'can view'
        },
        {
            key: 'cancomment',
            value: 'can comment'
        },
        {
            key: 'mustapprove',
            value: 'must approve'
        }
    ];

    const { register, handleSubmit, reset } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [campaigns, setCampaigns] = useState([]);
    const { onCopy, value, hasCopied } = useClipboard(mediaPlan?.shareUrl);

    const [createClient, { isSuccess: isSuccessCreateClient, isLoading: isLoadingCreateClient }] = useCreateClientsMediaPlanMutation();

    const { data, isSuccess } = useGetClientsMediaPlanQuery({ per_page: 7, page: currentPage, media_plan_id: mediaPlan?.id, }, { refetchOnMountOrArgChange: true });
    const [removeClient] = useDeleteClientsMediaPlanMutation();
    const isApproved = mediaPlan?.status == 'approved';

    const onCreateClient = (data) => {
        createClient({ ...data, media_plan_id: mediaPlan?.id });
    };

    useEffect(() => {
        if (isSuccessCreateClient) {
            reset();
        }
    }, [isSuccessCreateClient])

    return (
        <>
            <Button size={'sm'} leftIcon={<Icon as={FiCheckSquare} />} onClick={() => approvalDisclosure.onOpen()} variant={'outline'}>Request approval</Button>

            <Drawer
                size={'sm'}
                isOpen={approvalDisclosure.isOpen}
                placement='right'
                onClose={approvalDisclosure.onClose}>

                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Request Approval</DrawerHeader>

                    <DrawerBody>
                        <Text fontSize={'sm'} color={'gray.500'}>Invite your client to review and collaborate on this project.</Text>
                        <Divider my={8} />

                        <Text fontWeight={'semibold'} fontSize={'sm'} mb={2}>Private access</Text>
                        <Text fontSize={'xs'} mb={3} color={'gray.500'}>People with private access can view and comment the content shared with them.</Text>

                        {isSuccess && <form onSubmit={handleSubmit(onCreateClient)}>
                            <HStack mt={5}>
                                <InputGroup>
                                    <InputLeftElement w={12}>
                                        <Icon color={'gray.500'} as={FiMail} />
                                    </InputLeftElement>
                                    <Input {...register('email', { required: true })} pl={12} pr={'8rem'} type='email' placeholder='Enter e-mail address' />
                                    <InputRightElement width="auto" display="flex" alignItems="center" right="0">
                                        <Select {...register('permission', { required: true })} size="md" width="auto" minWidth="fit-content" border={'none'} borderColor={'none'} outline={'none'}>
                                            {permissions.map((option) => {
                                                if (data?.clientsMediaPlans?.find((item) => item.permission == 'mustapprove') && option.key == 'mustapprove') {
                                                    return;
                                                }

                                                return (
                                                    <option key={option.key} value={option.key}>
                                                        {option.value}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </InputRightElement>
                                </InputGroup>
                                <Box h={'full'}>
                                    <Tooltip isDisabled={campaigns.length > 0} hasArrow placement={'left'} label={'Please select permission before inviting.'}>
                                        <Button type={'submit'} isLoading={isLoadingCreateClient} px={5} fontSize={'sm'} colorScheme='purple'>Invite</Button>
                                    </Tooltip>
                                </Box>
                            </HStack>
                        </form>}


                        <Spacer h={'20px'} />

                        {data?.clientsMediaPlans?.map((client_media_plan) => {
                            const client = client_media_plan.client;

                            return (
                                <>
                                    <HStack justifyContent={'space-between'}>
                                        <HStack pl={3} spacing={3}>
                                            <Avatar size={'xs'} name={client.email} />
                                            <Text fontSize={'sm'}>{client.email}</Text>
                                        </HStack>
                                        <HStack>
                                            <Tag size={'sm'} colorScheme={client_media_plan.permission == 'mustapprove' ? 'green' : 'gray'}>{permissions.find((item) => item.key == client_media_plan.permission)?.value}</Tag>
                                            <IconButton isDisabled={isApproved && client_media_plan.permission == 'mustapprove'} onClick={() => removeClient({ id: client.id, media_plan_id: mediaPlan.id })} p={3} cursor={'pointer'} borderColor={'white'} color={'black'} variant={'outline'} icon={<Icon fontSize={'sm'} as={FiTrash2} />} aria-label={""} />
                                        </HStack>
                                    </HStack>
                                    <Divider my={2} />
                                </>
                            )
                        })}

                        <Spacer h={'20px'} />

                        {isSuccess && data?.clientsMediaPlans?.length > 0 && data?.meta?.pagination?.perPage < data?.meta?.pagination?.totalAmount && (<Paginator meta={data?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />)}


                        <Divider my={10} />

                        <HStack spacing={5}>
                            <Box bg={'#E9E5FD'} borderRadius={'7px'} px={5} py={4}>
                                <Icon fontSize={'x-large'} as={PiGlobeHemisphereEastLight} />
                            </Box>
                            <VStack alignItems={'flex-start'}>
                                <Text fontSize={'sm'} fontWeight={'semibold'}>Share a public link</Text>
                                <Text fontSize={'xs'} color={'gray.500'}>
                                    Anyone with the link can view the campaign but cannot edit content.
                                </Text>
                            </VStack>
                        </HStack>

                        <HStack>
                            <Input readOnly value={value} colorScheme='purple' borderRadius={'7px'} size={'sm'} mt={5} />
                            <Button onClick={onCopy} mt={5} size={'sm'} variant={'outline'} colorScheme={'purple'} borderColor={'purple.500'} px={7} leftIcon={<Icon as={FiLink} />}>{hasCopied ? 'Copied!' : 'Copy link'}</Button>
                        </HStack>

                        <Spacer h={'100px'} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer >
        </>
    )
};

export default ApprovalRequest;