import { baseApi } from "../base/base";

export const sharedCampaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedCampaign: builder.query({
      query: (data) => ({ url: `/shared/campaigns/${data.uuid}`, params: data }),
      providesTags: ['Campaign']
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSharedCampaignQuery
} = sharedCampaignsApi;
