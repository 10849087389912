import React from "react";

import { Box, Button, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { googlePmaxCallToActions } from "../../../../../../../components/Constants/google_pmax_call_to_actions";

const Display7 = ({ variation }) => {
  return (
    <>
      <VStack spacing={5} p={3} border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <HStack spacing={5} w={'full'}>
          <Image w={50} src={variation.logoUrl} />
          <Text fontWeight={600} fontSize={'xl'}>{variation.headline?.truncate(30)}</Text>
        </HStack>
        <Text fontSize={'md'} fontWeight={400}>{variation.description?.truncate(90)}</Text>
        <Text color={'gray.600'} fontSize={'sm'}>{variation.businessName}</Text>
        <Button boxShadow={'lg'} py={2} w={'full'} bg={'#0088ff'} color={'white'} borderRadius={'2xl'} textAlign={'center'} fontSize={'md'} fontWeight={600}>
          {googlePmaxCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}
        </Button>
      </VStack>
    </>
  )
};

export default Display7;