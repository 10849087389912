import React, { useEffect } from "react";

import { HStack, Icon, Image, Text, Avatar, SkeletonText, VStack, Box } from "@chakra-ui/react";
import { FiMenu, FiSearch } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import Display1 from "./Square/Display1";
import Display2 from "./Square/Display2";
import Display3 from "./Square/Display3";
import Display4 from "./Square/Display4";
import Display5 from "./Square/Display5";
import Display6 from "./Square/Display6";
import Display7 from "./Square/Display7";

const SquareAd = ({ variation, isDesktop = false }) => {

  const [style, setStyle] = React.useState(<Display1 variation={variation} />);

  const randomize = () => {
    const styles = [
      <Display1 variation={variation} />,
      <Display2 variation={variation} />,
      <Display3 variation={variation} />,
      <Display4 variation={variation} />,
      <Display5 variation={variation} />,
      <Display6 variation={variation} />,
      <Display7 variation={variation} />
    ];

    const random = Math.floor(Math.random() * styles.length);
    setStyle(styles[random]);
  }

  useEffect(() => {
    randomize();
  }, [variation])

  if (isDesktop) return (
    <>
      <Box mx={'auto'} mt={3} maxW={'sm'}>
        {style}
      </Box>

    </>
  )

  return (
    <>
      <Phone>
        <HStack spacing={10} bg={'gray.200'} justifyContent={'space-between'} px={4} py={2} w={'full'} >
          <Icon as={FiMenu} />
          <Box w={'full'} bg={'gray.300'} h={'20px'}></Box>
          <Icon as={FiSearch} />
        </HStack>

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />

        <Box p={3}>
          {style}
        </Box>

        <SkeletonText p={3} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />
        <SkeletonText p={3} mt={2} w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={2} />
      </Phone>
    </>
  )
};

export default SquareAd;