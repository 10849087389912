import React, { } from "react";
import { Box } from "@chakra-ui/react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { isLoggedIn } from "../../helpers/sessionHelper";
import Sidebar from './PublicLayout/Sidebar';
import GuestHeader from './PublicLayout/GuestHeader';
import { useGetSharedOrganizationQuery } from "../../api/services/shared/organizations";

const PublicLayout = ({ sidebar = null }) => {
  const location = useLocation();
  const { organization, token } = useParams();

  const { data, isLoading } = useGetSharedOrganizationQuery({ organization: organization, uuid: token });

  return (
    <>
      <Box w={"100%"} h={"100vh"}>
        <GuestHeader organization={data} />

        <Box w={'full'} minH="100vh" display={'flex'} gap={2} overflow={'visible'}>
          {sidebar && <>{sidebar}</>}

          <Box ml={{ base: 0, md: sidebar ? 80 : 0 }} w={'full'} overflow={'visible'}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PublicLayout;
