import { Text, Button, useSteps, VStack, HStack, Stack, SkeletonCircle, SkeletonText, Spinner, Radio, RadioGroup } from '@chakra-ui/react';
import React from 'react';
import { useGetGoogleAdAccountsQuery, useGetGoogleCampaignsQuery } from '../../../../api/services/integrations';

const Campaigns = ({ customerId, accountId, provider, setDisableNext, selectedCampaign, setSelectedCampaign }) => {
  const { data: campaigns, isLoading, isFetching } = useGetGoogleCampaignsQuery({ id: provider?.id, customer_id: customerId, account_id: accountId }, { refetchOnMountOrArgChange: true });

  React.useEffect(() => {
    if (isLoading || isFetching || selectedCampaign == '0') {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [isLoading, isFetching, selectedCampaign]);

  return (
    <>
      <Text fontWeight={500} mb={4}>Select Campaign</Text>

      {(isLoading || isFetching) &&
        <VStack my={6} spacing={6} justifyContent={'center'}>
          <Spinner />
          <Text textAlign={'center'} color={'gray'}>We're loading Campaigns from Google... Please wait!</Text>
        </VStack>}

      {!(isLoading || isFetching) && campaigns?.campaigns?.map((campaign) => (
        <>
          <HStack onClick={() => setSelectedCampaign(campaign)} bg={selectedCampaign?.id == campaign.id ? 'blue.50' : 'white'} cursor={'pointer'} py={2} px={4} rounded={'md'} border={'solid 1px'} borderColor={selectedCampaign?.id == campaign.id ? 'blue.400' : 'gray.300'} mb={4} spacing={5}>
            <Radio onClick={(e) => e.preventDefault()} isChecked={selectedCampaign?.id == campaign.id} />

            <VStack alignItems={'flex-start'}>
              <Text>{campaign.name || 'Untitled'}</Text>
              <Text color={'gray'} fontSize={'xs'}>{campaign.advertisingChannelType}, {campaign.id}</Text>
            </VStack>
          </HStack>
        </>
      ))}

    </>
  )
};

export default Campaigns;