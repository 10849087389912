import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";

const DesktopClosed2 = ({ variation }) => {

  return (
    <>
       <HStack mt={5} boxShadow={'base'} p={3} justifyContent={'space-between'} w={'full'}>
        <VStack alignItems={'flex-start'} spacing={0}>
          <Text fontSize={'x-small'} fontWeight={'bold'}>Ad</Text>
          <HStack>
            <Avatar w={4} h={4} src={variation.logoUrl} />
            <Text fontWeight={'bold'} fontSize={'xs'}>{variation.businessName}</Text>
          </HStack>
        </VStack>

        <HStack>
          <Image h={10} src={variation.imageUrl} borderRadius={'lg'} />
          <VStack alignItems={'flex-start'} spacing={0}>
            <Text fontWeight={'semibold'} fontSize={'x-small'}>{variation.headline?.truncate(30)}</Text>
            <Text fontSize={'xx-small'}>{variation.description?.truncate(90)}</Text>
          </VStack>
        </HStack>

        <VStack>
          <Icon as={FiMoreVertical} />
          <Icon as={FiExternalLink} />
        </VStack>
      </HStack>
    </>
  )
};

export default DesktopClosed2;