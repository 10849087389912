const currencyMaps = {
  AED: { symbol: 'د.إ', position: 'front' },
  AFN: { symbol: '؋', position: 'back' },
  ALL: { symbol: 'L', position: 'front' },
  AMD: { symbol: '֏', position: 'back' },
  ANG: { symbol: 'ƒ', position: 'front' },
  AOA: { symbol: 'Kz', position: 'back' },
  ARS: { symbol: '$', position: 'front' },
  AUD: { symbol: '$', position: 'front' },
  AWG: { symbol: 'ƒ', position: 'front' },
  AZN: { symbol: '₼', position: 'back' },
  BAM: { symbol: 'KM', position: 'back' },
  BBD: { symbol: '$', position: 'front' },
  BDT: { symbol: '৳', position: 'front' },
  BGN: { symbol: 'лв', position: 'back' },
  BHD: { symbol: '.د.ب', position: 'back' },
  BIF: { symbol: 'FBu', position: 'back' },
  BMD: { symbol: '$', position: 'front' },
  BND: { symbol: '$', position: 'front' },
  BOB: { symbol: '$b', position: 'front' },
  BRL: { symbol: 'R$', position: 'front' },
  BTC: { symbol: '₿', position: 'front' },
  BTN: { symbol: 'Nu.', position: 'front' },
  BWP: { symbol: 'P', position: 'front' },
  BYN: { symbol: 'Br', position: 'back' },
  BYR: { symbol: 'Br', position: 'back' },
  BZD: { symbol: 'BZ$', position: 'front' },
  CAD: { symbol: '$', position: 'front' },
  CDF: { symbol: 'FC', position: 'back' },
  CHF: { symbol: 'CHF', position: 'back' },
  CLP: { symbol: '$', position: 'front' },
  CNH: { symbol: '¥', position: 'front' },
  CNY: { symbol: '¥', position: 'front' },
  COP: { symbol: '$', position: 'front' },
  CRC: { symbol: '₡', position: 'front' },
  CUP: { symbol: '₱', position: 'front' },
  CZK: { symbol: 'Kč', position: 'back' },
  DKK: { symbol: 'kr', position: 'back' },
  DOP: { symbol: 'RD$', position: 'front' },
  DZD: { symbol: 'دج', position: 'back' },
  EGP: { symbol: '£', position: 'front' },
  ETB: { symbol: 'Br', position: 'back' },
  EUR: { symbol: '€', position: 'front' },
  FJD: { symbol: '$', position: 'front' },
  GBP: { symbol: '£', position: 'front' },
  GEL: { symbol: '₾', position: 'back' },
  GHS: { symbol: 'GH₵', position: 'front' },
  GIP: { symbol: '£', position: 'front' },
  GNF: { symbol: 'FG', position: 'back' },
  GTQ: { symbol: 'Q', position: 'front' },
  GYD: { symbol: '$', position: 'front' },
  HKD: { symbol: '$', position: 'front' },
  HNL: { symbol: 'L', position: 'front' },
  HRK: { symbol: 'kn', position: 'back' },
  HUF: { symbol: 'Ft', position: 'back' },
  IDR: { symbol: 'Rp', position: 'front' },
  ILS: { symbol: '₪', position: 'front' },
  INR: { symbol: '₹', position: 'front' },
  IQD: { symbol: 'ع.د', position: 'back' },
  IRR: { symbol: '﷼', position: 'back' },
  ISK: { symbol: 'kr', position: 'back' },
  JMD: { symbol: 'J$', position: 'front' },
  JOD: { symbol: 'JD', position: 'back' },
  JPY: { symbol: '¥', position: 'front' },
  KES: { symbol: 'KSh', position: 'front' },
  KGS: { symbol: 'лв', position: 'back' },
  KHR: { symbol: '៛', position: 'back' },
  KRW: { symbol: '₩', position: 'front' },
  KWD: { symbol: 'KD', position: 'back' },
  KYD: { symbol: '$', position: 'front' },
  KZT: { symbol: '₸', position: 'back' },
  LAK: { symbol: '₭', position: 'back' },
  LBP: { symbol: '£', position: 'front' },
  LKR: { symbol: '₨', position: 'front' },
  LRD: { symbol: '$', position: 'front' },
  LSL: { symbol: 'M', position: 'front' },
  LTC: { symbol: 'Ł', position: 'front' },
  LTL: { symbol: 'Lt', position: 'back' },
  LVL: { symbol: 'Ls', position: 'back' },
  LYD: { symbol: 'LD', position: 'back' },
  MAD: { symbol: 'MAD', position: 'back' },
  MDL: { symbol: 'lei', position: 'back' },
  MGA: { symbol: 'Ar', position: 'back' },
  MKD: { symbol: 'ден', position: 'back' },
  MMK: { symbol: 'K', position: 'front' },
  MNT: { symbol: '₮', position: 'front' },
  MOP: { symbol: 'MOP$', position: 'front' },
  MRO: { symbol: 'UM', position: 'back' },
  MRU: { symbol: 'UM', position: 'back' },
  MUR: { symbol: '₨', position: 'front' },
  MVR: { symbol: 'Rf', position: 'back' },
  MWK: { symbol: 'MK', position: 'front' },
  MXN: { symbol: '$', position: 'front' },
  MYR: { symbol: 'RM', position: 'front' },
  MZN: { symbol: 'MT', position: 'back' },
  NAD: { symbol: '$', position: 'front' },
  NGN: { symbol: '₦', position: 'front' },
  NIO: { symbol: 'C$', position: 'front' },
  NOK: { symbol: 'kr', position: 'back' },
  NPR: { symbol: '₨', position: 'front' },
  NZD: { symbol: '$', position: 'front' },
  OMR: { symbol: '﷼', position: 'back' },
  PAB: { symbol: 'B/.', position: 'front' },
  PEN: { symbol: 'S/.', position: 'front' },
  PGK: { symbol: 'K', position: 'front' },
  PHP: { symbol: '₱', position: 'front' },
  PKR: { symbol: '₨', position: 'front' },
  PLN: { symbol: 'zł', position: 'back' },
  PYG: { symbol: 'Gs', position: 'back' },
  QAR: { symbol: '﷼', position: 'back' },
  RON: { symbol: 'lei', position: 'back' },
  RSD: { symbol: 'Дин.', position: 'back' },
  RUB: { symbol: '₽', position: 'back' },
  RWF: { symbol: 'R₣', position: 'front' },
  SAR: { symbol: '﷼', position: 'back' },
  SBD: { symbol: '$', position: 'front' },
  SCR: { symbol: '₨', position: 'front' },
  SDG: { symbol: 'ج.س.', position: 'back' },
  SEK: { symbol: 'kr', position: 'back' },
  SGD: { symbol: 'S$', position: 'front' },
  SLL: { symbol: 'Le', position: 'front' },
  SOS: { symbol: 'S', position: 'front' },
  SRD: { symbol: '$', position: 'front' },
  SSP: { symbol: '£', position: 'front' },
  STD: { symbol: 'Db', position: 'back' },
  STN: { symbol: 'Db', position: 'back' },
  SVC: { symbol: '$', position: 'front' },
  SYP: { symbol: '£', position: 'front' },
  SZL: { symbol: 'E', position: 'front' },
  THB: { symbol: '฿', position: 'front' },
  TJS: { symbol: 'SM', position: 'back' },
  TMT: { symbol: 'T', position: 'back' },
  TND: { symbol: 'د.ت', position: 'back' },
  TOP: { symbol: 'T$', position: 'front' },
  TRY: { symbol: '₺', position: 'front' },
  TTD: { symbol: 'TT$', position: 'front' },
  TVD: { symbol: '$', position: 'front' },
  TWD: { symbol: 'NT$', position: 'front' },
  TZS: { symbol: 'TSh', position: 'front' },
  UAH: { symbol: '₴', position: 'back' },
  UGX: { symbol: 'USh', position: 'front' },
  USD: { symbol: '$', position: 'front' },
  UYU: { symbol: '$U', position: 'front' },
  UZS: { symbol: 'лв', position: 'back' },
  VEF: { symbol: 'Bs', position: 'front' },
  VES: { symbol: 'Bs.S', position: 'front' },
  VND: { symbol: '₫', position: 'back' },
  VUV: { symbol: 'VT', position: 'back' },
  WST: { symbol: 'WS$', position: 'front' },
  XAF: { symbol: 'FCFA', position: 'back' },
  XBT: { symbol: 'Ƀ', position: 'front' },
  XCD: { symbol: '$', position: 'front' },
  XOF: { symbol: 'CFA', position: 'back' },
  XPF: { symbol: '₣', position: 'back' },
  YER: { symbol: '﷼', position: 'back' },
  ZAR: { symbol: 'R', position: 'front' },
  ZMW: { symbol: 'ZK', position: 'front' },
  ZWD: { symbol: 'Z$', position: 'front' }
};

export const getCurrencySymbol = (currency) => {
  const currencyInfo = currencyMaps[currency];
  const currencyData = currencyInfo ? currencyInfo : { symbol: currency, position: 'front' };

  return currencyData.symbol;
};

export const formatPrice = (currency, amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(amount);
};
