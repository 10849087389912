import { baseApi } from "../base/base";

export const sharedClientsMediaPlansApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharedClientsMediaPlan: builder.query({
      query: (data) => ({ url: '/shared/clients_media_plans', params: data }),
      providesTags: ['ClientMediaPlan']
    }),

  }),
  overrideExisting: false
});

export const {
  useGetSharedClientsMediaPlanQuery
} = sharedClientsMediaPlansApi;
