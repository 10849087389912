import { Box, Card, CardBody, CardHeader, HStack, Icon, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import React from "react";
import { FiBarChart2, FiHelpCircle } from "react-icons/fi";
import { formatDateTime } from "../../../../helpers/dateHelper";
import { getCurrencySymbol } from "../../../../helpers/currencyHelper";

const DisplayInsights = ({ insight }) => {
  return (
    <>
      {insight && <>
        <Card bg={'gray.50'} mt={4} maxW={'xl'} borderRadius={'16px'}>
          <CardHeader>
            <HStack justifyContent={'space-between'}>
              <HStack>
                <Text>Ad Insights</Text>
                <Icon as={FiBarChart2} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Updated at {formatDateTime(insight?.updatedAt)}</Text>
            </HStack>
          </CardHeader>
          <CardBody fontSize={'sm'} p={3} px={5}>

            <StatGroup gap={5}>
              <Stat>
                <StatLabel>Impressions</StatLabel>
                <StatNumber>{insight?.impressions}</StatNumber>
                <StatHelpText fontSize={'xs'}>
                  Number of times the ad was shown.
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>CTR</StatLabel>
                <StatNumber>{insight?.ctr}%</StatNumber>
                <StatHelpText fontSize={'xs'}>
                  Click Through Rate
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>CPC</StatLabel>
                <StatNumber> {getCurrencySymbol(insight?.currencyCode)} {insight?.cpc} </StatNumber>
                <StatHelpText fontSize={'xs'}>
                  Cost Per Click
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>CPM</StatLabel>
                <StatNumber>{getCurrencySymbol(insight?.currencyCode)} {insight?.cpm} </StatNumber>
                <StatHelpText fontSize={'xs'}>
                  Cost Per Thousand
                </StatHelpText>
              </Stat>
            </StatGroup>

          </CardBody>
        </Card>
      </>}
    </>
  )
};

export default DisplayInsights;