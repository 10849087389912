import React, { useState } from 'react';
import { Box, Radio, RadioGroup, Stack, VStack, Text, Flex, Image } from '@chakra-ui/react';
import CustomerAcquisitionImage from '../../../../../assets/images/meta_instant_experience_image.jpeg';
import SellProductsImage from '../../../../../assets/images/meta_instant_experience_image_1.jpeg';
import StorefrontAndStorytellingImage from '../../../../../assets/images/meta_instant_experience_image_2.jpeg';

const ChooseTemplate = ({ setTemplate, availableTemplates, selectedTemplate }) => {

  return (
    <VStack spacing="24px" align="start">
      <RadioGroup onChange={setTemplate} value={selectedTemplate} w="100%">
        <Stack direction="column" spacing={5}>
          {availableTemplates.includes('customer_acquisition') &&
            <Flex cursor={'pointer'} onClick={() => setTemplate('customerAcquisition')} w="100%" borderWidth="1px" borderRadius="md" p={4} position='relative'>
              <Image src={CustomerAcquisitionImage} w={'80px'} objectFit="contain"></Image>
              <Box pl={4}>
                <Text fontWeight={'bold'}>Customer Acquisition</Text>
                <Flex justifyContent="flex-start" alignItems="flex-start">
                  <Radio value="customerAcquisition" position={'absolute'} top={4} right={4}></Radio>
                  <Text fontSize="xs" mt={4} color={'gray.600'}>
                    Encourage new customers to discover your brand and products with a mobile landing page that encourages action.
                  </Text>
                </Flex>
              </Box>
            </Flex>
          }
          {availableTemplates.includes('sell_products') &&
            <Flex cursor={'pointer'} onClick={() => setTemplate('sell_products')} w="100%" borderWidth="1px" borderRadius="md" p={4} position="relative">
              <Image src={SellProductsImage} w="80px" objectFit="contain"></Image>
              <Box pl={4}>
                <Text fontWeight={'bold'}>Sell products (without catalogue)</Text>
                <Flex justifyContent="flex-start" alignItems="flex-start">
                  <Radio value="sell_products" position="absolute" top={4} right={4}></Radio>
                  <Text fontSize="xs" mt={4} color={'gray.600'}>
                    Create a mobile shopping experience by uploading your product information instead of using a catalogue.
                  </Text>
                </Flex>
              </Box>
            </Flex>
          }
          {availableTemplates.includes('storytelling') &&
            <Flex cursor={'pointer'} onClick={() => setTemplate('storytelling')} w="100%" borderWidth="1px" borderRadius="md" p={4} position="relative">
              <Image src={StorefrontAndStorytellingImage} w="80px" objectFit="contain"></Image>
              <Box pl={4}>
                <Text fontWeight={'bold'}>Storytelling</Text>
                <Flex justifyContent="flex-start" alignItems="flex-start">
                  <Radio value="storytelling" position="absolute" top={4} right={4}></Radio>
                  <Text fontSize="xs" mt={4} color={'gray.600'}>
                    Give people an engaging way to explore your brand, product or service.
                  </Text>
                </Flex>
              </Box>
            </Flex>
          }
          {availableTemplates.includes('storefront') &&
            <Flex cursor={'pointer'} onClick={() => setTemplate('storefront')} w="100%" borderWidth="1px" borderRadius="md" p={4} position="relative">
              <Image src={StorefrontAndStorytellingImage} w="80px" objectFit="contain"></Image>
              <Box pl={4}>
                <Text fontWeight={'bold'}>Storefront</Text>
                <Flex justifyContent="flex-start" alignItems="flex-start">
                  <Radio value="storefront" position="absolute" top={4} right={4}></Radio>
                  <Text fontSize="xs" mt={4} color={'gray.600'}>
                    Give people an engaging way to explore your brand, product or service.
                  </Text>
                </Flex>
              </Box>
            </Flex>
          }
        </Stack>
      </RadioGroup>
    </VStack >
  );
};

export default ChooseTemplate;