import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import SnapchatCollection from "../../Preview/Snapchat/Collection";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CollectionCommonEditor from "./Collection/CommonEditor";

const CollectionEditor = ({ isLoading, onFormSubmit, ad }) => {
    const { register, watch, getValues, setValue, handleSubmit, control } = useForm({
        defaultValues: transformKeysTo('snake', structuredClone(ad?.metadata))
    });

    const asset = ad?.metadata?.asset;
    const assetsState = React.useState(asset == null ? [] : [asset]);

    const snapchatLogoAsset = ad?.metadata?.snapchatLogo;
    const snapchatLogoState = React.useState(snapchatLogoAsset == null ? [] : [snapchatLogoAsset])
    const wAll = watch();

    return (
        <>
            <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
                <CollectionCommonEditor getValues={getValues} setValue={setValue} ad={ad} control={control} isLoading={isLoading} handleSubmit={handleSubmit} onFormSubmit={onFormSubmit} register={register} watch={watch} snapchatLogoState={snapchatLogoState} assetsState={assetsState} />
                <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'125px'}>
                    <VStack spacing={10}>
                        <SnapchatCollection ad={{
                            headline: getValues('headline'),
                            brandName: getValues('brand_name'),
                            websiteUrl: getValues('website_url'),
                            callToAction: getValues('call_to_action'),
                            asset: assetsState[0][0],
                            snapchatAccountName: getValues('snapchat_account_name'),
                            collectionThumbnailsCount: getValues('collection_thumbnails_count'),
                            snapchatLogo: snapchatLogoState[0][0],
                            snapchatAdCollectionItems: getValues('snapchat_ad_collection_items')
                        }} />
                    </VStack>
                </Box>
            </HStack>
        </>
    )
}

export default CollectionEditor;