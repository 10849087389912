import React, { useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
  Input,
  useToast,
  FormControl,
  FormLabel,
  FormHelperText,
  Divider,
} from '@chakra-ui/react'
import { useDeleteMediaPlanMutation, useUpdateMediaPlanMutation } from '../../api/services/mediaPlans';
import { useForm } from 'react-hook-form';

const Rename = ({ mediaPlan, disclosure }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = disclosure;
  const cancelRef = React.useRef();
  const { register, setValue, handleSubmit } = useForm();
  const [updateMediaPlan, { isLoading, isSuccess, isError }] = useUpdateMediaPlanMutation();

  const onSubmit = (data) => {
    updateMediaPlan({
      ...data,
      id: mediaPlan?.id
    })
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: "Media Plan renamed!",
        status: "success",
      });
      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast({
        description: "Error during renaming media plan! Please contact support.",
        status: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    setValue('name', mediaPlan?.name);
  }, [mediaPlan])

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        size={'lg'}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Rename Media Plan
              <Divider mt={5} />
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <AlertDialogBody>
                <FormControl mb={5}>
                  <FormLabel>Media Plan name</FormLabel>
                  <FormHelperText color={'gray.400'}>Enter a name for your media plan.</FormHelperText>
                  <Input defaultValue={mediaPlan?.name} mt={3} {...register('name', { required: true })} placeholder='eg. Summer Campaign' />
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button type='button' colorScheme='gray' _hover={{ bg: 'gray.50' }} variant={'outline'} ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button isDisabled={isLoading} type='submit' ml={3}>
                  Save
                </Button>
              </AlertDialogFooter>
            </form>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
};

export default Rename;