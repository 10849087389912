
import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import SidebarFolders from "../../../views/Public/Components/SidebarFolders";

const Sidebar = () => {

  return (
    <>
      <Box maxW={'xs'} overflowY={'scroll'} h={'full'} display={{ base: 'none', sm: 'none', md: 'flex' }} position={'fixed'} flexDir={'column'} borderRight={'solid 1px'} minW={'xs'} borderRightColor={'gray.200'}>
        <SidebarFolders />
      </Box>
    </>
  )
}

export default Sidebar;