import { SiGoogleads, SiMeta, SiTiktok, SiLinkedin, SiSnapchat } from "react-icons/si";

export const campaignPlatforms = [
  { label: 'Google Search Ads', value: 'google_search', icon: SiGoogleads, enabled: true },
  { label: 'Google Display Ads', value: 'google_display', icon: SiGoogleads, enabled: true },
  { label: 'Google Performance Max', value: 'google_pmax', icon: SiGoogleads, enabled: true },
  { label: 'Google Demand Gen', value: 'google_demandgen', icon: SiGoogleads, enabled: true },
  { label: 'Meta', value: 'meta', icon: SiMeta, enabled: true },
  { label: 'Tiktok', value: 'tiktok', icon: SiTiktok, enabled: true },
  { label: 'Linkedin', value: 'linkedin', icon: SiLinkedin, enabled: true },
  { label: 'Snapchat', value: 'snapchat', icon: SiSnapchat, enabled: true },
  { label: 'Meta Instant Experience', value: 'meta_instant_experience', icon: SiMeta, enabled: true },
];