import { baseApi } from "../base/base";

export const publicMediaPlanCampaignsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicMediaPlanCampaigns: builder.query({
      query: (data) => ({ url: `/public/media_plans/${data.uuid}/media_plan_campaigns`, params: data }),
      providesTags: ['MediaPlan']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetPublicMediaPlanCampaignsQuery
} = publicMediaPlanCampaignsApi;
