import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  IconButton,
  Spacer,
  Text,
  Card,
  CardBody,
  CardHeader,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi';

function CalloutCard({
  callout,
  index,
  handleInputChange,
  handleRemoveCallout
}) {
  const [cardVisible, setCardVisible] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!cardVisible);
  };

  return (
    <Card w={'full'}>
      <CardHeader pb="4">
        <HStack>
          <Text>Callout {index + 1}</Text>
          <Spacer />
          <IconButton variant={'ghost'} color={'#96999F'} onClick={() => handleRemoveCallout(index)} aria-label={"Remove headline"} icon={<Icon as={FiTrash2} />} />
          <IconButton
            icon={cardVisible ? <FiChevronUp /> : <FiChevronDown />}
            variant="ghost"
            onClick={toggleCardVisibility}
            aria-label={cardVisible ? "Collapse Descriptions" : "Expand Descriptions"}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        {cardVisible && (<Box key={index} p={2} pt={0} borderRadius='md'>
          <VStack spacing={5}>
            <FormControl>
              <Input
                type='text'
                name="text"
                placeholder={"Callout text "+(index + 1)}
                value={callout.text}
                onChange={(e) => handleInputChange(e, index)}
              />
            </FormControl>
          </VStack>
        </Box>)}
      </CardBody>
    </Card>
  );
}

export default CalloutCard;
