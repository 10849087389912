import React from "react";

import { HStack, Image, Text, Avatar, SkeletonText, VStack } from "@chakra-ui/react";

const DesktopSearchAd = ({ variation, withImage = false }) => {
  return (
    <>
      <HStack mt={10} boxShadow={'base'} p={2} w={'full'} alignItems={'flex-start'} justifyContent={'space-between'}>

        <VStack alignItems={'flex-start'}>
          <Text fontWeight={'bold'} fontSize={'xx-small'}>Sponsored</Text>
          <HStack>
            <Avatar w={5} h={5} borderRadius={0} src={variation.logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontSize={'x-small'}>{variation.businessName}</Text>
              <Text noOfLines={1} fontSize={'xx-small'}>{variation.finalUrl} </Text>
            </VStack>
          </HStack>

          <Text fontSize={'md'} color={'#1558d6'} fontWeight={500}>
            {variation.headlines[Math.floor(Math.random() * variation.headlines.length)]?.truncate(30)} - {variation.headlines[Math.floor(Math.random() * variation.headlines.length)]?.truncate(30)}
          </Text>

          <HStack w={'full'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Text fontSize={'x-small'}>{variation.description?.truncate(90)} {variation?.snippets?.shuffle()?.join('. ')}</Text>
          </HStack>

          <HStack>
            <Text color={'#1558d6'} fontSize={'x-small'}>{variation?.sitelinks?.shuffle()?.join(' · ')}</Text>
          </HStack>
        </VStack>

        {withImage && <Image rounded={'md'} w={20} src={variation.imageUrl} />}
      </HStack>


      {[1, 2, 3].map((i) => (<>
        <HStack rounded={'md'} p={3} border={'solid 1px'} borderColor={'gray.200'} mt={4} w={'full'}>
          <SkeletonText w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />
        </HStack>
      </>))}

    </>
  )
};

export default DesktopSearchAd;