import React, { useEffect, useState } from 'react';

import { Box, Heading, VStack, Grid, Flex, Input, Stack, Button, Text, useDisclosure, Spacer, HStack, useToast, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Icon, Tag, IconButton, Menu, MenuButton, MenuItem, MenuList, MenuDivider } from "@chakra-ui/react";
import { useGetCampaignsQuery } from '../../api/services/campaigns';
import { useDispatch, useSelector } from 'react-redux';
import Create from './Create';
import Import from './Import';
import Rename from './Rename';
import { useUpdateUserMutation } from '../../api/services/users';
import { useGetFoldersQuery } from '../../api/services/folders';
import { FiDownloadCloud, FiEdit, FiFolder, FiMoreVertical, FiPlus, FiShare, FiShare2, FiYoutube } from 'react-icons/fi';
import PlatformIcon from './Components/PlatformIcon';
import CampaignMode from './Components/CampaignMode';
import { useNavigate } from 'react-router-dom';
import { campaignPlatforms } from '../../components/Constants/campaigns';
import { FolderProvider, useFolder } from './Contexts/FolderContext';
import Paginator from '../../components/Common/Pagination/Paginator';
import Share from './Share';
import Onboarding from '../OnboardingWizard/Onboarding';

const CampaignsContent = () => {
  const [search, setSearch] = React.useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  const { folder, setFolder } = useFolder();
  const [currentPage, setCurrentPage] = useState(1);
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);
  const currentUser = useSelector((state) => state.user.currentUser);
  const createDisclosure = useDisclosure();
  const importDisclosure = useDisclosure();
  const renameDisclosure = useDisclosure();
  const shareDisclosure = useDisclosure();
  const toast = useToast();
  const toastId = 'initLoginToast';
  const [updateUser] = useUpdateUserMutation();

  const navigate = useNavigate();

  const { data: folders, isSuccess } = useGetFoldersQuery({ per_page: 10, page: currentPage, workspace_id: currentWorkspace?.id, query: search }, { pollingInterval: 10000 });

  useEffect(() => {
    if (currentUser?.initLogin && !toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: "If you are using AdBlocker there is a possibility that certain features might not work properly. To avoid any issues we suggest that you whitelist whatcampaign.com or turn off the AdBlocker for this app.",
        status: "warning",
        duration: null,
        isClosable: true,
        variant: "top-accent",
        onCloseComplete: () => {
          updateUser({ id: currentUser.id, initLogin: false });
        },
      });
    }
  }, [currentUser?.initLogin]);

  const [openIds, setOpenIds] = useState(() => {
    const storedIds = localStorage.getItem('foldersState');
    return storedIds ? JSON.parse(storedIds) : [];
  });

  const handleToggle = (id) => {
    setOpenIds(prevIds => (
      prevIds.includes(id)
        ? prevIds.filter(i => i !== id)
        : [...prevIds, id]
    ));
  };

  useEffect(() => {
    localStorage.setItem('foldersState', JSON.stringify(openIds));
  }, [openIds]);


  return (
    <>
      <Box p={10}>
        <Box w='100%'>
          <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                Campaigns
              </Heading>

              <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                <Input onChange={(e) => setSearch(e.target.value)} w={['100%', 'md']} placeholder='Search campaigns...' />
                <Create disclosure={createDisclosure} />
                <Import disclosure={importDisclosure} />
                <Rename disclosure={renameDisclosure} />
                {folder != null && <Share disclosure={shareDisclosure} folder={folder} />}
              </Stack>
            </Flex>

            {isSuccess && folders?.folders?.length > 0 && (
              <>
                <Accordion allowMultiple index={openIds.map(id => folders?.folders?.findIndex(item => item.id === id))} defaultIndex={openIds.map(id => folders?.folders?.findIndex(item => item.id === id))}>
                  {folders?.folders?.map((folderItem, index) => {
                    const isHovered = hoveredItem === folderItem.id;

                    return (
                      <AccordionItem key={index} onMouseOver={() => setHoveredItem(folderItem.id)} onMouseOut={() => setHoveredItem(null)} border={0} >
                        <AccordionButton justifyContent={'space-between'} _hover={{ bg: 'transparent' }} px={0} py={6} borderBottom={'solid 1px'} borderColor={'gray.200'} onClick={() => handleToggle(folderItem.id)}>
                          <HStack>
                            <AccordionIcon fontSize={'2xl'} />
                            <Icon fontSize={'xl'} as={FiFolder} />
                            <Text fontWeight={'semibold'} pl={3}>{folderItem.name}</Text>
                          </HStack>
                          <HStack visibility={isHovered ? 'visible' : 'hidden'}>
                            <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFolder(folderItem); createDisclosure.onOpen() }} leftIcon={<Icon as={FiPlus} />} size={'sm'} variant={'ghost'} m={0}>New Channel</Button>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<Icon as={FiMoreVertical} />}
                                variant='ghost'
                                onClick={(e) => { e.stopPropagation(); }}
                              />
                              <MenuList>
                                <MenuItem onClick={(e) => { setFolder(folderItem); importDisclosure.onOpen(); e.stopPropagation() }} icon={<Icon as={FiDownloadCloud} />} >
                                  Import
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={(e) => { setFolder(folderItem); shareDisclosure.onOpen(); e.stopPropagation() }} icon={<Icon as={FiShare} />} >
                                  Share
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={(e) => { setFolder(folderItem); renameDisclosure.onOpen(); e.stopPropagation() }} icon={<Icon as={FiEdit} />} >
                                  Rename
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </HStack>
                        </AccordionButton>
                        <AccordionPanel p={0}>
                          {folderItem.campaigns.map(campaign => {
                            return (
                              <>
                                <HStack _hover={{ bg: 'gray.50' }} cursor={'pointer'} onClick={() => navigate(campaign.mode !== 'importing' ? `/campaigns/${campaign.uuid}` : '#')} borderBottom={'solid 1px'} borderColor={'gray.200'} px={8} py={6} justifyContent={'space-between'}>
                                  <HStack spacing={5}>
                                    <PlatformIcon simplified platform={campaign.platform} />
                                    <Text fontWeight={'light'} fontSize={'sm'}>{campaign.name}</Text>

                                    {campaign.platform.includes('google') && <Tag size={'sm'}>
                                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label?.replace('Google ', '')}
                                    </Tag>}

                                    {campaign.platform.includes('meta_instant_experience') && <Tag size={'sm'}>
                                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label}
                                    </Tag>}
                                  </HStack>
                                  <HStack spacing={10}>
                                    <Tag fontSize={'xs'} fontWeight={'light'} colorScheme='purple'>{campaign.adsCount} Ads</Tag>
                                    <Text fontSize={'sm'} fontWeight={'light'}><CampaignMode campaign={campaign} /></Text>
                                  </HStack>
                                </HStack>
                              </ >
                            )
                          })}

                        </AccordionPanel>
                      </AccordionItem>
                    )
                  })}
                </Accordion>

                <Paginator meta={folders?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />
              </>
            )}


            {isSuccess && folders?.folders?.length === 0 && (<>
              <VStack alignItems="center" justifyContent="center" minH="60vh">
                <Heading mb={5} fontWeight={600}>Welcome to WhatCampaign!</Heading>
                <Text textAlign={'center'}>
                  <Box>This will become your command center for creating and previewing Ads</Box>
                  <Box>before publising them. You can start by creating new campaign.</Box>
                </Text>
                <Button onClick={createDisclosure.onOpen} mt={5} bg={'brand.500'}>Create Campaign</Button>
              </VStack>
            </>)}
          </VStack>
        </Box>
      </Box >

    </>
  );
};


const Campaigns = () => {

  const currentUser = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  return (
    <FolderProvider>
      <CampaignsContent />
    </FolderProvider>
  )
}

export default Campaigns;