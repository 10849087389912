import React, { useEffect } from 'react';

import { Box, Heading, VStack, Flex, Input, Stack, Button, Text, HStack, useToast, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Icon, Tag } from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import { useGetSharedFoldersQuery } from '../../../api/services/shared/folders';
import { FiFolder } from 'react-icons/fi';
import PlatformIcon from '../../Campaigns/Components/PlatformIcon';
import CampaignMode from '../../Campaigns/Components/CampaignMode';
import { useNavigate } from 'react-router-dom';
import { campaignPlatforms } from '../../../components/Constants/campaigns';
import { useState } from 'react';
import Paginator from '../../../components/Common/Pagination/Paginator';
import { useClientToken } from '../../../components/Hooks/useClientToken';


const Folders = () => {
  const organization = localStorage.getItem('organization');
  const [search, setSearch] = React.useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useClientToken();
  const navigate = useNavigate();

  const { data: folders, isSuccess } = useGetSharedFoldersQuery({ per_page: 10, page: currentPage, query: search, token: token }, { pollingInterval: 10000 });

  const [openIds, setOpenIds] = useState(() => {
    const storedIds = localStorage.getItem('foldersState');
    return storedIds ? JSON.parse(storedIds) : [];
  });

  const handleToggle = (id) => {
    setOpenIds(prevIds => (
      prevIds.includes(id)
        ? prevIds.filter(i => i !== id)
        : [...prevIds, id]
    ));
  };

  useEffect(() => {
    localStorage.setItem('foldersState', JSON.stringify(openIds));
  }, [openIds]);

  return (
    <>
      <Box px={10}>
        <Box w='100%'>
          <VStack spacing={5} alignItems="stretch" maxW="100%" bg="white" borderRadius={8}>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Heading w={'full'} fontSize={'3xl'} as="h1" mb={0} pb={0}>
                Campaigns
              </Heading>

              <Stack justifyContent={'flex-end'} w={'full'} direction={['column', 'row']} spacing={6}>
                <Input onChange={(e) => setSearch(e.target.value)} w={['100%', 'md']} placeholder='Search campaigns...' />
              </Stack>
            </Flex>

            {isSuccess && folders?.folders?.length > 0 && (
              <>
                <Accordion allowMultiple index={openIds.map(id => folders?.folders?.findIndex(item => item.id === id))} defaultIndex={openIds.map(id => folders?.folders?.findIndex(item => item.id === id))}>
                  {folders?.folders?.map((folderItem, index) => {

                    return (
                      <AccordionItem key={index} border={0} >
                        <AccordionButton justifyContent={'space-between'} _hover={{ bg: 'transparent' }} px={0} py={6} borderBottom={'solid 1px'} borderColor={'gray.200'} onClick={() => handleToggle(folderItem.id)}>
                          <HStack>
                            <AccordionIcon fontSize={'2xl'} />
                            <Icon fontSize={'xl'} as={FiFolder} />
                            <Text fontWeight={'semibold'} pl={3}>{folderItem.name}</Text>
                          </HStack>

                        </AccordionButton>
                        <AccordionPanel p={0}>
                          {folderItem.campaigns.map(campaign => {
                            return (
                              <>
                                <HStack _hover={{ bg: 'gray.50' }} cursor={'pointer'} onClick={() => navigate(`/portal/${organization}/campaign/${campaign.uuid}`)} borderBottom={'solid 1px'} borderColor={'gray.200'} px={8} py={6} justifyContent={'space-between'}>
                                  <HStack spacing={5}>
                                    <PlatformIcon simplified platform={campaign.platform} />
                                    <Text fontWeight={'light'} fontSize={'sm'}>{campaign.name}</Text>

                                    {campaign.platform.includes('google') && <Tag size={'sm'}>
                                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label?.replace('Google ', '')}
                                    </Tag>}

                                    {campaign.platform.includes('meta_instant_experience') && <Tag size={'sm'}>
                                      {campaignPlatforms.find((i) => i.value == campaign.platform)?.label}
                                    </Tag>}
                                  </HStack>
                                  <HStack spacing={10}>
                                    <Text fontSize={'sm'} fontWeight={'light'}>{campaign.adsCount} Ads</Text>
                                  </HStack>
                                </HStack>
                              </ >
                            )
                          })}

                        </AccordionPanel>
                      </AccordionItem>
                    )
                  })}
                </Accordion>

                <Paginator meta={folders?.meta?.pagination} onPageChange={(page) => setCurrentPage(page)} />
              </>
            )}


            {isSuccess && folders?.folders?.length === 0 && (<>
              <VStack alignItems="center" justifyContent="center" minH="60vh">
                <Heading mb={5} fontWeight={600}>Welcome!</Heading>
                <Text textAlign={'center'}>
                  <Box>This will become your command center for previewing campaigns</Box>
                  <Box>before publising them.</Box>
                </Text>
              </VStack>
            </>)}
          </VStack>
        </Box>
      </Box >

    </>
  );
};


export default Folders;