import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  IconButton,
  Spacer,
  Text,
  Card,
  CardBody,
  CardHeader,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi';

function SitelinkCard({
  sitelink,
  index,
  handleInputChange,
  handleRemoveSitelink
}) {
  const [cardVisible, setCardVisible] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!cardVisible);
  };

  return (
    <Card w={'full'}>
      <CardHeader pb="4">
        <HStack>
          <Text>Sitelink {index + 1}</Text>
          <Spacer />
          <IconButton 
            variant={'ghost'}
            color={'#96999F'}
            onClick={() => handleRemoveSitelink(index)}
            aria-label={"Remove headline"}
            icon={<Icon as={FiTrash2} />}
          />
          <IconButton
            icon={cardVisible ? <FiChevronUp /> : <FiChevronDown />}
            variant="ghost"
            onClick={toggleCardVisibility}
            aria-label={cardVisible ? "Collapse Descriptions" : "Expand Descriptions"}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        {cardVisible && (<Box key={index} p={2} pt={0} borderRadius='md'>
          <VStack spacing={5}>
            <FormControl>
              <Input
                type='text'
                name="text"
                placeholder={"Sitelink text "+(index + 1)}
                value={sitelink.text}
                onChange={(e) => handleInputChange(e, index)}
              />
            </FormControl>
            <FormControl>
              <Input
                type='text'
                name="descriptionLine_1"
                placeholder={"Description line 1 (recommended)"}
                value={sitelink.descriptionLine_1}
                onChange={(e) => handleInputChange(e, index)}
              />
            </FormControl>
            <FormControl>
              <Input
                type='text'
                name="descriptionLine_2"
                placeholder={"Description line 2 (recommended)"}
                value={sitelink.descriptionLine_2}
                onChange={(e) => handleInputChange(e, index)}
              />
            </FormControl>
            <FormControl>
              <Input
                type='text'
                name="finalUrl"
                placeholder={"Final URL"}
                value={sitelink.finalUrl}
                onChange={(e) => handleInputChange(e, index)}
              />
            </FormControl>
          </VStack>
        </Box>)}
      </CardBody>
    </Card>
  );
}

export default SitelinkCard;
