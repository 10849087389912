export const googleDemandgenCallToActions = [
  { value: "Apply now", label: "Apply Now" },
  { value: "Book now", label: "Book Now" },
  { value: "Buy now", label: "Buy Now" },
  { value: "Contact us", label: "Contact Us" },
  { value: "Donate now", label: "Donate Now" },
  { value: "Download", label: "Download" },
  { value: "Get quote", label: "Get Quote" },
  { value: "Learn more", label: "Learn More" },
  { value: "Order now", label: "Order Now" },
  { value: "Play now", label: "Play Now" },
  { value: "See more", label: "See More" },
  { value: "Shop now", label: "Shop Now" },
  { value: "Sign up", label: "Sign Up" },
  { value: "Start now", label: "Start Now" },
  { value: "Subscribe", label: "Subscribe" },
  { value: "Visit site", label: "Visit Site" },
  { value: "Watch now", label: "Watch Now" },
];
