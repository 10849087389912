import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    StepIcon,
    StepNumber,
    StepIndicator,
    StepStatus,
    StepDescription,
    useSteps,
    Stepper,
    Step,
    Box,
    StepSeparator,
    Flex,
    StepTitle,
    Spacer,
    Container,
    Button,
    HStack,
    Image,
    Icon,
    Divider,
    Center
} from '@chakra-ui/react';
import BusinessInformation from './BusinessInformation';
import PaymentInformation from './PaymentInformation';
import Confirmation from './Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { FiArrowLeft, FiLogOut } from 'react-icons/fi';
import { logoutSession } from '../../helpers/sessionHelper';
import LogoHorizontal from '../../assets/images/logo_horizontal.svg';

const steps = [
    { title: 'Business Information' },
    { title: 'Subscription' },
    { title: 'Confirmation' },
];

const Onboarding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const [activeStep, setActiveStep] = useState(currentUser?.organization?.state == 'payment_details' ? 1 : 0);
    const [selectedPlan, setSelectedPlan] = useState('enterprise_monthly');

    const goToStep = (stepNumber, plan) => {
        if (plan) {
            setSelectedPlan(plan);
        }
        setActiveStep(stepNumber);
    };

    const handleLogout = () => {
        logoutSession(dispatch);
    }

    useEffect(() => {
        if (!['created', 'organization_details', 'payment_details'].includes(currentUser?.organization?.state)) {
            navigate('/campaigns');
        }
    }, [])

    return (
        <Container px={12} maxW={'container.lg'} mt={5}>
            <HStack justifyContent={'space-between'} w={'full'} spacing={10}>
                <Image src={LogoHorizontal} />
                <Box>
                    <Button onClick={() => handleLogout()} border={'solid 1px'} borderColor={'gray.200'} leftIcon={<Icon as={FiLogOut} />} size={'sm'} bg='gray.100' color={'black'}>Logout</Button>
                </Box>
            </HStack>

            <Divider my={5} mb={8} />

            <Stepper w={'full'} colorScheme='purple' index={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>
                        <StepTitle>{step.title}</StepTitle>
                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>

            <Box mt={10}>
                {activeStep == 0 && <BusinessInformation goToStep={goToStep} />}
                {activeStep == 1 && <PaymentInformation goToStep={goToStep} />}
                {activeStep == 2 && <Confirmation goToStep={goToStep} selectedPlan={selectedPlan} />}
            </Box>

            <Spacer h={'100px'} />
        </Container>

    );
};

export default Onboarding;
