import { Card, Text, Box, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Button, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal, FiThumbsUp, FiMessageCircle, FiShare } from "react-icons/fi";
import LinkedinBlankImage from '../../../../assets/images/linkedin_empty_image.png';
import { linkedinCallToActions } from "../../../../components/Constants/linkedin_call_to_actions";
import { Swiper, SwiperSlide } from 'swiper/react';
import Like from '../../../../assets/icons/linkedin/like.svg';
import Comment from '../../../../assets/icons/linkedin/comment.svg';
import Share from '../../../../assets/icons/linkedin/share.svg';
import LikeCount from '../../../../assets/icons/linkedin/like_count.svg';

// Import Swiper styles
import 'swiper/css';
import { transformKeysTo } from "../../../../helpers/apiHelper";

const Carousel = ({ ad }) => {
    return (
        <Card minW={'sm'} maxW={'sm'} borderRadius={0}>
            <CardHeader>
                <HStack justifyContent={'space-between'}>
                    <HStack spacing={5}>
                        <Avatar bg={'gray.300'} src={ad.linkedinLogo?.fileUrl} icon={<></>} name='' borderRadius={2} />
                        <VStack alignItems={'flex-start'}>
                            <Text fontSize={'sm'} fontWeight={'bold'}>{ad.linkedinPageName || 'Account Name'}</Text>
                            <Text color={'gray.500'} fontWeight={400} fontSize={'xs'}>Promoted</Text>
                        </VStack>
                    </HStack>
                    <Icon as={FiMoreHorizontal} color={'gray.500'} boxSize={8} />
                </HStack>
            </CardHeader>
            <CardBody fontSize={'sm'} p={0}>
                <Text p={4} whiteSpace={'pre-wrap'} color={'gray.700'}>{(ad.introductoryText || 'Post introductory content...')?.truncate(150)}</Text>

                <Swiper centeredSlidesBounds centeredSlides grabCursor slidesPerView={1.25} freeMode spaceBetween={35} style={{ padding: '1rem' }}>
                    {ad?.linkedinAdCarouselItems?.map((item) => {
                        const transformedItem = transformKeysTo('snake', structuredClone(item));

                        return (
                            <SwiperSlide style={{ padding: '0rem' }}>
                                <Card w={'full'} mt={5} p={0}>
                                    <CardBody p={0} >
                                        <VStack w={'full'}>
                                            <Box h={'300px'} overflow={'hidden'}>
                                                {transformedItem.asset == null && <Image borderTopRadius={'md'} w={'100%'} src={LinkedinBlankImage} />}
                                                {transformedItem.asset?.asset_type == 'image' && <Image borderTopRadius={'md'} w={'100%'} src={transformedItem?.asset?.file_url} />}
                                                {transformedItem.asset?.asset_type == 'video' && !transformedItem.asset?.file_url.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                                                    <video key={transformedItem?.asset?.uuid} controls muted width={'100%'} height="auto" loop>
                                                        <source src={transformedItem?.asset?.file_url} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Box>}
                                                {transformedItem.asset?.asset_type == 'video' && transformedItem.asset?.file_url.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
                                                    <iframe width="100%"
                                                        height="315"
                                                        src={transformedItem?.asset?.file_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                </Box>}
                                            </Box>

                                            <HStack noOfLines={1} w={'full'} px={3} py={2} justifyContent={'space-between'} >
                                                <Flex w="100%" justifyContent="space-between" alignItems={'center'}>
                                                    <HStack w={'full'} px={3} py={2} justifyContent={'space-between'} >
                                                        <VStack alignItems={'flex-start'} justifyContent={'flex-start'}>
                                                            <Box maxH="3.2em" overflow="hidden">
                                                                <Text fontSize={'md'} fontWeight={'600'} noOfLines={2}>
                                                                    {item?.headline || 'Headline'}
                                                                </Text>
                                                            </Box>
                                                        </VStack>
                                                    </HStack>

                                                    {ad.callToAction && <Button minW={'75px'} h={'34px'} px={7} fontSize={'14px'} borderRadius={'full'} fontWeight={'bold'} variant={'outline'} borderColor={'linkedin.500'} color={'linkedin.500'} _hover={{ bg: 'linkedin.500', color: 'white' }}>
                                                        {linkedinCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}
                                                    </Button>}
                                                </Flex>
                                            </HStack>
                                        </VStack>
                                    </CardBody>
                                </Card>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                <HStack spacing={2} ml={3} mt={3}>
                    <Image w={'18px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={LikeCount} />
                    <Text fontSize={'xs'} color={"#666666"}>100</Text>
                    <Text fontSize={'xs'} color={"#666666"}>·</Text>
                    <Text fontSize={'xs'} color={"#666666"}>3 comments</Text>
                </HStack>
                <Divider mt={3} borderColor={"#CED0D4"}></Divider>
                <HStack p={7} py={2} justifyContent={'space-between'} spacing={6}>
                    <VStack spacing={1} alignItems="center">
                        <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Like} />
                        <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Like</Text>
                    </VStack>
                    <VStack spacing={1} alignItems="center">
                        <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Comment} />
                        <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Comment</Text>
                    </VStack>
                    <VStack alignItems="center">
                        <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Share} />
                        <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Share</Text>
                    </VStack>
                </HStack>
            </CardBody>
        </Card >
    )
};

export default Carousel;