import React from 'react';
import { Box, VStack, Text, Avatar, HStack, Icon, Skeleton, Image, SkeletonCircle, SkeletonText, Button } from '@chakra-ui/react';
import { FaHouse, FaFire, FaVideo, FaBell, FaFolder } from 'react-icons/fa6';
import { FiExternalLink, FiMoreVertical } from 'react-icons/fi';
import { googleDemandgenCallToActions } from '../../../../../../components/Constants/google_demandgen_call_to_actions';
import YoutubeLogo from '../../../../../../assets/icons/pmax/youtube_logo.png';
import Phone from './Phone';

const HomePageAdVideo = ({ variation }) => {

  return (
    <>
      <Text color={'gray.500'} textAlign={'center'}>Example of your in-feed video ad on YouTube</Text>

      <Phone p={3}>
        <HStack justifyContent={'space-between'}>
          <Image w={20} src={YoutubeLogo} />
          <Avatar size={'sm'} />
        </HStack>

        <HStack mt={4} w={'full'}>
          <Box>
            <SkeletonCircle startColor="gray.400" endColor="gray.400" size='10' />
          </Box>
          <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
        </HStack>

        <Box h={'20px'} w={'full'}></Box>

        {!variation.remoteProvider && <video muted key={variation.videoUrl} autoPlay width={'300px'} height="auto"><source src={variation.videoUrl} type="video/mp4" /></video>}
        {variation.remoteProvider == 'youtube' && <iframe key={variation.videoUrl} width="300px" height="auto" src={variation.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}

        <HStack w={'full'} alignItems={'flex-start'}>
          <Image w={'60px'} mt={4} src={variation.logoUrl} />

          <VStack alignItems={'flex-start'} w={'full'}>
            <HStack w={'full'} justifyContent={'space-between'} mt={2} p={1}>
              <Text fontWeight={'bold'} fontSize={'sm'}>{variation.longHeadline?.truncate(30)}</Text>
              <Icon color={'black.500'} fontSize={'sm'} as={FiMoreVertical} />
            </HStack>

            <Text px={1} fontSize={'xs'} color={'gray.600'}>
              {variation.description?.truncate(90)}
            </Text>

            <HStack p={1}>
              <Text fontSize={'xs'} fontWeight={'bold'}>Ad</Text>
              <Text fontSize={'xs'}>• {variation.businessName}</Text>
            </HStack>

          </VStack>
        </HStack>

        <Button mt={2} borderRadius={'2xl'} fontSize={'sm'} w={'full'} bg={'blue.600'} color={'white'} px={2} py={2}>{googleDemandgenCallToActions.find((i) => i.value == variation?.cta)?.label || 'Learn more'}</Button>


        <Skeleton mt={4} startColor="gray.400" endColor="gray.400" height='50px' />

        <HStack mt={10} px={5} w={'full'} justifyContent={'space-between'}>
          <Icon color={'red.500'} as={FaHouse} />
          <Icon color={'gray.500'} as={FaFire} />
          <Icon color={'gray.500'} as={FaVideo} />
          <Icon color={'gray.500'} as={FaBell} />
          <Icon color={'gray.500'} as={FaFolder} />
        </HStack>

      </Phone>
    </>
  )
};

export default HomePageAdVideo;