import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack, Stack, Button, Box, InputGroup, InputLeftAddon, Input } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiExternalLink, FiMenu, FiMoreVertical, FiSearch, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";
import { googleDemandgenCallToActions } from "../../../../../../components/Constants/google_demandgen_call_to_actions";
import GmailLogo from "../../../../../../assets/icons/pmax/gmail.svg";

const DesktopLayout = ({ children }) => {

  return (
    <>
      <Box w={'full'} bg={'gray.50'} border={'solid 1px'} borderColor={'gray.200'} borderRadius={'lg'} p={3}>
        <HStack justifyContent={'space-between'} w={'full'}>
          <HStack spacing={5}>
            <Icon as={FiMenu} color={'gray.300'} />
            <Image src={GmailLogo} w={5} />
            <Text fontWeight={'light'} fontSize={'sm'} color={'black'}>Gmail</Text>

            <InputGroup>
              <InputLeftAddon py={2} bg={'gray.200'}>
                <Icon as={FiSearch} color={'gray.500'} />
              </InputLeftAddon>
              <Input py={1} bg={'gray.200'} />
            </InputGroup>
          </HStack>
          <Avatar size={'xs'} src={''} />
        </HStack>

        <HStack mt={4} alignItems={'flex-start'} spacing={5}>
          <Box w={'30%'}>
            <Box bg={'gray.200'} rounded={'md'} h={'50px'}></Box>
            <Box bg={'gray.200'} mt={3} rounded={'md'} h={'15px'}></Box>
            <Box bg={'gray.200'} mt={3} rounded={'md'} h={'15px'}></Box>
            <Box bg={'gray.200'} mt={3} rounded={'md'} h={'15px'}></Box>
          </Box>
          <Box bg={'white'} mt={3} rounded={'md'} w={'full'}>
            <Box p={3}>
              {children}

            </Box>

            <Divider my={2} />
            {[1, 2, 3].map(() => (
              <HStack p={3}>
                <Box bg={'gray.200'} w={4} h={4} borderRadius={'full'}></Box>
                <Box bg={'gray.200'} w={4} h={4} borderRadius={'full'}></Box>
                <Box bg={'gray.200'} w={'15%'} h={4} borderRadius={'full'}></Box>
                <Box bg={'gray.200'} w={'full'} h={4} borderRadius={'full'}></Box>
              </HStack>
            ))}
          </Box>
        </HStack>

      </Box>
    </>
  )
};

export default DesktopLayout;