import React from "react";

import { Center, HStack, Icon, Text, useSteps } from "@chakra-ui/react";
import DiscoverAd from "./Discover/DiscoverAd";
import DiscoverAdVideo from "./Discover/DiscoverAdVideo";
import DiscoverCarouselAd from "./Discover/DiscoverCarouselAd";

const Discover = ({ variations, displayType, adType }) => {

  return (
    <>
      {displayType == 'mobile' && <>
        {adType == 'image' && <DiscoverAd variation={variations[0]} />}
        {adType == 'video' && <>
          <DiscoverAdVideo variation={variations[0]} />
        </>}
        {adType == 'carousel' && <>
          <DiscoverCarouselAd variation={variations[0]} />
        </>}
      </>}

      {displayType == 'desktop' && <>
        <Text textAlign={'center'} mt={20}>Discover ads are not available on desktop devices.</Text>
      </>}
    </>
  )
};

export default Discover;