const TransformTypes = {
  CamelCase: 'camel',
  SnakeCase: 'snake'
};

export const snakeKeyToCamel = (key) => key.replace(/_([a-z])/g, (_, m) => m.toUpperCase());
export const camelKeyToSnake = (key) => key.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);

export const transformKeysTo = (type, obj) => {
  if (typeof obj != 'object') return obj;

  for (const oldName in obj) {
    const newName =
      type === TransformTypes.SnakeCase ? camelKeyToSnake(oldName) : snakeKeyToCamel(oldName);

    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError
      if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Use recursion to transform nested objects
    if (typeof obj[newName] == 'object' && obj instanceof FileList === false) {
      obj[newName] = transformKeysTo(type, obj[newName]);
    }
  }
  return obj;
};

export const toCamelCase = (obj) => transformKeysTo(TransformTypes.CamelCase, obj);
export const toSnakeCase = (obj) => transformKeysTo(TransformTypes.SnakeCase, obj);
