import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from '@chakra-ui/react';

const DatePickerComponent = ({ value, onChange, readOnly }) => {
    return (
        <>
            {readOnly && <p>{value}</p>}
            {
                !readOnly &&
                <DatePicker
                    selected={value ? new Date(value) : null}
                    onChange={date => onChange(date)}
                    customInput={<Input />}
                    dateFormat="dd/MM/yyyy"
                />
            }
        </>
    );
};

export default DatePickerComponent;