import { Card, Flex, Text, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Box, Button } from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal, FiX, FiChevronUp } from "react-icons/fi";
import MetaTiles from '../../../../assets/images/meta_tiles.png';
import { metaCallToActions } from "../../../../components/Constants/meta_call_to_actions";
import CustomerAcquisitionPreview from "../Facebook/InstantExperience/CustomerAcquisitionPreview";
import StorytellingPreview from "../Facebook/InstantExperience/StorytellingPreview";

const Story = ({ ad, instantExperienceData }) => {
  const [instantExperienceOpened, setInstantExperienceOpened] = React.useState(false);

  React.useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setInstantExperienceOpened(false);
      }
    };

    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const setInstantExperienceOpenedIfPossible = (value) => {
    if (instantExperienceData) {
      setInstantExperienceOpened(value);
    }
  }

  return (
    <>
      {!instantExperienceOpened &&
        <Card blur={'xl'} justifyContent={'space-between'} w={'container.sm'} h={'container.sm'} overflow={'hidden'} boxShadow={'inset 0px 37px 49px 3px rgba(0, 0, 0, 0.2)'} bgSize={'100%'} maxW={'sm'} borderRadius={0}>
          <>


            {ad.asset?.assetType == 'image' &&
              <Image src={ad.asset?.fileUrl} zIndex={0} w={'full'} h={'full'} objectFit={'cover'} position={'absolute'} />
            }

            {ad.asset?.assetType == 'video' && !/youtube/.test(ad.asset?.fileUrl) &&
              <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                <video key={ad.asset?.uuid} muted playsInline width={'100%'} height="100%" loop autoPlay style={{ objectFit: 'cover' }}>
                  <source src={ad.asset?.fileUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            }

            {ad.asset?.assetType == 'video' && /youtube/.test(ad.asset?.fileUrl) &&
              <Box w={'full'} h={'full'} position={'absolute'} overflow={'hidden'}>
                <iframe width="100%" height="100%" src={`${ad.asset?.fileUrl.replace('watch?v=', 'embed/')}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ objectFit: 'cover', pointerEvents: 'auto' }}></iframe>
              </Box>
            }


            <CardHeader zIndex={100} p={4}>
              <HStack spacing={1}>
                {[1, 2, 3].map((i) => <Box key={i} w={'full'} h={0.5} bg={'gray.100'}></Box>)}
                <Box w={'full'} h={0.5} bg={'gray.300'}></Box>
              </HStack>
              <HStack mt={3} justifyContent={'space-between'}>
                <HStack py={0} spacing={5}>
                  <Avatar bg={'gray.300'} size={'sm'} src={ad.instagramLogo?.fileUrl} icon={<></>} name='' />
                  <VStack spacing={0} alignItems={'flex-start'}>
                    <Text textShadow={'base'} color={'white'} fontSize={'xs'} fontWeight={'bold'}>{ad.instagramAccountName || 'whatcampaign'}</Text>
                    <Text textShadow={'base'} color={'white'} fontSize={'2xs'} fontWeight={300}>Sponsored</Text>
                  </VStack>
                </HStack>
                <HStack>
                  <Icon as={FiMoreHorizontal} color={'white'} />
                  <Icon as={FiX} color={'white'} />
                </HStack>
              </HStack>

            </CardHeader>
            <CardBody zIndex={100} justifyContent={'center'} pb={7} w={'full'} display={'flex'} alignItems={'flex-end'} fontSize={'sm'} p={0} pt={4}>
              {ad.callToAction && <>
                <VStack textAlign={'center'} onClick={() => setInstantExperienceOpenedIfPossible(true)}>
                  <Icon color={'white'} textShadow={'base'} as={FiChevronUp} />
                  <Button fontSize={'xs'} borderRadius={'2xl'} p={2} color={'black'} bg={'white'}>{metaCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}</Button>
                </VStack>
              </>}
            </CardBody>
          </>
        </Card>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'customer_acquisition' &&
        <VStack spacing={5}>
          <Card maxW={'sm'} borderRadius={0}>
            <CustomerAcquisitionPreview instantExperienceData={{
              headline: instantExperienceData?.fields_data?.headline,
              description: instantExperienceData?.fields_data?.description,
              buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
              buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
              buttonLabel_2: instantExperienceData?.fields_data?.button_label_2,
              buttonDestination_2: instantExperienceData?.fields_data?.button_destination_2,
              carouselText: instantExperienceData?.fields_data?.carousel_text,
              metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
              instantExperienceAsset: instantExperienceData?.fields_data?.instant_experience_asset
            }} />
          </Card>
        </VStack>
      }
      {instantExperienceOpened && instantExperienceData && instantExperienceData?.instant_experience_type == 'storytelling' &&
        <Card maxW={'sm'} borderRadius={0}>
          <VStack spacing={5}>
            <StorytellingPreview instantExperienceData={{
              headline: instantExperienceData?.fields_data?.headline,
              buttonLabel_1: instantExperienceData?.fields_data?.button_label_1,
              buttonDestination_1: instantExperienceData?.fields_data?.button_destination_1,
              carouselText: instantExperienceData?.fields_data?.carousel_text,
              metaInstantExperienceCarouselItems: instantExperienceData?.fields_data?.meta_instant_experience_carousel_items,
              instantExperienceAsset_1: instantExperienceData?.fields_data?.instant_experience_asset_1,
              destination_url_1: instantExperienceData?.fields_data?.destination_url_1,
              instantExperienceAsset_2: instantExperienceData?.fields_data?.instant_experience_asset_2,
              destination_url_2: instantExperienceData?.fields_data?.destination_url_2,
              instantExperienceAsset_3: instantExperienceData?.fields_data?.instant_experience_asset_3,
              destination_url_3: instantExperienceData?.fields_data?.destination_url_3
            }} />
          </VStack>
        </Card>
      }
    </>
  )
};

export default Story;