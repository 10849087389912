export const snapchatCallToActions = [
    { value: "APPLY_NOW", label: "Apply now" },
    { value: "BOOK_NOW", label: "Book now" },
    { value: "BUY_TICKETS", label: "Buy tickets" },
    { value: "DONATE", label: "Donate" },
    { value: "GET_NOW", label: "Get Now" },
    { value: "LISTEN", label: "Listen" },
    { value: "MORE", label: "More" },
    { value: "ORDER_NOW", label: "Order now" },
    { value: "PLAY", label: "Play" },
    { value: "PLAY GAME", label: "Play game" },
    { value: "PRE-REGISTER", label: "Pre-register" },
    { value: "READ", label: "Read" },
    { value: "SHOP_NOW", label: "Shop now" },
    { value: "SHOW", label: "Show" },
    { value: "SHOWTIMES", label: "Showtimes" },
    { value: "SIGN_UP", label: "Sign up" },
    { value: "VIEW", label: "View" },
    { value: "VIEW MENU", label: "View menu" },
    { value: "VOTE", label: "Vote" },
    { value: "WATCH", label: "Watch" }
]