import React from 'react';
import { Box, Heading, Input, Button, Alert, AlertIcon, ScaleFade, Text, Icon, Stack, Spacer, HStack } from "@chakra-ui/react";
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Link as ReachLink } from 'react-router-dom';
import { FiSend, FiUserPlus } from 'react-icons/fi';
import { useResetPasswordMutation } from '../../api/services/users';


const ForgotPassword = () => {
  const { register, handleSubmit, formState: { isValid } } = useForm();
  const [resetPassword, { isSuccess, isError, isLoading }] = useResetPasswordMutation();

  const onSubmit = async data => {
    resetPassword(data);
  };

  return (
    <>
      <Spacer />
      <Box w={'full'} justifyContent={'center'} p={10} display={'flex'}>
        <Stack flexDir={['column-reverse', 'row']} maxW={'container.xl'} direction={['column', 'row']} gap={'7rem'}>
          <Box w={['full', '2xl']} mt={10} >
            <Heading as="h3" fontWeight={400} size="xl">Simplify Your Digital Advertising Journey - From Concept to Launch</Heading>
            <Text color="gray.400" mt={5}>Navigate the complexities of digital ad creation with ease. Our platform bridges the gap between advertising agencies and their clients, ensuring a seamless and understandable journey from the initial concept to the final launch.</Text>
          </Box>

          <Box w={'full'}>
            <Heading as="h2" fontWeight={400} textAlign="center" mb={6}>
              Forgot password
            </Heading>

            <Text fontSize={'sm'} color={'gray.500'} mb={5}>
              Enter your email address and we will send you a link to reset your password. If you don't receive an email, please check your spam folder. If you still can't find it, please contact our support team.
            </Text>

            {isSuccess && <>
              <ScaleFade initialScale={0.9} in={isSuccess}>
                <Alert variant={'top-accent'} mt={5} mb={5} borderRadius={'md'} fontSize={'sm'} status='success'>
                  <AlertIcon />
                  If the email address you entered is associated with an account, you will receive an email with a link to reset your password. Link will be valid for 3 days.
                </Alert>
              </ScaleFade>
            </>}

            <form mt={4} onSubmit={handleSubmit(onSubmit)}>
              <Box mb={6}>
                <Text fontSize={'sm'} mb={4} fontWeight={'500'}>E-Mail</Text>
                <Input placeholder="name@example.com" {...register('email', { required: true })} name="email" />
              </Box>

              <HStack mt={4} justifyContent="space-between">
                <Button leftIcon={<Icon as={FiSend} />} isLoading={isLoading} isDisabled={!isValid} type="submit" colorScheme="brand" width="auto">
                  Send reset link
                </Button>
                <HStack>
                  <Button as={ReachLink} to="/login" leftIcon={<Icon as={FiUserPlus} />} variant="outline" colorScheme="brand" size="sm">Sign In</Button>
                </HStack>
              </HStack>
            </form>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ForgotPassword;
