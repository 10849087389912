import { Box, Button, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { HexColorPicker } from "react-colorful";

const ColorPicker = ({ controlName, initColor, text, setValue, register }) => {
    const [color, setColor] = useState(initColor);

    const { isOpen, onClose, onOpen } = useDisclosure();

    useEffect(() => {
        setValue(controlName, color, { shouldDirty: true });
    }, [color])

    const handleInputChange = (event) => {
        setColor(event.nativeEvent.target.value)
    }

    return (
        <>
            <FormControl>
                <FormLabel fontSize={'sm'}>{text}</FormLabel>
                <InputGroup>
                    <InputLeftElement >
                        <Box w={'32px'} h={'32px'} border={'1px solid'} borderColor={'gray.200'} bg={color} borderRadius={4} onClick={onOpen} cursor={'pointer'}>
                        </Box>
                    </InputLeftElement>

                    <Input pl={10} placeholder={text} value={color} onChange={handleInputChange} />
                </InputGroup>
            </FormControl>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader p={0}>
                        <Box p={5}>
                            <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Choose your brand color</Text>
                        </Box>
                        <ModalCloseButton mt={2} />
                    </ModalHeader>
                    <form>
                        <ModalBody>
                            <Box>
                                <VStack>
                                    <HexColorPicker color={color} onChange={setColor} />

                                    <FormControl gap={2}>
                                        <FormLabel fontSize={'lg'} mb={4}>{text}</FormLabel>
                                        <Text fontSize={'md'} mb={8}>If you already have an agency color, please add or select it</Text>

                                        <InputGroup>
                                            <InputLeftElement >
                                                <Box w={'32px'} h={'32px'} border={'1px solid'} borderColor={'gray.200'} bg={color} borderRadius={4}>
                                                </Box>
                                            </InputLeftElement>

                                            <Input {...register(controlName)} defaultValue={color} onChange={handleInputChange} />
                                        </InputGroup>
                                    </FormControl>
                                </VStack>
                            </Box>
                        </ModalBody>

                        <ModalFooter gap={3} justifyContent={'flex-end'}>
                            <Button onClick={onClose} variant='outline'>Cancel</Button>
                            <Button colorScheme='primary' onClick={onClose}>
                                Select
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ColorPicker