import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  IconButton,
  Spacer,
  Text,
  Card,
  CardBody,
  CardHeader,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input'
import { formatCall } from '../../../../../../helpers/googleSearchHelper';

function CallCard({
  call,
  index,
  handleInputChange,
  handleRemoveCall
}) {
  const [cardVisible, setCardVisible] = useState(true);
  const [value, setValue] = useState(call.phoneNumber || '')
  
  const toggleCardVisibility = () => {
    setCardVisible(!cardVisible);
  };

  useEffect(() => {
    if (value != undefined && value != '') {
      // const country = parsePhoneNumber(value) && parsePhoneNumber(value).country
      // const callingCode = country && getCountryCallingCode(country)
      // const phoneNumber = value && formatPhoneNumber(value)
      handleInputChange(index, '', value)
    }
  }, [value]);

  return (
    <Card w={'full'}>
      <CardHeader pb="4">
        <HStack>
          <Text>Call {index + 1}</Text>
          <Spacer />
          <IconButton variant={'ghost'} color={'#96999F'} onClick={() => handleRemoveCall(index)} aria-label={"Remove call"} icon={<Icon as={FiTrash2} />} />
          <IconButton
            icon={cardVisible ? <FiChevronUp /> : <FiChevronDown />}
            variant="ghost"
            onClick={toggleCardVisibility}
            aria-label={cardVisible ? "Collapse Call" : "Expand Call"}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        {cardVisible && (<Box key={index} p={2} pt={0} borderRadius='md'>
          <FormControl>
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              international={true}
              defaultCountry="US"
              onChange={setValue}/>
          </FormControl>
        </Box>)}
      </CardBody>
    </Card>
  );
}

export default CallCard;
