import { Box, VStack } from "@chakra-ui/react";
import React from "react";

const Phone = ({ children, ...props }) => {
  return (
    <Box mt={4} display={'flex'} mx={'auto'} maxW={'22rem'} border={'solid 1px'} borderTop={'solid 1px'} borderColor={'gray.400'} borderRadius={'50px'} p={5}>
      <VStack pb={5} w={'full'} justifyContent={'center'}>
        <Box w={5} h={5} borderRadius={'full'} border={'solid 1px'} borderColor={'gray.200'}></Box>
        <Box {...props} border={'solid 1px'} borderColor={'gray.200'} w={'full'}>
          {children}
        </Box>
      </VStack>
    </Box>
  )
}

export default Phone;