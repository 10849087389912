import React, { useEffect } from 'react';
import { Flex, Box, Text, Button, useDisclosure, Collapse, Icon, HStack, Menu, MenuButton, MenuItem, MenuList, MenuDivider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Stack } from "@chakra-ui/react";
import EditableInput from '../../components/Common/Input/EditableInput';
import { useDeleteAdGroupMutation, useUpdateAdGroupMutation } from '../../api/services/adGroups';
import { Link, useParams } from 'react-router-dom';
import Ad from './Ad';
import { FiChevronDown, FiChevronUp, FiMoreVertical, FiPlus, FiPlusSquare, FiTrash2 } from 'react-icons/fi';
import { adsApi } from '../../api/services/ads';

const AdGroup = ({ ad_group, platform, mode, disableEdit = false }) => {
  const [updateAdGroup] = useUpdateAdGroupMutation();
  const [deleteAdGroup, { isLoading, isSuccess }] = useDeleteAdGroupMutation();

  const { uuid } = useParams();
  const { isOpen, onToggle, } = useDisclosure({ defaultIsOpen: true });
  const deleteDisclosure = useDisclosure()

  const onAdGroupNameSave = (name) => {
    updateAdGroup({
      uuid: ad_group.uuid,
      name: name
    })
  }

  useEffect(() => {
    if (isSuccess) {
      deleteDisclosure.onClose();
    }
  }, [isSuccess]);

  return (
    <>

      <Flex borderBottom={'solid 1px'} borderBottomColor={'gray.300'} onClick={() => onToggle()} cursor={'pointer'} id={ad_group?.uuid} gap={6} direction={['column', 'row']} alignItems={'center'} p={0} py={2} mt={4} mx={8} justifyContent={'space-between'}>
        <EditableInput isDisabled={disableEdit} letterSpacing={'-0.04rem'} fontSize={'2xl'} fontWeight={500} label={ad_group.name} onSave={onAdGroupNameSave} />

        {ad_group?.ads?.length > 0 && <>
          <HStack onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} gap={8}>
            {!disableEdit &&
              <Menu>
                <MenuButton
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}>
                  <Icon as={FiMoreVertical} />
                </MenuButton>
                <MenuList>
                  <MenuItem color={'gray.500'} as={Link} to={`/campaigns/${uuid}/ad_groups/${ad_group?.uuid}/ads/create`} icon={<Icon as={FiPlusSquare} fontSize={'xl'} />} >Create Ad</MenuItem>
                  <MenuDivider />
                  <MenuItem color={'gray.500'} icon={<Icon as={FiTrash2} fontSize={'xl'} />} onClick={deleteDisclosure.onOpen}>Delete Ad Group</MenuItem>
                </MenuList>
              </Menu>
            }
            <Icon as={isOpen ? FiChevronUp : FiChevronDown} onClick={() => onToggle()} />
          </HStack>
        </>}

        {ad_group?.ads?.length == 0 && <>
          <HStack onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} gap={8}>
            <Button variant={'outline'} border={0} leftIcon={<Icon as={FiPlus} />} as={Link} to={`/campaigns/${uuid}/ad_groups/${ad_group?.uuid}/ads/create`}>Create Ad</Button>
            <Icon as={FiTrash2} onClick={deleteDisclosure.onOpen} />
          </HStack>
        </>}
      </Flex>

      <Collapse in={isOpen}>
        <Stack flexDir={'row'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={10} >
          {ad_group?.ads.length > 0 && ad_group?.ads.map((ad) => (
            <Ad
              key={ad.uuid}
              ad={ad}
              platform={platform}
              uuid={uuid}
              adGroupUuid={ad_group.uuid}
              mode={mode}
              disableEdit={disableEdit}
            />
          ))
          }
        </Stack>

        {ad_group?.ads?.length == 0 && <>
          <Box gap={6} direction={['column', 'row']} alignItems={'center'} p={8} justifyContent={'space-between'}>
            <Text>It looks a bit empty here, doesn't it? Let's change that.</Text>
            <Text>Click the button below to start adding ads and unlock its full potential.</Text>
            <Button as={Link} to={`/campaigns/${uuid}/ad_groups/${ad_group?.uuid}/ads/create`} mt="40px">Create Ad</Button>
          </Box>
        </>
        }
      </Collapse>

      <AlertDialog
        isOpen={deleteDisclosure.isOpen}
        onClose={deleteDisclosure.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Ad Group
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant={'outline'} onClick={deleteDisclosure.onClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} _hover={{ bg: 'red.800' }} colorScheme='red' bg={'red.600'} onClick={() => deleteAdGroup(ad_group.uuid)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AdGroup;