import React from 'react';
import { Box, Button, HStack, Icon, Text, Tag, useDisclosure } from "@chakra-ui/react";
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { useGetSharedMediaPlanQuery } from '../../../api/services/shared/mediaPlans';
import SimpleTable from '../Simple/Shared/SimpleTable';
import { FiCheck, FiCheckCircle, FiChevronRight, FiMessageCircle } from 'react-icons/fi';
import Budget from '../Components/Budget';
import Breadcrumbs from '../../../components/Common/Breadcrumbs';
import { useClientToken } from '../../../components/Hooks/useClientToken';
import { useGetSharedClientsMediaPlanQuery } from '../../../api/services/shared/clientsMediaPlans';
import { useApproveSharedMediaPlanMutation } from '../../../api/services/shared/mediaPlans';
import Comments from '../../Comments/Comments';

const SharedMediaPlan = () => {
  const { uuid, organization } = useParams();
  const { token } = useClientToken();
  const commentsDisclosure = useDisclosure();

  const { data, isSuccess } = useGetSharedMediaPlanQuery({ uuid: uuid, token }, { refetchOnMountOrArgChange: true });
  const mediaPlan = data?.mediaPlan;

  const { data: clientsMediaPlanData, isSuccess: isSuccessClientsMediaPlan } = useGetSharedClientsMediaPlanQuery({ media_plan_id: mediaPlan?.id, token }, { pollingInterval: 10000 });
  const clientsMediaPlan = clientsMediaPlanData?.clientsMediaPlans[0];

  // Approval

  const [approveMediaPlan, { isLoading }] = useApproveSharedMediaPlanMutation();
  const handleApprove = () => {
    approveMediaPlan({ uuid: mediaPlan?.uuid, token });
  };

  return (
    <>
      {isSuccess && isSuccessClientsMediaPlan && <Box p={4} minHeight="100vh" >

        <Breadcrumbs links={[
          { label: 'Home', to: `/portal/${organization}/home` },
          { label: 'Media Plans', to: `/portal/${organization}/media-plans` },
          { label: mediaPlan.name, to: `/portal/${organization}/media-plans/${mediaPlan.uuid}` }
        ]} />

        <HStack mb={10} w={'full'} justifyContent={'space-between'}>
          <Text mb={5} fontSize={'lg'} fontWeight={'semibold'}>{mediaPlan?.name}</Text>
          <HStack spacing={5}>
            {clientsMediaPlan?.permission != 'canview' && <Button onClick={() => commentsDisclosure.onOpen()} variant={'outline'} size={'sm'} leftIcon={<Icon as={FiMessageCircle} />}>Comments</Button>}
            {clientsMediaPlan?.permission == 'mustapprove' && mediaPlan?.status == 'requested_approval' && <Button size={'sm'} onClick={() => handleApprove()} isLoading={isLoading} colorScheme='green' variant={'outline'} leftIcon={<Icon as={FiCheckCircle} />}>Approve Media Plan</Button>}
            {mediaPlan.status == 'approved' && <Tag colorScheme='green'>
              <HStack>
                <Icon as={FiCheckCircle} />
                <Text>Approved</Text>
              </HStack>
            </Tag>}
          </HStack>
        </HStack>

        <Budget mediaPlan={mediaPlan} />

        <Box overflow={'scroll'} px={0}>
          <SimpleTable mediaPlan={mediaPlan} />
        </Box>


        {commentsDisclosure.isOpen && <Comments externalOnly allowCommenting mediaPlan={mediaPlan} disclosure={commentsDisclosure} />}
      </Box>}
    </>
  );
};

export default SharedMediaPlan;