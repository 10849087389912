import React from "react";

import { Box, Button, HStack, Icon, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

const Display3 = ({ variation }) => {
  return (
    <>
      <VStack position={'relative'} p={3} backgroundSize={'cover'} backgroundImage={variation.imageUrl} border={'solid 1px'} borderColor={'gray.200'} alignItems={'flex-start'}>
        <VStack mt={20} zIndex={5} spacing={5} w={'full'} px={3} py={5} bg={'white'} boxShadow={'md'} rounded={'md'}>
          <Text textAlign={'center'} fontSize={'2xl'} fontWeight={700}>{variation.headline?.truncate(30)}</Text>
          <Image src={variation.logoUrl} w={50} />
          <Text color={'gray.600'} fontSize={'lg'}>{variation.description?.truncate(90)}</Text>
          <IconButton bg={'black'} fontSize={'2xl'} color={'white'} borderRadius={'full'} p={4} icon={<Icon as={FiChevronRight} />} aria-label={""} />

        </VStack>
      </VStack>
    </>
  )
};

export default Display3;