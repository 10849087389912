import { languages } from './languages';
import { locations } from './locations';

export const tableColumns = [
  { label: 'Campaign Name', name: 'campaignName', type: 'text' },
  { label: 'Platform', name: 'platform', type: 'dropdown', values: [{ name: 'google', label: 'Google' }, { name: 'meta', label: 'Meta' }, { name: 'tiktok', label: 'TikTok' }, { name: 'linkedin', label: 'LinkedIn' }, { name: 'snapchat', label: 'Snapchat' }, { name: 'youtube', label: 'YouTube' }] },
  { label: 'Ad Format', name: 'adFormat', type: 'dropdown', values: [{ name: 'singleimagevideo', label: 'Single Image/Video' }, { name: 'carousel', label: 'Carousel' }, { name: 'collection', label: 'Collection' }, { name: 'story', label: 'Story' }, { name: 'instream', label: 'In-stream' }, { name: 'infeedvideo', label: 'In-feed video' }, { name: 'shorts', label: 'Shorts' }, { name: 'search_ad', label: 'Search Ad' }, { name: 'display_ad', label: 'Display Ad' }, { name: 'performance_max', label: 'Performance Max' }, { name: 'demandgen', label: 'DemandGen' }] },
  { label: 'Objective', name: 'objective', type: 'dropdown', values: [{ name: 'awareness', label: 'Awareness' }, { name: 'traffic', label: 'Traffic' }, { name: 'conversion', label: 'Conversion' }, { name: 'leads', label: 'Leads' }] },
  { label: 'Audience Language', name: 'audienceLanguage', type: 'dropdown_multiple', values: languages },
  { label: 'Audience Location', name: 'audienceLocation', type: 'dropdown_multiple', values: locations },
  { label: 'Audience Age From', name: 'audienceAgeFrom', type: 'number' },
  { label: 'Audience Age To', name: 'audienceAgeTo', type: 'number' },
  { label: 'Audience Genders', name: 'audienceGenders', type: 'dropdown', values: [{ name: 'male', label: 'Male' }, { name: 'female', label: 'Female' }, { name: 'all', label: 'All' }] },
  { label: 'Audience Interest', name: 'audienceInterests', type: 'text' },
  { label: 'Budget', name: 'lifetimeBudget', type: 'text_addon', meta: { addonLabel: 'CURRENCY' } },
  { label: 'Start Date', name: 'startDateDuration', type: 'date' },
  { label: 'End Date', name: 'endDateDuration', type: 'date' },
  { label: 'CPC', name: 'cpc', type: 'text_addon', meta: { addonLabel: 'CURRENCY' } },
  { label: 'Conversions', name: 'conversions', type: 'number' },
  { label: 'Conversion rate', name: 'conversionRate', type: 'text_addon', meta: { addonLabel: '%', addonLabelPosition: 'right' } },
  { label: 'Reach', name: 'reach', type: 'number' },
  { label: 'Clicks', name: 'clicks', type: 'number' },
  { label: 'URL', name: 'url', type: 'text' },
  { label: 'Ad placement', name: 'adPlacement', type: 'text' },
  {
    label: 'Call to action', name: 'callToAction', type: 'dropdown', values: [
      { name: 'shopNow', label: 'Shop Now' },
      { name: 'learnMore', label: 'Learn More' },
      { name: 'signUp', label: 'Sign Up' },
      { name: 'subscribe', label: 'Subscribe' },
      { name: 'getStarted', label: 'Get Started' },
      { name: 'contactUs', label: 'Contact Us' },
      { name: 'bookNow', label: 'Book Now' },
      { name: 'download', label: 'Download' },
      { name: 'watchVideo', label: 'Watch Video' },
      { name: 'buyNow', label: 'Buy Now' },
      { name: 'joinNow', label: 'Join Now' },
      { name: 'applyNow', label: 'Apply Now' },
      { name: 'donateNow', label: 'Donate Now' },
      { name: 'viewMore', label: 'View More' },
      { name: 'playNow', label: 'Play Now' }
    ]
  },
];