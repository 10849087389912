import React from 'react';
import { Box, Center, Heading, Text, Link, VStack, HStack, Button, useDisclosure, Divider } from "@chakra-ui/react";
import { useGetCampaignsQuery } from '../../api/services/campaigns';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSession } from '../../helpers/sessionHelper';
import Create from '../Workspaces/Create';
import EditableInput from '../../components/Common/Input/EditableInput';
import { useGetIntegrationsQuery } from '../../api/services/integrations';

const Dashboard = () => {
  const workspaceDisclosure = useDisclosure();
  const { data } = useGetCampaignsQuery(null, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });
  const currentUser = useSelector((state) => state.user.currentUser);

  const { data: integrationsData } = useGetIntegrationsQuery({ per_page: 20, page: 1 }, { refetchOnMountOrArgChange: true });

  return (
    <Box display={'flex'} h={'100vh'} w={'full'}>
      <VStack mt={5} w={'full'} maxW="full" bg="white" borderRadius={8}>
        <Heading as="h1" size="xl" mt={'20'} mb={0} pb={0} fontFamily="heading">
          Welcome to Your Dashboard!
        </Heading>

        <Text fontSize="md" mt={5} pb={0} color="brand.500">
          It looks a bit empty here, doesn't it? Let's change that.
        </Text>
        <Text fontSize="md" mb={6} mt={0} color="brand.500">
          Click the button below to start creating workspaces and unlock its full potential.
        </Text>

        <Button onClick={workspaceDisclosure.onOpen} >Create Workspace</Button>

        <Create disclosure={workspaceDisclosure} />

        <Divider my={4} />

        <Link bg={'black'} color={'white'} href={`/auth/google_oauth2?uuid=${currentUser?.uuid}`}>Connect Google</Link>

        {integrationsData?.integrations?.map((integration) => {
          return (
            <>
              <Text>{integration.provider}: {integration.name} ({integration.email})</Text>
            </>
          )
        })}
      </VStack>
    </Box>
  );
};

export default Dashboard;
