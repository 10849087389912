// some-inner-component.jsx
import { Icon, IconButton } from '@chakra-ui/react';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useSwiper } from 'swiper/react';

export default function Previous() {
  const swiper = useSwiper();

  return (
    <IconButton bg={'none'} color={'white'} fontSize={'5xl'} onClick={() => swiper.slidePrev()} icon={<Icon filter={'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))'} as={FiChevronLeft} />} aria-label={""}></IconButton>
  );
}