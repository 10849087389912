export const googleDisplayCallToActions = [
  { value: "Apply Now", label: "Apply Now" },
  { value: "Book Now", label: "Book Now" },
  { value: "Contact Us", label: "Contact Us" },
  { value: "Download", label: "Download" },
  { value: "Learn More", label: "Learn More" },
  { value: "Install", label: "Install" },
  { value: "Visit Site", label: "Visit Site" },
  { value: "Shop Now", label: "Shop Now" },
  { value: "Sign Up", label: "Sign Up" },
  { value: "Get Quote", label: "Get Quote" },
  { value: "Subscribe", label: "Subscribe" },
  { value: "See More", label: "See More" }
];