import React from "react";

import { Tab, TabList, TabPanel, TabPanels, Tabs, useSteps } from "@chakra-ui/react";
import MobileClosed from "./Gmail/MobileClosed";
import DesktopClosed from "./Gmail/DesktopClosed";
import MobileOpened from "./Gmail/MobileOpened";
import DesktopOpened from "./Gmail/DesktopOpened";

const Gmail = ({ variations, displayType }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 1
  })

  return (
    <>
      <Tabs w={'full'}>
        <TabList>
          <Tab w={'full'}>
            Closed
          </Tab>
          <Tab w={'full'}>
            Open
          </Tab>
        </TabList>
        <TabPanels mt={5}>
          <TabPanel>
            {displayType == 'mobile' && <MobileClosed variations={variations} />}
            {displayType == 'desktop' && <DesktopClosed variations={variations} />}
          </TabPanel>
          <TabPanel>
            {displayType == 'mobile' && <MobileOpened variations={variations} />}
            {displayType == 'desktop' && <DesktopOpened variations={variations} />}

          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
};

export default Gmail;