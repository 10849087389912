import { Box, Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, HStack, Icon, Input, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FiHelpCircle, FiImage } from 'react-icons/fi';
import AssetsLibraryButton from '../../../../AssetsLibrary/Manager/AssetsLibraryButton';
import AssetsLibraryPreview from '../../../../AssetsLibrary/Preview/AssetsLibraryPreview';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Slide from './Slide';
import { IoIosWarning } from "react-icons/io";
import StorytellingPreview from '../../../Preview/Facebook/InstantExperience/StorytellingPreview';
import { useFieldArray } from 'react-hook-form';
import { toSnakeCase, toCamelCase, transformKeysTo } from '../../../../../helpers/apiHelper';
import { validateUrl } from "../../../../../components/utils/urlUtils";

const warningIcon = <Icon alignSelf={'end'} color={'red.500'} as={IoIosWarning} />;

const Storytelling = ({ register, setValue, getValues, control, missingFields, setRequiredFields}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [instantExperienceAsset1, setInstantExperienceAsset1] = useState(getValues('meta_instant_experience.fields_data.instant_experience_asset_1') ? [transformKeysTo('camel', structuredClone(getValues('meta_instant_experience.fields_data.instant_experience_asset_1')))] : []);
  const [instantExperienceAsset2, setInstantExperienceAsset2] = useState(getValues('meta_instant_experience.fields_data.instant_experience_asset_2') ? [transformKeysTo('camel', structuredClone(getValues('meta_instant_experience.fields_data.instant_experience_asset_2')))] : []);
  const [instantExperienceAsset3, setInstantExperienceAsset3] = useState(getValues('meta_instant_experience.fields_data.instant_experience_asset_3') ? [transformKeysTo('camel', structuredClone(getValues('meta_instant_experience.fields_data.instant_experience_asset_3')))] : []);

  const camelToSnake = (camelCaseStr) => {
    return camelCaseStr.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  const instantExperienceDefaultValues = { 
    'meta_instant_experience.instant_experience_type': 'storytelling',
    'meta_instant_experience.fields_data.destination_url': '',
    'meta_instant_experience.fields_data.headline': 'Add Context',
    'meta_instant_experience.fields_data.button_label_1': 'Write something...',
    'meta_instant_experience.fields_data.button_destination_1': 'https://www.example.com',
    'meta_instant_experience.fields_data.carousel_text': 'Add descriptive content for people to read while they swipe through your carousel images.',
    'meta_instant_experience.fields_data.meta_instant_experience_carousel_items': null,
    'meta_instant_experience.fields_data.instant_experience_asset_1': null,
    'meta_instant_experience.fields_data.instant_experience_asset_2': null,
    'meta_instant_experience.fields_data.instant_experience_asset_3': null

  };

  useEffect(() => {
    setRequiredFields([
      'meta_instant_experience.instant_experience_type',
      'meta_instant_experience.fields_data.headline',
      'meta_instant_experience.fields_data.button_label_1',
      'meta_instant_experience.fields_data.button_destination_1',
      'meta_instant_experience.fields_data.carousel_text',
      'meta_instant_experience.fields_data.meta_instant_experience_carousel_items',
      'meta_instant_experience.fields_data.instant_experience_asset_1',
      'meta_instant_experience.fields_data.instant_experience_asset_2',
      'meta_instant_experience.fields_data.instant_experience_asset_3'
    ]);
  }, [setRequiredFields]);

  useEffect(() => {
    instantExperienceDefaultValues && Object.entries(instantExperienceDefaultValues).forEach(([key, value]) => {
      if (getValues(key) === undefined || getValues(key) == [])
        setValue(key, value);
    });
  }, []);

  const superSetInstantExperienceAssets1 = (assets) => {
    setInstantExperienceAsset1(assets);

    if (assets[0]?.id) {
      setValue(`meta_instant_experience.fields_data.instant_experience_asset_1`, transformKeysTo('snake', structuredClone(assets[0])));
    }
  }

  const superSetInstantExperienceAssets2 = (assets) => {
    setInstantExperienceAsset2(assets);

    if (assets[0]?.id) {
      setValue(`meta_instant_experience.fields_data.instant_experience_asset_2`, transformKeysTo('snake', structuredClone(assets[0])));
    }
  }

  const superSetInstantExperienceAssets3 = (assets) => {
    setInstantExperienceAsset3(assets);

    if (assets[0]?.id) {
      setValue(`meta_instant_experience.fields_data.instant_experience_asset_3`, transformKeysTo('snake', structuredClone(assets[0])));
    }
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "meta_instant_experience.fields_data.meta_instant_experience_carousel_items"
  });

  const handleToggleSlide = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(getValues('meta_instant_experience.fields_data.meta_instant_experience_carousel_items'));

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue('meta_instant_experience.fields_data.meta_instant_experience_carousel_items', items);
  }

  return (
    <Flex direction="row" gap="5">
      <VStack w="50%" spacing={5} >
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Cover image or video</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <Input type="hidden" {...register('meta_instant_experience.instant_experience_type')} value="storytelling" />
            <FormControl mb='4'>
              <Text mb={5} fontSize={'sm'}>Introduce your brand, product or service by using an eye-catching video or image. { missingFields.includes('meta_instant_experience.fields_data.instant_experience_asset_1') && warningIcon }</Text>

              <HStack>
                <AssetsLibraryButton assets={instantExperienceAsset1} setAssets={superSetInstantExperienceAssets1} types={['image', 'video']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={instantExperienceAsset1} setAssets={superSetInstantExperienceAssets1} />
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel>Website URL (optional) { missingFields.includes('meta_instant_experience.fields_data.destination_url_1') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.destination_url_1')} placeholder={'Destination URL...'} />
            </FormControl>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardBody>
            <FormControl>
              <FormLabel>Headline { missingFields.includes('meta_instant_experience.fields_data.headline') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.headline')} placeholder={'Add headline here...'} />
            </FormControl>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Image or video</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <FormControl mb='4'>
              <Text mb={5} fontSize={'sm'}>Introduce your brand, product or service by using an eye-catching video or image. { missingFields.includes('meta_instant_experience.fields_data.instant_experience_asset_2') && warningIcon }</Text>

              <HStack>
                <AssetsLibraryButton assets={instantExperienceAsset2} setAssets={superSetInstantExperienceAssets2} types={['image', 'video']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={instantExperienceAsset2} setAssets={superSetInstantExperienceAssets2} />
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel>Website URL (optional) { missingFields.includes('meta_instant_experience.fields_data.destination_url_2') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.destination_url_2')} 
              placeholder={'Destination URL...'}
               onBlur={(e) => {
                                        const validatedUrl = validateUrl(e.target.value);
                                        setValue(`meta_instant_experience.fields_data.destination_url_2`, validatedUrl);
                                    }} />
            </FormControl>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Carousel</Text>
              <Icon as={FiHelpCircle} />
            </HStack>
          </CardHeader>
          <CardBody>
            <Text mb={5} fontSize={'sm'}>Upload 2-10 images to show them in a carousel format. If the images are not the same size, they will be cropped to match your first image. { missingFields.includes('meta_instant_experience.fields_data.meta_instant_experience_carousel_items') && warningIcon }</Text>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((item, index) => {
                      return (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <Slide
                              isOpen={index === openIndex}
                              onToggle={() => handleToggleSlide(index)}
                              setValue={setValue} getValues={getValues}
                              register={register} index={index}
                              provided={provided} item={item}
                              remove={() => remove(index)}
                              attributeName='meta_instant_experience.fields_data.meta_instant_experience_carousel_items' />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
            {fields.length < 10 && 
              <Box w={'full'} display={'flex'} justifyContent={'flex-end'}>
                <Button mt={5} right={0} variant={'outline'} onClick={() => append({})} leftIcon={<Icon as={FiImage} />}>Add new slide</Button>
              </Box>
            }
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Text</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <FormControl>
              <FormLabel>Label { missingFields.includes('meta_instant_experience.fields_data.carousel_text') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.carousel_text')} placeholder={'Add text under the carousel here...'} />
            </FormControl>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Image or video</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <FormControl mb='4'>
              <Text mb={5} fontSize={'sm'}>Introduce your brand, product or service by using an eye-catching video or image. { missingFields.includes('meta_instant_experience.fields_data.instant_experience_asset_3') && warningIcon }</Text>

              <HStack>
                <AssetsLibraryButton assets={instantExperienceAsset3} setAssets={superSetInstantExperienceAssets3} types={['image', 'video']} />
              </HStack>

              <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                <AssetsLibraryPreview assets={instantExperienceAsset3} setAssets={superSetInstantExperienceAssets3} />
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel>Website URL (optional)</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.destination_url_3')} placeholder={'Destination URL...'} />
            </FormControl>
          </CardBody>
        </Card>
        <Card w={'full'}>
          <CardHeader>
            <HStack>
              <Text>Button</Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <FormControl>
              <FormLabel>Label { missingFields.includes('meta_instant_experience.fields_data.button_label_1') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.button_label_1')} placeholder={'Add label here...'} />
            </FormControl>
            <FormControl>
              <FormLabel>Destination { missingFields.includes('meta_instant_experience.fields_data.button_destination_1') && warningIcon }</FormLabel>
              <Input {...register('meta_instant_experience.fields_data.button_destination_1')} placeholder={'Add destination here...'} />
            </FormControl>
          </CardBody>
        </Card>
      </VStack>
      <VStack w="50%" spacing={5}>
        <StorytellingPreview instantExperienceData={{
              headline: getValues('meta_instant_experience.fields_data.headline'),
              buttonLabel_1: getValues('meta_instant_experience.fields_data.button_label_1'),
              buttonDestination_1: getValues('meta_instant_experience.fields_data.button_destination_1'),
              instantExperienceAsset_1: getValues('meta_instant_experience.fields_data.instant_experience_asset_1'),
              destination_url_1: getValues('meta_instant_experience.fields_data.destination_url_1'),
              instantExperienceAsset_2: getValues('meta_instant_experience.fields_data.instant_experience_asset_2'),
              destination_url_2: getValues('meta_instant_experience.fields_data.destination_url_2'),
              instantExperienceAsset_3: getValues('meta_instant_experience.fields_data.instant_experience_asset_3'),
              destination_url_3: getValues('meta_instant_experience.fields_data.destination_url_3'),
              carouselText: getValues('meta_instant_experience.fields_data.carousel_text'),
              metaInstantExperienceCarouselItems: getValues('meta_instant_experience.fields_data.meta_instant_experience_carousel_items')
            }} />
      </VStack>
    </Flex>
  );
};

export default Storytelling;