import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text, HStack, VStack, Icon, SkeletonText, Center, IconButton, Image, useSteps, Avatar, Input, Select, Divider, useToast, Spinner, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreVertical, FiSearch, FiStar, FiMonitor, FiRefreshCcw, FiSmartphone, FiGlobe, FiPhoneCall, FiRefreshCw } from "react-icons/fi";
import { generateCombinations, getRandomArrays } from "../../../../../helpers/variationsHelper";
import { formatCall } from "../../../../../helpers/googleSearchHelper";
import { toggle } from "../../../../../api/slices/variationsSlice";
import SimpleAd from "./SimpleAd";

import Phone from "../../../../Ads/Preview/Google/PMax/YouTube/Phone";
import GoogleLogo from "../../../../../assets/icons/pmax/google_logo.png";

const AggregatedPreview = ({
  refreshFlag = false,
  selectedVariation = 3,
  businessName = '',
  baseUrl = 'http://example.com',
  display_path_1 = 'path1',
  display_path_2 = 'path2',
  headlines = ['Example headline 1', 'Example headline 2', 'Example headline 3'],
  descriptions = ['Example description 1', 'Example description 2'],
  calls = [{ country: '+490', phoneNumber: '4564 56' }],
  callouts = [{ text: 'Test 5' }, { text: 'Test 4' }],
  sitelinks = [{ text: 'Test 1', descriptionLine_1: 'desc 1', descriptionLine_2: 'desc 2', finalUrl: 'http://example.com' }],
  snippets = [{ text: 'Brands', values: ['Nike', 'Adidas', 'Puma'], valid: false }]
}) => {
  const [headlinesCombinations, setHeadlinesCombinations] = useState([[]]);
  const [descriptionsCombinations, setDescriptionsCombinations] = useState([[]]);
  const [randomHeadlines, setRandomHeadlines] = useState([]);
  const [randomDescriptions, setRandomDescriptions] = useState([]);
  const [reload, setReload] = useState(true);

  const [display_type, setDisplayType] = React.useState('mobile');

  const variationsValue = useSelector(state => state.variation.toggleValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (headlinesCombinations.length > 1 || descriptionsCombinations.length > 1) {
      if (variationsValue == false) {
        dispatch(toggle())
      }
    }
    else {
      if (variationsValue == true) {
        dispatch(toggle())
      }
    }
  }, [headlinesCombinations, descriptionsCombinations]);

  useEffect(() => {
    const headlinesCombinations = generateCombinations(headlines, 3);
    const randomHeadlines = getRandomArrays(headlinesCombinations, selectedVariation);
    setRandomHeadlines(randomHeadlines);
    setHeadlinesCombinations(headlinesCombinations);
  }, [headlines, refreshFlag, selectedVariation]);

  useEffect(() => {
    const descriptionsCombinations = generateCombinations(descriptions, 2);
    const randomDescriptions = getRandomArrays(descriptionsCombinations, selectedVariation);
    setRandomDescriptions(randomDescriptions);
    setDescriptionsCombinations(descriptionsCombinations);
  }, [descriptions, refreshFlag, selectedVariation]);

  return (

    <>
      {randomHeadlines && randomDescriptions && randomHeadlines.map((arr, index) => (
        <VStack alignItems={'flex-start'} w='full' spacing={10}>
          <HStack h={'55px'} w={'full'} display={'flex'} justifyContent={'flex-end'}>

            <IconButton p={5} variant={display_type == 'mobile' ? 'solid' : 'outline'} h={'auto'} onClick={() => setDisplayType('mobile')} icon={<Icon fontSize={'sm'} as={FiSmartphone} />} />
            <IconButton p={5} variant={display_type == 'desktop' ? 'solid' : 'outline'} h={'auto'} onClick={() => setDisplayType('desktop')} icon={<Icon fontSize={'sm'} as={FiMonitor} />} />
          </HStack>
          {display_type == 'mobile' &&
            <Phone p={3}>
              <HStack justifyContent={'space-between'} px={1} py={1} w={'full'} >
                <Icon as={FiMenu} />
                <Image src={GoogleLogo} w={20} />
                <Avatar size={'xs'} name={' '} bg={'gray.200'} />
              </HStack>

              <HStack mt={1} w={'full'} p={1} border={'solid 1px'} borderColor={'gray.200'} borderRadius={'3xl'}>
                <Icon as={FiSearch} />
              </HStack>

              <HStack mt={2} h={'10px'} w={'full'} spacing={10} justifyContent={'space-between'}>
                {Array(5).fill().map((_, i) => (
                  <Box key={i} w={'full'} bg={i === 0 ? 'gray.500' : 'gray.300'} h={'10px'} />
                ))}
              </HStack>

              <VStack alignItems={'flex-start'} mt={2} p={1}>
                <SimpleAd
                  key={index}
                  businessName={businessName}
                  baseUrl={baseUrl}
                  display_path_1={display_path_1}
                  display_path_2={display_path_2}
                  headlines={arr}
                  descriptions={randomDescriptions[index]}
                  calls={calls}
                  callouts={callouts}
                  sitelinks={sitelinks}
                  snippets={snippets}
                  display_type='mobile'
                />
                {[1, 2].map((i) => (<>
                  <HStack rounded={'md'} p={1} border={'solid 1px'} borderColor={'gray.200'} mt={4} w={'full'}>
                    <SkeletonText w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />
                  </HStack>
                </>
                ))}
              </VStack>
            </Phone>
          }
          {display_type == 'desktop' &&
            <VStack mt={10} maxW="rem" boxShadow={'base'} p={2} w={'full'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <SimpleAd
                key={index}
                businessName={businessName}
                baseUrl={baseUrl}
                display_path_1={display_path_1}
                display_path_2={display_path_2}
                headlines={arr}
                descriptions={randomDescriptions[index]}
                calls={calls}
                callouts={callouts}
                sitelinks={sitelinks}
                snippets={snippets}
                display_type='desktop'
              />
              {[1, 2, 3].map((i) => (<>
                <HStack rounded={'md'} p={3} border={'solid 1px'} borderColor={'gray.200'} mt={4} w={'full'}>
                  <SkeletonText w={'full'} startColor="gray.300" endColor="gray.300" noOfLines={4} />
                </HStack>
              </>
              ))}
            </VStack>
          }
        </VStack>

      ))}
    </>

  );
}

export default AggregatedPreview;
