import React, { useEffect } from 'react';
import { useUpdateCommentMutation } from '../../../api/services/comments';
import { format } from 'timeago.js';
import { Box, Text, useToast, VStack, Icon, HStack, Avatar, IconButton, Tooltip, useDisclosure, Button, Badge } from "@chakra-ui/react";
import { FiCheckCircle, FiCornerDownRight, FiCornerUpLeft } from 'react-icons/fi';
import { isLoggedIn } from '../../../helpers/sessionHelper';

const Comment = ({ comment, onOpenReplies, showRepliesButton = false }) => {
  const [resolveComment, { isSuccess: resolveSuccess }] = useUpdateCommentMutation();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (resolveSuccess) {
      toast({
        title: "Comment resolved.",
        description: "The comment has been successfully resolved.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [resolveSuccess]);

  return (
    <>
      <Box onMouseOver={() => onOpen()} borderTopRadius={'md'} mt={1} onMouseOut={() => onClose()} alignItems={'flex-start'} w={'full'} key={comment.id} backgroundColor={comment.resolved ? '#D1FADF' : 'transparent'} p={'1rem'} opacity={comment.resolved ? '0.65' : 'initial'}>
        <HStack justifyContent={'space-between'} alignItems={'flex-start'}>
          <HStack alignItems={'flex-start'}>
            <Avatar size={'sm'} name={comment.user?.email || comment.client?.email} />

            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontSize={'sm'}>{comment.user?.email || comment.client?.email || 'Anonymous Guest'} {comment.directionType == 'internal' && <Badge bg={'#C1A9EE'} fontSize={'10px'} color={'#7845DB'} marginLeft={'0.25rem'} marginTop={'-0.25rem'}>Internal</Badge>}</Text>
              <Text fontSize={'xs'} color={'gray.500'}>{format(comment.createdAt)}</Text>
            </VStack>
          </HStack>

          {!comment.resolved && isLoggedIn && <>
            <Tooltip label={'Click to resolve comment.'} hasArrow placement='top'>
              <IconButton visibility={isOpen ? 'visible' : 'hidden'} _hover={{ bg: 'green.100', borderColor: 'green.500' }} variant={'outline'} py={3} borderRadius={'full'} icon={<Icon as={FiCheckCircle} />} onClick={() => resolveComment({ id: comment.id })} aria-label={''} />
            </Tooltip>
          </>}

          {comment.resolved &&
            <IconButton bg={'transparent'} color={'#4D5761'} py={3} borderRadius={'full'} icon={<Icon as={FiCheckCircle} />} onClick={() => resolveComment({ id: comment.id })} aria-label={''} />
          }

        </HStack>
        <VStack mt={3} alignItems={'flex-start'}>
          <Text color={'gray.600'} fontSize={'sm'}>{comment.text}</Text>

          {showRepliesButton && (
            <Button _hover={{ bg: 'gray.100' }} variant={'ghost'} leftIcon={<Icon as={FiCornerDownRight} />} fontSize={'sm'} onClick={() => onOpenReplies(comment)}>{comment.hasReplies ? 'View all replies' : `Reply`}</Button>
          )}

        </VStack>
      </Box>


    </>
  )
}

export default Comment;