import React, { useState } from 'react';
import { Box, Text, Stack, Button, Divider, VStack, List, ListItem, ListIcon, HStack, useColorModeValue } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { plans } from './plans';

const SubscriptionPlans = ({ selectedPlan, setSelectedPlan }) => {

    return (
        <Box>
            <Box width="100%" textAlign="center" mb={8}>
                <Text fontSize="xl" fontWeight="bold" mb={2}>Great! Let's set up the subscription.</Text>
                <Text fontSize="sm" color="gray.600">
                    Choose a plan that best fits your needs.
                </Text>
            </Box>
            <Stack
                mt={3}
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={3}>
                {plans.map((plan, index) => (
                    <Box
                        cursor={'pointer'}
                        key={index}
                        mb={4}
                        bg={'white'}
                        shadow="base"
                        w={'full'}
                        borderWidth={'2px'}
                        alignSelf={{ base: 'center', lg: 'flex-start' }}
                        borderColor={selectedPlan === plan.lookupKey ? 'purple.500' : useColorModeValue('gray.200', 'gray.700')}
                        borderRadius={'xl'}
                        onClick={() => setSelectedPlan(plan.lookupKey)}
                    >
                        <Box w={'full'} position="relative">
                            <Box display={'flex'} alignItems={'flex-start'} flexDir={'column'} py={4} px={8}>
                                <Text fontWeight="400" fontSize="xl" color={'gray.600'}>
                                    {plan.name}
                                </Text>
                                <HStack mt={1} justifyContent="flex-start" alignItems={'baseline'}>
                                    <Text fontSize="3xl" color={'gray.600'} fontWeight={'semibold'}>
                                        {plan.price}
                                    </Text>
                                    <Text fontSize="md" color="gray.500">
                                        {plan.lookupKey == 'enterprise_monthly' ? '' : '/month'}
                                    </Text>
                                </HStack>
                                <Text color={'gray.500'} fontSize={'xs'}>{plan.subtitle}</Text>
                                {plan.lookupKey !== 'enterprise_monthly' && (
                                    <Button fontSize={'xs'} mt={3} py={2} borderRadius={'full'} bgGradient={'linear(115.04deg, #7845DB -69.4%, #E08AFF 3.11%, #9747FF 76.91%)'}>14 day free trial included</Button>
                                )}
                                <Divider mt={4} />
                            </Box>
                            <VStack py={4} borderBottomRadius={'xl'}>
                                <List w={'full'} fontSize={'sm'} spacing={3} textAlign="start" px={8}>
                                    {plan.features.map((feature, index) => (
                                        <ListItem key={index} display={'flex'} justifyContent={'space-between'}>
                                            <Text fontWeight={'semibold'} color={'gray.600'}>{feature}</Text>
                                            <ListIcon as={FaCheckCircle} color="brand.500" />
                                        </ListItem>
                                    ))}
                                </List>
                            </VStack>
                        </Box>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};

export default SubscriptionPlans;