import { baseApi } from "./base/base";

export const foldersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFolders: builder.query({
      query: (data) => ({ url: '/folders', params: data }),
      providesTags: ['Folder']
    }),
    updateFolder: builder.mutation({
      query: (data) => ({ url: `/folders/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Folder']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetFoldersQuery,
  useUpdateFolderMutation
} = foldersApi;
