import { Box, Flex, Icon } from '@chakra-ui/react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator
} from "@ajna/pagination";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useEffect } from 'react';
import React from 'react';


const Paginator = ({ meta = { page: 1, perPage: 10, totalAmount: 0 }, onPageChange }) => {
  const totalPages = Math.ceil(meta.totalAmount / meta.perPage) || 1;

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages
  } = usePagination({
    limits: {
      outer: 1,
      inner: 1,
    },
    pagesCount: totalPages,
    initialState: { currentPage: 1 },
  });

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage])

  return (
    <>
      <Flex alignItems={'center'}>
        <Box>

          <Pagination
            pagesCount={totalPages}
            currentPage={currentPage}
            onPageChange={setCurrentPage}

          >
            <PaginationContainer>
              <PaginationPrevious bg={'transparent'} color={'black'} mr={2} w={8} h={8}><Icon fontSize={'sm'} as={FiChevronLeft} /></PaginationPrevious>
              <PaginationPageGroup separator={<PaginationSeparator bg='white' color={'black'} py={2} px={2} />}>
                {pages.map((page) => (
                  <PaginationPage
                    w={8}
                    h={8}
                    bg={'transparent'}
                    color={'black'}
                    fontSize="sm"
                    key={`pagination_page_${page}`}
                    page={page}
                    _current={{
                      bg: 'gray.200',
                      color: 'black'
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext bg={'transparent'} color={'black'} ml={2} w={8} h={8}><Icon fontSize={'sm'} as={FiChevronRight} /></PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Box>
      </Flex>
    </>
  );
}

export default Paginator;