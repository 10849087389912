import React, { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Heading,
  Progress,
  Select,
  Text,
  Textarea,
  FormErrorMessage,
  useToast,
  HStack,
  Flex,
  Spacer,
  Checkbox,
  Alert,
  AlertIcon,
  useDisclosure,
  CloseButton,
  Tooltip,
  Icon
} from '@chakra-ui/react';
import { set, useForm } from 'react-hook-form';
import { useCreateSuggestionMutation } from '../../api/services/suggestions';

function Suggestions(
  {
    isOpen,
    onClose,
    updateHeadline = null,
    updateDescription = null,
    minNumberOfHeadlines = 1,
    maxNumberOfHeadlines = 1,
    minNumberOfDescriptions = 1,
    maxNumberOfDescriptions = 1,
  }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm();
  const [currentStep, setCurrentStep] = useState(1);
  const [suggestions, setSuggestions] = useState([]);
  const [create, { isLoading, isSuccess, isError }] = useCreateSuggestionMutation();
  const toast = useToast();

  const numberOfResultsArray = () => {
    const minNumberOfResults = Math.max(minNumberOfHeadlines, minNumberOfDescriptions);
    const resultArray = [];
    for (let i = minNumberOfResults; i <= 5; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  const languages = [
    { value: 'english', label: 'English' },
    { value: 'arabic', label: 'Arabic' },
    { value: 'german', label: 'German' },
    { value: 'french', label: 'French' },
    { value: 'russian', label: 'Russian' },
    { value: 'turkish', label: 'Turkish' },
    { value: 'spanish', label: 'Spanish' },
  ];

  const toneOfVoices = [
    { value: 'professional', label: 'Professional' },
    { value: 'casual', label: 'Casual' },
    { value: 'humorous', label: 'Humorous' },
    { value: 'informative', label: 'Informative' },
    { value: 'optimistic', label: 'Optimistic' },
    { value: 'motivating', label: 'Motivating' },
  ];

  const onSuggestionsSubmit = (formData) => {
    create(formData)
      .unwrap()
      .then((res) => {
        setSuggestions(res);
        setCurrentStep(updateHeadline != null ? 2 : 3);
        onErrorClose();
      })
      .catch((err) => {
        onOpen();
      });
  };

  const handleCancel = () => {
    reset();
    setSuggestions([]);
    setSelectedHeadlines([]);
    setSelectedDescriptions([]);
    setCurrentStep(1);
    onErrorClose();
    onClose();
  };

  const [selectedHeadlines, setSelectedHeadlines] = useState([]);

  const handleHeadlineCheckboxChange = (headline) => {
    const updatedHeadlines = [...selectedHeadlines];
    const index = updatedHeadlines.indexOf(headline);

    if (index !== -1) {
      updatedHeadlines.splice(index, 1);
    } else {
      updatedHeadlines.push(headline);
      if (updatedHeadlines.length > maxNumberOfHeadlines) {
        updatedHeadlines.shift();
      }
    }

    setSelectedHeadlines(updatedHeadlines);
  };

  const [selectedDescriptions, setSelectedDescriptions] = useState([]);
  const handleDescriptionCheckboxChange = (description) => {
    const updatedDescriptions = [...selectedDescriptions];
    const index = updatedDescriptions.indexOf(description);
    if (index !== -1) {
      updatedDescriptions.splice(index, 1);
    } else {
      updatedDescriptions.push(description);
      if (updatedDescriptions.length > maxNumberOfDescriptions) {
        updatedDescriptions.shift();
      }
    }
    setSelectedDescriptions(updatedDescriptions);
  };

  const finishSuggestionsWizard = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (updateHeadline != null) {
      updateHeadline(selectedHeadlines);
    }

    if (updateDescription != null) {
      updateDescription(selectedDescriptions);
    }

    handleCancel();
    toast({
      title: 'Suggestions imported successfully.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }

  const backToForm = () => {
    setSelectedHeadlines([]);
    setSelectedDescriptions([]);
    setSuggestions([]);
    onErrorClose();
    setCurrentStep(1);
  }

  const {
    isOpen: isVisible,
    onClose: onErrorClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false })

  return (
    <>
      <Drawer onClose={handleCancel} isOpen={isOpen} closeOnOverlayClick={false} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>

          <DrawerHeader>
            <Flex alignItems={'center'}>
              AI Suggestions
              <Spacer />
              <DrawerCloseButton position={'unset'} />
            </Flex>
            <Progress my={4} height='6px' colorScheme='brand' value={(currentStep / 4) * 100} />
            {isVisible &&
              <Alert status='error'>
                <AlertIcon />
                Unexpected error ocurred, please try again.
                <CloseButton
                  position='absolute'
                  right='10px'
                  onClick={onErrorClose}
                />
              </Alert>
            }
          </DrawerHeader>
          <DrawerBody>
            {currentStep === 1 && (
              <form onSubmit={handleSubmit(onSuggestionsSubmit)}>
                <FormControl isInvalid={errors.serviceName} my='3'>

                  <FormLabel >Service Name
                    <Tooltip hasArrow
                      label={` Please provide the name of your product, brand, or company here. Make sure it reflects your unique identity!`}>
                      <Icon mx={2} />
                    </Tooltip>
                  </FormLabel>
                  <Input
                    {...register('serviceName', { required: 'Service name is required', validate: value => value.trim().length > 0 || 'Service name is required' })}
                    type="text"
                    placeholder="Service Name.."
                  />

                  <FormErrorMessage>{errors.serviceName && errors.serviceName.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.description} my='3'>
                  <FormLabel>Description
                    <Tooltip hasArrow
                      label={`Share any helpful information about your product, brand, or company here. This could include product descriptions, customer testimonials, or insights about competitors. Feel free to update or add new information as needed!`}>
                      <Icon ml={2} />
                    </Tooltip>
                  </FormLabel>

                  <Textarea
                    {...register('description', { required: 'Description is required', validate: value => value.trim().length > 0 || 'Description is required' })}
                    type="text"
                    height='150px'
                    placeholder="Description.."
                  />

                  <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.toneOfVoice} my='3'>
                  <FormLabel>Tone of voice</FormLabel>
                  <Select {...register('toneOfVoice', { required: 'Tone of voice is required' })} defaultValue={'professional'}>
                    <option value="">Select option</option>
                    {toneOfVoices.map((tone) => (
                      <option key={tone.value} value={tone.value}>
                        {tone.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.toneOfVoice && errors.toneOfVoice.message}</FormErrorMessage>
                </FormControl>

                <HStack spacing={5}>
                  <FormControl isInvalid={errors.language} my='3'>
                    <FormLabel>Language</FormLabel>
                    <Select {...register('language', { required: 'Language is required' })} defaultValue={'english'}>
                      <option value="">Select option</option>
                      {languages.map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.language && errors.language.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.numberOfResults} my='3' >
                    <FormLabel>Number of results</FormLabel>
                    <Select {...register('numberOfResults', { required: 'Number of results is required' })} defaultValue={'3'}>
                      <option value="">Select option</option>
                      {numberOfResultsArray().map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.numberOfResults && errors.numberOfResults.message}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <Flex py={5} spacing='4' alignItems='center'>
                  <Spacer />
                  <Button isDisabled={isLoading} variant='outline' mr='3' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="button" onClick={handleSubmit(onSuggestionsSubmit)} isLoading={isLoading}>
                    Continue
                  </Button>
                </Flex>
              </form>
            )}

            {currentStep === 2 && (
              <Box py={4}>
                {suggestions['headlines'] && suggestions['headlines'].length > 0 && (
                  <>
                    <Heading size='16px' fontWeight='500'>Headlines ({minNumberOfHeadlines} min {maxNumberOfHeadlines} max)</Heading>
                    {suggestions['headlines'].map((headline, index) => (
                      <HStack alignContent={'center'} verticalAlign={'center'} my={4} key={index}>
                        <Checkbox
                          size='lg'
                          border={'solid 1px'}
                          borderColor={selectedHeadlines.includes(headline) ? 'purple.500' : 'gray.200'}
                          borderRadius={'15px'}
                          bg={selectedHeadlines.includes(headline) ? 'purple.50' : 'white'}
                          px={5}
                          py={3}
                          w={'full'}
                          colorScheme='purple'
                          isChecked={selectedHeadlines.includes(headline)}
                          onChange={() => handleHeadlineCheckboxChange(headline)}>
                          <Text fontSize="sm" color='#656972'>
                            {headline}
                          </Text>
                        </Checkbox>

                      </HStack>
                    ))}
                  </>
                )}

                <Flex py={5} spacing='4' alignItems='center'>
                  <Spacer />
                  <Button mr={3} variant="outline" onClick={() => backToForm()}>
                    Back
                  </Button>
                  {updateDescription != null && <Button type="button" colorScheme="primary"
                    isDisabled={selectedHeadlines.filter(Boolean).length < minNumberOfHeadlines}
                    onClick={() => setCurrentStep(3)}>
                    Continue
                  </Button>}
                  {updateDescription == null && <>
                    <Button type="button" colorScheme="primary" isDisabled={selectedHeadlines.filter(Boolean).length < minNumberOfHeadlines} onClick={(e) => finishSuggestionsWizard(e)}>
                      Finish
                    </Button>
                  </>}
                </Flex>
              </Box>
            )}

            {currentStep === 3 && (
              <Box py={5}>
                {suggestions['descriptions'] && suggestions['descriptions'].length > 0 && (
                  <>
                    <Heading size='16px' fontWeight='500'>Descriptions ({minNumberOfDescriptions} min {maxNumberOfDescriptions} max)</Heading>
                    {suggestions['descriptions'].map((description, index) => (
                      <HStack alignContent={'center'} verticalAlign={'center'} my={4} key={index}>
                        <Checkbox
                          size='lg'
                          border={'solid 1px'}
                          borderColor={selectedDescriptions.includes(description) ? 'purple.500' : 'gray.200'}
                          borderRadius={'15px'}
                          bg={selectedDescriptions.includes(description) ? 'purple.50' : 'white'}

                          px={5}
                          py={3}
                          w={'full'}
                          colorScheme='purple'
                          isChecked={selectedDescriptions.includes(description)}
                          onChange={() => handleDescriptionCheckboxChange(description)}
                        >
                          <Text fontSize="sm" color='#656972'>
                            {description}
                          </Text>
                        </Checkbox>

                      </HStack>
                    ))}
                  </>
                )}

                <Flex py={5} spacing='4' alignItems='center'>
                  <Spacer />
                  <Button mr={3} variant="outline" onClick={() => setCurrentStep(updateHeadline != null ? 2 : 1)}>
                    Back
                  </Button>
                  <Button type="button" colorScheme="primary" isDisabled={selectedDescriptions.filter(Boolean).length < minNumberOfDescriptions} onClick={(e) => finishSuggestionsWizard(e)}>
                    Finish
                  </Button>
                </Flex>
              </Box>
            )}


          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Suggestions;
