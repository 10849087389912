import { Box, HStack, VStack, Text, Tabs, TabList, Tab, Icon, Image, TabPanels, TabPanel } from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { transformKeysTo } from "../../../../helpers/apiHelper";
import CarouselCommonEditor from "./Carousel/CommonEditor";
import FacebookCarousel from "../../Preview/Facebook/Carousel";
import InstagramCarousel from "../../Preview/Instagram/Carousel";
import { SiFacebook, SiInstagram } from "react-icons/si";

const Carousel = ({ isLoading, onFormSubmit, ad }) => {
  const getDefaultMetadata = () => {
    const defaultData = transformKeysTo('snake', structuredClone(ad?.metadata));

    if (defaultData?.meta_ad_carousel_items?.length == 0) {
      defaultData.meta_ad_carousel_items = [
        {
          headline: 'Headline',
          description: 'Description'
        }
      ]
    }

    return defaultData;
  }

  const { register, watch, getValues, handleSubmit, control, setValue } = useForm({
    defaultValues: getDefaultMetadata()
  });

  const facebookLogoAsset = ad?.metadata?.facebookLogo;
  const facebookLogoState = React.useState(facebookLogoAsset == null ? [] : [facebookLogoAsset])

  const instagramLogoAsset = ad?.metadata?.instagramLogo;
  const instagramLogoState = React.useState(instagramLogoAsset == null ? [] : [instagramLogoAsset])

  const [activeTab, setActiveTab] = React.useState(0); // 0 for Facebook, 1 for Instagram

  const wAll = watch();

  return (
    <>
      <HStack alignItems={'flex-start'} gap={20} spacing={10} mt={5} justifyContent={'space-between'}>
        <CarouselCommonEditor
          getValues={getValues}
          setValue={setValue}
          control={control}
          ad={ad}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          onFormSubmit={onFormSubmit}
          register={register}
          facebookLogoState={facebookLogoState}
          instagramLogoState={instagramLogoState}
          activeTab={activeTab}
        />
        <Box w={'full'} display={'flex'} justifyContent={'center'} position={'sticky'} top={'115px'}>
          <VStack spacing={10}>
            <Tabs w={'full'} onChange={(index) => setActiveTab(index)}>
              <TabList>
                <Tab w={'full'}>
                  <VStack spacing={3}>
                    <Icon color={'blue.500'} fontSize={'xl'} as={SiFacebook} />
                    <Text fontSize={'sm'} color={'black'}>Facebook</Text>
                  </VStack>
                </Tab>
                <Tab w={'full'}>
                  <VStack spacing={3}>
                    <Image color={'purple.500'} w={6} as={SiInstagram} />
                    <Text fontSize={'sm'} color={'black'}>Instagram</Text>
                  </VStack>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FacebookCarousel ad={{
                    primaryText: getValues('primary_text'),
                    callToAction: getValues('call_to_action'),
                    facebookPageName: getValues('facebook_page_name'),
                    facebookLogo: facebookLogoState[0][0],
                    metaAdCarouselItems: getValues('meta_ad_carousel_items')
                  }}
                    instantExperienceData={getValues('meta_instant_experience')} />
                </TabPanel>
                <TabPanel>
                  <InstagramCarousel ad={{
                    primaryText: getValues('primary_text'),
                    callToAction: getValues('call_to_action'),
                    instagramAccountName: getValues('instagram_account_name'),
                    instagramLogo: instagramLogoState[0][0],
                    metaAdCarouselItems: getValues('meta_ad_carousel_items')
                  }}
                    instantExperienceData={getValues('meta_instant_experience')} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Box>
      </HStack>
    </>
  )
}

export default Carousel;