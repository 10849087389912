import { Text, Button, useSteps, VStack, HStack, Stack, SkeletonCircle, SkeletonText, Spinner, Radio, RadioGroup } from '@chakra-ui/react';
import React from 'react';
import { useGetGoogleAdAccountsQuery } from '../../../api/services/integrations';
import Campaigns from './Google/Campaigns';
import Import from './Google/Import';

const GoogleFlow = ({ setProvider, provider, onClose }) => {
  const [disableNext, setDisableNext] = React.useState(false)
  const [selectedAdAccount, setSelectedAdAccount] = React.useState('0')
  const [selectedCampaign, setSelectedCampaign] = React.useState('0');

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: 3,
  })

  const { data: adAccounts, isLoading, isFetching } = useGetGoogleAdAccountsQuery(provider?.id, { refetchOnMountOrArgChange: true });

  React.useEffect(() => {
    if (isLoading || isFetching || selectedAdAccount == '0') {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [isLoading, isFetching, selectedAdAccount]);

  return (
    <>
      {activeStep == 1 && <>

        <Text fontWeight={500} mb={4}>Select Ad Account</Text>

        {(isLoading || isFetching) &&
          <VStack my={6} spacing={6} justifyContent={'center'}>
            <Spinner />
            <Text textAlign={'center'} color={'gray'}>We're loading Ad Accounts from Google... Please wait!</Text>
          </VStack>}

        {!(isLoading || isFetching) && adAccounts?.adAccounts?.map((adAccount) => (
          <>
            <HStack bg={selectedAdAccount?.id == adAccount.id ? 'blue.50' : 'white'} onClick={() => setSelectedAdAccount(adAccount)} cursor={'pointer'} py={2} px={4} rounded={'md'} border={'solid 1px'} borderColor={selectedAdAccount?.id == adAccount?.id ? 'blue.400' : 'gray.300'} mb={4} spacing={5}>
              <Radio onClick={(e) => e.preventDefault()} isChecked={selectedAdAccount?.id == adAccount.id} />

              <VStack alignItems={'flex-start'}>
                <Text>{adAccount.name || 'Untitled'}</Text>
                <Text color={'gray'} fontSize={'xs'}>{adAccount.currencyCode}, {adAccount.id}</Text>
              </VStack>
            </HStack>
          </>
        ))}

      </>}

      {activeStep == 2 && <>
        <Campaigns selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} customerId={selectedAdAccount?.customerId} setDisableNext={setDisableNext} accountId={selectedAdAccount?.id} provider={provider} />
      </>}

      {activeStep == 3 && <>
        <Import integration={provider} campaign={selectedCampaign} adAccount={selectedAdAccount} />
        <Button my={5} mt={10} onClick={() => onClose()} variant='outline'>Close</Button>
      </>}

      {activeStep < 3 && <HStack my={5} mt={10}>
        <Button onClick={() => activeStep == 1 ? setProvider({}) : goToPrevious()} variant='outline'>Previous</Button>
        <Button isDisabled={disableNext} onClick={goToNext} colorScheme='primary' mr={3} type='submit'>
          Next
        </Button>
      </HStack>}
    </>
  )
};

export default GoogleFlow;