import { baseApi } from "../base/base";

export const publicFoldersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicFolder: builder.query({
      query: (uuid) => ({ url: `/public/folders/${uuid}` }),
      providesTags: ['Folder']
    }),
  }),
  overrideExisting: false
});

export const {
  useGetPublicFolderQuery
} = publicFoldersApi;
