import React from 'react';
import SimpleTableContents from './SimpleTableContents';
import { useGetMediaPlanCampaignsQuery } from '../../../api/services/mediaPlanCampaigns';

const SimpleTable = ({ mediaPlan, readOnly = false }) => {
  const { data } = useGetMediaPlanCampaignsQuery({ media_plan_id: mediaPlan.id }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

  return (
    <SimpleTableContents mediaPlanCampaigns={data} mediaPlan={mediaPlan} canDelete={true} readOnly={false} />
  );
};

export default SimpleTable;