import React from "react";

import HomePageAd from "./YouTube/HomePageAd1";
import WatchPageAd from "./YouTube/WatchPageAd";
import YoutubeAd from "./YouTube/YoutubeAd";
import MobileClosed from "./Gmail/MobileClosed";
import DesktopClosed1 from "./Gmail/DesktopClosed1";
import DesktopClosed2 from "./Gmail/DesktopClosed2";
import MobileOpened from "./Gmail/MobileOpened";
import DesktopOpened from "./Gmail/DesktopOpened";
import { Box, Center, HStack, Icon, IconButton, useSteps } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import DesktopVideoInStreamAd from "./YouTube/DesktopVideoInStreamAd";

const YouTubeandGmail = ({ variations, displayType,  }) => {

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 8
  })

  return (
    <>
      {displayType == 'mobile' && <>
        <Center>
          <HStack spacing={10} mb={4}>
            <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
            <IconButton isDisabled={activeStep == 4} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
          </HStack>
        </Center>

        {activeStep == 0 && <HomePageAd variation={variations[0]} />}
        {activeStep == 1 && <WatchPageAd variation={variations[1]} />}
        {activeStep == 2 && <YoutubeAd variation={variations[0]} />}
        {activeStep == 3 && <MobileClosed variation={variations[1]} activeStep={0} />}
        {activeStep == 4 && <MobileOpened variation={variations[0]} />}
      </>}

      {displayType == 'desktop' &&  <>
       <Center>
          <HStack spacing={10} mb={4}>
            <IconButton isDisabled={activeStep == 0} px={4} variant={'outline'} onClick={() => goToPrevious()} h={'50px'} icon={<Icon as={FiChevronLeft} />} aria-label={"Next preview"}></IconButton>
            <IconButton isDisabled={activeStep == 4} px={4} variant={'outline'} onClick={() => goToNext()} h={'50px'} icon={<Icon as={FiChevronRight} />} aria-label={"Previous preview"}></IconButton>
          </HStack>
        </Center>
        {activeStep == 0 && <DesktopClosed1 variation={variations[1]} />}
        {activeStep == 1 && <DesktopClosed2 variation={variations[0]} />}
        {activeStep == 2 && <DesktopOpened variation={variations[1]} activeStep={0}/>}
        {activeStep == 3 && <DesktopOpened variation={variations[0]} activeStep={1}/>}
        {activeStep == 4 && <DesktopVideoInStreamAd variation={variations[1]} />}
        
      </>}
    </>
  )
};

export default YouTubeandGmail;