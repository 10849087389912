import React, { useEffect } from 'react'
import {
  IconButton,
  Avatar,
  Image,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiTv,
  FiFolder,
} from 'react-icons/fi'
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useGetSharedOrganizationQuery } from '../../api/services/shared/organizations';
import Header from './ClientLayout/Header';
import { useOrganization } from '../../components/Hooks/useOrganization';
import SidebarFolders from '../../views/Client/Components/SidebarFolders';


const SidebarContent = ({ onClose, ...rest }) => {
  const { organization: organizationSlug } = useParams();
  const location = useLocation();

  const LinkItems = [
    { name: 'Home', icon: FiHome, to: `/portal/${organizationSlug}/home`, slug: 'home' },
    { name: 'Campaigns', icon: FiFolder, to: `/portal/${organizationSlug}/campaigns`, slug: 'campaign', additionalItems: <SidebarFolders /> },
    { name: 'Media Plans', icon: FiCompass, to: `/portal/${organizationSlug}/media-plans`, slug: 'media-plans' },
  ]

  const { organization } = useOrganization();

  useEffect(() => {
    document.title = `${(organization?.displayName ?? 'WhatCampaign')} Client Portal`;

    const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = organization?.organizationFavicon?.fileUrl;
    document.head.appendChild(favicon);
  }, [organization?.displayName, organization?.organizationFavicon?.fileUrl])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={'gray.200'}
      w={{ base: 'full', md: 80 }}
      boxShadow={'none'}
      pos="fixed"
      h="full"
      overflowY={'scroll'}
      {...rest}>


      {organization?.organizationLogo == null && <Flex mb={5} h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          {organization?.displayName}
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>}
      {organization?.organizationLogo != null && <Box display={'flex'} justifyContent={'center'} px={5} py={3}><Image maxH={'80px'} my={3} maxW={'full'} src={organization?.organizationLogo?.fileUrl} alt={organization?.displayName} /></Box>}


      {LinkItems.map((link) => {
        const isCurrentPath = location.pathname.includes(link.slug);

        return (
          <>
            <NavItem key={link.name} isCurrentPath={isCurrentPath} to={link.to} slug={link.slug} icon={link.icon}>
              {link.name}
            </NavItem>
            {link.additionalItems && isCurrentPath && <>{link.additionalItems}</>}
          </>
        )
      })}
    </Box>
  )
}

const NavItem = ({ icon, children, slug, to, isCurrentPath, ...rest }) => {

  return (
    <RouterLink to={to}>
      <Box
        as="a"
        mb={2}
        href="#"
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          px={5}
          mx="4"
          mb={1}
          borderRadius="lg"
          role="group"
          cursor="pointer"
          fontSize={'sm'}
          fontWeight={'500'}
          color={'gray.600'}
          bg={isCurrentPath ? 'gray.100' : 'transparent'}

          _hover={{
            bg: 'gray.100',
            color: 'gray.600',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'gray.600',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    </RouterLink>
  )
}

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH="100vh" bg={useColorModeValue('white', 'gray.900')}>
      <SidebarContent onClose={() => onClose()} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />
      <Box ml={{ base: 0, md: 80 }} p="4">
        <Outlet />
      </Box>
    </Box>
  )
}

export default SidebarWithHeader
