import { Image, Button, Icon, Box, Text, HStack, Select, Card, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, RadioGroup, Radio, FormHelperText } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiHelpCircle, FiImage, FiEdit3 } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { useFieldArray, useForm } from "react-hook-form";
import { metaCallToActions } from "../../../../../components/Constants/meta_call_to_actions";
import { transformKeysTo } from "../../../../../helpers/apiHelper";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";
import { useNavigate, useParams } from "react-router-dom";
import Slide from "./Slide";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import flattenObject from "../../../../../helpers/objectHelper";
import InstantExperience from "../InstantExperience/InstantExperience";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import { validateUrl } from "../../../../../components/utils/urlUtils";

const CarouselCommonEditor = ({ ad, control, getValues, setValue, onFormSubmit, isLoading, register, handleSubmit, instagramLogoState, facebookLogoState, activeTab }) => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const [facebookLogo, setFacebookLogo] = facebookLogoState;
  const [instagramLogo, setInstagramLogo] = instagramLogoState;
  const primaryTextMaxLength = activeTab === 0 ? 80 : 125;

  const handleToggleSlide = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "meta_ad_carousel_items"
  });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(getValues('meta_ad_carousel_items'));

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setValue('meta_ad_carousel_items', items);
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('facebook_logo_asset_id', facebookLogo[0]?.id);
    formData.append('instagram_logo_asset_id', instagramLogo[0]?.id);

    const flObject = flattenObject(data);
    Object.keys(flObject).forEach((key) => {
      if (flObject[key] == null)
        return;

      const modifiedKey = key.replace(/\[\d+\]/g, '[]');
      formData.append(modifiedKey, flObject[key]);
    });

    onFormSubmit(formData);
  }


  return (
    <>
      <form style={{ width: '100%' }} mode="onBlur" onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} >

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Identity</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
            </CardHeader>
            <CardBody>
              <FormControl>
                <FormLabel>Facebook Page</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={facebookLogo} setAssets={setFacebookLogo} type={'image'} rounded />

                  <Input w={'full'} {...register('facebook_page_name')} placeholder={'Page name'} />
                </HStack>

              </FormControl>

              <FormControl mt={10}>
                <FormLabel>Instagram Account</FormLabel>
                <HStack spacing={5}>
                  <AssetsLibraryButton assets={instagramLogo} setAssets={setInstagramLogo} type={'image'} rounded />

                  <Input {...register('instagram_account_name')} w={'full'} placeholder={'Account name'} />
                </HStack>
              </FormControl>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Media</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>
              <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((item, index) => {
                        return (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <Slide
                                isOpen={index === openIndex}
                                onToggle={() => handleToggleSlide(index)}
                                setValue={setValue} getValues={getValues}
                                register={register} index={index}
                                provided={provided} item={item}
                                remove={() => remove(index)} />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
              <Box w={'full'} display={'flex'} justifyContent={'flex-end'}>
                <Button mt={5} right={0} variant={'outline'} onClick={() => append({})} leftIcon={<Icon as={FiImage} />}>Add new slide</Button>
              </Box>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Content</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
            </CardHeader>
            <CardBody>

              <VStack spacing={5}>
                <FormControl>
                  <FormLabel>Primary Text</FormLabel>
                  <Textarea {...register('primary_text')} placeholder={'Add primary text here...'} maxLength={primaryTextMaxLength} />
                  <FormHelperText color={(getValues('primary_text')?.length || 0) > primaryTextMaxLength ? 'red' : 'gray.500'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                    {`${getValues('primary_text')?.length || 0} / ${primaryTextMaxLength}`}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel>Call To Action</FormLabel>
                  <Select {...register('call_to_action')}>
                    <option value={''}>No Button</option>
                    {metaCallToActions.map((cta, index) => (
                      <option value={cta.value} key={index}>{cta.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <Card w={'full'}>
            <CardHeader>
              <HStack>
                <Text>Destination</Text>
                <Icon as={FiHelpCircle} />
              </HStack>
              <Text fontSize={'xs'} color={'gray.400'}>Please provide the destination URL for your ad.</Text>
            </CardHeader>
            <CardBody>
              <VStack spacing={5}>
                <FormControl>
                  <VStack align={'start'} mb='4'>
                    <Text>Website URL</Text>
                    <Input {...register('website_url')}
                      placeholder={'Enter your website name'}
                      onBlur={(e) => {
                        const validatedUrl = validateUrl(e.target.value);
                        setValue('website_url', validatedUrl);
                      }} />
                  </VStack>
                </FormControl>
              </VStack>
            </CardBody>
          </Card>

          <HStack w={'full'} justifyContent={'flex-start'}>
            <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
            <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
          </HStack>
        </VStack>
      </form>
    </>
  )
}

export default CarouselCommonEditor;