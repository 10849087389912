import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Box,
  FormLabel,
  Input,
  FormControl,
  HStack,
  Collapse,
  Icon,
  InputRightAddon,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  ScaleFade,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiPlus } from 'react-icons/fi';
import { campaignPlatforms } from '../../components/Constants/campaigns';
import { useForm } from 'react-hook-form';
import { useCreateCampaignMutation } from '../../api/services/campaigns';
import { useSelector } from 'react-redux';
import { useFolder } from './Contexts/FolderContext';

const Create = ({ disclosure = useDisclosure() }) => {
  const { setFolder, folder } = useFolder();
  const currentWorkspace = useSelector((state) => state.user.currentWorkspace);

  const toast = useToast();
  const { register, handleSubmit, setValue, reset, resetField, formState: { errors }, clearErrors } = useForm();
  const [platform, setplatform] = useState('');

  const platformDisclosure = useDisclosure();
  const [create, { isLoading, isSuccess, isError }] = useCreateCampaignMutation();

  const onSubmit = (data) => {
    data.platform = platform;
    data.workspace_id = currentWorkspace?.id;

    create(data);
  };

  useEffect(() => {
    if (!disclosure.isOpen) {
      reset();
      setFolder(null);
        setplatform(''); // Reset the platform state
    } else {
      setValue('name', folder?.name);
    }
  }, [disclosure.isOpen])

  useEffect(() => {
    setValue('platform', campaignPlatforms.find((item) => item.value == platform)?.label);
  }, [platform]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Campaign created.',
        description: 'Campaign has been created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      disclosure.onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <Button size={'sm'} onClick={disclosure.onOpen} leftIcon={<Icon as={FiPlus} />}>Create Campaign</Button>

      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={0}>
            <Box p={5}>
              {folder == null && <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Create a new campaign</Text>}
              {folder != null && <Text borderBottom={'solid 1px'} borderColor={'gray.300'} pb={2}>Create a new channel</Text>}
            </Box>
            <ModalCloseButton mt={2} />
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>

              {isError && <ScaleFade initialScale={0.9} in={isError}>
                <Alert rounded={'md'} mb={5} fontSize={'sm'} status='error'>
                  <AlertIcon />
                  Campaign could not be created.
                </Alert>
              </ScaleFade>}

              <FormControl isInvalid={errors.name}>
                <FormLabel>{folder == null ? 'Campaign' : 'Channel'}  Name</FormLabel>
                <Input {...register('name', { required: true })} type='text' placeholder='Campaign Name..' />
                <FormErrorMessage>{errors.name && 'Campaign name is required field.'}</FormErrorMessage>
              </FormControl>

              {folder != null && <Input type='hidden' {...register('folder_id', { valueAsNumber: true })} value={folder.id} />}

              <FormControl mt={5} isInvalid={errors.platform}>
                <FormLabel>Advertising Channel</FormLabel>

                <InputGroup alignItems={'center'} cursor={'pointer'} onClick={platformDisclosure.onToggle}>
                  <Input {...register('platform', { required: true })} cursor={'pointer'} type='text' readOnly placeholder='Choose advertising channel' />
                  <InputRightElement p={5}>
                    {platformDisclosure.isOpen ? <FiChevronUp /> : <FiChevronDown />}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.platform && 'Choosing platform is required.'}</FormErrorMessage>
              </FormControl>

              <Collapse in={platformDisclosure.isOpen} animateOpacity>
                <Box my={5} overflow={'visible'} borderRadius={'md'} border={'solid 1px'} borderColor={'brand.500'} boxShadow={'lg'}>
                  {campaignPlatforms.map((item, index) => {
                    return (
                      <HStack key={index} onClick={item.enabled ? () => { setplatform(item.value); clearErrors('platform'); platformDisclosure.onClose(); } : () => { }} cursor={item.enabled ? 'pointer' : 'not-allowed'} _hover={{ color: 'brand.500' }} color={platform == item.value ? 'brand.500' : 'gray.500'} borderBottom={index == 8 ? '' : 'solid 1px'} borderColor={'gray.200'} gap={3} p={4}>
                        <Icon as={item.icon} fontSize={'xl'} />
                        <Text fontSize={'sm'}>{item.label}</Text>
                      </HStack>
                    )
                  })}
                </Box>
              </Collapse>
            </ModalBody>

            <ModalFooter gap={3} justifyContent={'flex-start'}>
              <Button onClick={disclosure.onClose} variant='outline'>Cancel</Button>
              <Button colorScheme='primary' mr={3} type='submit'>
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Create;