import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    fontSize: 'sm',
    borderRadius: 'md',
    paddingY: 2,
    h: 'auto'
  },
  addon: {
    fontSize: 'sm',
    w: 12
  }
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle
})