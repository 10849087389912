import { Card, Text, Box, Image, CardHeader, HStack, Avatar, VStack, Icon, CardBody, Button, Divider, Spacer, Flex } from "@chakra-ui/react";
import React from "react";
import { FiMoreHorizontal, FiThumbsUp, FiMessageCircle, FiShare } from "react-icons/fi";
import LinkedinBlankImage from '../../../../assets/images/linkedin_empty_image.png';
import Like from '../../../../assets/icons/linkedin/like.svg';
import Comment from '../../../../assets/icons/linkedin/comment.svg';
import Share from '../../../../assets/icons/linkedin/share.svg';
import LikeCount from '../../../../assets/icons/linkedin/like_count.svg';
import { linkedinCallToActions } from "../../../../components/Constants/linkedin_call_to_actions";

const SingleImageVideo = ({ ad }) => {
  return (
    <Card maxW={'sm'} borderRadius={0} boxShadow={'sm'}>
      <CardHeader>
        <HStack justifyContent={'space-between'}>
          <HStack spacing={5}>
            <Avatar size={'md'} bg={'gray.300'} src={ad.linkedinLogo?.fileUrl} icon={<></>} name='' borderRadius={2} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontSize={'sm'} fontWeight={'bold'}>{ad.linkedinPageName || 'Account Name'}</Text>
              <Text fontSize={'xs'} color={'gray.500'}>{'23,000 followers'}</Text>
              <VStack color={'gray.500'} fontWeight={400} fontSize={'xs'}>
                <Text>Promoted</Text>
              </VStack>
            </VStack>
          </HStack>
          <Icon as={FiMoreHorizontal} color={'gray.500'} boxSize={8} />
        </HStack>
      </CardHeader>

      <CardBody fontSize={'sm'} p={0}>
        <Text p={4} whiteSpace={'pre-wrap'} color={'gray.700'}>{(ad.introductoryText || 'Post introductory content...')?.truncate(150)}</Text>

        {ad.asset == null && <Image w={'full'} src={LinkedinBlankImage} />}

        {ad.asset?.assetType == 'image' && <Box maxH={'400px'} overflow={'hidden'}>
          <Image w={'full'} src={ad.asset?.fileUrl} />
        </Box>}
        {ad.asset?.assetType == 'video' && !ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <video key={ad.asset?.uuid} muted width={'100%'} height="auto" loop autoPlay>
            <source src={ad.asset?.fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>}
        {ad.asset?.assetType == 'video' && ad.asset?.fileUrl.includes('youtube') && <Box maxH={'400px'} overflow={'hidden'}>
          <iframe width="100%"
            height="315"
            src={ad.asset?.fileUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Box>}

        <HStack noOfLines={6} bg={'gray.100'} p={3}>
          <Flex justifyContent="space-between" alignItems={'center'}>
            <Box w={'100%'}>
              <Text fontSize={'md'} fontWeight={'bold'}>{(ad.headline || 'Headline')?.truncate(70)}</Text>
              <Text fontSize={'xs'} color={'gray.500'}>
                {(ad.destinationUrl && ad.destinationUrl.length > 50) ? new URL(ad.destinationUrl).hostname : (ad.destinationUrl || 'https://app.whatcampaign.com/')}
              </Text>
            </Box>
            {ad.callToAction && <Button minW={'75px'} h={'34px'} px={4} fontSize={'14px'} borderRadius={'full'} fontWeight={'bold'} variant={'outline'} borderColor={'linkedin.500'} color={'linkedin.500'} _hover={{ bg: 'linkedin.500', color: 'white' }}>
              {linkedinCallToActions.find((c) => c.value == ad.callToAction)?.label || 'CTA'}
            </Button>}
          </Flex>
        </HStack>
        <HStack spacing={2} ml={3} mt={3}>
          <Image w={'18px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={LikeCount} />
          <Text fontSize={'xs'} color={"#666666"}>100</Text>
          <Text fontSize={'xs'} color={"#666666"}>·</Text>
          <Text fontSize={'xs'} color={"#666666"}>3 comments</Text>
        </HStack>
        <Divider mt={3} borderColor={"#CED0D4"}></Divider>
        <HStack p={7} py={2} justifyContent={'space-between'} spacing={6}>
          <VStack spacing={1} alignItems="center">
            <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Like} />
            <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Like</Text>
          </VStack>
          <VStack spacing={1} alignItems="center">
            <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Comment} />
            <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Comment</Text>
          </VStack>
          <VStack alignItems="center">
            <Image w={'22px'} filter={'drop-shadow(0px 0.393px 4.764px rgba(0, 0, 0, 0.10))'} src={Share} />
            <Text fontSize={'sm'} textColor={'#65676B'} fontWeight={600}>Share</Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  )
};
export default SingleImageVideo;