export const tiktokCallToActions = [
  { value: "APPLY_NOW", label: "Apply now" },
  { value: "BOOK_NOW", label: "Book now" },
  { value: "CALL_NOW", label: "Call now" },
  { value: "CONTACT_US", label: "Contact us" },
  { value: "DOWNLOAD_NOW", label: "Download" },
  { value: "EXPERIENCE_NOW", label: "Experience now" },
  { value: "GET_QUOTE", label: "Get quote" },
  { value: "GET_SHOWTIMES", label: "Get showtimes" },
  { value: "GET_TICKETS_NOW", label: "Get tickets now" },
  { value: "INSTALL_NOW", label: "Install now" },
  { value: "INTERESTED", label: "Interested" },
  { value: "LEARN_MORE", label: "Learn more" },
  { value: "LISTEN_NOW", label: "Listen now" },
  { value: "ORDER_NOW", label: "Order now" },
  { value: "PLAY_GAME", label: "Play game" },
  { value: "PREORDER_NOW", label: "Pre-order now" },
  { value: "READ_MORE", label: "Read more" },
  { value: "SEND_MESSAGE", label: "Send message" },
  { value: "SHOP_NOW", label: "Shop now" },
  { value: "SIGN_UP", label: "Sign up" },
  { value: "SUBSCRIBE", label: "Subscribe" },
  { value: "VIEW_NOW", label: "View now" },
  { value: "VIEW_PROFILE", label: "View profile (Only supported in ads with the advertising objective ENGAGEMENT)" },
  { value: "VISIT_STORE", label: "Visit store" },
  { value: "WATCH_LIVE", label: "Watch LIVE (Only supported in LIVE ads)" },
  { value: "WATCH_NOW", label: "Watch now" },
  { value: "JOIN_THIS_HASHTAG", label: "Join this hashtag (Only supported in R&F ads)" },
  { value: "SHOOT_WITH_THIS_EFFECT", label: "Shoot with this effect (Only supported in R&F ads)" },
  { value: "VIEW_VIDEO_WITH_THIS_EFFECT", label: "View video with this effect (Only supported in R&F ads)" }
];