import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Stack,
  Divider,
  Text,
  Flex,
  useToast
} from '@chakra-ui/react';
import ChooseTemplate from './ChooseTemplate';
import CustomerAcquisition from './CustomerAcquisition';
import Storytelling from './Storytelling';
import Storefront from './Storefront';
import SellProducts from './SellProducts';
import { FiArrowLeft } from "react-icons/fi";

function InstantExperience({ isOpen, onClose, register, setValue, getValues, control, clearInstantExperienceValues, availableTemplates }) {
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [template, setTemplate] = useState(getValues('meta_instant_experience.instant_experience_type') || '');
  const [missingFields, setMissingFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);

  useEffect(() => {
    if (getValues('meta_instant_experience.instant_experience_type')) {
      setStep(2);
    }
  }, [isOpen]);

  const handleSave = () => {
    const tempMissingFields = requiredFields.filter(field => !getValues(field) || getValues(field) == []);
    setMissingFields(tempMissingFields);

    if (tempMissingFields?.length > 0) {
      toast({
        title: "Missing Fields",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }

    if (tempMissingFields.length === 0) {
      setStep(1);
      setMissingFields([])
      onClose();
    }
  };

  const handleCancel = () => {
    setStep(1);
    setMissingFields([])
    onClose();
  };

  const renderStepContent = () => {
    if (step === 1) {
      return <ChooseTemplate setTemplate={setTemplate} availableTemplates={availableTemplates} selectedTemplate={template} />;
    } else if (step === 2) {
      switch (template) {
        case 'customerAcquisition':
        case 'customer_acquisition':
          return <CustomerAcquisition
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            missingFields={missingFields}
            setRequiredFields={setRequiredFields} />;
        case 'storytelling':
          return <Storytelling
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            missingFields={missingFields}
            setRequiredFields={setRequiredFields}
          />;
        case 'storefront':
          return <Storefront
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            missingFields={missingFields}
            setRequiredFields={setRequiredFields}
          />;
        case 'sell_products':
          return <SellProducts
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            missingFields={missingFields}
            setRequiredFields={setRequiredFields}
          />;
        default:
          return null;
      }
    }
  };

  return (
    <Modal onClose={handleCancel} isOpen={isOpen} closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent width={step == 1 ? "40%" : "70%"}>
        <ModalCloseButton />
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex alignItems="center">
              {step === 2 && (
                <Button ml={-4} leftIcon={<FiArrowLeft color="gray.700" />} variant="ghost" onClick={() => setStep(1)}>
                </Button>
              )}
              <Text mb='1'>{step === 1 ? 'Choose a template' : 'Create an Instant Experience'}</Text>
            </Flex>
          </Flex>
          <Divider />
        </ModalHeader>
        <ModalBody>
          {renderStepContent()}
        </ModalBody>
        <ModalFooter>
          <Button borderColor={'.gray-200'} minW={'100px'} mr={3} colorScheme='gray' variant='outline' onClick={handleCancel}>
            Cancel
          </Button>
          {step === 1 && (
            <Button minW={'100px'} isDisabled={template === ''} colorScheme='gray' onClick={() => setStep(2)}>
              Next
            </Button>
          )}
          {step === 2 && (
            <Button minW={'100px'} colorScheme='gray' onClick={handleSave}>
              Save
            </Button>
          )}

        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InstantExperience;