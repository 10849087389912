import React from "react";

import { Center, HStack, Icon, Text, useSteps } from "@chakra-ui/react";
import DiscoverAd from "./Discover/DiscoverAd";

const Discover = ({ variations, displayType }) => {

  return (
    <>
      {displayType == 'mobile' && <>
        <DiscoverAd variation={variations[0]} />
      </>}

      {displayType == 'desktop' && <>
        <Text textAlign={'center'} mt={20}>Discover ads are not available on desktop devices.</Text>
      </>}
    </>
  )
};

export default Discover;