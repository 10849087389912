import { Button, Icon, Box, Text, HStack, Select, Card, Stack, CardBody, CardHeader, Input, FormControl, FormLabel, VStack, Textarea, FormHelperText, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiHelpCircle, FiPlus } from "react-icons/fi";
import { useFieldArray } from "react-hook-form";
import { snapchatCallToActions } from "../../../../../components/Constants/snapchat_call_to_actions";
import { useNavigate, useParams } from "react-router-dom";
import Slide from "./Slide";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import flattenObject from "../../../../../helpers/objectHelper";
import AssetsLibraryButton from "../../../../AssetsLibrary/Manager/AssetsLibraryButton";
import { collectionThumbnails } from "../../../../../components/Constants/collectionThumbnails";
import AssetsLibraryPreview from "../../../../AssetsLibrary/Preview/AssetsLibraryPreview";

const CollectionCommonEditor = ({ ad, control, getValues, setValue, onFormSubmit, isLoading, register, handleSubmit, snapchatLogoState, assetsState }) => {
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [openIndex, setOpenIndex] = useState(null);

    const [snapchatLogo, setSnapchatLogo] = snapchatLogoState;
    const [assets, setAssets] = assetsState;

    const brandName = getValues('brand_name');
    const headline = getValues('headline');
    const thumbnailsCount = getValues('collection_thumbnails_count');

    const handleToggleSlide = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "snapchat_ad_collection_items"
    });

    React.useEffect(() => {

        if (thumbnailsCount === undefined) {
            setValue('collection_thumbnails_count', 3);
        }
        const currentCount = fields.length;
        const newCount = Number(thumbnailsCount);

        if (newCount > currentCount) {
            for (let i = 0; i < newCount - currentCount; i++) {
                append({});
            }
        } else if (newCount < currentCount) {
            for (let i = 0; i < currentCount - newCount; i++) {
                remove(currentCount - i - 1);
            }
        }
    }, [thumbnailsCount, append, remove, fields.length]);


    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(getValues('snapchat_ad_collection_items'));

        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setValue('snapchat_ad_collection_items', items);
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('asset_id', assets[0]?.id);
        formData.append('snapchat_logo_asset_id', snapchatLogo[0]?.id);

        const flObject = flattenObject(data);
        Object.keys(flObject).forEach((key) => {
            if (flObject[key] == null)
                return;

            const modifiedKey = key.replace(/\[\d+\]/g, '[]');
            formData.append(modifiedKey, flObject[key]);
        });

        onFormSubmit(formData);
    }
    return (
        <>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={5} >

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Identity</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                            <Text fontSize={'xs'} color={'gray.400'}>Please provide your profile photo to display an accurate preview of the ad, including your page/profile name.</Text>
                        </CardHeader>
                        <CardBody>
                            <FormControl>
                                <FormLabel>Public Profile</FormLabel>
                                <HStack spacing={5}>
                                    <AssetsLibraryButton assets={snapchatLogo} setAssets={setSnapchatLogo} type={'image'} rounded />
                                    <Input w={'full'} {...register('snapchat_account_name')} placeholder={'Account name'} />
                                </HStack>
                            </FormControl>
                        </CardBody>
                    </Card>


                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Content</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                        </CardHeader>
                        <CardBody>

                            <VStack spacing={5}>
                                <FormControl>
                                    <FormLabel>Brand Name (Optional)</FormLabel>
                                    <Input {...register('brand_name')} placeholder={'Add brand name here...'} maxLength={32} />
                                    <FormHelperText color={(getValues('brand_name')?.length || 0) > 32 ? 'red' : '#96999F'} mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>{getValues('brand_name')?.length || 0} / 32</FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Headline</FormLabel>
                                    <Input {...register('headline')} placeholder={'Add headline here...'} maxLength={34} />
                                    <FormHelperText mt={3} fontSize={'xs'} textAlign={'right'} w={'full'}>
                                        {(headline?.trim()?.toLowerCase() || '') === (brandName?.trim()?.toLowerCase() || '') ? <Text color={'#96999F'}>Headline cannot be the same as Brand Name</Text> : <Text color={'#96999F'}>{`${getValues('headline')?.length || 0} / 34`}</Text>}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl>
                                    <FormLabel> Call To Action</FormLabel>
                                    <Select {...register('call_to_action')}>
                                        <option value={''}>No Button</option>
                                        {snapchatCallToActions.map((cta, index) => (
                                            <option value={cta.value} key={index}>{cta.label}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl mb={5}>
                                    <FormLabel>Collection Thumbnails</FormLabel>
                                    <Select {...register('collection_thumbnails_count')}>
                                        {collectionThumbnails.map((ct, index) => (
                                            <option value={ct.value} key={index}>{ct.value}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </VStack>
                            <AssetsLibraryButton assets={assets} setAssets={setAssets} types={['all']} />
                            <Stack w={'full'} flexWrap={'wrap'} direction={'row'}>
                                <AssetsLibraryPreview assets={assets} setAssets={setAssets} />
                            </Stack>
                        </CardBody>
                    </Card>

                    <Card w={'full'}>
                        <CardHeader>
                            <HStack>
                                <Text>Collection details</Text>
                                <Icon as={FiHelpCircle} />
                            </HStack>
                        </CardHeader>
                        <CardBody>
                            <Text mb={5} fontSize={'sm'}>Select the media, text and destination for your ad. You can also customise your media and text for each placement.</Text>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="characters">
                                    {(provided) => (
                                        <Box className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                            {fields.map((item, index) => {
                                                return (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided) => (
                                                            <Slide
                                                                isOpen={index === openIndex}
                                                                onToggle={() => handleToggleSlide(index)}
                                                                setValue={setValue} getValues={getValues}
                                                                register={register} index={index}
                                                                provided={provided} item={item}
                                                                remove={() => remove(index)} />
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </CardBody>
                    </Card>


                    <HStack w={'full'} justifyContent={'flex-start'}>
                        <Button onClick={() => navigate(`/campaigns/${uuid}`)} variant={'outline'}>Cancel</Button>
                        <Button isDisabled={isLoading} type={'submit'}>{ad == null ? 'Create' : 'Update'}</Button>
                    </HStack>
                </VStack>
            </form>
        </>
    )
}

export default CollectionCommonEditor;