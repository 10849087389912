import React from "react";

import { Center, HStack, Icon, IconButton, Image, useSteps, Text, Avatar, SkeletonText, Divider, VStack } from "@chakra-ui/react";
import { FiBookmark, FiChevronLeft, FiChevronRight, FiMenu, FiMoreVertical, FiStar } from "react-icons/fi";
import Phone from "../YouTube/Phone";

const MobileClosed = ({ variation, activeStep }) => {

  return (
    <>
  

      <Phone p={3}>
        <HStack justifyContent={'space-between'} px={3} py={1} w={'full'} rounded={'md'} border={'solid 1px'} borderColor={'gray.200'} boxShadow={'base'}>
          <HStack>
            <Icon as={FiMenu} />
            <Text fontSize={'sm'}>Search in mail</Text>
          </HStack>
          <Avatar size={'xs'} name={' '} bg={'gray.200'} />
        </HStack>

        <Text mt={3} fontWeight={'semibold'} fontSize={'sm'} color={'gray.700'}>PROMOTIONS</Text>

        {activeStep == 0 && <HStack mt={5} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Avatar size={'sm'} src={variation.logoUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <HStack>
                <Text fontSize={'x-small'}>Sponsored ·</Text>
                <Text fontSize={'xs'}>{variation.businessName}</Text>
              </HStack>
              <Text fontSize={'xs'}>{variation.headline?.truncate(30)}</Text>
              <Text fontSize={'x-small'}>{variation.description?.truncate(90)}</Text>
            </VStack>
          </HStack>

          <VStack>
            <Icon as={FiMoreVertical} />
            <Icon as={FiStar} />
          </VStack>
        </HStack>}

        {activeStep == 1 && <HStack border={'solid 1px'} borderRadius={'2xl'} p={2} borderColor={'gray.200'} boxShadow={'base'} mt={5} justifyContent={'space-between'} w={'full'}>
          <HStack>
            <Image borderRadius={'lg'} w={'5rem'} src={variation.imageUrl} />
            <VStack alignItems={'flex-start'} spacing={0}>
              <Text fontWeight={'bold'} fontSize={'x-small'}>Ad</Text>

              <HStack>
                <Avatar w={3} h={3} src={variation.logoUrl} />
                <Text fontSize={'xs'}>{variation.businessName}</Text>
              </HStack>
              <Text fontSize={'xs'}>{variation.headline?.truncate(30)}</Text>
              <Text fontSize={'x-small'}>{variation.description?.truncate(90)}</Text>
            </VStack>
          </HStack>

          <VStack>
            <Icon as={FiMoreVertical} />
            <Icon as={FiStar} />
          </VStack>
        </HStack>}

        {[1, 2, 3, 4, 5].map((i) => (<>
          <HStack mt={4} w={'full'}>
            <Avatar size={'xs'} name={' '} bg={'gray.200'} />
            <SkeletonText w={'full'} startColor="gray.400" endColor="gray.400" noOfLines={2} />
          </HStack>

          <Divider my={3} />
        </>))}

      </Phone>
    </>
  )
};

export default MobileClosed;