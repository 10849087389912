import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetClientQuery } from "../../api/services/shared/clients";


export const useClientToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('token');

  return { token };
};